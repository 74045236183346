<div class="popup" [class.is-active]="visible && !search">
    <div class="popup__inner">
        <button class="popup__close" (click)="closePopup()">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.0312 17.0921L12.9599 12.0208L18.0312 6.94953C18.2905 6.69019 18.2905 6.26975 18.0312 6.01041C17.7718 5.75107 17.3514 5.75107 17.092 6.01041L12.0208 11.0817L6.94947 6.01041C6.69013 5.75107 6.26968 5.75107 6.01034 6.01041C5.751 6.26975 5.751 6.69019 6.01034 6.94953L11.0816 12.0208L6.01034 17.0921C5.751 17.3514 5.751 17.7719 6.01034 18.0312C6.26968 18.2906 6.69013 18.2906 6.94947 18.0312L12.0208 12.9599L17.092 18.0312C17.3514 18.2906 17.7718 18.2906 18.0312 18.0312C18.2905 17.7719 18.2905 17.3514 18.0312 17.0921Z" fill="white"/>
            </svg>
        </button>

        <div class="popup__slider">
            <app-article-slider [like]="like" [data]="data" (changedSlide)="onSlideChange($event)" [free]="free" [repost]="repost"></app-article-slider>
        </div>

        <div class="popup__footer">
            <app-article-footer [like]="like" (likeToggled)="toggleLike($event)" [cost]="cost" [free]="free" (shownDetails)="showDetails($event)" (isReporst)="isRepost($event)" [repost]="repost"></app-article-footer>
        </div>
    </div>
</div>

<app-article-details (likeEmitted)="toggleLike($event)" [data]="data" [activePublication]="activeSlide"></app-article-details>
