/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-bottom.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../pages/global-library/global-library.component";
import * as i4 from "../../service/global-library/loadpreview.service";
import * as i5 from "@angular/router";
import * as i6 from "../../service/session/back.service";
import * as i7 from "../../service/content/new-content.service";
import * as i8 from "../../service/global-library/globalLibrary.service";
import * as i9 from "./menu-bottom.component";
import * as i10 from "../../service/phone.service";
import * as i11 from "../../service/menu/menuBottomAddContent.service";
import * as i12 from "../popup/popup.component";
var styles_MenuBottomComponent = [i0.styles];
var RenderType_MenuBottomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuBottomComponent, data: {} });
export { RenderType_MenuBottomComponent as RenderType_MenuBottomComponent };
function View_MenuBottomComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "menu__icon"], ["height", "20"], ["width", "17"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#pin"]], null, null, null, null, null))], null, null); }
function View_MenuBottomComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "menu__icon"], ["height", "20"], ["width", "18"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#audio"]], null, null, null, null, null))], null, null); }
function View_MenuBottomComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "menu__icon"], ["height", "20"], ["width", "21"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#gallery"]], null, null, null, null, null))], null, null); }
function View_MenuBottomComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "menu__icon"], ["height", "20"], ["width", "20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#camera"]], null, null, null, null, null))], null, null); }
function View_MenuBottomComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "a", [["class", "menu__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCommand(_v.context.$implicit.cmd) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuBottomComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuBottomComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuBottomComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuBottomComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.icon === "pin"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.icon === "audio"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.icon === "gallery"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_v.context.$implicit.icon === "camera"); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_4); }); }
export function View_MenuBottomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "menu js-publication-menu"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuBottomComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.list; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 0, 0, currVal_0); }); }
export function View_MenuBottomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-menu-bottom", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).clickout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_MenuBottomComponent_0, RenderType_MenuBottomComponent)), i1.ɵprd(4608, null, i3.GlobalLibraryComponent, i3.GlobalLibraryComponent, [i4.LoadPreviewService, i5.ActivatedRoute, i1.NgZone, i6.BackService, i7.NewContentService, i8.GlobalLibraryService, i5.Router]), i1.ɵdid(2, 114688, null, 0, i9.MenuBottomComponent, [i1.NgZone, i10.PhoneService, i5.Router, i8.GlobalLibraryService, i11.MenuBottomAddContentService, i7.NewContentService, i1.ElementRef, i12.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var MenuBottomComponentNgFactory = i1.ɵccf("app-menu-bottom", i9.MenuBottomComponent, View_MenuBottomComponent_Host_0, {}, {}, []);
export { MenuBottomComponentNgFactory as MenuBottomComponentNgFactory };
