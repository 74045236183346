import {
    Component,
    ElementRef,
    EventEmitter,
    Input, NgZone,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {PopupComponent} from "../../../shared/popup/popup.component";
import {TypesService} from "../../../service/content/types.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PhoneService} from "../../../service/phone.service";
import {ActivatedRoute} from "@angular/router";


import {PROJECT_CONFIG} from "../../../config/project";

@Component({
    selector: 'app-global-library-edit',
    templateUrl: './global-library-edit.component.html',
    styleUrls: ['./global-library-edit.component.scss']
})
export class GlobalLibraryEditComponent implements OnInit {

    total;

    createContentForm: FormGroup;

    @Input() data;
    @Input() editable;
    @Input() publication;
    @Input() validation;
    @Input() categoriesList;

    @ViewChild("dropdown", {static: false}) dropdown: ElementRef;
    @ViewChild("tagsInput", {static: false}) tagsInput: ElementRef;
    @ViewChild("tokensInput", {static: false}) tokensInput: ElementRef;
    @ViewChildren('categories') categories: QueryList<ElementRef>;

    searchTags = false;

    chooseCategory = false;

    defaultAvatar = '';
    defaultImage = PROJECT_CONFIG.defaultImage;

    // selectedTags = [
    //     '#tagtag',
    //     '#tag',
    //     '#tagtag',
    //     '#tag'
    // ];

    searchVariants = [
        {
            title: 'summer',
            quantity: '1 million'
        },
        {
            title: 'sea',
            quantity: '3,990'
        },
        {
            title: 'like',
            quantity: '23'
        },
        {
            title: 'youtube',
            quantity: '4.5 million'
        },
        {
            title: 'ta',
            quantity: '1.3 million'
        },
        {
            title: 'take',
            quantity: '10'
        },
        {
            title: 'table',
            quantity: '2,400'
        },
        {
            title: 'tane',
            quantity: '4 million'
        },
        {
            title: 'taxi',
            quantity: '3,700'
        },
    ];


    constructor(private popupComponent: PopupComponent,
                private typesService: TypesService,
                private phoneService:PhoneService,
                private route: ActivatedRoute,
                private ngZone: NgZone,
    ) {

    }

    ngOnInit() {
        this.phoneService.postAvatarUploded.subscribe((value: any) => this.changeAvatar(value));
    }

    ngOnChanges() {
        this.ngZone.run( () => {
            this.defaultAvatar = PROJECT_CONFIG.defaultAvatar;

            if(this.publication) {
                if (this.publication.avatar_link) {
                    if (this.publication.avatar_link != '') {
                        this.defaultAvatar = this.publication.avatar_link;
                    }
                }
            }
        });
    }

    changeAvatar(data){
        if(data){
            this.ngZone.run( () => {
                this.defaultAvatar = data.avatar_link;
            });
        }
    }

    getAvatar(){
        return this.defaultAvatar;
    }

    doesFileExist(urlToFile) {
        const xhr = new XMLHttpRequest();
        xhr.open('HEAD', urlToFile, false);
        xhr.send();

        if (xhr.status == 404) {
            //console.log("File doesn't exist");
            return false;
        } else {
            //console.log("File exists");
            return true;
        }
    }

    formatLabel(value: number) {
        return value + '%';
    }

    onSearch($event) {
        if ($event.data === ' ') {
            this.tagsInput.nativeElement.value = this.tagsInput.nativeElement.value.trim();
        }
        let searchValue = $event.target.value;

        const dropdownItems = this.dropdown.nativeElement.querySelectorAll('li');

        for (let i = 0; i < dropdownItems.length; i++) {
           if (dropdownItems[i].title.indexOf(searchValue) > -1) {
               dropdownItems[i].style.display = '';
           } else {
               dropdownItems[i].style.display = 'none';
           }
        }
    }

    openSearchTags() {
        this.searchTags = true;
    }

    closeSearchTags() {
        this.searchTags = false;
    }

    selectTag($event) {
        const selectedTag = $event.currentTarget.title.toString();
        let selectedIndex = 0;

        const dropdownItems = this.dropdown.nativeElement.querySelectorAll('li');

        for (let i = 0; i < dropdownItems.length; i++) {
            if (dropdownItems[i].title === selectedTag) {
                selectedIndex = i;
            }
        }

        dropdownItems[selectedIndex].style.display = 'none';

        if (!this.publication.tags) {
            this.publication.tags = [];
        }
        this.publication.tags.push('#' + selectedTag);
    }

    addTag(e) {
        e.preventDefault();
        const dropdownItems = this.dropdown.nativeElement.querySelectorAll('li');
        const newTag = this.tagsInput.nativeElement.value;
        this.tagsInput.nativeElement.value = '';

        dropdownItems.forEach((el) => {
            el.style.display = '';
        });

        if (!this.publication.tags) {
            this.publication.tags = [];
        }
        this.publication.tags.push('#' + newTag);
    }

    changeCost() {
        if (this.publication.payment_type) {
            this.publication.payment_type = 0;
        } else {
            this.publication.payment_type = 1;
        }

    }

    toggleCategories() {
        this.chooseCategory = !this.chooseCategory;
    }

    updatessCategories($event) {
        const category  = $event.target.attributes.element.value;
        const checked = $event.target.checked;
        if (!this.publication.categories) {
            this.publication.categories = [];
        }
        if (checked) {
            this.publication.categories.push(category);
        } else  {
            this.publication.categories.splice(this.publication.categories.indexOf(category), 1);
        }
    }

    isVideo(){
        return this.typesService.getVideo();
    }

    addAvatar(){
        this.phoneService.uploadAvatarToContent(this.publication.id);
    }

    changeTokens(){
        const tokens = this.tokensInput.nativeElement.value;

        this.publication.tokens = tokens;
    }

    checkCost(){
        if(this.publication.payment_type){
            return false;
        }

        return true;
    }
}
