/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./radio.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./radio.component";
var styles_RadioComponent = [i0.styles];
var RenderType_RadioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RadioComponent, data: {} });
export { RenderType_RadioComponent as RenderType_RadioComponent };
function View_RadioComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "radio__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "radio__input"], ["type", "radio"]], [[8, "value", 0], [8, "name", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "radio__label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.config.name, ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_2); }); }
export function View_RadioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "radio"]], [[2, "is-hidden", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "radio__head"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleVariants() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["class", "radio__arrow"], ["height", "10"], ["width", "18"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#thick-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "radio__body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RadioComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.config.variants; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ishiden; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.config.title; _ck(_v, 2, 0, currVal_1); }); }
export function View_RadioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-radio", [], null, null, null, View_RadioComponent_0, RenderType_RadioComponent)), i1.ɵdid(1, 114688, null, 0, i3.RadioComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RadioComponentNgFactory = i1.ɵccf("app-radio", i3.RadioComponent, View_RadioComponent_Host_0, { filter: "filter", config: "config" }, { checkedValue: "checkedValue" }, []);
export { RadioComponentNgFactory as RadioComponentNgFactory };
