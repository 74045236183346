<div class="page content" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menuClose()">Collection</app-header>
    <div class="page__inner">
        <div class="page__collection">

            <ul  class="collection-list">

<!--                <swiper (slideChange)="onIndexChange()" [config]="config" [(index)]="index">-->
<!--                <swiper (slidePrevTransitionEnd)="slidePrev()" (slideNextTransitionEnd)="slideNext()" [config]="config">-->

                    <div  *ngFor="let item of itemsSlider; let i = index">
                        <div class="collection__item">
                            <div *ngIf="getType(item) === postTypes['video']">
                                <app-video-publication [publication]="item"></app-video-publication>
                            </div>

                            <div *ngIf="getType(item) === postTypes['image']">
                                <app-image-publication [publication]="item"></app-image-publication>
                            </div>'

                            <div *ngIf="getType(item) === postTypes['audio']">
                                <app-sound-publication [menu]="menu" (menuShowed)="menuShowed($event)" [publication]="item"></app-sound-publication>
                            </div>

                        </div>
                    </div>
<!--                </swiper>-->
            </ul>
        </div>
    </div>
</div>

<!--<div class="menu js-publication-menu" [class.is-active]="menu">-->
<!--    <a class="menu__item" href="#">-->
<!--        <svg class="menu__icon" width="18" height="18">-->
<!--            <use xlink:href="../../../../assets/images/sprite.svg#edit"></use>-->
<!--        </svg>-->
<!--        Edit-->
<!--    </a>-->
<!--    <a class="menu__item" href="#">-->
<!--        <svg class="menu__icon" width="18" height="18">-->
<!--            <use xlink:href="../../../../../assets/images/sprite.svg#delete"></use>-->
<!--        </svg>-->

<!--        Delete-->
<!--    </a>-->
<!--</div>-->
