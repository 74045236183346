export class HomeArticles {
    articles: string;
    add: string;
}

export class HomeArticle {
    private _category: any;
    private _img: string;
    public _title: string;
    public _id: number;

    constructor(data) {
        this._category = data.style;
        this._img = data.avatar_link;
        this._title = data.title;
        this._id = data.id;
    }

    get category(): any {
        return this._category;
    }

    set category(value: any) {
        this._category = value;
    }

    get img(): string {
        return this._img;
    }

    set img(value: string) {
        this._img = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }
}
