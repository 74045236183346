import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

    @Input() navItems;
    @Output() clickAction = new EventEmitter();

    constructor() {
    }

    ngOnInit() {

    }

    clickHandler(popup) {
        this.clickAction.emit(popup);
    }
}
