/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./distributions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/header/header.component.ngfactory";
import * as i3 from "../../../shared/header/header.component";
import * as i4 from "../../../service/menu.service";
import * as i5 from "../../../shared/nav/nav.component.ngfactory";
import * as i6 from "../../../shared/nav/nav.component";
import * as i7 from "@angular/common";
import * as i8 from "./distributions-payouts/distributions-payouts.component.ngfactory";
import * as i9 from "./distributions-payouts/distributions-payouts.component";
import * as i10 from "../../../service/admin/distributions/distributions.service";
import * as i11 from "../../../service/admin/distributions/distributionsPayouts.service";
import * as i12 from "./distributions-ratio/distributions-ratio.component.ngfactory";
import * as i13 from "./distributions-ratio/distributions-ratio.component";
import * as i14 from "@angular/forms";
import * as i15 from "../../../shared/popup/popup.component";
import * as i16 from "../../../service/admin/distributions/distributionsRatio.service";
import * as i17 from "./distributions-snek/distributions-snek.component.ngfactory";
import * as i18 from "./distributions-snek/distributions-snek.component";
import * as i19 from "../../../service/admin/distributions/distributionsSnek.service";
import * as i20 from "./distributions.component";
var styles_DistributionsComponent = [i0.styles];
var RenderType_DistributionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DistributionsComponent, data: {} });
export { RenderType_DistributionsComponent as RenderType_DistributionsComponent };
function View_DistributionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "distributions content"]], [[2, "is-visible", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "menuAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("menuAction" === en)) {
        var pd_1 = (_co.menu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { menuActionCheck: [0, "menuActionCheck"], backActionCheck: [1, "backActionCheck"] }, { menuAction: "menuAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["Distributions"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "distributions__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "distributions__nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-nav", [], null, [[null, "clickAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickAction" === en)) {
        var pd_0 = (_co.showChild($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NavComponent_0, i5.RenderType_NavComponent)), i1.ɵdid(7, 114688, null, 0, i6.NavComponent, [], { navItems: [0, "navItems"] }, { clickAction: "clickAction" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.navItems; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 0, 0, currVal_0); }); }
export function View_DistributionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DistributionsComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-distributions-payouts", [], null, null, null, i8.View_DistributionsPayoutsComponent_0, i8.RenderType_DistributionsPayoutsComponent)), i1.ɵdid(3, 114688, null, 0, i9.DistributionsPayoutsComponent, [i4.MenuService, i10.DistributionsService, i11.DistributionsPayoutsService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-distributions-ratio", [], null, null, null, i12.View_DistributionsRatioComponent_0, i12.RenderType_DistributionsRatioComponent)), i1.ɵdid(5, 114688, null, 0, i13.DistributionsRatioComponent, [i14.FormBuilder, i4.MenuService, i10.DistributionsService, i15.PopupComponent, i16.DistributionsRatioService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-distributions-snek", [], null, null, null, i17.View_DistributionsSnekComponent_0, i17.RenderType_DistributionsSnekComponent)), i1.ɵdid(7, 114688, null, 0, i18.DistributionsSnekComponent, [i4.MenuService, i10.DistributionsService, i19.DistributionsSnekService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_DistributionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-distributions", [], null, null, null, View_DistributionsComponent_0, RenderType_DistributionsComponent)), i1.ɵdid(1, 114688, null, 0, i20.DistributionsComponent, [i4.MenuService, i10.DistributionsService, i19.DistributionsSnekService, i16.DistributionsRatioService, i11.DistributionsPayoutsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DistributionsComponentNgFactory = i1.ɵccf("app-distributions", i20.DistributionsComponent, View_DistributionsComponent_Host_0, {}, {}, []);
export { DistributionsComponentNgFactory as DistributionsComponentNgFactory };
