/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./policy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-perfect-scrollbar/ngx-perfect-scrollbar.ngfactory";
import * as i3 from "ngx-perfect-scrollbar";
import * as i4 from "./policy.component";
var styles_PolicyComponent = [i0.styles];
var RenderType_PolicyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PolicyComponent, data: {} });
export { RenderType_PolicyComponent as RenderType_PolicyComponent };
export function View_PolicyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "policy"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "policy__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms and Creative Content Policy"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "perfect-scrollbar", [["class", "scroll-container"], ["style", "height: 154px;"]], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i2.View_PerfectScrollbarComponent_0, i2.RenderType_PerfectScrollbarComponent)), i1.ɵdid(4, 507904, null, 0, i3.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "div", [["class", "policy__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pulvinar ultrices dictum. Curabitur pretium eu nulla vel convallis. Nam a sapien non velit sodales cursus vitae at velit. Donec Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pulvinar ultrices dictum. Curabitur pretium eu nulla vel convallis. Nam a sapien non velit sodales cursus vitae at velit. Donec"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "policy__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hidePolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.policy; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).autoPropagation; var currVal_2 = i1.ɵnov(_v, 4).scrollIndicators; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_PolicyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-policy", [], null, null, null, View_PolicyComponent_0, RenderType_PolicyComponent)), i1.ɵdid(1, 114688, null, 0, i4.PolicyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PolicyComponentNgFactory = i1.ɵccf("app-policy", i4.PolicyComponent, View_PolicyComponent_Host_0, { policy: "policy" }, { policyState: "policyState" }, []);
export { PolicyComponentNgFactory as PolicyComponentNgFactory };
