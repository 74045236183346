import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
// import {jQuery, $} from 'jquery';
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {ActivityItem} from "../../../models/activityItem";
import {ArticleSlide} from "../../../models/articleSlide";
import {POST_CATEGORIES_COLORS, POST_CATEGORIES_ID} from "../../../config/post_categories";
import {PROJECT_CONFIG} from "../../../config/project";
import {ArticleService} from "../../../service/article.service";

@Component({
    selector: 'app-article-slider',
    templateUrl: './article-slider.component.html',
    styleUrls: ['./article-slider.component.scss']
})
export class ArticleSliderComponent implements OnInit {

    @Input() repost: boolean;
    @Input() free: boolean;
    @Input() data: any;
    @Input() like: boolean;

    @Output() changedSlide = new EventEmitter();

    @ViewChild('slickModal', {static: false}) slickModal: SlickCarouselComponent;
    @ViewChild('slickModalBig', {static: false}) slickModalBig: SlickCarouselComponent;

    postCategoriesColors = POST_CATEGORIES_COLORS;
    postCategoriesIds = POST_CATEGORIES_ID;
    projectConfig = PROJECT_CONFIG;

    oldActiveSlide = 0;
    nextAdditionSlide;
    prevAdditionSlide;

    _trackBy(slide) {
        return slide.img;
    }

    // slides = [
    //     {
    //         img: "bg_1.jpg",
    //         id: 1,
    //         view: 1,
    //     },
    //     {
    //         img: "bg_2.jpg",
    //         id: 2,
    //         view: 2,
    //     },
    //     {
    //         img: "bg_3.jpg",
    //         id: 3,
    //         view: 3,
    //     },
    //     {
    //         img: "bg_4.jpg",
    //         id: 4,
    //         view: 4,
    //     },
    //     {
    //         img: "bg_5.jpg",
    //         id:5,
    //         view: 5,
    //     },
    //     {
    //         img: "bg_6.jpg",
    //         id: 6,
    //         view: 6,
    //     },
    //     {
    //         img: "bg_7.jpg",
    //         id: 7,
    //         view: 1,
    //     },
    //     {
    //         img: "bg_8.jpg",
    //         id:8,
    //         view: 2,
    //     },
    //     {
    //         img: "bg_9.jpg",
    //         id:9,
    //         view: 3,
    //     }
    // ];

    //slides = this.data.slides;
    slides = [];

    slideConfig = {
        slidesToShow: 3,
        variableWidth: true,
        nextArrow: false,
        prevArrow: false,
        focusOnSelect: true,
        asNavFor: '.container'};

    slideConfigBig = {
        slidesToShow: 1,
        nextArrow: false,
        prevArrow: false,
        // variableWidth: true,
        centerMode: true,
        centerPadding: '0px',
        asNavFor: '.carousel',
    };

    constructor(private elementRef: ElementRef,
                private articleService: ArticleService) {
        this.articleService.sliderCreated.subscribe((value: any) => this.changeVisibility(value));

    }

    ngOnInit() {
        //this.slides = this.data.slides;
        // this.slides = this.data
        // console.log(this.data)
    }

    ngAfterContentChecked() {
        //this.slides = this.data.slides;
        //
        // if (this.data) {
        //     //console.log(this.data)
        //     this.slides = this.data
        //     //console.log(this.slides)
        //     console.log(this.data)
        // }

    }
    changeVisibility(value) {
        setTimeout(() => {
            if (value && this.data.length) {
                this.slides = this.data;
                //console.log(this.data);
            }
        }, 0);


    }

    // addSlide() {
    //     this.slides.push({img: "http://placehold.it/350x150/777777", id: 9})
    // }
    //
    // removeSlide() {
    //     this.slides.length = this.slides.length - 1;
    // }

    slickInit(e) {
        // console.log('slick initialized');
    }

    breakpoint(e) {
        // console.log('breakpoint');
    }

    afterChange(e) {
        //console.log(e.currentSlide, this.oldActiveSlide);
        //this.slickModal.slickGoTo('3')
        const newActiveSlide = e.currentSlide;
        if (this.oldActiveSlide !== newActiveSlide) {
            if (this.oldActiveSlide < newActiveSlide) {
                if (newActiveSlide === 3) {

                    this.articleService.getNewSlides('next', this.slides[6].id).subscribe(data => {
                        if (data.status) {
                            //console.log(data.result.slides);
                            this.slides[0] = data.result.slides[0];
                            this.slides[1] = data.result.slides[1];
                            this.nextAdditionSlide = data.result.slides[2];
                        }
                    });
                    // this.slides[0].img = "bg_8.jpg";
                    // this.slides[0].id = 1200000;
                    // this.slides[0].view = 4;
                    //
                    // this.slides[1].img = "bg_8.jpg";
                    // this.slides[1].id = 120088;
                    // this.slides[1].view = 4;


                    // this.slickModal.unslick();
                    // this.slickModalBig.unslick();
                    // this.slickModal.config.initialSlide = newActiveSlide;
                    // this.slickModalBig.config.initialSlide = newActiveSlide;

                    setTimeout(() => {
                        this.slickModal.$instance.slick('refresh');
                        this.slickModalBig.$instance.slick('refresh')
                        // this.slickModal.initSlick();
                        // this.slickModalBig.initSlick();
                        //
                        // this.slickModal.$instance.slick('refresh')
                    }, 0)

                }
                else if (newActiveSlide === 4) {
                    this.slides[2] = this.nextAdditionSlide;
                    // this.slides[2].img = "bg_7.jpg";
                    // this.slides[2].id = 11000;
                    // this.slides[2].view = 5;
                    setTimeout(() => {
                        this.slickModal.$instance.slick('refresh');
                        this.slickModalBig.$instance.slick('refresh')
                    }, 0)
                }
                // console.log('next');
            } else {
                //console.log(newActiveSlide)
                if (newActiveSlide === 3) {
                    this.articleService.getNewSlides('prev', this.slides[0].id).subscribe(data => {
                        if (data.status) {
                            console.log(data.result.slides);
                            this.slides[0] = data.result.slides[0];
                            this.slides[6] = data.result.slides[1];
                            this.prevAdditionSlide = data.result.slides[2];
                        }
                    });
                }
                if (newActiveSlide === 2) {
                    //this.slides[5].id = 'prev'
                    this.slides[5] = this.prevAdditionSlide;
                }
            }

            this.oldActiveSlide = newActiveSlide;
            this.changedSlide.emit({slideId: this.slides[newActiveSlide].id,
                                            paymentType: this.slides[newActiveSlide].payment_type,
                                            paymentForView: this.slides[newActiveSlide].payment_for_view,
                                            liked: this.slides[newActiveSlide].user_likes});
        }

    }

    getStyle(item){
        const articleSlideItem = new ArticleSlide(item);
        const categories = articleSlideItem.categories;
        const categoryNumber = categories[0];

        return `view-${this.postCategoriesColors[categoryNumber]}`;
    }

    getTitle(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.title;
    }

    getId(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.id;
    }

    getPlaceholder(item){
        const articleSlideItem = new ArticleSlide(item);
        if (articleSlideItem.img) {
            return articleSlideItem.img;
        }
        return this.projectConfig.defaultImage;
    }

    getLikes(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.likes;
    }

    getViewed(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.viewed;
    }

    getText(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.text;
    }

    getPrice(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.price;
    }

    getCategories(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.categories;
    }

    getAuthorImg(item){
        const articleSlideItem = new ArticleSlide(item);
        if (articleSlideItem.author) {
            return articleSlideItem.author.avatar_link;
        }
        return this.projectConfig.defaultAvatar;
    }

    getTags(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.tags;
    }

    getFacebookSharedAmount(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.facebookShared;
    }

    getPinterestSharedAmount(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.pinterestShared;
    }

    getInstagramSharedAmount(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.instagramShared;
    }

    getTwitterSharedAmount(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.twitterShared;
    }

    getYoutubeSharedAmount(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.youtubeShared;
    }

    getFreeContent(item){
        const articleSlideItem = new ArticleSlide(item);
        return articleSlideItem.free;
    }
}
