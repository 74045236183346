import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {POST_TYPES} from '../../config/post_types';


import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class TypesService {
    private config = POST_TYPES;


    constructor(private http: HttpClient) {

    }

    getImage() {
        return this.config.image;
    }

    getVideo() {
        return this.config.video;
    }

    getAudio() {
        return this.config.audio;
    }
}
