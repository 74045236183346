<div class="collection">
    <button *ngFor="let item of items;" class="collection__item {{getStyle(item)}}" (click)="openPopup(getId(item))">
        <img
                src="{{getImg(item)}}"
                alt="{{getTitle(item)}}"
                class="collection__img">
        <div class="collection__title"> {{getTitle(item)}}</div>
    </button>
</div>

<app-footer-filter
        (filterChanged)="filterChanged($event)">
</app-footer-filter>



