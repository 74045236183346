import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class GlobalLibraryService {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this.globalLibraryChanged = new BehaviorSubject(false);
        this.globalLibraryHidePreviewChanged = new BehaviorSubject(false);
    }
    show() {
        this.globalLibraryChanged.next(true);
        document.body.classList.add('is-hidden');
    }
    hide() {
        this.globalLibraryChanged.next(false);
        this.globalLibraryHidePreviewChanged.next(false);
        document.body.classList.remove('is-hidden');
    }
}
GlobalLibraryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalLibraryService_Factory() { return new GlobalLibraryService(i0.ɵɵinject(i0.NgZone)); }, token: GlobalLibraryService, providedIn: "root" });
