import { BehaviorSubject } from 'rxjs';
import { NOTIFICATIONS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotificationsListService {
    constructor(http) {
        this.http = http;
        this.config = NOTIFICATIONS_CONFIG;
        this.visible = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    getData(page) {
        return this.http.post(this.config.data, { page });
    }
    getTotal() {
        return this.http.post(this.config.total, {});
    }
}
NotificationsListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsListService_Factory() { return new NotificationsListService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationsListService, providedIn: "root" });
