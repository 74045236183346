import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SearchService} from "./search.service";
import {NotificationsListService} from "./users/notificationsList.service";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    public menuChanged = new BehaviorSubject(false);
    public totalNotificationsAmount = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private route: ActivatedRoute,
                private router: Router,
                private notificationsListService: NotificationsListService) {

    }

    show() {
        this.menuChanged.next(true);
        //this.searchService.hide();
        this.getTotalNotificationsAmount();
        document.body.classList.add('is-hidden');

    }

    hide() {
        this.menuChanged.next(false);
        // this.router.navigate(
        //     [],
        //     {
        //         relativeTo: this.route,
        //         queryParams: {},
        //         //queryParamsHandling: 'merge', // remove to replace all query params by provided
        //     });
        document.body.classList.remove('is-hidden');
    }

    getTotalNotificationsAmount() {
        this.notificationsListService.getTotal().subscribe(data => {
            if (data.status) {
                this.totalNotificationsAmount.next(data.result);
            }
        });
    }
}
