import { NEW_CONTENT_CONFIG } from '../../config/api';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/popup/popup.component";
export class NewContentService {
    constructor(http, popupComponent) {
        this.http = http;
        this.popupComponent = popupComponent;
        this.config = NEW_CONTENT_CONFIG;
        this.newContentChanged = new BehaviorSubject(false);
        this.newContentId = new BehaviorSubject(0);
    }
    loadData(id) {
        return this.http.post(this.config.info, { id: id });
    }
    saveData(id, title, description, categories, paymentType, tokens, partOfFree, tags) {
        return this.http.post(this.config.save, { id, title, description, categories, paymentType, tokens, partOfFree, tags });
    }
    show() {
        this.newContentChanged.next(true);
        document.body.classList.add('is-hidden');
    }
    hide() {
        this.newContentChanged.next(false);
        document.body.classList.remove('is-hidden');
    }
    setId(id) {
        this.newContentId.next(id);
    }
}
NewContentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewContentService_Factory() { return new NewContentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PopupComponent)); }, token: NewContentService, providedIn: "root" });
