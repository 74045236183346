import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HOME_ARTICLES_CONFIG} from '../../config/api';


import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class HomeArticlesService {
    private config = HOME_ARTICLES_CONFIG;

    constructor(private http: HttpClient) {

    }

    loadData(number) {
        return this.http.post<any>(this.config.articles, {number});
    }
}
