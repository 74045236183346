import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ARTICLE_CONFIG, HOME_ARTICLES_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./search.service";
export class ArticleService {
    constructor(ngZone, http, searchService) {
        this.ngZone = ngZone;
        this.http = http;
        this.searchService = searchService;
        this.config = HOME_ARTICLES_CONFIG;
        this.configArticles = ARTICLE_CONFIG;
        this.visible = new BehaviorSubject(null);
        this.articleId = new BehaviorSubject(null);
        this.sliderCreated = new BehaviorSubject(false);
        this.showDetailsId = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
        //this.searchService.hide();
        document.body.classList.add('is-hidden');
    }
    showArticle(id) {
        this.hide();
        this.articleId.next(id);
        document.body.classList.add('is-hidden');
    }
    createSlider() {
        this.sliderCreated.next(true);
    }
    showDetails(id, advertising) {
        this.showDetailsId.next({ id: id, advertisingLink: advertising });
    }
    hide() {
        this.visible.next(false);
        this.articleId.next(null);
        this.showDetailsId.next(null);
        document.body.classList.remove('is-hidden');
    }
    getData(id) {
        return this.http.post(this.config.articles, { id });
    }
    // get new 3 slides
    getNewSlides(direction, id) {
        return this.http.post(this.config.add, { direction, id });
    }
    buyPublication(id) {
        return this.http.post(this.configArticles.buy, { id });
    }
    setAdvertising(id) {
        return this.http.post(this.configArticles.view, { id });
    }
    toggleLike(id) {
        return this.http.post(this.configArticles.like, { id });
    }
}
ArticleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ArticleService_Factory() { return new ArticleService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SearchService)); }, token: ArticleService, providedIn: "root" });
