export class ArticleSlide {
    public _id: string;
    private _title: string;
    private _author: any;
    private _img: string;
    public _view: string;
    public _text: string;
    public _likes: string;
    public _viewed: string;
    public _tags: any;
    public _categories: any;
    public _price: string;
    public _facebookShared: string;
    public _pinterestShared: string;
    public _instagramShared: string;
    public _twitterShared: string;
    public _youtubeShared: string;
    public _free: boolean;

    constructor(data) {
        this._id = data.id;
        this._title = data.title;
        this._author = data.author;
        this._img = data.avatar_link;
        this._view = data.view;
        this._text = data.description;
        this._likes = data.likes;
        this._viewed = data.view;
        this._tags = data.tags;
        this._categories = data.categories;
        this._price = data.price;
        this._facebookShared = data.price;
        this._pinterestShared = data.pinterestShared;
        this._instagramShared = data.instagramShared;
        this._twitterShared = data.twitterShared;
        this._youtubeShared = data.youtubeShared;
        this._free = data.free;

    }

    get view(): string {
        return this._view;
    }

    set view(value: string) {
        this._view = value;
    }

    get img(): string {
        return this._img;
    }

    set img(value: string) {
        this._img = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }


    get author(): any {
        return this._author;
    }

    set author(value: any) {
        this._author = value;
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    get likes(): string {
        return this._likes;
    }

    set likes(value: string) {
        this._likes = value;
    }

    get viewed(): string {
        return this._viewed;
    }

    set viewed(value: string) {
        this._viewed = value;
    }

    get tags(): any {
        return this._tags;
    }

    set tags(value: any) {
        this._tags = value;
    }

    get price(): string {
        return this._price;
    }

    set price(value: string) {
        this._price = value;
    }

    get categories(): any {
        return this._categories;
    }

    set categories(value: any) {
        this._categories = value;
    }

    get facebookShared(): string {
        return this._facebookShared;
    }

    set facebookShared(value: string) {
        this._facebookShared = value;
    }

    get pinterestShared(): string {
        return this._pinterestShared;
    }

    set pinterestShared(value: string) {
        this._pinterestShared = value;
    }

    get instagramShared(): string {
        return this._instagramShared;
    }

    set instagramShared(value: string) {
        this._instagramShared = value;
    }

    get twitterShared(): string {
        return this._twitterShared;
    }

    set twitterShared(value: string) {
        this._twitterShared = value;
    }

    get youtubeShared(): string {
        return this._youtubeShared;
    }

    set youtubeShared(value: string) {
        this._youtubeShared = value;
    }

    get free(): boolean {
        return this._free;
    }

    set free(value: boolean) {
        this._free = value;
    }
}
