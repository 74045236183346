<header
    class="header">

    <div *ngIf="backLink">
        <a [href]="backLink" class="header__left">
            Back
        </a>
    </div>

    <div *ngIf="backActionCheck">
        <button class="header__left" (click)="handleBackClick()">
            Back
        </button>
    </div>


    <div class="header__title"><ng-content></ng-content></div>

<!--    <div *ngIf="menuActionCheck">-->
        <button *ngIf="menuActionCheck" class="header__menu" (click)="handleMenuClick()">
            <span class="visually-hidden">menu</span>
            <svg class="header__icon" width="28" height="17">
                <use xlink:href="../../../../assets/images/sprite.svg#burger"></use>
            </svg>
        </button>
<!--    </div>-->


<!--    <a *ngIf="menuLink" [href]="menuLink" class="header__menu">-->
<!--        <span class="visually-hidden">menu</span>-->
<!--        <svg class="header__icon" width="28" height="17">-->
<!--            <use xlink:href="../../../../assets/images/sprite.svg#burger"></use>-->
<!--        </svg>-->
<!--    </a>-->

    <div *ngIf="nextLink">
        <a [href]="nextLink" class="header__right" [class.header__right--accent]="rightAccent">
            Next
        </a>
    </div>

    <div *ngIf="nextActionCheck">
        <button class="header__right" [class.header__right--accent]="rightAccent" (click)="handleNextClick()">
            Next
        </button>
    </div>

    <div *ngIf="doneActionCheck">
        <button class="header__right" [class.header__right--accent]="rightAccent" (click)="handleDoneClick()">
            Done
        </button>
    </div>


    <div *ngIf="doneLink">
        <a [href]="doneLink" class="header__right" [class.header__right--accent]="rightAccent">
            Done
        </a>
    </div>
</header>
