import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
// import {SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {SignUpService} from "../../service/signup.service";
import {LoginService} from "../../service/login.service";
// import Swal from "sweetalert2";

import {PopupComponent} from "../../shared/popup/popup.component";
import {MenuService} from "../../service/menu.service";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    pinForm: FormGroup;
    phoneForm: FormGroup;

    phoneFormActive = true;
    phoneLastFour = '0005';
    numOfDigits = 6;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;


    // swalStyled = Swal.mixin({
    //     customClass: {
    //         container: 'popup',
    //         popup: 'popup__inner',
    //         image: 'popup__image',
    //         title: 'popup__title',
    //         content: 'popup__content',
    //         confirmButton: 'popup__btn popup__btn_confirm',
    //         cancelButton: 'popup__btn popup__btn_cancel'},
    // });


    constructor(
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
        private signUpService: SignUpService,
        private menuService: MenuService,
        private router: Router,
        private auth:AuthenticationService,
        private popupComponent: PopupComponent) {

        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
    }
    ngOnInit() {
        this.auth.logout();

        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required]
        });

        for (let i = 0; i< this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null))
        }

    }
    ngAfterViewInit() {
        // POPUPS USINGS
        //this.popupComponent.showPopupSuccess();
    }

    resendSms(){
        this.onPhoneSubmit();
    }

    onPhoneSubmit(){
        if (this.phoneForm.invalid) {
            return;
        }

        let phone = this.phoneForm.controls.phone.value;

        this.authenticationService.checkPhoneAndSendPin(phone).subscribe(data => {
            if(data.status){
                this.phoneLastFour = phone.substr(-4);

                this.phoneFormActive = false;

                let $this = this;
                setTimeout(function(){
                    $this.inputs.toArray()[0].nativeElement.focus();
                },0);
            }else{
                this.popupComponent.showPopupError({text:data.message});
            }
        });

    }

    onSubmit() {
        if (this.pinForm.invalid) {
            return;
        }

        let phone = this.phoneForm.controls.phone.value;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        this.authenticationService.login(phone,pin).subscribe(data => {
            if(data.status){
                // let currentUrl = this.router.url;
                // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                //     this.router.navigate([currentUrl]);
                // });

                this.menuService.getTotalNotificationsAmount();
                this.hideLogin();
                this.router.navigate([], {
                    queryParams: {
                        notLogged: undefined,
                    },
                    queryParamsHandling: 'merge',
                });
            }else{
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    isPhoneClassActive(){
        if(this.phoneFormActive){
            return true;
        }

        return false;
    }

    isPinClassActive(){
        if(!this.phoneFormActive){
            return true;
        }

        return false;
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    hideLogin() {
        this.loginService.hide();
        document.body.classList.remove('is-hidden');
    }

    showSignUp() {
        this.signUpService.show();
        this.loginService.hide();
    }

}

