<div class="tokens content" [class.is-visible]="visible">
    <app-header
            [backActionCheck]="true"
            (backAction)="goBack()"
            [menuActionCheck]="true"
            (menuAction)="menu()">Advertisement</app-header>
    <app-header *ngIf="popupCollection" (backAction)="hidePopup('collection')" [backActionCheck]="true" (nextAction)="showPopup('budget')" [nextActionCheck]="true">Collection</app-header>
    <app-header *ngIf="popupBudget" (backAction)="hidePopup('budget')" [backActionCheck]="true" (nextAction)="showPopup('create')" [nextActionCheck]="true">Budget & Duration</app-header>
    <app-header *ngIf="popupCreate" (backAction)="hidePopup('create')" [backActionCheck]="true">Create Advertisment</app-header>
    <div class="tokens__inner">
        <div class="tokens__top">
            <app-balance-card [data]="data">Avaliable Balance</app-balance-card>
        </div>

        <div class="tokens__btns">
            <button (click)="showPopup('collection')" [class.is-active]="redeem" class="tokens__btn">PURCHASE Advertisement</button>
        </div>

        <div *ngIf="!redeem" class="tokens__activity">
            <app-activity (listScrolled)="emitScroll($event)" [activity]="activity" [data]="data">Activity</app-activity>
        </div>

        <div *ngIf="redeem">
            <div class="tokens__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed felis a eros ultricies fringilla. Quisque lobortis elementum nisi non rutrum. In varius metus vel aliquam molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed felis a eros ultricies fringilla.</div>
            <div class="tokens__btns">
                <a href="#" class="tokens__btn">WEBSITE</a>
            </div>
        </div>
    </div>
</div>

<div class="popup" [class.is-active]="popupCollection">
    <div class="popup__inner">
        <app-collection
                (closeCollection)="closeCollection($event)"
                (chooseSettings)="showBudget($event)"
                [settings]="collectionSettings">
        </app-collection>
    </div>
</div>

<div class="popup" [class.is-active]="popupBudget">
    <div class="popup__inner">
        <app-tokens-budget
                (closeBudget)="closeBudget($event)"
                (goToNext)="showCreate($event)">
        </app-tokens-budget>
    </div>
</div>

<div class="popup" [class.is-active]="popupCreate">
    <div class="popup__inner">
        <app-tokens-create-advertisement
                [settings]="createSettings"
                (closeCreate)="closeCreate($event)"
                (done)="doneCreate($event)">
        </app-tokens-create-advertisement>
    </div>
</div>

