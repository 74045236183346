/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./publication-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./publication-menu.component";
import * as i4 from "../../../service/collection.service";
import * as i5 from "../../../service/collectionList.service";
import * as i6 from "../../../service/global-library/loadpreview.service";
import * as i7 from "../../../service/content/new-content.service";
import * as i8 from "../../popup/popup.component";
var styles_PublicationMenuComponent = [i0.styles];
var RenderType_PublicationMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicationMenuComponent, data: {} });
export { RenderType_PublicationMenuComponent as RenderType_PublicationMenuComponent };
function View_PublicationMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, ":svg:svg", [["fill", "none"], ["height", "31"], ["viewBox", "0 0 30 31"], ["width", "30"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:circle", [["cx", "15"], ["cy", "15.1538"], ["fill", "#2E2E2E"], ["r", "14.75"], ["stroke", "white"], ["stroke-width", "0.5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:circle", [["cx", "15.3261"], ["cy", "15.4799"], ["r", "2.03261"], ["stroke", "white"], ["stroke-width", "0.5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:circle", [["cx", "15.3261"], ["cy", "23.3058"], ["r", "2.03261"], ["stroke", "white"], ["stroke-width", "0.5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:circle", [["cx", "15.3261"], ["cy", "7.6537"], ["r", "2.03261"], ["stroke", "white"], ["stroke-width", "0.5"]], null, null, null, null, null))], null, null); }
function View_PublicationMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, ":svg:svg", [["fill", "none"], ["height", "31"], ["viewBox", "0 0 30 31"], ["width", "30"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:circle", [["cx", "15"], ["cy", "15.1538"], ["fill", "#E5E558"], ["r", "14.75"], ["stroke", "#353A40"], ["stroke-width", "0.5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:circle", [["cx", "15.3261"], ["cy", "15.4799"], ["r", "2.03261"], ["stroke", "#353A40"], ["stroke-width", "0.5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:circle", [["cx", "15.3261"], ["cy", "23.3058"], ["r", "2.03261"], ["stroke", "#353A40"], ["stroke-width", "0.5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:circle", [["cx", "15.3261"], ["cy", "7.6537"], ["r", "2.03261"], ["stroke", "#353A40"], ["stroke-width", "0.5"]], null, null, null, null, null))], null, null); }
export function View_PublicationMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn-menu js-publication-btn-menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicationMenuComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicationMenuComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "menu js-publication-menu"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "menu__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editPublication() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, ":svg:svg", [["class", "menu__icon"], ["height", "18"], ["width", "18"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Edit "])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "menu__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.askAboutDeleting() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, ":svg:svg", [["class", "menu__icon"], ["height", "18"], ["width", "18"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../../assets/images/sprite.svg#delete"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Delete "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.menu; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.menu; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.menu; _ck(_v, 5, 0, currVal_2); }); }
export function View_PublicationMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-publication-menu", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PublicationMenuComponent_0, RenderType_PublicationMenuComponent)), i1.ɵdid(1, 114688, null, 0, i3.PublicationMenuComponent, [i1.ElementRef, i4.CollectionService, i5.CollectionListService, i6.LoadPreviewService, i1.NgZone, i7.NewContentService, i8.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PublicationMenuComponentNgFactory = i1.ɵccf("app-publication-menu", i3.PublicationMenuComponent, View_PublicationMenuComponent_Host_0, { id: "id" }, {}, []);
export { PublicationMenuComponentNgFactory as PublicationMenuComponentNgFactory };
