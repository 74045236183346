import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MenuService} from "../../../../service/menu.service";
import {DistributionsService} from "../../../../service/admin/distributions/distributions.service";
import {DistributionsPayoutsService} from "../../../../service/admin/distributions/distributionsPayouts.service";

@Component({
    selector: 'app-distributions-payouts',
    templateUrl: './distributions-payouts.component.html',
    styleUrls: ['./distributions-payouts.component.scss']
})
export class DistributionsPayoutsComponent implements OnInit {
    visible: boolean;

    payouts = [
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
    ]

    constructor(private menuService: MenuService,
                private distributionsService: DistributionsService,
                private distributionsPayoutsService: DistributionsPayoutsService) {
        this.distributionsPayoutsService.visible.subscribe((value: any) => this.toggleVisibility(value));

    }

    ngOnInit() {

    }

    goBack() {
        this.distributionsPayoutsService.hide();
    }

    menu() {
        this.distributionsPayoutsService.hide();
        this.distributionsService.hide();
        //this.menuService.hide();
    }

    toggleVisibility(state) {
        this.visible = state;

        if (this.visible) {
            this.distributionsPayoutsService.getData().subscribe(data => {
                if (data.status) {
                    this.payouts = data.result;
                }
            });
        }
    }

}
