import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./tokensTokens.service";
import * as i2 from "./tokensAdvertisement.service";
import * as i3 from "./tokensDistribution.service";
import * as i4 from "./tokensSweepstakes.service";
export class TokensService {
    constructor(ngZone, tokensTokensService, tokensAdvertisementService, tokensDistributionService, tokensSweepstakesService) {
        this.ngZone = ngZone;
        this.tokensTokensService = tokensTokensService;
        this.tokensAdvertisementService = tokensAdvertisementService;
        this.tokensDistributionService = tokensDistributionService;
        this.tokensSweepstakesService = tokensSweepstakesService;
        this.tokensVisible = new BehaviorSubject(null);
    }
    show() {
        this.tokensVisible.next(true);
    }
    hide() {
        this.tokensVisible.next(false);
    }
    hideSection() {
        this.hide();
        this.tokensTokensService.hide();
        this.tokensAdvertisementService.hide();
        this.tokensDistributionService.hide();
        this.tokensSweepstakesService.hide();
    }
}
TokensService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokensService_Factory() { return new TokensService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.TokensTokensService), i0.ɵɵinject(i2.TokensAdvertisementService), i0.ɵɵinject(i3.TokensDistributionService), i0.ɵɵinject(i4.TokensSweepstakesService)); }, token: TokensService, providedIn: "root" });
