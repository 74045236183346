<app-header-search></app-header-search>

<div *ngIf="!search">
    <app-collection-main
            [parent]="'library'"
            (dataChanged)="updateData($event)"
            [popup]="popup"
            (showPopup)="showPopup($event)">
    </app-collection-main>
</div>

<!--<app-article (popupShow)="hidePopup($event)" [data]="data" [popup]="popup"></app-article>-->

