import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AdSettingsCostService} from "../../../../service/admin/adSettingsCost.service";
import {MenuService} from "../../../../service/menu.service";
import {AdSettingsService} from "../../../../service/admin/adSettings.service";
import {PopupComponent} from "../../../../shared/popup/popup.component";

@Component({
    selector: 'app-settings-cost',
    templateUrl: './ad-settings-cost.component.html',
    styleUrls: ['./ad-settings-cost.component.scss']
})
export class AdSettingsCostComponent implements OnInit {
    visible: boolean;
    costForm: FormGroup;


    constructor(private formBuilder: FormBuilder,
                private menuService: MenuService,
                private adSettingsService: AdSettingsService,
                private popupComponent: PopupComponent,
                private adSettingsCostService: AdSettingsCostService) {
        this.adSettingsCostService.visible.subscribe((value: any) => this.toggleVisibility(value));
    }

    ngOnInit() {
        this.costForm = this.formBuilder.group({
            minDay: [null, Validators.required],
            maxDay: [null, Validators.required],
            min: [null, Validators.required],
            max: [null, Validators.required]
        });
    }

    onSubmit() {
        const formData = this.costForm.value;

        this.adSettingsCostService.saveData(formData.minDay, formData.maxDay, formData.min, formData.max).subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }

    toggleVisibility(value) {
        this.visible = value;

        if (this.visible) {
            this.adSettingsCostService.getData().subscribe(data => {
                if (data.status) {
                    this.costForm.controls['minDay'].setValue(data.result.minPerDay);
                    this.costForm.controls['maxDay'].setValue(data.result.maxPerDay);
                    this.costForm.controls['min'].setValue(data.result.min);
                    this.costForm.controls['max'].setValue(data.result.max);
                }
            })
        }
    }

    goBack() {
        this.adSettingsCostService.hide();
    }

    menu() {
        this.adSettingsCostService.hide();
        this.adSettingsService.hide();
        //this.menuService.hide();
    }

}
