import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { COLLECTION_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CollectionListService {
    constructor(ngZone, http) {
        this.ngZone = ngZone;
        this.http = http;
        this.config = COLLECTION_CONFIG;
        this.visible = new BehaviorSubject(null);
        this.id = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    showPost(id) {
        this.id.next(id);
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    getPost(id) {
        return this.http.post(this.config.post, { id });
    }
}
CollectionListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CollectionListService_Factory() { return new CollectionListService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.HttpClient)); }, token: CollectionListService, providedIn: "root" });
