import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PROJECT_CONFIG} from "../../../config/project";
import {PublicationItem} from "../../../models/publicationItem";

@Component({
    selector: 'app-sound-publication',
    templateUrl: './sound-publication.component.html',
    styleUrls: ['./sound-publication.component.scss']
})
export class SoundPublicationComponent implements OnInit {
    projectConfig = PROJECT_CONFIG;

    @Input() noDescr;
    @Input() noMenu;
    @Input() author;

    @Input() clickable;
    @Input() publication;
    @Input() menu;

    @Output() clickAction = new EventEmitter();
    @Output() menuShowed = new EventEmitter();

    @ViewChild('audioElement', {static: false}) audio: ElementRef;
    @ViewChild('ranger', {static: false}) ranger: ElementRef;

    repost = false;

    play = false;
    startVideo = false;
    rangePosition = '0';

    mediaType = 'audio';
    mediaDuration;
    mediaDurationFormated;
    mediaProgress;
    mediaProgressFormated;

    advertising;

    constructor() {
    }

    ngOnInit() {
        setTimeout(() => {
            this.mediaDuration = Math.round(this[this.mediaType].nativeElement.duration);
            this.mediaDurationFormated = this.formatTime(+this.mediaDuration);
            this.mediaProgress = Math.round(this[this.mediaType].nativeElement.currentTime);
            this.mediaProgressFormated  = this.formatTime(+this.mediaProgress);
        }, 1000);
    }

    toggleRepost() {
        this.repost = !this.repost;
    }

    toggleMenu() {
        this.menu = !this.menu;
        this.menuShowed.emit(this.menu);
        if (this.menu) {
            document.body.classList.add('is-hidden');
        } else {
            document.body.classList.remove('is-hidden');
        }
    }

    handleClick() {
        this.clickAction.emit();
    }

    changeRange(value) {
        this.rangePosition = value;

        if (this.mediaType) {
            this[this.mediaType].nativeElement.currentTime = this[this.mediaType].nativeElement.duration * value / 100;
            this.mediaProgress = Math.round(this[this.mediaType].nativeElement.currentTime);
            this.mediaProgressFormated = this.formatTime(this.mediaProgress);
        }
    }

    toggleMedia() {
        this.play = !this.play;
        this.play ? this[this.mediaType].nativeElement.play()  : this[this.mediaType].nativeElement.pause();

        if (this.play) {
            let playInterval = setInterval(() => {

                if (this.mediaType && this.ranger) {
                    this.mediaProgress = Math.round(this[this.mediaType].nativeElement.currentTime);
                    this.mediaProgressFormated  = this.formatTime(this.mediaProgress);

                    let mediaProgressLong = Math.round(this[this.mediaType].nativeElement.currentTime*1000)/1000;
                    let mediaDurationLong = Math.round(this[this.mediaType].nativeElement.duration*1000)/1000;

                    this.rangePosition = `${(mediaProgressLong * 100) / mediaDurationLong}`;
                    this.ranger.nativeElement.value = this.rangePosition;

                    if (mediaProgressLong >= mediaDurationLong) {
                        this.play = false;
                        clearInterval(playInterval);
                    }
                }


            }, 100);
        }

    }

    formatTime(seconds) {
        const h = Math.floor(seconds / 3600)
        const m = Math.floor((seconds % 3600) / 60)
        const s = seconds % 60
        return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
            .filter(a => a)
            .join(':')
    }

    getAuthorAvatar(){
        this.author = this.author ? this.author : this.publication.author;
        if(this.author){
            if(this.author.avatar_link){
                return this.author.avatar_link;
            }
        }


        return this.projectConfig.defaultAvatar;
    }

    getAuthorName(){
        let name = '';
        this.author = this.author ? this.author : this.publication.author;
        if(this.author) {
            if (this.author.first_name) {
                name = this.author.first_name;
            }

            if (this.author.last_name) {
                name = name + ' ' + this.author.last_name;
            }
        }

        return name;
    }

    getId(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.id;
    }

    getTitle(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.title;
    }

    getFile(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.file;
    }

    getPlaceholder(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.placeholder;
    }

    getLikes(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.likes;
    }

    getTokens(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.tokens;
    }

    getReposts(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.reposts;
    }

    getPayment(item){
        const publicationItem = new PublicationItem(item);
        return !publicationItem.cost;
    }

    getDescription(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.descr;
    }

    getTimeAgo(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.timeAgo;
    }

    getTags(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.tags;
    }

    getCategories(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.categories;
    }

    getAdvertising(item){
        const publicationItem = new PublicationItem(item);
        this.advertising = publicationItem.advertising.url ? publicationItem.advertising.url : '';
        return publicationItem.advertising.url;
    }

    advertisingIsOver() {
        this.publication.advertising.url = '';
    }
 }
