// import {PreviewPublication} from '../../models/preview-publication';
import { GLOBAL_LIBRARY_CONFIG } from '../../config/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class LoadPreviewService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
        this.config = GLOBAL_LIBRARY_CONFIG;
    }
    loadPage(type, sense, page) {
        //console.log(type, sense, page)
        return this.http.post(this.config.list, { type, sense, page });
    }
    createContent(id) {
        return this.http.post(this.config.create, { id });
    }
}
LoadPreviewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadPreviewService_Factory() { return new LoadPreviewService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: LoadPreviewService, providedIn: "root" });
