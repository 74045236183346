<ul class="nav">
    <li *ngFor="let item of navItems" class="nav__item">
        <button class="nav__link" (click)="clickHandler(item.url)">
            <div class="nav__icon-wrapper">
                <img src="../../../assets/images/nav-icons/{{item.icon}}" alt="" class="nav__icon">
            </div>

            <div class="nav__title">{{item.title}}</div>

            <svg class="nav__arrow" width="6" height="10">
                <use xlink:href="../../../../assets/images/sprite.svg#arrow-right"></use>
            </svg>
        </button>

<!--        <a class="nav__link" href="{{item.url}}">-->
<!--            <div class="nav__icon-wrapper">-->
<!--                <img src="../../../assets/images/nav-icons/{{item.icon}}" alt="" class="nav__icon">-->
<!--            </div>-->

<!--            <div class="nav__title">{{item.title}}</div>-->

<!--            <svg class="nav__arrow" width="6" height="10">-->
<!--                <use xlink:href="../../../../assets/images/sprite.svg#arrow-right"></use>-->
<!--            </svg>-->
<!--        </a>-->
    </li>
</ul>
