import { POST_TYPES } from '../../config/post_types';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TypesService {
    constructor(http) {
        this.http = http;
        this.config = POST_TYPES;
    }
    getImage() {
        return this.config.image;
    }
    getVideo() {
        return this.config.video;
    }
    getAudio() {
        return this.config.audio;
    }
}
TypesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TypesService_Factory() { return new TypesService(i0.ɵɵinject(i1.HttpClient)); }, token: TypesService, providedIn: "root" });
