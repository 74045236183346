<div class="tokens content" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">Sweepstakes</app-header>
    <div class="tokens__inner">
        <div class="tokens__top">
            <app-balance-card [data]="data">Available Points</app-balance-card>
        </div>

        <div class="tokens__btns">
            <button (click)="showRedeem()" [class.is-active]="redeem" class="tokens__btn">HOW TO REDEEM</button>
        </div>

        <div *ngIf="!redeem" class="tokens__activity">
            <app-activity [activity]="activity">Activity</app-activity>
        </div>

        <div *ngIf="redeem">
            <div class="tokens__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed felis a eros ultricies fringilla. Quisque lobortis elementum nisi non rutrum. In varius metus vel aliquam molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed felis a eros ultricies fringilla.</div>
            <div class="tokens__btns">
                <a href="#" class="tokens__btn">WEBSITE</a>
            </div>
        </div>
    </div>
</div>
