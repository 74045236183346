/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./collection-main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../footer-filter/footer-filter.component.ngfactory";
import * as i4 from "../footer-filter/footer-filter.component";
import * as i5 from "./collection-main.component";
import * as i6 from "../../service/global-library/homeCollection.service";
import * as i7 from "../../service/search.service";
import * as i8 from "../../service/article.service";
import * as i9 from "../../service/global-library/loadpreview.service";
import * as i10 from "@angular/router";
var styles_CollectionMainComponent = [i0.styles];
var RenderType_CollectionMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CollectionMainComponent, data: {} });
export { RenderType_CollectionMainComponent as RenderType_CollectionMainComponent };
function View_CollectionMainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPopup(_co.getId(_v.context.$implicit)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "collection__img"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "collection__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "collection__item ", _co.getStyle(_v.context.$implicit), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.getImg(_v.context.$implicit), ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.getTitle(_v.context.$implicit), ""); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.getTitle(_v.context.$implicit); _ck(_v, 3, 0, currVal_3); }); }
export function View_CollectionMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "collection"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollectionMainComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-footer-filter", [], null, [[null, "filterChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterChanged" === en)) {
        var pd_0 = (_co.filterChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FooterFilterComponent_0, i3.RenderType_FooterFilterComponent)), i1.ɵdid(4, 114688, null, 0, i4.FooterFilterComponent, [], null, { filterChanged: "filterChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_CollectionMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-collection-main", [], null, null, null, View_CollectionMainComponent_0, RenderType_CollectionMainComponent)), i1.ɵdid(1, 4308992, null, 0, i5.CollectionMainComponent, [i6.HomeCollectionService, i7.SearchService, i8.ArticleService, i9.LoadPreviewService, i10.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CollectionMainComponentNgFactory = i1.ɵccf("app-collection-main", i5.CollectionMainComponent, View_CollectionMainComponent_Host_0, { popup: "popup", parent: "parent" }, { showPopup: "showPopup", dataChanged: "dataChanged" }, []);
export { CollectionMainComponentNgFactory as CollectionMainComponentNgFactory };
