import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuService} from "./service/menu.service";
import {SignUpService} from "./service/signup.service";
import {LoginService} from "./service/login.service";
import {Router} from "@angular/router";

import {PhoneService} from "./service/phone.service";
import {GlobalLibraryService} from "./service/global-library/globalLibrary.service";
import {StripeService} from "./service/stripe/stripe.service";
import {MenuBottomAddContentService} from "./service/menu/menuBottomAddContent.service";
import {SearchService} from "./service/search.service";

//declare var Stripe: Stripe;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    menu;
    signup = false;
    login = false;
    search = true;

    constructor(private menuService: MenuService,
                private signUpService: SignUpService,
                private router: Router,
                private phoneService:PhoneService,
                private stripeService:StripeService,
                private menuBottomAddContentService: MenuBottomAddContentService,
                private searchService: SearchService,
                private loginService: LoginService) {
        this.searchService.visible.subscribe((value: any) => this.searchVisibilityChanged(value));
    }

    ngOnInit() {
        this.phoneService.init();

        this.signUpService.signUpChanged.subscribe((value: any) => this.signUpChanged(value));
        this.loginService.loginChanged.subscribe((value: any) => this.loginChanged(value));

        setTimeout(() => {
            this.menuService.menuChanged.subscribe((value: any) => this.menuChanged(value));
        })
        // this.stripeService.getPublishableKey().subscribe(data => {
        //     if (data.key) {
        //         this.addStripeJs(data.key);
        //     }
        // });
    }

    ngOnDestroy() {

    }

    addStripeJs(key){
        this.stripeService.setPublishableKey(key);



    }

    menuChanged(status){
        this.menu = status;
    }

    signUpChanged(status){
        this.signup = status;
    }

    loginChanged(status){
        this.login = status;
    }

    searchVisibilityChanged(value) {
        this.search = value;
    }
}


