import { HOME_LIBRARY_CONFIG } from '../../config/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HomeCollectionService {
    constructor(http) {
        this.http = http;
        this.config = HOME_LIBRARY_CONFIG;
    }
    // loadPublications(type, sense, cost, page, search) {
    //     return this.http.post<any>(this.config.home, {type, sense, cost, page, search});
    // }
    loadPublications(sense, page, search) {
        return this.http.post(this.config.home, { sense, page, search });
    }
}
HomeCollectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomeCollectionService_Factory() { return new HomeCollectionService(i0.ɵɵinject(i1.HttpClient)); }, token: HomeCollectionService, providedIn: "root" });
