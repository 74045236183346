<button class="btn-menu js-publication-btn-menu" (click)="toggleMenu()">
    <svg *ngIf="!menu" width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15.1538" r="14.75" fill="#2E2E2E" stroke="white" stroke-width="0.5"/>
        <circle cx="15.3261" cy="15.4799" r="2.03261" stroke="white" stroke-width="0.5"/>
        <circle cx="15.3261" cy="23.3058" r="2.03261" stroke="white" stroke-width="0.5"/>
        <circle cx="15.3261" cy="7.6537" r="2.03261" stroke="white" stroke-width="0.5"/>
    </svg>

    <svg *ngIf="menu" width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15.1538" r="14.75" fill="#E5E558" stroke="#353A40" stroke-width="0.5"/>
        <circle cx="15.3261" cy="15.4799" r="2.03261" stroke="#353A40" stroke-width="0.5"/>
        <circle cx="15.3261" cy="23.3058" r="2.03261" stroke="#353A40" stroke-width="0.5"/>
        <circle cx="15.3261" cy="7.6537" r="2.03261" stroke="#353A40" stroke-width="0.5"/>
    </svg>
</button>

<div class="menu js-publication-menu" [class.is-active]="menu">
    <button class="menu__item" (click)="editPublication()">
        <svg class="menu__icon" width="18" height="18">
            <use xlink:href="../../../../assets/images/sprite.svg#edit"></use>
        </svg>
        Edit
    </button>
    <button class="menu__item" (click)="askAboutDeleting()">
        <svg class="menu__icon" width="18" height="18">
            <use xlink:href="../../../../../assets/images/sprite.svg#delete"></use>
        </svg>

        Delete
    </button>
</div>
