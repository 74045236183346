import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
// import {UserIdleService} from '../user-idle/user-idle.service';
import {Router} from '@angular/router';

// import {PreviewPublication} from '../../models/preview-publication';
import {GLOBAL_LIBRARY_CONFIG} from '../../config/api';


import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadPreviewService {
    private config = GLOBAL_LIBRARY_CONFIG;

    constructor(private http: HttpClient,
                private router: Router) {
    }

    loadPage(type, sense, page) {
        //console.log(type, sense, page)
        return this.http.post<any>(this.config.list, {type, sense, page});
    }

    createContent(id) {
        return this.http.post<any>(this.config.create, {id});
    }
}
