<header class="home-header">
    <div class="home-header__logo">
        <svg class="home-header__logo-icon" viewBox="0 0 36 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.4611 2.37724L16.4963 2.08496L16.126 6.49567L21.4611 2.37724Z" fill="#EC008C"/>
            <path d="M17.6621 5.89792L21.5983 2.9353L24.4647 6.37619L17.6621 5.89792Z" fill="#92278F"/>
            <path d="M19.0227 6.4292L23.9738 6.84104L23.6721 11.265L19.0227 6.4292Z" fill="#ED1C24"/>
            <path d="M20.394 8.30249L23.3564 12.1818L19.7219 14.8787L20.394 8.30249Z" fill="#F15A29"/>
            <path d="M19.9143 10.4812L19.1874 15.2506L14.6477 14.6793L19.9143 10.4812Z" fill="#FFF200"/>
            <path d="M18.0626 11.4113L14.1127 14.3473L11.26 10.8799L18.0626 11.4113Z" fill="#39B54A"/>
            <path d="M16.0328 10.8798L11.1228 10.1092L11.7674 5.7251L16.0328 10.8798Z" fill="#27AAE1"/>
            <path d="M15.1275 9.00673L12.0828 5.19386L15.6624 2.41724L15.1275 9.00673Z" fill="#662D91"/>
            <path d="M12.4669 14.2277C13.7698 15.0248 15.4156 15.7555 17.0614 15.7555C21.2033 15.7555 24.5635 12.4209 24.5635 8.30247C24.5635 6.76137 24.0972 5.00772 23.288 3.81205L25.3041 4.87487C26.0859 6.08383 26.3739 7.33264 26.3739 8.83388C26.3739 13.4439 22.1085 17.177 16.8557 17.177C15.3333 17.177 13.8933 16.8847 12.6315 16.3135C12.2612 16.1408 0 9.55128 0 9.55128V8.35561L12.028 2.08496C12.028 2.08496 10.9994 2.72265 10.2725 3.7589C9.57304 4.74201 9.42218 5.10072 9.42218 5.10072L2.88014 8.42203V9.13944C2.88014 9.12615 11.452 13.6166 12.4669 14.2277Z" fill="#6D6E71"/>
            <path d="M22.9861 3.09459C21.6832 2.29748 20.0374 1.56679 18.3916 1.56679C14.2496 1.56679 10.8895 4.90139 10.8895 9.01982C10.8895 10.5609 11.3558 12.3146 12.165 13.5102L10.1489 12.4474C9.36712 11.2385 9.0791 9.98965 9.0791 8.48841C9.0791 3.87842 13.3445 0.145264 18.5973 0.145264C20.1196 0.145264 21.5597 0.43754 22.8215 1.00881C23.1918 1.18151 35.453 7.77101 35.453 7.77101V8.96668L23.425 15.2506C23.425 15.2506 24.4536 14.6129 25.1805 13.5767C25.8799 12.5936 26.0308 12.2349 26.0308 12.2349L32.5728 8.91354V8.20942C32.5728 8.20942 24.001 3.719 22.9861 3.09459Z" fill="#6D6E71"/>
        </svg>
    </div>

    <button (click)="showBottomMenu()" *ngIf="isAuth() && canAdd" [class.is-active]="!search" class="home-header__add-btn">
        <span class="visually-hidden">Add Content</span>
        <svg class="home-header__icon" width="17" height="17">
            <use xlink:href="../../../../assets/images/sprite.svg#plus"></use>
        </svg>
    </button>

    <div [class.is-active]="search" class="home-header__search">
        <div [class.is-active]="search" class="search">
            <form [formGroup]="searchForm" class="search__input-wrapper" (ngSubmit)="submitSearch()">
                <input formControlName="search" [value]="searchValue" (input)="searchValue = $event.target.value.toString().trim()" type="text" class="search__input">
                <span class="search__line"></span>
            </form>

            <button class="search__btn" (click)="searchHandler()">
                <span class="visually-hidden">Search</span>
                <svg class="search__icon" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.4864 0C3.35851 0 0 3.35851 0 7.4864C0 11.6145 3.35851 14.9728 7.4864 14.9728C11.6145 14.9728 14.9728 11.6145 14.9728 7.4864C14.9728 3.35851 11.6145 0 7.4864 0ZM7.4864 13.5907C4.12052 13.5907 1.38211 10.8523 1.38211 7.48644C1.38211 4.12055 4.12052 1.38211 7.4864 1.38211C10.8523 1.38211 13.5907 4.12052 13.5907 7.4864C13.5907 10.8523 10.8523 13.5907 7.4864 13.5907Z" fill="white"/>
                    <path d="M16.7974 15.8203L12.8354 11.8582C12.5654 11.5883 12.1282 11.5883 11.8582 11.8582C11.5883 12.128 11.5883 12.5657 11.8582 12.8354L15.8203 16.7974C15.9553 16.9324 16.132 16.9999 16.3089 16.9999C16.4855 16.9999 16.6624 16.9324 16.7974 16.7974C17.0674 16.5277 17.0674 16.09 16.7974 15.8203Z" fill="white"/>
                </svg>
            </button>
        </div>
    </div>
    <button *ngIf="!onClick" (click)="showMenu()" class="home-header__menu">
        <svg class="home-header__menu-icon" width="28" height="17">
            <use xlink:href="../../../../assets/images/sprite.svg#menu"></use>
        </svg>
    </button>
    <button *ngIf="onClick" (click)="onClick($event)" class="home-header__menu">
        <svg class="home-header__menu-icon" width="28" height="17">
            <use xlink:href="../../../../assets/images/sprite.svg#menu"></use>
        </svg>
    </button>
</header>

