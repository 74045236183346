import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-collection-thumbnails',
    templateUrl: './collection-thumbnails.component.html',
    styleUrls: ['./collection-thumbnails.component.scss']
})
export class CollectionThumbnailsComponent implements OnInit {

    @Input() settings;
    @Input() items;

    @Output() clickAction = new EventEmitter();

    constructor() {
    }

    ngOnInit() {


    }

    clickHandler(item) {
        this.clickAction.emit(item);
    }
    

}
