<div class="account content" *ngIf="visible" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">Payment Method</app-header>
    <div class="account__inner">
        <div class="account__form">
            <form [formGroup]="paymentForm" (ngSubmit)="onSubmit()" class="form-labeled">
                <div class="form-labeled__item">
                    <input mask="0000 0000 0000 0000"
                           formControlName="ccnumber"
                           placeholder="0000 0000 0000 0000"
                           [type]="'text'"
                           class="form-labeled__input"
                           id="ccnumber"
                           autocomplete="off">
                    <label class="form-labeled__label" for="ccnumber">CC Number</label>
                </div>
                <div class="form-labeled__item form-labeled__item--group">

                    <select class="form-labeled__input form-labeled__select" formControlName="expDateYY" name="" id="expDate">
                        <option *ngFor="let item of availableYears" value="{{item}}">{{item}}</option>
                    </select>
                    <select class="form-labeled__input form-labeled__select" formControlName="expDateMM" (change)="getAvailableYears()" name="">
                        <option *ngFor="let item of [].constructor(12); let i = index" value="{{i+1}}">{{i+1}}</option>
                    </select>
<!--                    <input mask="00"-->
<!--                           formControlName="expDateYY"-->
<!--                           id="expDate"-->
<!--                           placeholder="YY"-->
<!--                           [value]=""-->
<!--                           [type]="'text'"-->
<!--                           class="form-labeled__input"-->
<!--                           autocomplete="off">-->
<!--                    <input mask="00"-->
<!--                           formControlName="expDateMM"-->
<!--                           placeholder="MM"-->
<!--                           id="expDate"-->
<!--                           [value]=""-->
<!--                           [type]="'text'"-->
<!--                           class="form-labeled__input"-->
<!--                           autocomplete="off">-->
                    <label class="form-labeled__label" for="expDate">Expiration Date</label>
                </div>
                <div class="form-labeled__item">
                    <input mask="000"
                           formControlName="securityCode"
                           id="securityCode"
                           placeholder="Security Code"
                           [value]=""
                           [type]="'text'"
                           class="form-labeled__input"
                           autocomplete="off">
                    <label class="form-labeled__label" for="securityCode">Security Code</label>
                </div>
                <div class="form-labeled__item">
                    <input mask="00000"
                           formControlName="zipCode"
                           id="zipCode"
                           placeholder="Zip Code"
                           [value]=""
                           [type]="'text'"
                           class="form-labeled__input"
                           autocomplete="off">
                    <label class="form-labeled__label" for="zipCode">Zip Code</label>
                </div>
                <div class="form-labeled__item">
                    <input formControlName="description"
                           id="description"
                           placeholder="Description"
                           [value]=""
                           [type]="'text'"
                           class="form-labeled__input"
                           autocomplete="off">
                    <label class="form-labeled__label" for="description">Description</label>
                </div>

                <div class="form-labeled__btns">
                    <button class="form-labeled__btn" [disabled]="paymentForm.invalid">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>

