<div class="tokens content">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [nextActionCheck]="true"
                (nextAction)="goNext()">Budget & Duration</app-header>
    <div class="tokens__inner">

        <div class="tokens__range">
            <div class="range-slider">
                <div class="range-slider__title">Budget</div>
                <div class="range-slider__wrapper">
                    <mat-slider class="range-slider__input range-slider__input--big"
                                [(ngModel)]="budget"
                                thumbLabel
                                [displayWith]="formatLabel"
                                min="100"
                                max="1000"
                                step="50"
                                [value]="budget"></mat-slider>
                    <div class="range-slider__values">
                        <div class="range-slider__min">100</div>
                        <div class="range-slider__max">1000</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tokens__range">
            <div class="range-slider">
                <div class="range-slider__title">Duration</div>
                <div class="range-slider__wrapper">
                    <mat-slider class="range-slider__input"
                                [(ngModel)]="duration"
                                thumbLabel
                                [displayWith]="formatLabelDays"
                                min="1"
                                max="30"
                                [value]="duration"></mat-slider>
                    <div class="range-slider__values">
                        <div class="range-slider__min">1</div>
                        <div class="range-slider__max">30</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
