import { BehaviorSubject } from 'rxjs';
import { USERS_EDITING_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserEditingService {
    constructor(http) {
        this.http = http;
        this.config = USERS_EDITING_CONFIG;
        this.visible = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    getUsers(name, phone) {
        return this.http.post(this.config.data, { name, phone });
    }
}
UserEditingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserEditingService_Factory() { return new UserEditingService(i0.ɵɵinject(i1.HttpClient)); }, token: UserEditingService, providedIn: "root" });
