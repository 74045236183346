import { OnInit } from '@angular/core';
import Swal from "sweetalert2";
import * as i0 from "@angular/core";
export class PopupComponent {
    constructor() {
        this.swalStyled = Swal.mixin({
            customClass: {
                container: 'popup',
                popup: 'popup__inner',
                image: 'popup__image',
                title: 'popup__title',
                content: 'popup__content',
                confirmButton: 'popup__btn popup__btn_confirm',
                cancelButton: 'popup__btn popup__btn_cancel'
            },
        });
    }
    ngOnInit() {
    }
    showPopupSuccess(message) {
        this.swalStyled.fire({
            title: 'Success',
            imageUrl: '../assets/images/popup/success-logo.png',
            text: message.text ? message.text : 'You are successfully logged in to your account!',
            confirmButtonText: 'OK, THANKS',
        });
    }
    showPopupError(message) {
        let text = message.text ? message.text : 'Something went wrong. Please try again.';
        let confirmButtonText = message.confirmButtonText ? message.confirmButtonText : 'TRY AGAIN';
        this.swalStyled.fire({
            title: 'Error',
            imageUrl: '../assets/images/popup/error-logo.png',
            text: text,
            confirmButtonText: confirmButtonText
        });
    }
    showPopupAgreement(options, handler) {
        let text = options.text ? options.text : 'Something went wrong. Please try again.';
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'TRY AGAIN';
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : 'CANCEL';
        this.swalStyled.fire({
            title: 'Attention',
            imageUrl: '../assets/images/popup/error-logo.png',
            text: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    }
}
PopupComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopupComponent_Factory() { return new PopupComponent(); }, token: PopupComponent, providedIn: "root" });
