import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {COLLECTION_CONFIG, TOKENS_ADVERTISEMENT_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class CollectionService {
    private config = COLLECTION_CONFIG;
    public visible = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private http: HttpClient) {

    }

    show() {
        this.visible.next(true);

    }

    hide() {
        this.visible.next(false);
    }

    getCollection(type, sense, page) {
        return this.http.post<any>(this.config.data, {type, sense, page});
    }

    // getPost(id) {
    //     return this.http.post<any>(this.config.post, {id});
    // }

    deletePost(id) {
        return this.http.post<any>(this.config.delete, {id});
    }
}
