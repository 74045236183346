import {Inject, Injectable, NgZone} from '@angular/core';
import {ROLES_CONFIG} from '../../config/roles';
import {AuthenticationService} from '../../modules/auth/_services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AccessService {
    private roles = ROLES_CONFIG;

    constructor(private auth:AuthenticationService) {

    }

    isCustomer() {
        if(this.roles.customer == this.auth.getRole()){
            return true;
        }

        return false;
    }

    isAdmin() {
        if(this.roles.admin == this.auth.getRole()){
            return true;
        }

        return false;
    }
}
