import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    NgZone,
    HostListener,
    ElementRef
} from '@angular/core';
import {PhoneService} from "../../service/phone.service";
import {Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {GlobalLibraryComponent} from "../../pages/global-library/global-library.component";
import {GlobalLibraryService} from "../../service/global-library/globalLibrary.service";
import {MenuBottomAddContentService} from "../../service/menu/menuBottomAddContent.service";
import {NewContentService} from "../../service/content/new-content.service";
// import {type} from "os";

@Component({
    selector: 'app-menu-bottom',
    templateUrl: './menu-bottom.component.html',
    styleUrls: ['./menu-bottom.component.scss'],
    providers: [GlobalLibraryComponent]

})
export class MenuBottomComponent implements OnInit {

    // @Input() menu: boolean;
    menu = false;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.menu) {
            if(!this.eRef.nativeElement.contains(event.target) && !event.target.classList.contains('home-header__add-btn')) {
                this.menuBottomAddContentService.hide();
            }
        }
    }

    list = [
        {
            title: 'Global Library',
            cmd: 'globalLibrary',
            icon: 'pin'
        },
        {
            title: 'My Files',
            cmd: 'myDevice',
            icon: 'audio'
        },
        {
            title: 'My Device',
            cmd: 'myDevice',
            icon: 'gallery'
        },
        {
            title: 'Take Photo',
            cmd: 'takePhoto',
            icon: 'camera'
        },
        {
            title: 'Take Video',
            cmd: 'takeVideo',
            icon: 'camera'
        }
    ];

    constructor(private ngZone: NgZone,
                private phoneService:PhoneService,
                private router: Router,
                private globalLibraryService: GlobalLibraryService,
                private menuBottomAddContentService: MenuBottomAddContentService,
                private newContentService: NewContentService,
                private eRef: ElementRef,
                private popupComponent: PopupComponent) {
        this.menuBottomAddContentService.menuChanged.subscribe((value: any) => this.menuBottomChanged(value));
    }



    ngOnInit() {
        this.phoneService.postUploded.subscribe((value: any) => this.goToUpdatePost(value));
        //this.router.navigate(["/new-content/2?refresh=1"]);
    }

    goToUpdatePost(data){
        if(data){
            if(data.id){
                this.ngZone.run( () => {
                    this.newContentService.show();
                    this.newContentService.setId(data.id);

                });
            }
        }
    }

    setCommand(type){
        if(type == 'myDevice'){
            this.phoneService.setFilesList();
        }
        if(type == 'takePhoto'){
            this.phoneService.setTakePhoto();
        }
        if(type == 'takeVideo'){
            this.phoneService.setTakeVideo();
        }
        if(type == 'globalLibrary'){
            this.globalLibraryService.show();
            this.menuBottomAddContentService.hide();
        }
    }

    menuBottomChanged(state) {
        this.menu = state;
    }

}
