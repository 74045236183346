import { BehaviorSubject } from 'rxjs';
import { TOKENS_TOKENS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TokensTokensService {
    constructor(http) {
        this.http = http;
        this.config = TOKENS_TOKENS_CONFIG;
        this.tokensVisible = new BehaviorSubject(null);
    }
    show() {
        this.tokensVisible.next(true);
    }
    hide() {
        this.tokensVisible.next(false);
    }
    getData(page) {
        return this.http.post(this.config.data, { page });
    }
    getActivity() {
        return this.http.post(this.config.activity, {});
    }
    setRatio(amount) {
        return this.http.post(this.config.submit, { amount });
    }
}
TokensTokensService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokensTokensService_Factory() { return new TokensTokensService(i0.ɵɵinject(i1.HttpClient)); }, token: TokensTokensService, providedIn: "root" });
