<div class="filter" [class.is-active]="filter">
    <div class="filter__inner">
        <div class="filter__head">
            Trees
        </div>
        <div class="filter__body">
            <div class="filter__title">FILTERS</div>
            <app-radio (checkedValue)="radioChange($event)" [config]="radio1"></app-radio>

            <div class="checkbox" [class.is-hidden]="checkboxIsHiden">
                <div class="checkbox__head" (click)="toggleVariants()">
                    Senses
                    <svg class="checkbox__arrow" width="18" height="10">
                        <use xlink:href="../../../../assets/images/sprite.svg#thick-arrow"></use>
                    </svg>
                </div>
                <div #categories class="checkbox__body">
                    <div class="checkbox__item">
                        <input class="checkbox__input" [attr.data-category]="'Smell'" type="checkbox">
                        <label (click)="checkInput($event)" class="checkbox__label">
                            <svg class="checkbox__icon" width="33" height="45">
                                <use xlink:href="../../../../assets/images/sprite.svg#smell"></use>
                            </svg>
                            <div class="checkbox__title">Smell</div>
                        </label>
                    </div>

                    <div class="checkbox__item">
                        <input class="checkbox__input" [attr.data-category]="'Taste'" type="checkbox">
                        <label (click)="checkInput($event)" class="checkbox__label">
                            <svg class="checkbox__icon" width="36" height="37">
                                <use xlink:href="../../../../assets/images/sprite.svg#bowl"></use>
                            </svg>
                            <div class="checkbox__title">Taste</div>
                        </label>
                    </div>

                    <div class="checkbox__item">
                        <input class="checkbox__input" [attr.data-category]="'Touch'" type="checkbox">
                        <label (click)="checkInput($event)" class="checkbox__label">
                            <svg class="checkbox__icon" width="36" height="39">
                                <use xlink:href="../../../../assets/images/sprite.svg#touch"></use>
                            </svg>
                            <div class="checkbox__title">Touch</div>
                        </label>
                    </div>

                    <div class="checkbox__item">
                        <input class="checkbox__input" [attr.data-category]="'6th Sense'" type="checkbox">
                        <label (click)="checkInput($event)" class="checkbox__label">
                            <svg class="checkbox__icon" width="39" height="47" viewBox="0 0 39 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.3523 12.3572C6.24101 12.5413 6.13248 12.7267 6.01845 12.9067C3.99611 16.1078 2.60026 19.5508 2.41066 23.3605C2.09467 29.6803 4.97705 34.3089 10.2692 37.5031C11.8588 38.4648 13.6379 39.1078 15.4446 39.95C15.8059 41.0202 16.2236 42.2512 16.6811 43.5976C14.6591 43.3433 12.7023 42.7143 10.9108 41.7429C7.13678 39.7522 4.35881 36.797 2.58926 32.924C-1.05012 24.9391 1.95591 16.3016 6.3523 12.3572Z"/>
                                <path d="M24.3843 43.1209C25.1275 42.7389 25.8131 42.4339 26.4561 42.0492C31.6864 38.9635 35.1018 34.5163 36.1144 28.4891C37.006 23.1915 35.275 18.6893 31.2523 15.1296C30.3647 14.3424 29.6036 13.5126 29.0472 12.4712C28.6969 11.8172 28.2531 11.2113 27.8396 10.5656L29.9773 10.0669C35.0332 13.2062 38.891 20.0549 37.8881 27.6524C36.6433 37.0841 29.3591 42.0492 24.3843 43.1209Z"/>
                                <path d="M32.5841 32.8877H6.16113C6.20767 32.7979 6.24447 32.7189 6.28797 32.6443C6.74239 31.8617 7.20046 31.0815 7.65244 30.2977C8.13454 29.4619 8.60965 28.6218 9.09114 27.7856C9.46434 27.1372 9.84455 26.4926 10.2187 25.8448C10.5837 25.2151 10.9395 24.5825 11.3033 23.9529C11.6853 23.2919 12.0735 22.634 12.4555 21.9728C12.9692 21.0843 13.4781 20.1931 13.9915 19.3043C14.3605 18.6656 14.7358 18.0305 15.1038 17.3912C15.6562 16.4315 16.2037 15.4692 16.7554 14.5104C17.2807 13.5998 17.8118 12.6928 18.3371 11.783C18.6273 11.2811 18.9132 10.7765 19.2021 10.2737C19.2493 10.1916 19.3013 10.1125 19.37 10L32.5841 32.8877ZM19.3697 11.4819C19.3211 11.5576 19.2864 11.6069 19.2566 11.6586C18.8105 12.4336 18.3644 13.2088 17.9183 13.9842C17.3099 15.0387 16.7008 16.0931 16.0908 17.1475C15.6184 17.9663 15.1467 18.7855 14.6759 19.6052C14.2219 20.3934 13.7673 21.1813 13.3123 21.9691C12.7648 22.9187 12.2162 23.8682 11.6665 24.8176C11.1883 25.6449 10.7102 26.4723 10.2321 27.2996C9.86493 27.9335 9.49476 28.5658 9.12764 29.2C8.65922 30.0091 8.19173 30.8188 7.72514 31.6293C7.63054 31.7933 7.53869 31.9591 7.43375 32.1446H31.2801C31.27 32.1028 31.256 32.0621 31.2385 32.0229C30.9532 31.5288 30.6667 31.035 30.3792 30.5417C30.0087 29.9035 29.6385 29.2648 29.2684 28.6254C28.8235 27.8549 28.3794 27.0843 27.9362 26.3138C27.643 25.8058 27.3467 25.2997 27.0541 24.793C26.6891 24.1585 26.3259 23.5219 25.9591 22.8877C25.585 22.2398 25.2069 21.5941 24.8337 20.9459C24.4687 20.316 24.1098 19.6839 23.7463 19.0537C23.4422 18.5236 23.1325 17.9949 22.8262 17.4654C22.4444 16.8037 22.063 16.1424 21.682 15.4813C21.3372 14.8862 20.9938 14.2908 20.6518 13.6953C20.3598 13.187 20.0708 12.6769 19.7782 12.169C19.6499 11.9466 19.5157 11.7273 19.3697 11.4819Z"/>
                                <path d="M12.4348 24.9633C12.7311 24.6786 13.0203 24.39 13.3211 24.1138C13.8932 23.5923 14.5146 23.1276 15.1765 22.7262C15.9178 22.2724 16.7019 21.9101 17.5417 21.6838C18.1477 21.52 18.7727 21.4373 19.4005 21.4377C20.3394 21.4377 21.2236 21.6507 22.0874 21.9895C23.0854 22.38 23.979 22.9461 24.8161 23.6079C25.3042 23.9936 25.7619 24.4164 26.1848 24.8727C26.2736 24.9685 26.2727 25.0211 26.1848 25.1181C25.4104 25.9698 24.5198 26.6806 23.5419 27.281C22.8492 27.7103 22.1025 28.0459 21.3215 28.279C20.5378 28.5103 19.719 28.5997 18.9038 28.543C17.7452 28.4651 16.6834 28.0868 15.6687 27.5441C14.9054 27.1367 14.191 26.6435 13.5395 26.0741C13.1824 25.7626 12.8576 25.4144 12.5191 25.0816C12.4885 25.044 12.4603 25.0045 12.4348 24.9633ZM19.3588 27.9C20.9255 27.9249 22.2751 26.6411 22.2787 24.9937C22.2812 23.4011 20.9657 22.0884 19.3892 22.0841C17.8009 22.0798 16.4586 23.3397 16.4562 24.9934C16.4537 26.6471 17.8069 27.9158 19.3588 27.9ZM22.1166 27.2853C23.3582 26.7174 24.4304 25.9369 25.4037 24.9962C24.4234 24.0575 23.3628 23.2758 22.1227 22.7149C23.2074 24.2531 23.1912 25.762 22.1166 27.2853ZM16.6155 27.2828C15.5373 25.762 15.5297 24.2482 16.6025 22.7271C15.5552 23.149 14.0812 24.2111 13.3397 25.0083C14.3075 25.9351 15.3703 26.7244 16.6155 27.2828Z"/>
                                <path d="M20.3403 23.4418C19.842 23.4853 19.5729 23.7348 19.5774 24.1332C19.5826 24.3014 19.6501 24.4617 19.7668 24.5828C19.8836 24.704 20.0413 24.7774 20.2092 24.7887C20.5979 24.8097 20.8516 24.5469 20.9258 24.0456C21.2813 24.4818 21.3921 25.5153 20.7506 26.221C20.0966 26.9409 19.0308 27.0894 18.1917 26.4232C17.4407 25.8271 17.3096 24.6567 17.8711 23.9072C18.5198 23.0434 19.6638 22.9707 20.3403 23.4418Z"/>
                                <line class="stroke" x1="18.7553" y1="22.0513" x2="14.1411" y2="0.0512245" stroke-width="0.5"/>
                                <path class="stroke" d="M21 22L26.0381 4.41323" stroke-width="0.5"/>
                                <path class="stroke" d="M14.5 23.4998L2.99996 5.32407" stroke-width="0.5"/>
                                <path class="stroke" d="M16.2325 22.5162L10.4988 10.2509" stroke-width="0.5"/>
                                <path class="stroke" d="M24.9999 24.3864L29.2063 22.2986" stroke-width="0.5"/>
                                <line class="stroke" x1="22.8232" y1="22.8232" x2="29.8232" y2="15.8232" stroke-width="0.5"/>
                            </svg>
                            <div class="checkbox__title">6th Sense</div>
                        </label>
                    </div>

                    <div class="checkbox__item">
                        <input class="checkbox__input" [attr.data-category]="'Vision'" type="checkbox">
                        <label (click)="checkInput($event)" class="checkbox__label">
                            <svg class="checkbox__icon" width="38" height="44" viewBox="0 0 38 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.3038 36.4297C13.6901 36.3797 13.0673 36.3478 12.4651 36.2761C10.3259 36.0225 8.6926 35.4478 7.61914 34.5249C7.71673 34.5526 7.81817 34.5785 7.9119 34.6086C10.3413 35.3948 13.048 35.6628 15.9525 35.5496C17.7694 35.4797 19.5016 35.2249 21.2505 35.0074C22.93 34.7996 24.6198 34.6267 26.3751 34.7135C28.2857 34.8086 29.9909 35.1526 31.5613 35.658C31.6088 35.6737 31.6409 35.7002 31.6024 35.6797C29.7713 35.6195 27.8787 35.546 25.9732 35.4954C24.5265 35.4554 23.0785 35.5625 21.7281 35.8092C19.7533 36.1707 17.7232 36.3923 15.5956 36.4002C15.4845 36.4052 15.3745 36.4146 15.2669 36.4285L14.3038 36.4297Z"/>
                                <path d="M28.8662 16.1155C27.9227 15.6069 27.0676 15.0691 26.1516 14.6704C24.8737 14.0833 23.4282 13.9749 22.0774 14.3649C21.2284 14.6124 20.7254 14.9867 20.4815 15.4663C21.4525 15.5534 22.4767 15.5641 23.4659 15.752C25.0894 16.0377 26.6021 16.769 27.8358 17.8646C29.6084 19.4563 30.1008 21.4116 29.4225 23.6694C29.0399 24.9465 28.1269 26.558 27.4868 27.1462C27.3023 26.2739 27.1926 25.3635 26.9121 24.5096C25.8955 21.4071 23.9476 19.1371 20.8443 17.9578C19.9879 17.6175 19.0386 17.5937 18.1663 17.8906C13.9717 19.2746 11.4872 22.1678 10.5651 26.4633C10.5133 26.7047 10.4721 26.9491 10.4127 27.2867C10.2938 27.0728 10.2023 26.9369 10.1368 26.7887C9.41737 25.1557 8.86408 23.4785 8.86103 21.6652C8.86103 18.7094 10.5056 16.4791 13.3407 15.6145C14.3962 15.2795 15.5063 15.1519 16.6101 15.2387C16.6969 15.2463 16.7869 15.2387 16.9027 15.2387C16.2031 14.2076 14.1942 13.7554 12.4612 14.1984C10.8333 14.6155 9.66429 15.6038 8.89761 17.1008L8.76196 17.0779C8.7955 16.6685 8.81836 16.2576 8.86256 15.8558C9.11405 13.5263 10.6047 11.6443 12.6471 11.0959C14.0083 10.7308 15.3419 10.9126 16.6101 11.4549C17.3903 11.8075 18.1441 12.2159 18.8659 12.677C19.1707 12.8664 19.3704 12.8664 19.6691 12.6693C20.9205 11.8429 22.2603 11.212 23.7631 11.018C25.4824 10.7965 26.8314 11.3953 27.7611 12.8664C28.3634 13.8548 28.7408 14.9642 28.8662 16.1155Z"/>
                                <path d="M19.402 34.729C20.0827 37.9549 23.2786 42.8919 26.6579 43.8896C26.2648 44.1346 24.9204 44.1915 22.6871 42.4596C19.8954 40.2947 18.2671 34.1585 17.7435 31.6771C17.3246 29.6919 17.2318 22.9293 17.2377 19.7962C17.1941 19.5894 17.4073 19.1772 18.6092 19.1828C20.1117 19.1897 18.8754 25.1204 18.7237 26.7724C18.5719 28.4244 18.5511 30.6967 19.402 34.729Z"/>
                                <circle cx="11.6192" cy="6.90538" r="3.23814"/>
                                <path class="stroke" d="M15.6167 4.44505C15.837 4.30494 16.0782 4.15161 16.2912 4.0161C16.3996 3.9471 16.7779 3.70668 16.8335 3.67126" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M6.4763 9.77464C6.69662 9.63453 6.93785 9.4812 7.15083 9.34569C7.25927 9.27669 7.63751 9.03627 7.6931 9.00085" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M15.6192 9.00071C15.8386 9.1422 16.0788 9.29714 16.291 9.43388C16.399 9.5035 16.7756 9.74645 16.8311 9.78212" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M8.38112 1.76244C8.53314 1.97471 8.69952 2.20713 8.84654 2.41234C8.9214 2.51681 9.18228 2.88125 9.2207 2.9348" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M14.4763 10.5246C14.6284 10.7369 14.7947 10.9693 14.9418 11.1745C15.0166 11.279 15.2775 11.6435 15.3159 11.697" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M14.6919 1.76246C14.5839 2.00017 14.4656 2.26038 14.3612 2.49022C14.3081 2.60725 14.1226 3.01526 14.0954 3.07529" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M9.35867 10.9058C9.25066 11.1435 9.13237 11.4037 9.02799 11.6335C8.97486 11.7506 8.78937 12.1586 8.76216 12.2186" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M11.4595 1C11.4589 1.26109 11.4582 1.54693 11.4577 1.79936C11.4575 1.92788 11.4564 2.37608 11.4563 2.44198" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M11.4595 11.2864C11.4589 11.5475 11.4582 11.8333 11.4577 12.0857C11.4575 12.2143 11.4564 12.6625 11.4563 12.7284" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M16.0002 6.71506C16.2612 6.72193 16.547 6.7295 16.7993 6.73609C16.9278 6.73943 17.3758 6.75131 17.4417 6.75299" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M5.71436 6.3342C5.97475 6.35325 6.25982 6.37416 6.51159 6.39252C6.63977 6.40187 7.08676 6.43466 7.1525 6.43941" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M6.47633 3.66746C6.69577 3.80894 6.93597 3.96389 7.14815 4.10063C7.25619 4.17025 7.63282 4.4132 7.68824 4.44887" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5.50575 10.4768C5.39204 10.6649 5.28114 10.8544 5.16463 11.0383C3.09826 14.3091 1.67202 17.827 1.47829 21.7197C1.15542 28.1771 4.10056 32.9064 9.50793 36.1702C11.1321 37.1529 12.95 37.8099 14.796 38.6704C15.1652 39.7639 15.5919 41.0217 16.0594 42.3974C13.9934 42.1376 11.994 41.4949 10.1635 40.5023C6.30731 38.4682 3.46886 35.4487 1.66078 31.4914C-2.05784 23.3326 1.01364 14.5071 5.50575 10.4768Z"/>
                                <path d="M23.9304 41.9103C24.6899 41.5201 25.3904 41.2084 26.0473 40.8154C31.3915 37.6625 34.8813 33.1184 35.9159 26.96C36.827 21.5471 35.0582 16.9469 30.9479 13.3097C30.0411 12.5053 29.2634 11.6574 28.6949 10.5933C28.3369 9.92514 27.8835 9.30607 27.4609 8.64629L29.6452 8.13672C34.8111 11.3444 38.753 18.3422 37.7282 26.1051C36.4564 35.7421 29.0135 40.8154 23.9304 41.9103Z"/>
                            </svg>
                            <div class="checkbox__title">Vision</div>
                        </label>
                    </div>

                    <div class="checkbox__item">
                        <input class="checkbox__input" [attr.data-category]="'Sound'" type="checkbox">
                        <label (click)="checkInput($event)" class="checkbox__label">
                            <svg class="checkbox__icon" width="35" height="47" viewBox="0 0 35 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.9706 18.9886C27.1541 18.4894 26.3831 17.9732 25.5723 17.5311C24.3863 16.8811 23.1375 16.3909 21.7655 16.3022C21.649 16.2949 21.5313 16.2892 21.4141 16.2912C21.0267 16.2974 20.823 16.4366 20.6947 16.8046C20.4655 17.4592 20.2447 18.117 20.0215 18.7739C19.1803 21.2497 18.3431 23.7257 17.4977 26.2C16.9515 27.7925 15.4105 28.7653 13.7832 28.4506C12.2419 28.1515 11.1758 27.2008 10.6955 25.699C10.294 24.4493 10.9819 23.3399 12.2725 23.0719C13.4758 22.8208 14.549 23.1838 15.5368 23.8558C15.7509 24.0136 15.9561 24.1832 16.1513 24.3638C16.4963 24.6617 16.8879 24.5687 17.0354 24.1359C18.0186 21.2598 18.9992 18.3823 19.9771 15.5032C20.191 14.8764 20.4046 14.2501 20.6147 13.6204C20.8085 13.0388 20.9956 12.9378 21.5955 13.1023C23.4312 13.6039 24.9512 14.6004 26.1867 16.0385C26.9335 16.9212 27.5356 17.917 27.9706 18.9886Z"/>
                                <path d="M27.9706 17.9886C27.1541 17.4894 26.3831 16.9732 25.5723 16.5311C24.3863 15.8811 23.1375 15.3909 21.7655 15.3022C21.649 15.2949 21.5313 15.2892 21.4141 15.2912C21.0267 15.2974 20.823 15.4366 20.6947 15.8046C20.4655 16.4592 20.2447 17.117 20.0215 17.7739C19.1803 20.2497 18.3431 22.7257 17.4977 25.2C16.9515 26.7925 15.4105 27.7653 13.7832 27.4506C12.2419 27.1515 11.1758 26.2008 10.6955 24.699C10.294 23.4493 10.9819 22.3399 12.2725 22.0719C13.4758 21.8208 14.549 22.1838 15.5368 22.8558C15.7509 23.0136 15.9561 23.1832 16.1513 23.3638C16.4963 23.6617 16.8879 23.5687 17.0354 23.1359C18.0186 20.2598 18.9992 17.3823 19.9771 14.5032C20.191 13.8764 20.4046 13.2501 20.6147 12.6204C20.8085 12.0388 20.9956 11.9378 21.5955 12.1023C23.4312 12.6039 24.9512 13.6004 26.1867 15.0385C26.9335 15.9212 27.5356 16.917 27.9706 17.9886Z"/>
                                <path d="M5.0734 10.1365C4.96863 10.3098 4.86644 10.4844 4.75907 10.6539C2.85497 13.6679 1.54072 16.9095 1.36221 20.4965C1.06469 26.4468 3.77856 30.8048 8.76131 33.8123C10.2579 34.7178 11.9331 35.3232 13.6341 36.1161C13.9743 37.1238 14.3676 38.2828 14.7983 39.5505C12.8946 39.3111 11.0521 38.7188 9.3654 37.8042C5.81202 35.9298 3.19646 33.1474 1.53037 29.5009C-1.89624 21.9828 0.934044 13.8503 5.0734 10.1365Z"/>
                                <path d="M22.0513 39.1017C22.7511 38.7421 23.3966 38.455 24.0019 38.0928C28.9265 35.1875 32.1423 31.0002 33.0956 25.3254C33.9351 20.3375 32.3052 16.0985 28.5177 12.747C27.6821 12.0058 26.9655 11.2244 26.4416 10.2439C26.1117 9.62821 25.6939 9.05775 25.3045 8.44978L27.3173 7.98022C32.0776 10.936 35.7099 17.3843 34.7656 24.5377C33.5936 33.4179 26.7352 38.0928 22.0513 39.1017Z"/>
                                <path d="M6.37812 6.89567C5.97903 7.19781 5.68795 7.46962 5.3548 7.66394C4.593 8.1031 3.77434 8.03122 3.37639 7.53252C2.97843 7.03382 3.13761 6.19705 3.77434 5.56694C4.759 4.59313 6.17118 4.79306 6.78972 6.03868C7.54016 7.54937 8.25193 9.07803 8.95006 10.6146C9.15018 11.0571 9.36621 11.1851 9.85968 11.0549C11.4686 10.6314 13.0922 10.2619 14.7079 9.86539C15.3617 9.70478 15.4425 9.52843 15.1525 8.90956C14.7773 8.11434 14.3998 7.31799 14.0155 6.52727C13.796 6.07799 13.6937 6.06563 13.3265 6.42169C12.85 6.88444 12.3031 7.19781 11.6209 7.1877C10.7807 7.17647 10.3043 6.53738 10.5271 5.74103C10.75 4.94469 11.514 4.28088 12.31 4.21911C13.0843 4.15508 13.7301 4.56842 14.1292 5.39397C14.9888 7.18583 15.8457 8.9777 16.7 10.7696C16.9854 11.3693 16.8819 11.576 16.2327 11.7052C14.1349 12.1208 12.0337 12.5172 9.94268 12.9575C9.49697 13.0508 9.29458 12.9407 9.11152 12.5487C8.32811 10.8639 7.51969 9.19822 6.71696 7.52466C6.60553 7.3281 6.50092 7.13604 6.37812 6.89567Z"/>
                                <path d="M18.6769 30.7384C17.9651 31.3655 17.2374 31.9632 16.5659 32.618C16.2421 32.9597 15.9652 33.343 15.7428 33.7579C15.3517 34.4254 15.4108 34.6227 16.0019 35.1067C17.5843 36.4049 19.164 37.7055 20.7412 39.0084C21.3676 39.5241 21.6908 40.1791 21.6183 41.0013C21.4998 42.3453 20.0024 43.4601 18.6755 43.1981C17.8603 43.037 17.4032 42.3317 17.5923 41.4924C17.7875 40.6217 18.3402 40.0215 19.0912 39.5847C19.6907 39.2365 19.7043 39.1121 19.1624 38.658C17.3939 37.1772 15.6215 35.6971 13.8453 34.2177C13.2833 33.7486 13.2668 33.6561 13.7513 33.08C15.0373 31.555 16.7065 30.8377 18.6769 30.7384Z"/>
                                <path class="stroke" d="M17.9032 46.1623C18.6638 40.8967 18.6053 28.3644 12.2865 20.3607C4.38808 10.356 6.31882 5.61688 5.96777 4.91479" stroke-width="0.5"/>
                                <path class="stroke" d="M24.1139 36.6842C24.1139 31.243 23.2363 25.2753 17.7952 17.7278C10.3408 7.38797 12.354 2.80853 12.0029 2.10645" stroke-width="0.5"/>
                                <path class="stroke" d="M30.111 33.6999C30.111 28.4343 29.0578 23.8707 23.9677 16.4989C16.7251 6.00958 18.351 1.75506 18 1.05298" stroke-width="0.5"/>
                            </svg>
                            <div class="checkbox__title">Sound</div>
                        </label>
                    </div>
                </div>
            </div>

            <div *ngIf="!onlyFree">
                <app-radio (checkedValue)="radioChange($event)" [config]="radio2"></app-radio>
            </div>

        </div>
    </div>
</div>

<button *ngIf="!filter" class="btn btn_filter" (click)="openFilter()" >
    <svg width="23" height="26">
        <use xlink:href="../../../../assets/images/sprite.svg#filter"></use>
    </svg>
    <span class="btn__title">Filter</span>
</button>

<button *ngIf="filter" class="btn btn_filter" (click)="closeFilter()">
    <svg width="23" height="26">
        <use xlink:href="../../../../assets/images/sprite.svg#filter"></use>
    </svg>
    <span class="btn__title">Close</span>
</button>
