export class ActivityItem {
    public _title: string;
    private _date: string;
    private _currency: string;
    public _amount: string;
    public _direction?: string;

    constructor(data) {
        if(data){
            this._title = data.transaction_title;
            this._date = data.created;
            this._currency = data.currency;
            this._amount = data.tokens;
            this._direction = data.transaction_type_id;
        }

    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get currency(): string {
        return this._currency;
    }

    set currency(value: string) {
        this._currency = value;
    }

    get amount(): string {
        return this._amount;
    }

    set amount(value: string) {
        this._amount = value;
    }

    get direction(): string {
        return this._direction;
    }

    set direction(value: string) {
        this._direction = value;
    }

}
