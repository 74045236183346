<div class="radio" [class.is-hidden]="ishiden" >
    <div class="radio__head" (click)="toggleVariants()">
        {{config.title}}
        <svg class="radio__arrow" width="18" height="10">
            <use xlink:href="../../../../assets/images/sprite.svg#thick-arrow"></use>
        </svg>
    </div>
    <div class="radio__body">
        <div *ngFor="let item of config.variants" class="radio__item">
            <input value="{{item.id}}" class="radio__input" name="{{config.name}}" type="radio">
            <label (click)="checkInput($event)" class="radio__label">{{item.label}}</label>
        </div>
    </div>
</div>
