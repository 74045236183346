import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopupComponent} from "../../../shared/popup/popup.component";
import {ArticleService} from "../../../service/article.service";

@Component({
    selector: 'app-article-footer',
    templateUrl: './article-footer.component.html',
    styleUrls: ['./article-footer.component.scss']
})
export class ArticleFooterComponent implements OnInit {
    @Output() isReporst = new EventEmitter();
    @Output() likeToggled = new EventEmitter();
    @Output() shownDetails = new EventEmitter();
    @Input() repost: boolean;
    @Input() free: boolean;
    @Input() cost;


    @Input() like;

    //cost = true;

    constructor(private popupComponent: PopupComponent,
                private articleService: ArticleService) {
    }

    ngOnInit() {

    }

    toggleLike() {
        //this.like =  !this.like;
        this.likeToggled.emit(!this.like);

    }

    toggleRepost() {
        this.repost =  !this.repost;
        this.isReporst.emit(this.repost);
    }

    showDetails(state) {
        this.shownDetails.emit(state);
    }

    askShowPaidContent() {
       this.popupComponent.showPopupAgreement({text: 'Are you sure you want to pay tokens for watching this publication?',
           confirmButtonText: 'Yes, I am!',
           cancelButtonText: 'No, I am not!'}, (status) => {
           if (status) {
               //this.deletePublication();
               this.showDetails('paid');
               //console.log(status)
           }
       })
    }

}
