export class UserWithActiveAd {
    public _id: string;
    public _avatar: string;
    private _firstName: string;
    private _lastName: string;
    public _price: string;
    public _days: string;

    constructor(data) {
        this._id = data.id;
        this._avatar = data.avatar;
        this._firstName = data.firstName;
        this._lastName = data.lastName;
        this._price = data.price;
        this._days = data.days;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get avatar(): string {
        return this._avatar;
    }

    set avatar(value: string) {
        this._avatar = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get price(): string {
        return this._price;
    }

    set price(value: string) {
        this._price = value;
    }

    get days(): string {
        return this._days;
    }

    set days(value: string) {
        this._days = value;
    }

}
