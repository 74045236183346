/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tokens-advertisement.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/header/header.component.ngfactory";
import * as i3 from "../../../shared/header/header.component";
import * as i4 from "../../../service/menu.service";
import * as i5 from "../../../shared/activity/activity.component.ngfactory";
import * as i6 from "../../../shared/activity/activity.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../shared/balance-card/balance-card.component.ngfactory";
import * as i9 from "../../../shared/balance-card/balance-card.component";
import * as i10 from "../../collection/collection.component.ngfactory";
import * as i11 from "../../../service/scrollUpdating.service";
import * as i12 from "../../collection/collection.component";
import * as i13 from "../../../shared/popup/popup.component";
import * as i14 from "../../../service/collection.service";
import * as i15 from "../../../service/collectionList.service";
import * as i16 from "../../../service/tokens/tokens.service";
import * as i17 from "../../../service/tokens/tokensAdvertisement.service";
import * as i18 from "../tokens-budget/tokens-budget.component.ngfactory";
import * as i19 from "../tokens-budget/tokens-budget.component";
import * as i20 from "../tokens-create-advertisement/tokens-create-advertisement.component.ngfactory";
import * as i21 from "../tokens-create-advertisement/tokens-create-advertisement.component";
import * as i22 from "./tokens-advertisement.component";
var styles_TokensAdvertisementComponent = [i0.styles];
var RenderType_TokensAdvertisementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TokensAdvertisementComponent, data: {} });
export { RenderType_TokensAdvertisementComponent as RenderType_TokensAdvertisementComponent };
function View_TokensAdvertisementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "nextAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.hidePopup("collection") !== false);
        ad = (pd_0 && ad);
    } if (("nextAction" === en)) {
        var pd_1 = (_co.showPopup("budget") !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { nextActionCheck: [0, "nextActionCheck"], backActionCheck: [1, "backActionCheck"] }, { nextAction: "nextAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["Collection"]))], function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TokensAdvertisementComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "nextAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.hidePopup("budget") !== false);
        ad = (pd_0 && ad);
    } if (("nextAction" === en)) {
        var pd_1 = (_co.showPopup("create") !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { nextActionCheck: [0, "nextActionCheck"], backActionCheck: [1, "backActionCheck"] }, { nextAction: "nextAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["Budget & Duration"]))], function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TokensAdvertisementComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-header", [], null, [[null, "backAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.hidePopup("create") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { backActionCheck: [0, "backActionCheck"] }, { backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["Create Advertisment"]))], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TokensAdvertisementComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "tokens__activity"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-activity", [], null, [[null, "listScrolled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("listScrolled" === en)) {
        var pd_0 = (_co.emitScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ActivityComponent_0, i5.RenderType_ActivityComponent)), i1.ɵdid(2, 638976, null, 0, i6.ActivityComponent, [], { activity: [0, "activity"], data: [1, "data"] }, { listScrolled: "listScrolled" }), (_l()(), i1.ɵted(-1, 0, ["Activity"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activity; var currVal_1 = _co.data; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TokensAdvertisementComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "tokens__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed felis a eros ultricies fringilla. Quisque lobortis elementum nisi non rutrum. In varius metus vel aliquam molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed felis a eros ultricies fringilla."])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "tokens__btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "tokens__btn"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["WEBSITE"]))], null, null); }
export function View_TokensAdvertisementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "tokens content"]], [[2, "is-visible", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "menuAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("menuAction" === en)) {
        var pd_1 = (_co.menu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { menuActionCheck: [0, "menuActionCheck"], backActionCheck: [1, "backActionCheck"] }, { menuAction: "menuAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["Advertisement"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TokensAdvertisementComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TokensAdvertisementComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TokensAdvertisementComponent_3)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 11, "div", [["class", "tokens__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "tokens__top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "app-balance-card", [], null, null, null, i8.View_BalanceCardComponent_0, i8.RenderType_BalanceCardComponent)), i1.ɵdid(13, 114688, null, 0, i9.BalanceCardComponent, [], { data: [0, "data"] }, null), (_l()(), i1.ɵted(-1, 0, ["Avaliable Balance"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "tokens__btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "tokens__btn"]], [[2, "is-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showPopup("collection") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["PURCHASE Advertisement"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TokensAdvertisementComponent_4)), i1.ɵdid(19, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TokensAdvertisementComponent_5)), i1.ɵdid(21, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["class", "popup"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 3, "div", [["class", "popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "app-collection", [], null, [[null, "closeCollection"], [null, "chooseSettings"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeCollection" === en)) {
        var pd_0 = (_co.closeCollection($event) !== false);
        ad = (pd_0 && ad);
    } if (("chooseSettings" === en)) {
        var pd_1 = (_co.showBudget($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_CollectionComponent_0, i10.RenderType_CollectionComponent)), i1.ɵprd(512, null, i11.ScrollUpdatingService, i11.ScrollUpdatingService, []), i1.ɵdid(26, 114688, null, 0, i12.CollectionComponent, [i13.PopupComponent, i14.CollectionService, i15.CollectionListService, i11.ScrollUpdatingService, i4.MenuService, i16.TokensService, i17.TokensAdvertisementService], { settings: [0, "settings"] }, { closeCollection: "closeCollection", chooseSettings: "chooseSettings" }), (_l()(), i1.ɵeld(27, 0, null, null, 3, "div", [["class", "popup"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "div", [["class", "popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "app-tokens-budget", [], null, [[null, "closeBudget"], [null, "goToNext"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeBudget" === en)) {
        var pd_0 = (_co.closeBudget($event) !== false);
        ad = (pd_0 && ad);
    } if (("goToNext" === en)) {
        var pd_1 = (_co.showCreate($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i18.View_TokensBudgetComponent_0, i18.RenderType_TokensBudgetComponent)), i1.ɵdid(30, 114688, null, 0, i19.TokensBudgetComponent, [], null, { closeBudget: "closeBudget", goToNext: "goToNext" }), (_l()(), i1.ɵeld(31, 0, null, null, 3, "div", [["class", "popup"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 2, "div", [["class", "popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "app-tokens-create-advertisement", [], null, [[null, "closeCreate"], [null, "done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeCreate" === en)) {
        var pd_0 = (_co.closeCreate($event) !== false);
        ad = (pd_0 && ad);
    } if (("done" === en)) {
        var pd_1 = (_co.doneCreate($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i20.View_TokensCreateAdvertisementComponent_0, i20.RenderType_TokensCreateAdvertisementComponent)), i1.ɵdid(34, 638976, null, 0, i21.TokensCreateAdvertisementComponent, [i17.TokensAdvertisementService, i13.PopupComponent], { settings: [0, "settings"] }, { closeCreate: "closeCreate", done: "done" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.popupCollection; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.popupBudget; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.popupCreate; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.data; _ck(_v, 13, 0, currVal_6); var currVal_8 = !_co.redeem; _ck(_v, 19, 0, currVal_8); var currVal_9 = _co.redeem; _ck(_v, 21, 0, currVal_9); var currVal_11 = _co.collectionSettings; _ck(_v, 26, 0, currVal_11); _ck(_v, 30, 0); var currVal_14 = _co.createSettings; _ck(_v, 34, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 0, 0, currVal_0); var currVal_7 = _co.redeem; _ck(_v, 16, 0, currVal_7); var currVal_10 = _co.popupCollection; _ck(_v, 22, 0, currVal_10); var currVal_12 = _co.popupBudget; _ck(_v, 27, 0, currVal_12); var currVal_13 = _co.popupCreate; _ck(_v, 31, 0, currVal_13); }); }
export function View_TokensAdvertisementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-tokens-advertisement", [], null, null, null, View_TokensAdvertisementComponent_0, RenderType_TokensAdvertisementComponent)), i1.ɵprd(512, null, i11.ScrollUpdatingService, i11.ScrollUpdatingService, []), i1.ɵdid(2, 114688, null, 0, i22.TokensAdvertisementComponent, [i17.TokensAdvertisementService, i4.MenuService, i14.CollectionService, i11.ScrollUpdatingService, i16.TokensService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TokensAdvertisementComponentNgFactory = i1.ɵccf("app-tokens-advertisement", i22.TokensAdvertisementComponent, View_TokensAdvertisementComponent_Host_0, {}, {}, []);
export { TokensAdvertisementComponentNgFactory as TokensAdvertisementComponentNgFactory };
