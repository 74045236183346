import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-policy',
    templateUrl: './policy.component.html',
    styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

    @Input() policy


    @Output() policyState: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {

    }


    hidePolicy() {
        this.policy = false;
        this.policyState.emit(this.policy)
    }
}
