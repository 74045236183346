import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TOKENS_TOKENS_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class TokensTokensService {
    private config = TOKENS_TOKENS_CONFIG;
    public tokensVisible = new BehaviorSubject(null);

    constructor(private http: HttpClient) {

    }

    show() {
        this.tokensVisible.next(true);
    }

    hide() {
        this.tokensVisible.next(false);
    }

    getData(page) {
        return this.http.post<any>(this.config.data, {page});
    }

    getActivity() {
        return this.http.post<any>(this.config.activity, {});
    }

    setRatio(amount) {
        return this.http.post<any>(this.config.submit, {amount});
    }
}
