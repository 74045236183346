import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class BackService {
    constructor(http) {
        this.http = http;
        this.storage = sessionStorage;
    }
    setLink(link) {
        this.storage = sessionStorage;
        this.storage.setItem('backLink', link);
    }
    getLink() {
        this.storage = sessionStorage;
        return this.storage.getItem('backLink');
    }
}
BackService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackService_Factory() { return new BackService(i0.ɵɵinject(i1.HttpClient)); }, token: BackService, providedIn: "root" });
