/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/collection-main/collection-main.component.ngfactory";
import * as i3 from "../../shared/collection-main/collection-main.component";
import * as i4 from "../../service/global-library/homeCollection.service";
import * as i5 from "../../service/search.service";
import * as i6 from "../../service/article.service";
import * as i7 from "../../service/global-library/loadpreview.service";
import * as i8 from "@angular/router";
import * as i9 from "../../shared/header-search/header-search.component.ngfactory";
import * as i10 from "../../shared/header-search/header-search.component";
import * as i11 from "../../service/menu.service";
import * as i12 from "@angular/forms";
import * as i13 from "../../service/menu/menuBottomAddContent.service";
import * as i14 from "../../modules/auth/_services/authentication.service";
import * as i15 from "@angular/common";
import * as i16 from "./home.component";
import * as i17 from "../../service/global-library/homeArticles.service";
import * as i18 from "../../service/login.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-collection-main", [], null, [[null, "dataChanged"], [null, "showPopup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dataChanged" === en)) {
        var pd_0 = (_co.updateData($event) !== false);
        ad = (pd_0 && ad);
    } if (("showPopup" === en)) {
        var pd_1 = (_co.showPopup($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CollectionMainComponent_0, i2.RenderType_CollectionMainComponent)), i1.ɵdid(2, 4308992, null, 0, i3.CollectionMainComponent, [i4.HomeCollectionService, i5.SearchService, i6.ArticleService, i7.LoadPreviewService, i8.ActivatedRoute], { popup: [0, "popup"], parent: [1, "parent"] }, { showPopup: "showPopup", dataChanged: "dataChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popup; var currVal_1 = "home"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-search", [], null, null, null, i9.View_HeaderSearchComponent_0, i9.RenderType_HeaderSearchComponent)), i1.ɵdid(1, 114688, null, 0, i10.HeaderSearchComponent, [i11.MenuService, i12.FormBuilder, i5.SearchService, i13.MenuBottomAddContentService, i4.HomeCollectionService, i14.AuthenticationService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = !_co.search; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i16.HomeComponent, [i17.HomeArticlesService, i8.ActivatedRoute, i8.Router, i5.SearchService, i11.MenuService, i18.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i16.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
