<div class="notifications" *ngIf="visible" [class.is-active]="visible">
    <div class="notifications__top">
        <button (click)="back()" class="notifications__back">Back</button>
        <div class="notifications__title">Notifications</div>
        <app-button class="notifications__close" (onClick)="closeMenu()" [type]="'close'"></app-button>
    </div>
    <div class="notifications__inner">

        <div class="notifications__header">
            <img src="{{currentUser.avatar}}" class="notifications__avatar"/>
            <div class="notifications__info">
                <div>{{currentUser.firstName}}</div>
                <div>{{currentUser.lastName}}</div>
            </div>
        </div>
        <div class="notifications__list" (scroll)="scrollHandler($event)">
            <ul class="notific-list">

                <li *ngFor="let item of items" class="notific-list__item">
                    <div class="notific-list__icon-wrapper">
                        <svg *ngIf="isLiked(item)" class="notific-list__icon" width="15" height="14">
                            <use xlink:href="../../../../assets/images/sprite.svg#heart"></use>
                        </svg>

                        <svg *ngIf="isShared(item)" class="notific-list__icon" width="13" height="18">
                            <use xlink:href="../../../../assets/images/sprite.svg#share"></use>
                        </svg>

                        <svg *ngIf="isPurchased(item)" class="notific-list__icon" width="16" height="16">
                            <use xlink:href="../../../../assets/images/sprite.svg#token"></use>
                        </svg>

                        <svg *ngIf="isAttention(item)" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.85456 10.3273L7.63638 5.81821V2.90912H8.80002V5.81821L8.58183 10.3273H7.85456ZM8.78547 13.0037C8.63032 13.1588 8.44123 13.2364 8.2182 13.2364C7.99517 13.2364 7.80608 13.1588 7.65092 13.0037C7.49577 12.8485 7.4182 12.6594 7.4182 12.4364C7.4182 12.2134 7.49577 12.0243 7.65092 11.8691C7.80608 11.714 7.99517 11.6364 8.2182 11.6364C8.44123 11.6364 8.63032 11.714 8.78547 11.8691C8.94062 12.0243 9.0182 12.2134 9.0182 12.4364C9.0182 12.6594 8.94062 12.8485 8.78547 13.0037Z" fill="white"/>
                            <circle cx="8" cy="8" r="7.5" stroke="white"/>
                        </svg>
                    </div>
                    <div class="notific-list__text">
                        {{getText(item)}}
                        <span class="notific-list__time">{{getTime(item)}}</span>
                    </div>
                    <img src="{{getAvatar(item)}}" class="notific-list__preview">
                </li>

            </ul>
        </div>
    </div>
    <div class="notifications__footer">
        <button (click)="back()" class="button">Back</button>
    </div>
</div>
