import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { USERS_WITH_ACTIVE_ADS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AdSettingsActiveService {
    constructor(ngZone, http) {
        this.ngZone = ngZone;
        this.http = http;
        this.config = USERS_WITH_ACTIVE_ADS_CONFIG;
        this.visible = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    getUsers(name, phone) {
        return this.http.post(this.config.data, { name, phone });
    }
    deactivateAdvertisement(id) {
        return this.http.post(this.config.deactivate, { id });
    }
}
AdSettingsActiveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdSettingsActiveService_Factory() { return new AdSettingsActiveService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.HttpClient)); }, token: AdSettingsActiveService, providedIn: "root" });
