import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-publication-statistics',
    templateUrl: './publication-statistics.component.html',
    styleUrls: ['./publication-statistics.component.scss']
})
export class PublicationStatisticsComponent implements OnInit {

    @Input() data;
    @Output() likeEmitted = new EventEmitter();

    repost = false;


    constructor() {
    }

    ngOnInit() {

    }

    toggleRepost() {
        this.repost = !this.repost;
    }

    emitLikeToggle() {
        //this.data.likedByUser = !this.data.likedByUser;
        //console.log(this.data.likedByUser)
        this.likeEmitted.emit(!this.data.likedByUser);
    }

}
