<div class="menu js-publication-menu" [class.is-active]="menu">
    <a *ngFor="let item of list" class="menu__item" (click)="setCommand(item.cmd)">
        <svg *ngIf="item.icon === 'pin'" class="menu__icon" width="17" height="20">
            <use xlink:href="../../../../assets/images/sprite.svg#pin"></use>
        </svg>

        <svg *ngIf="item.icon === 'audio'" class="menu__icon" width="18" height="20">
            <use xlink:href="../../../../assets/images/sprite.svg#audio"></use>
        </svg>

        <svg *ngIf="item.icon === 'gallery'" class="menu__icon" width="21" height="20">
            <use xlink:href="../../../../assets/images/sprite.svg#gallery"></use>
        </svg>

        <svg *ngIf="item.icon === 'camera'" class="menu__icon" width="20" height="20">
            <use xlink:href="../../../../assets/images/sprite.svg#camera"></use>
        </svg>
        {{item.title}}
    </a>
</div>
