/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./article-slider/article-slider.component.ngfactory";
import * as i3 from "./article-slider/article-slider.component";
import * as i4 from "../../service/article.service";
import * as i5 from "./article-footer/article-footer.component.ngfactory";
import * as i6 from "./article-footer/article-footer.component";
import * as i7 from "../../shared/popup/popup.component";
import * as i8 from "./article-details/article-details.component.ngfactory";
import * as i9 from "./article-details/article-details.component";
import * as i10 from "../../service/search.service";
import * as i11 from "./article.component";
import * as i12 from "../../modules/auth/_services/authentication.service";
import * as i13 from "../../service/login.service";
var styles_ArticleComponent = [i0.styles];
var RenderType_ArticleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArticleComponent, data: {} });
export { RenderType_ArticleComponent as RenderType_ArticleComponent };
export function View_ArticleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "popup"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "popup__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "25"], ["viewBox", "0 0 25 25"], ["width", "25"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M18.0312 17.0921L12.9599 12.0208L18.0312 6.94953C18.2905 6.69019 18.2905 6.26975 18.0312 6.01041C17.7718 5.75107 17.3514 5.75107 17.092 6.01041L12.0208 11.0817L6.94947 6.01041C6.69013 5.75107 6.26968 5.75107 6.01034 6.01041C5.751 6.26975 5.751 6.69019 6.01034 6.94953L11.0816 12.0208L6.01034 17.0921C5.751 17.3514 5.751 17.7719 6.01034 18.0312C6.26968 18.2906 6.69013 18.2906 6.94947 18.0312L12.0208 12.9599L17.092 18.0312C17.3514 18.2906 17.7718 18.2906 18.0312 18.0312C18.2905 17.7719 18.2905 17.3514 18.0312 17.0921Z"], ["fill", "white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "popup__slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-article-slider", [], null, [[null, "changedSlide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changedSlide" === en)) {
        var pd_0 = (_co.onSlideChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ArticleSliderComponent_0, i2.RenderType_ArticleSliderComponent)), i1.ɵdid(7, 2211840, null, 0, i3.ArticleSliderComponent, [i1.ElementRef, i4.ArticleService], { repost: [0, "repost"], free: [1, "free"], data: [2, "data"], like: [3, "like"] }, { changedSlide: "changedSlide" }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "popup__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-article-footer", [], null, [[null, "likeToggled"], [null, "shownDetails"], [null, "isReporst"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("likeToggled" === en)) {
        var pd_0 = (_co.toggleLike($event) !== false);
        ad = (pd_0 && ad);
    } if (("shownDetails" === en)) {
        var pd_1 = (_co.showDetails($event) !== false);
        ad = (pd_1 && ad);
    } if (("isReporst" === en)) {
        var pd_2 = (_co.isRepost($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_ArticleFooterComponent_0, i5.RenderType_ArticleFooterComponent)), i1.ɵdid(10, 114688, null, 0, i6.ArticleFooterComponent, [i7.PopupComponent, i4.ArticleService], { repost: [0, "repost"], free: [1, "free"], cost: [2, "cost"], like: [3, "like"] }, { isReporst: "isReporst", likeToggled: "likeToggled", shownDetails: "shownDetails" }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-article-details", [], null, [[null, "likeEmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("likeEmitted" === en)) {
        var pd_0 = (_co.toggleLike($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ArticleDetailsComponent_0, i8.RenderType_ArticleDetailsComponent)), i1.ɵdid(12, 638976, null, 0, i9.ArticleDetailsComponent, [i4.ArticleService, i10.SearchService], { data: [0, "data"], activePublication: [1, "activePublication"] }, { likeEmitted: "likeEmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.repost; var currVal_2 = _co.free; var currVal_3 = _co.data; var currVal_4 = _co.like; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.repost; var currVal_6 = _co.free; var currVal_7 = _co.cost; var currVal_8 = _co.like; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.data; var currVal_10 = _co.activeSlide; _ck(_v, 12, 0, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible && !_co.search); _ck(_v, 0, 0, currVal_0); }); }
export function View_ArticleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-article", [], null, null, null, View_ArticleComponent_0, RenderType_ArticleComponent)), i1.ɵdid(1, 114688, null, 0, i11.ArticleComponent, [i4.ArticleService, i7.PopupComponent, i12.AuthenticationService, i13.LoginService, i10.SearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArticleComponentNgFactory = i1.ɵccf("app-article", i11.ArticleComponent, View_ArticleComponent_Host_0, {}, {}, []);
export { ArticleComponentNgFactory as ArticleComponentNgFactory };
