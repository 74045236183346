<div class="page content" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">Ad Settings</app-header>
    <div class="page__inner">
        <div class="page__nav">
            <app-nav (clickAction)="showChild($event)" [navItems]="navItems"></app-nav>
        </div>
    </div>
</div>

<app-ad-settings-active></app-ad-settings-active>
<app-settings-cost></app-settings-cost>

