import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { COLLECTION_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CollectionService {
    constructor(ngZone, http) {
        this.ngZone = ngZone;
        this.http = http;
        this.config = COLLECTION_CONFIG;
        this.visible = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    getCollection(type, sense, page) {
        return this.http.post(this.config.data, { type, sense, page });
    }
    // getPost(id) {
    //     return this.http.post<any>(this.config.post, {id});
    // }
    deletePost(id) {
        return this.http.post(this.config.delete, { id });
    }
}
CollectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CollectionService_Factory() { return new CollectionService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.HttpClient)); }, token: CollectionService, providedIn: "root" });
