import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NEW_CONTENT_CONFIG} from '../../config/api';


import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";

@Injectable({
    providedIn: 'root'
})
export class NewContentService {
    private config = NEW_CONTENT_CONFIG;
    public newContentChanged = new BehaviorSubject(false);
    public newContentId = new BehaviorSubject(0);


    constructor(private http: HttpClient,
                private popupComponent: PopupComponent) {

    }

    loadData(id) {
        return this.http.post<any>(this.config.info, {id:id});
    }

    saveData(id,title,description,categories,paymentType,tokens,partOfFree,tags) {
        return this.http.post<any>(this.config.save, {id,title,description,categories,paymentType,tokens,partOfFree,tags});
    }

    show() {
        this.newContentChanged.next(true);
        document.body.classList.add('is-hidden');
    }

    hide() {
        this.newContentChanged.next(false);
        document.body.classList.remove('is-hidden');
    }

    setId(id) {
        this.newContentId.next(id);
    }
}
