<div class="account content" *ngIf="visible" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">account settings</app-header>
    <div class="account__inner">
        <div class="account__top">
            <div class="account__avatar-wrapper">
                <img src="../../../assets/images/woman-avatar.jpg" alt="" class="account__avatar">
                <button class="account__btn-edit">
                    <svg width="14" height="14">
                        <use xlink:href="../../../../assets/images/sprite.svg#edit-pencil"></use>
                    </svg>
                </button>
            </div>
        </div>

        <div class="account__nav">
            <app-nav (clickAction)="showChild($event)" [navItems]="navItems"></app-nav>
        </div>

        <div class="account__policy">
            Terms and Creative Content Policy
            <button (click)="showPolicy()" class="account__policy-read">READ POLICIES</button>
        </div>
    </div>
</div>

<app-policy (policyState)="policyState($event)" [policy]="policy" ></app-policy>

<app-account-info></app-account-info>
<app-account-payment></app-account-payment>
