import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TOKEN_TO_DOLLAR_CONFIG} from "../../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class DistributionsRatioService {
    private config = TOKEN_TO_DOLLAR_CONFIG;
    public visible = new BehaviorSubject(null);

    constructor(private http: HttpClient) {

    }

    show() {
        this.visible.next(true);
    }

    hide() {
        this.visible.next(false);
    }

    getRatio() {
        return this.http.post<any>(this.config.data, {});
    }

    saveRatio(tokens) {
        return this.http.post<any>(this.config.save, {tokens});
    }
}
