import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TokensService} from "../../service/tokens/tokens.service";
import {TokensTokensService} from "../../service/tokens/tokensTokens.service";
import {TokensAdvertisementService} from "../../service/tokens/tokensAdvertisement.service";
import {TokensDistributionService} from "../../service/tokens/tokensDistribution.service";
import {TokensSweepstakesService} from "../../service/tokens/tokensSweepstakes.service";
import {MenuService} from "../../service/menu.service";

@Component({
    selector: 'app-tokens',
    templateUrl: './tokens.component.html',
    styleUrls: ['./tokens.component.scss']
})
export class TokensComponent implements OnInit {

    visibility:boolean;

    navItems = [
        {
            icon: 'token-i.svg',
            title: 'Tokens',
            url: 'tokens-tokens'
        },
        {
            icon: 'attention-i.svg',
            title: 'Advertisement',
            url: 'tokens-advertisement'
        },
        {
            icon: 'distribution-i.svg',
            title: 'Distribution',
            url: 'tokens-distribution'
        },
        {
            icon: 'sweepstakes-i.svg',
            title: 'Sweepstakes',
            url: 'tokens-sweepstakes'
        }
    ];




    constructor(private tokensService: TokensService,
                private tokensTokensService: TokensTokensService,
                private tokensAdvertisementService: TokensAdvertisementService,
                private tokensDistributionService: TokensDistributionService,
                private menuService: MenuService,
                private tokensSweepstakesService: TokensSweepstakesService) {
        this.tokensService.tokensVisible.subscribe((value: any) => this.tokensVisibility(value));
    }

    ngOnInit() {

    }

    tokensVisibility(state) {
        console.log(state);
        this.visibility = state;
    }

    goBack() {
        this.tokensService.hide();
    }

    menu() {
        //this.tokensService.hide();
        this.menuService.show();
    }

    showTokens() {
        this.tokensTokensService.show();
    }

    showChild(child) {
        switch (child) {
            case 'tokens-tokens':
                this.tokensTokensService.show();
                break;
            case 'tokens-advertisement':
                this.tokensAdvertisementService.show();
                break;
            case 'tokens-distribution':
                this.tokensDistributionService.show();
                break;
            case 'tokens-sweepstakes':
                this.tokensSweepstakesService.show();
                break;
            default:
                return;
        }
    }

}
