import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthConfig } from './auth-config';
import { AUTH_CONFIG } from '../../../config/api';
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "../user-idle/user-idle.service";
import * as i4 from "@angular/router";
export class AuthenticationService {
    constructor(authConfig, http, userIdle, router) {
        this.authConfig = authConfig;
        this.http = http;
        this.userIdle = userIdle;
        this.router = router;
        this.config = AUTH_CONFIG;
        this.storage = sessionStorage;
        this.rolesObservable = new EventEmitter();
        this.userLoggedIn = new EventEmitter();
    }
    isUserLogged() {
        return !!this.storage.getItem('currentUser');
    }
    sendPin(phone) {
        return this.http.post(this.config.sendPin, { phone });
    }
    checkUser(phone, userName) {
        return this.http.post(this.config.checkUser, { phone: phone, userName: userName });
    }
    checkPhoneAndSendPin(phone) {
        return this.http.post(this.config.checkPhoneAndSendPin, { phone: phone });
    }
    register(phone, firstName, lastName, userName, pin) {
        return this.http.post(this.config.register, { phone: phone, firstName: firstName, lastName: lastName, userName: userName, pin: pin }).pipe(map((res) => {
            if (res.status) {
                this.setData(res.result);
            }
            return res;
        }));
    }
    login(phone, pin) {
        this.storage = sessionStorage;
        return this.http.post(this.config.login, { phone, pin })
            .pipe(map((res) => {
            if (res.status) {
                this.setData(res.result);
                this.userLoggedIn.emit(true);
            }
            return res;
        }));
    }
    setData(result) {
        this.storage = sessionStorage;
        this.storage.setItem('currentUser', JSON.stringify(result));
        //this.roles = [];
        //this.roles = [result.role];
        //this.rolesObservable.next(this.roles);
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.storage.removeItem('currentUser');
            yield this.checkSession();
            this.userLoggedIn.emit(false);
        });
    }
    refreshToken() {
        // if (!this.storage.getItem('currentUser')) {
        //     return;
        // }
        // if (this.authConfig.refreshTokenUrl) {
        //     return this.http.post<any>(this.authConfig.refreshTokenUrl, {}).subscribe(res => {
        //         this.storage.removeItem('currentUser');
        //         if (res && res.token) {
        //             this.storage.setItem('currentUser', JSON.stringify({token: res.token}));
        //         }
        //     }, error => {
        //         this.storage.removeItem('currentUser');
        //     });
        // }
    }
    checkSession() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // this.storage = sessionStorage;
            // if (this.storage.getItem('currentUser')) {
            //
            //     this.refreshToken();
            // } else {
            //     this.router.navigate([this.authConfig.loginRoute],
            //         {
            //             queryParams: this.authConfig.loginRoute !== location.pathname ?
            //                 {
            //                     returnUrl:
            //                     location.pathname
            //                 } : null
            //         });
            //
            // }
        });
    }
    getCurrentUser() {
        if (sessionStorage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                return currentUser;
            }
            catch (e) {
            }
        }
        return false;
    }
    getToken() {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.token;
            }
            catch (e) {
            }
        }
        return '';
    }
    getRole() {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.role_id;
            }
            catch (e) {
            }
        }
        return '';
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UserIdleService), i0.ɵɵinject(i4.Router)); }, token: AuthenticationService, providedIn: "root" });
