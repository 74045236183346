import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class BackService {
    private storage = sessionStorage;


    constructor(private http: HttpClient) {

    }

    setLink(link) {
        this.storage = sessionStorage;

        this.storage.setItem('backLink', link);
    }

    getLink(){
        this.storage = sessionStorage;

        return this.storage.getItem('backLink')
    }
}
