import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TokensTokensService} from "./tokensTokens.service";
import {TokensAdvertisementService} from "./tokensAdvertisement.service";
import {TokensDistributionService} from "./tokensDistribution.service";
import {MenuService} from "../menu.service";
import {TokensSweepstakesService} from "./tokensSweepstakes.service";

@Injectable({
    providedIn: 'root'
})
export class TokensService {
    public tokensVisible = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private tokensTokensService: TokensTokensService,
                private tokensAdvertisementService: TokensAdvertisementService,
                private tokensDistributionService: TokensDistributionService,
                private tokensSweepstakesService: TokensSweepstakesService) {

    }

    show() {
        this.tokensVisible.next(true);
    }

    hide() {
        this.tokensVisible.next(false);
    }

    hideSection() {
        this.hide();
        this.tokensTokensService.hide();
        this.tokensAdvertisementService.hide();
        this.tokensDistributionService.hide();
        this.tokensSweepstakesService.hide();
    }
}
