<div class="tokens content" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">Distribution</app-header>
    <div class="tokens__inner">
        <div class="tokens__top">
            <app-balance-card [data]="data">Money Earned</app-balance-card>
        </div>

        <div class="tokens__btns">
            <button (click)="showPayout()" [class.is-active]="payout" class="tokens__btn">PAYOUT</button>
            <!--<button (click)="showBankInfo()" [class.is-active]="bankInfo" class="tokens__btn">BANK INFO</button>-->
            <button (click)="showPaypalInfo()" [class.is-active]="paypalInfo" class="tokens__btn">PAYPAL</button>
        </div>

        <div *ngIf="!payout && !bankInfo" class="tokens__activity">
            <!--<app-activity [activity]="activity">Activity Payout</app-activity>-->
        </div>

        <div *ngIf="bankInfo || payout || paypalInfo" class="tokens__card">

            <form [formGroup]="paypalInfoForm" (ngSubmit)="onPaypalInfoFormSubmit()" class="form-bordered">

                <div *ngIf="paypalInfo" class="form-bordered__inputs">
                    <div class="form-bordered__item form-bordered__item--token">
                        <label class="form-bordered__label" for="routing">Email</label>
                        <input
                               formControlName="email"
                               [type]="'text'"
                               class="form-bordered__input"
                               autocomplete="off"
                               id="email">
                    </div>
                </div>

                <div *ngIf="paypalInfo" class="form-bordered__btns">
                    <button class="form-simple__btn" [disabled]="!paypalInfoForm.valid">
                        Save
                    </button>
                </div>

            </form>

            <form [formGroup]="bankInfoForm" (ngSubmit)="onBankInfoFormSubmit()" class="form-bordered">

                <div *ngIf="bankInfo" class="form-bordered__inputs">
                    <div class="form-bordered__item form-bordered__item--token">
                        <label class="form-bordered__label" for="routing">routing number</label>
                        <input mask="000 000 000"
                               formControlName="routing"
                               [type]="'tel'"
                               class="form-bordered__input"
                               autocomplete="off"
                               id="routing">
                    </div>
                    <div class="form-bordered__item form-bordered__item--dollar">
                        <label class="form-bordered__label" for="account">Account Number</label>
                        <input mask="000 000 000 000"
                               formControlName="account"
                               value="1"
                               [type]="'tel'"
                               class="form-bordered__input"
                               autocomplete="off"
                               id="account">

                    </div>
                    <div class="form-bordered__item form-bordered__item--token">
                        <label class="form-bordered__label" for="conformAccount">Confirm Account Number</label>
                        <input mask="000 000 000 000"
                               formControlName="confirmAccount"
                               [type]="'tel'"
                               class="form-bordered__input"
                               autocomplete="off"
                               id="conformAccount">
                    </div>
                </div>

                <div *ngIf="bankInfo" class="form-bordered__btns">
                    <button class="form-simple__btn" [disabled]="!bankInfoForm.valid">
                        Save
                    </button>
                </div>

            </form>

            <form [formGroup]="payoutForm" (ngSubmit)="onFormSubmit()" class="form-bordered">
                <ul *ngIf="payout" class="tokens__info info">
                    <li class="info__item">
                        <div class="info__title">Avaliable amount</div>
                        <div class="info__amount">{{avaliableAmount | currency}}</div>
                    </li>
                    <li class="info__item info__item_accent">
                        <div class="info__title">Transfer amount</div>
                        <input currencyMask
                               [options]="{ prefix: '$', thousands: ',', decimal: '.' }"
                               formControlName="transfer"
                               [type]="'text'"
                               class="form-bordered__input info__amount info__input"
                               autocomplete="off"
                               [value]="'$0.00'"
                               id="transfer">
                    </li>
                    <!--<li class="info__item">-->
                        <!--<div class="info__title">Transaction Fee</div>-->
                        <!--<div class="info__amount">-$0.30</div>-->
                    <!--</li>-->
                    <!--<li class="info__item">-->
                        <!--<div class="info__title">Transfer amount</div>-->
                        <!--<div class="info__amount">{{transferAmount | currency}}</div>-->
                    <!--</li>-->
                </ul>

                <!--<div *ngIf="payout" class="tokens__card">-->
                    <!--<div class="bank-card">-->
                        <!--<img src="../../../../assets/images/credit-card-i.svg" alt="" class="bank-card__img">-->
                        <!--<div class="bank-card__number">{{cardNumber}}</div>-->
                    <!--</div>-->

                <!--</div>-->


                <div *ngIf="payout" class="form-bordered__btns">
                    <button class="form-simple__btn" [disabled]="!payoutForm.valid">
                        CONFIRM PAYOUT
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>




