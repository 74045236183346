import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserItemEditingService} from "../../../../service/admin/userItemEditing.service";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {MenuService} from "../../../../service/menu.service";
import {UserEditingService} from "../../../../service/admin/userEditing.service";
import {PROJECT_CONFIG} from "../../../../config/project";
import {PhoneService} from "../../../../service/phone.service";

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

    id;
    visible: boolean;

    projectConfig = PROJECT_CONFIG;

    userEditForm: FormGroup;

    // user state - 1 -> active, 0 -> not active
    userState;
    userAvatar;

    constructor(private formBuilder: FormBuilder,
                private popupComponent: PopupComponent,
                private menuService: MenuService,
                private userEditingService: UserEditingService,
                private phoneService:PhoneService,
                private ngZone: NgZone,
                private userItemEditingService: UserItemEditingService) {
        this.userItemEditingService.id.subscribe((value: any) => this.idChanged(value));

    }

    ngOnInit() {
        this.userEditForm = this.formBuilder.group({
            phone: [null, Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            userName: ['', Validators.required]
        });

        this.phoneService.userAvatarUploded.subscribe((value: any) => this.changeAvatar(value));
    }

    ngOnChanges()  {
        if (this.visible && this.id) {

            this.userItemEditingService.getUser(this.id).subscribe(data => {
                if (data.status) {
                    this.userEditForm.controls['phone'].setValue(data.result.phone);
                    this.userEditForm.controls['firstName'].setValue(data.result.firstName);
                    this.userEditForm.controls['lastName'].setValue(data.result.lastName);
                    this.userEditForm.controls['userName'].setValue(data.result.userName);
                    this.userState = data.result.state;
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            })
        }
    }

    onUserSubmit() {

    }

    changeAvatar(data){
        if(data){
            this.ngZone.run( () => {
                this.userAvatar = data.avatar_link;
            });
        }
    }

    idChanged(value) {
        this.id = value;
        this.visible = value;
        if (this.visible && this.id) {

            this.userItemEditingService.getUser(this.id).subscribe(data => {
                if (data.status) {
                    this.userEditForm.controls['phone'].setValue(data.result.phone);
                    this.userEditForm.controls['firstName'].setValue(data.result.first_name);
                    this.userEditForm.controls['lastName'].setValue(data.result.last_name);
                    this.userEditForm.controls['userName'].setValue(data.result.user_name);
                    this.userState = data.result.status;
                    this.userAvatar = data.result.avatar_link ? data.result.avatar_link : this.projectConfig.defaultAvatar;
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            })
        }
    }

    goBack() {
        this.userItemEditingService.hide();
    }

    menu() {
        this.userItemEditingService.hide();
        this.userEditingService.hide();
        //this.menuService.hide();
    }

    changeUserState() {
        const id = this.id;

        if (this.userState) {
            this.userState = 0;
        } else {
            this.userState = 1;
        }

        this.userItemEditingService.changeUserState(id, this.userState).subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }

    saveUserInfo() {
        const id = this.id;
        const phone = this.userEditForm.controls['phone'].value;
        const firstName = this.userEditForm.controls['firstName'].value;
        const lastName = this.userEditForm.controls['lastName'].value;
        const userName = this.userEditForm.controls['userName'].value;
        const state = this.userState;

        //console.log(id, phone, firstName, lastName, userName, state)

        this.userItemEditingService.saveUserInfo(id, phone, firstName, lastName, userName).subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }

    getUserAvatar(){
        return this.userAvatar ? this.userAvatar : this.projectConfig.defaultAvatar;
    }

    addAvatar(){
        this.phoneService.uploadAvatarToUser(this.id);
    }
}
