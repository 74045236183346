import {Component, EventEmitter, OnInit, Output, QueryList, ViewChildren} from '@angular/core'
import {POST_CATEGORIES_ID} from "../../config/post_categories";

@Component({
    selector: 'app-footer-filter',
    templateUrl: './footer-filter.component.html',
    styleUrls: ['./footer-filter.component.scss']
})
export class FooterFilterComponent implements OnInit {

    @Output() filterChanged = new EventEmitter();
    @ViewChildren('filterBtn') filterBtn: QueryList<any>;

    postCategoriesId = POST_CATEGORIES_ID;
    selectedCategories = [];

    constructor() {
    }

    ngOnInit() {

    }

    activateFilter($event) {
        const thisEl = $event.currentTarget;
        thisEl.classList.toggle('is-active');
        this.selectedCategories = [];

        this.filterBtn.forEach((el) => {
            if (el.nativeElement.classList.contains('is-active')) {
                let categoryName = el.nativeElement.getAttribute('data-category-name');
                this.selectedCategories.push(this.postCategoriesId[categoryName]);
            }
        })

        this.filterChanged.emit(this.selectedCategories);
    }

}
