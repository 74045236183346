import { NOTIFICATIONS_TYPES } from "../../config/notifications_types";
import * as i0 from "@angular/core";
export class NotificationsService {
    constructor() {
        this.notificationTypes = NOTIFICATIONS_TYPES;
    }
    liked() {
        return this.notificationTypes.liked;
    }
    shared() {
        return this.notificationTypes.shared;
    }
    purchased() {
        return this.notificationTypes.purchased;
    }
    advertisementEnded() {
        return this.notificationTypes.advertisementEnded;
    }
}
NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(); }, token: NotificationsService, providedIn: "root" });
