import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HOME_LIBRARY_CONFIG} from '../../config/api';


import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class HomeCollectionService {
    private config = HOME_LIBRARY_CONFIG;

    constructor(private http: HttpClient) {

    }

    // loadPublications(type, sense, cost, page, search) {
    //     return this.http.post<any>(this.config.home, {type, sense, cost, page, search});
    // }

    loadPublications(sense, page, search) {
        return this.http.post<any>(this.config.home, {sense, page, search});
    }
}
