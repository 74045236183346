import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    public visible = new BehaviorSubject(false);
    public data = new BehaviorSubject(null);

    public categories = new BehaviorSubject([]);
    public cost = new BehaviorSubject('');
    public type = new BehaviorSubject('');

    constructor(private ngZone: NgZone) {

    }

    show() {
        this.visible.next(true);
    }

    hide() {
        this.visible.next(false);
    }


    setNewData(newData) {
        this.data.next(newData);
    }

    setSearchCategories(newData) {
        this.categories.next(newData);
    }

    setSearchCost(newData) {
        this.cost.next(newData);
    }

    setSearchType(newData) {
        this.type.next(newData);
    }

    resetSearchCategories() {
        this.categories.next([]);
    }

    resetSearchCost() {
        this.cost.next('');
    }

    resetSearchType() {
        this.type.next('');
    }
}
