import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {HomeCollectionService} from "../../service/global-library/homeCollection.service";
import {ActivatedRoute} from "@angular/router";

import {HomeArticle} from "../../models/home-articles"
import {SearchService} from "../../service/search.service";
import {ArticleService} from "../../service/article.service";
import {GlobalLibraryService} from "../../service/global-library/globalLibrary.service";
import {LoadPreviewService} from "../../service/global-library/loadpreview.service";
import {POST_CATEGORIES_COLORS} from "../../config/post_categories";

@Component({
    selector: 'app-collection-main',
    templateUrl: './collection-main.component.html',
    styleUrls: ['./collection-main.component.scss']
})
export class CollectionMainComponent implements OnInit {

    @Output() showPopup = new EventEmitter();
    @Output() dataChanged = new EventEmitter();
    @Input() popup: boolean;
    @Input() parent: string;

    postCategoriesColors = POST_CATEGORIES_COLORS;

    items = [];
    filteredCategories = [];
    filteredType = '';

    previewPagination = 0;
    prevPagination = 0;

    loading = false;
    dataRichEnd = false;
    pagesLoaded = 0;
    countLastAddedItems;
    itemsRemovedFromBegining = 0

    pageActive = 1;
    // init = true;
    lastScrollTop = 0;

    constructor(private homeCollectionService: HomeCollectionService,
                private searchService: SearchService,
                private articleService: ArticleService,
                private loadePreviewService: LoadPreviewService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        // this.pageActive = +this.route.snapshot.paramMap.get('page') ? +this.route.snapshot.paramMap.get('page') : 1;
    }

    ngAfterViewInit() {
        this.items = [];
        this.loadNewData(this.pageActive);
    }

    openPopup(id) {
        // this.popup =  !this.popup;
        //this.showPopup.emit(this.popup);
        this.articleService.showArticle(id);
    }

    loadNewData(page, step = 'end') {
        if (this.parent === 'home') {
            this.homeCollectionService.loadPublications(this.filteredCategories, page, '').subscribe(data => {
                console.log(data);
                this.dataLoadingLogic(page, step, data);
            })
        }

        if (this.parent === 'library') {
            this.loadePreviewService.loadPage(this.filteredType, this.filteredCategories, page).subscribe(data => {
                this.dataLoadingLogic(page, step, data);
            })
        }
    }

    dataLoadingLogic(page, step, data) {
        if (data.status) {
            if (data.result.length < 50 && step === 'end') {
                this.previewPagination += 1;
                this.pageActive = this.previewPagination;
                this.loading = false;
                this.dataRichEnd = true;
                this.itemsRemovedFromBegining = data.result.length;

                for (let i = 0; i < data.result.length; i++) {
                    this.items.push(data.result[i]);
                }

                this.prevPagination += 1;
                this.pagesLoaded += 1;

                this.countLastAddedItems = data.result.length;

                this.searchService.setNewData(this.items);
                this.dataChanged.emit(this.items);

                return;
            }

            // ADD PAGE TO BEGINING
            if (step === 'end') {
                // REMOVE PAGE FROM BEGINING
                if (this.items.length > (2 * data.result.length)) {
                    this.items.splice(0, data.result.length);
                    this.prevPagination += 1;
                    this.pagesLoaded -= 1;
                }
                for (let i = 0; i < data.result.length; i++) {
                    this.items.push(data.result[i]);
                }

                this.previewPagination += 1;
                this.pagesLoaded += 1;
                this.countLastAddedItems = data.result.length;
            }

            if ((step === 'start') && this.prevPagination) {

                let countItems = 50;
                if (this.itemsRemovedFromBegining < 50) {
                    countItems = countItems + this.itemsRemovedFromBegining;
                }

                // REMOVE PAGE FROM END
                this.items.splice(this.items.length - countItems, countItems);
                this.previewPagination -= 2;
                this.dataRichEnd = false;

                // ADD PAGE TO BEGINING
                for (let i = data.result.length - 1; i >= 0; i--) {
                    this.items.unshift(data.result[i]);
                }
                this.itemsRemovedFromBegining = 0;
                this.prevPagination -= 1;

            }


            if (step === 'new') {
                this.items = [];
                for (let i = 0; i < data.result.length; i++) {
                    this.items.push(data.result[i]);
                }
                this.previewPagination = 1;
                this.prevPagination = 0;
                if (data.result.length < 50) {
                    this.dataRichEnd =  true;
                }
                this.pagesLoaded = 1;
            }

            this.pageActive = this.previewPagination;
            //console.log(this.items);
            this.searchService.setNewData(this.items);
            this.dataChanged.emit(this.items);

            setTimeout(() => {
                this.loading = false;

            }, 500);
        }
    }

    getTitle(item){
        let article = new HomeArticle(item);

        return article.title;
    }

    getId(item){
        let article = new HomeArticle(item);

        return article.id;
    }

    getImg(item){
        let article = new HomeArticle(item);

        return article.img;
    }

    getStyle(item){
        const article = new HomeArticle(item);
        let category = article.category;
        category = [2, 1, 5]; // should be removed after category will be added in DB request result
        const styleNumber = category[0];
        return `view-${this.postCategoriesColors[styleNumber]}`;
    }

    filterChanged(value) {
        this.filteredCategories = value;
        this.loadNewData(1, 'new');
    }

    scrollHandler(e) {

        if (!this.loading) {
            const elems = document.querySelectorAll('.collection__item');

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            //const rectStart = elems[0] !== undefined ? elems[40].getBoundingClientRect() : undefined;

            //elems[elems.length - 1].classList.add('control')


            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > this.lastScrollTop) {
                //console.log('down')
                if ((e.target.scrollHeight - e.target.scrollTop) < e.target.clientHeight * 1.2 && !this.dataRichEnd) {
                    this.loading = true;
                    const loadingPage = this.pageActive + 1;
                    this.loadNewData(loadingPage);

                }
            } else {
                //console.log('up')
                if (e.target.scrollTop <= rect.height * 5  && this.prevPagination > 0) {
                    this.loading = true;
                    const loadingPage = this.prevPagination;
                    this.loadNewData(loadingPage, 'start');
                }
            }
            this.lastScrollTop = st <= 0 ? 0 : st;

        }
    }
}
