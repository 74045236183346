import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {GestureConfig} from "@angular/material";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './service/jwt.interceptor';

import {AUTH_CONFIG} from './config/api';
import {SharedModule} from './shared/shared.module';
import {Routes} from "@angular/router";

import {BaseRowDef} from "@angular/cdk/table";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AuthModule} from './modules/auth/auth.module';
import {ChatQuizModule} from "./pages/pages.module";

import {NotificationsComponent} from "./pages/notifications/notifications.component";
import {NotificationsListComponent} from "./pages/notifications/notifications-list/notifications-list.component";
import {ChartsModule, ThemeService} from 'ng2-charts';

import {TokensComponent} from "./pages/tokens/tokens.component";
import {TokensTokensComponent} from "./pages/tokens/tokens-tokens/tokens-tokens.component";
import {TokensSweepstakesComponent} from "./pages/tokens/tokens-sweepstakes/tokens-sweepstakes.component";
import {TokensAdvertisementComponent} from "./pages/tokens/tokens-advertisement/tokens-advertisement.component";
import {TokensDistributionComponent} from "./pages/tokens/tokens-distribution/tokens-distribution.component";
import {TokensBudgetComponent} from "./pages/tokens/tokens-budget/tokens-budget.component";
import {TokensCreateAdvertisementComponent} from "./pages/tokens/tokens-create-advertisement/tokens-create-advertisement.component";

import {CollectionComponent} from "./pages/collection/collection.component";
import {MatSliderModule} from '@angular/material/slider';

import { NgxCurrencyModule } from "ngx-currency";



import {GlobalLibraryComponent} from "./pages/global-library/global-library.component";
import {GlobalLibraryPublicationComponent} from "./pages/global-library/global-library-publication/global-library-publication.component";
import {GlobalLibraryEditComponent} from "./pages/global-library/global-library-edit/global-library-edit.component";
import {NewContentComponent} from "./pages/new-content/new-content.component";
import {UsersComponent} from "./pages/admin/users/users.component";
import {EditUserComponent} from "./pages/admin/users/edit-user/edit-user.component";
import {AdSettingsComponent} from "./pages/admin/ad-settings/ad-settings.component";
import {AdSettingsCostComponent} from "./pages/admin/ad-settings/ad-settings-costs/ad-settings-cost.component";
import {AdSettingsActiveComponent} from "./pages/admin/ad-settings/ad-settings-active/ad-settings-active.component";
import {DistributionsComponent} from "./pages/admin/distributions/distributions.component";
import {DistributionsRatioComponent} from "./pages/admin/distributions/distributions-ratio/distributions-ratio.component";
import {DistributionsPayoutsComponent} from "./pages/admin/distributions/distributions-payouts/distributions-payouts.component";
import {DistributionsSnekComponent} from "./pages/admin/distributions/distributions-snek/distributions-snek.component";
import {AccountComponent} from "./pages/account/account.component";
import {AccountInfoComponent} from "./pages/account/account-info/account-info.component";
import {AccountPaymentComponent} from "./pages/account/account-payment/account-payment.component";
import {ArticleComponent} from "./pages/article/article.component";
import {ArticleSliderComponent} from "./pages/article/article-slider/article-slider.component";
import {ArticleAboutComponent} from "./pages/article/article-about/article-about.component";
import {ArticleFooterComponent} from "./pages/article/article-footer/article-footer.component";
import {ArticleDetailsComponent} from "./pages/article/article-details/article-details.component";

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import {CarouselModule} from "ngx-owl-carousel-o";


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
};

// Import your library
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    NotificationsListComponent,
    TokensComponent,
    TokensTokensComponent,
    TokensAdvertisementComponent,
      TokensBudgetComponent,
    TokensCreateAdvertisementComponent,
    TokensDistributionComponent,
    TokensSweepstakesComponent,
    CollectionComponent,
      GlobalLibraryComponent,
      GlobalLibraryPublicationComponent,
      GlobalLibraryEditComponent,
      NewContentComponent,
      UsersComponent,
      EditUserComponent,
      AdSettingsComponent,
      AdSettingsCostComponent,
      AdSettingsActiveComponent,

      DistributionsComponent,
      DistributionsRatioComponent,
      DistributionsPayoutsComponent,
      DistributionsSnekComponent,

      AccountComponent,
      AccountInfoComponent,
      AccountPaymentComponent,

      ArticleComponent,
      ArticleSliderComponent,
      ArticleAboutComponent,
      ArticleFooterComponent,
      ArticleDetailsComponent,

  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        ChartsModule,
        AuthModule.forRoot(AUTH_CONFIG, {currentUserRoles: '/api/session/roles'}),
        ChatQuizModule,
        MatSliderModule,
        FormsModule,
        NgxCurrencyModule,
        CarouselModule,
        SwiperModule,
        SlickCarouselModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })

    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
      {
          provide: SWIPER_CONFIG,
          useValue: DEFAULT_SWIPER_CONFIG
      },
      ThemeService

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
