export class PublicationItem {
    public _title: string;
    public _descr: string;
    public _id: number;

    public _author: any;
    public _authorAvatar: string;

    private _timeAgo: string;
    private _type: number;

    public _placeholder: string;
    public _file: string;

    private _cost: number;
    public _likes: number;
    public _tokens: number;
    public _reposts: number;

    public _categories: any;
    public _tags: any;

    public _advertising: any;
    public _user_likes: boolean;

    constructor(data) {
        this._title = data.title;
        this._descr = data.description;
        this._id = data.id;

        this._author = data.author;
        this._authorAvatar = data.authorAvatar;

        this._timeAgo = data.time_ago;
        this._type = data.file_type;

        this._placeholder = data.avatar_link;
        this._file = data.url_link;

        this._cost = data.payment_type;
        this._likes = data.likes;
        this._tokens = data.tokens;
        this._reposts = data.reposts;

        this._categories = data.categories;
        this._tags = data.tags;

        this._advertising = data.advertising;
        this._user_likes = data.user_likes;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get advertising(): any {
        return this._advertising;
    }

    set advertising(value: any) {
        this._advertising = value;
    }

    get user_likes(): boolean {
        return this._user_likes;
    }

    set user_likes(value: boolean) {
        this._user_likes = value;
    }

    get descr(): string {
        return this._descr;
    }

    set descr(value: string) {
        this._descr = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }



    get author(): string {
        return this._author;
    }

    set author(value: string) {
        this._author = value;
    }

    get authorAvatar(): string {
        return this._authorAvatar;
    }

    set authorAvatar(value: string) {
        this._authorAvatar = value;
    }



    get timeAgo(): string {
        return this._timeAgo;
    }

    set timeAgo(value: string) {
        this._timeAgo = value;
    }

    get type(): number {
        return this._type;
    }

    set type(value: number) {
        this._type = value;
    }



    get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(value: string) {
        this._placeholder = value;
    }

    get file(): string {
        return this._file;
    }

    set file(value: string) {
        this._file = value;
    }



    get cost(): number {
        return this._cost;
    }

    set cost(value: number) {
        this._cost = value;
    }

    get likes(): number {
        return this._likes;
    }

    set likes(value: number) {
        this._likes = value;
    }

    get tokens(): number {
        return this._tokens;
    }

    set tokens(value: number) {
        this._tokens = value;
    }

    get reposts(): number {
        return this._reposts;
    }

    set reposts(value: number) {
        this._reposts = value;
    }




    get categories(): any {
        return this._categories;
    }

    set categories(value: any) {
        this._categories = value;
    }

    get tags(): any {
        return this._tags;
    }

    set tags(value: any) {
        this._tags = value;
    }

}
