<div class="page content" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">Edit Ad Costs</app-header>
    <div class="page__inner">

        <div class="page__form">
            <form [formGroup]="costForm" (ngSubmit)="onSubmit()" class="form-labeled">
                <div class="form-labeled__group-title">Budget</div>
                <div class="form-labeled__item">
                    <div class="form-labeled__input-wrapper form-labeled__input-wrapper_token">
                        <input formControlName="minDay"
                               id="minDay"
                               placeholder="100"
                               [value]="'100'"
                               [type]="'text'"
                               class="form-labeled__input"
                               autocomplete="off">
                    </div>
                    <label class="form-labeled__label" for="minDay">
                        Minimum per day
                    </label>
                </div>

                <div class="form-labeled__item">
                    <div class="form-labeled__input-wrapper form-labeled__input-wrapper_token">
                        <input formControlName="maxDay"
                               id="maxDay"
                               placeholder="1000"
                               [value]="'1000'"
                               [type]="'text'"
                               class="form-labeled__input"
                               autocomplete="off">
                    </div>
                    <label class="form-labeled__label" for="maxDay">
                        Maximum per day
                    </label>
                </div>

                <div class="form-labeled__group-title">Duration (Days)</div>

                <div class="form-labeled__item">
                    <div class="form-labeled__input-wrapper">
                        <input formControlName="min"
                               id="min"
                               placeholder="1000"
                               [value]="'1000'"
                               [type]="'text'"
                               class="form-labeled__input"
                               autocomplete="off">
                    </div>
                    <label class="form-labeled__label" for="min">
                        Minimum
                    </label>
                </div>

                <div class="form-labeled__item">
                    <div class="form-labeled__input-wrapper">
                        <input formControlName="max"
                               id="max"
                               placeholder="30"
                               [value]="'30'"
                               [type]="'text'"
                               class="form-labeled__input"
                               autocomplete="off">
                    </div>
                    <label class="form-labeled__label" for="max">
                        Maximum
                    </label>
                </div>

                <div class="form-labeled__btns">
                    <button class="form-labeled__btn" [disabled]="costForm.invalid">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>


