import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {

    ishiden = false;

    @Input() filter: boolean;
    @Input() config;

    @Output() checkedValue = new EventEmitter();

    select = {
        value: '',
        name: ''
    }


    constructor() {
    }

    ngOnInit() {

    }

    toggleVariants() {
        this.ishiden =  !this.ishiden;
    }

    radioChange(input) {
        this.select.value = input.value;
        this.select.name = input.name;
        this.checkedValue.emit(this.select);
    }

    checkInput(e) {
        const input = e.currentTarget.parentNode.querySelector('input');
        document.querySelectorAll(`input[name=${input.name}]`).forEach(el => {
            el.removeAttribute('checked');
        })
        input.setAttribute('checked', true);
        this.radioChange(input);
    }
}
