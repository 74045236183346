import {
    Component,
    ElementRef, EventEmitter,
    Input, NgZone,
    OnChanges,
    OnInit, Output,
    ViewChild,
} from '@angular/core';

import {POST_CATEGORIES} from '../../../config/post_categories';
import {POST_TYPES} from '../../../config/post_types';
import {LoadPreviewService} from "../../../service/global-library/loadpreview.service";
import {BackService} from "../../../service/session/back.service";
import {NewContentService} from "../../../service/content/new-content.service";

@Component({
    selector: 'app-global-library-publication',
    templateUrl: './global-library-publication.component.html',
    styleUrls: ['./global-library-publication.component.scss']
})
export class GlobalLibraryPublicationComponent implements OnInit, OnChanges {
    postCategories = POST_CATEGORIES;
    postTypes = POST_TYPES;

    @Input() publication;
    @Output() closePreview: EventEmitter<any> = new EventEmitter();

    @ViewChild('audioElement', {static: false}) audio: ElementRef;
    @ViewChild('videoElement', {static: false}) video: ElementRef;
    @ViewChild('ranger', {static: false}) ranger: ElementRef;

    play = false;
    startVideo = false;
    rangePosition = '0';

    mediaType;
    mediaDuration;
    mediaDurationFormated;
    mediaProgress;
    mediaProgressFormated;

    constructor(private loadPreview: LoadPreviewService,
                private ngZone: NgZone,
                private backService: BackService,
                private newContentService: NewContentService,) {
    }

    ngOnInit() {

    }

    ngOnChanges() {

        setTimeout(() => {
            this.mediaType = this.checkType();

            if (this.mediaType) {
                this.mediaDuration = Math.round(this[this.mediaType].nativeElement.duration);
                this.mediaDurationFormated = this.formatTime(+this.mediaDuration);
                this.mediaProgress = Math.round(this[this.mediaType].nativeElement.currentTime);
                this.mediaProgressFormated  = this.formatTime(+this.mediaProgress);
            }

        }, 500);

        if (!!this.publication) {
            this.startVideo = false;
            this.play = false;
            this.rangePosition = '0';

            this.mediaDuration = '';
            this.mediaDurationFormated = '';
            this.mediaProgress = '';
            this.mediaProgressFormated = '';
        }
    }


    checkType() {
        if (this.audio) {
            return 'audio';
        } else if (this.video) {
            return 'video';
        }
    }

    showVideo() {
        this.startVideo = true;
    }

    changeRange(value) {
        this.rangePosition = value;

        if (this.mediaType) {
            this[this.mediaType].nativeElement.currentTime = this[this.mediaType].nativeElement.duration * value / 100;
            this.mediaProgress = Math.round(this[this.mediaType].nativeElement.currentTime);
            this.mediaProgressFormated = this.formatTime(this.mediaProgress);
        }
    }

    toggleMedia() {
        this.play = !this.play;
        this.play ? this[this.mediaType].nativeElement.play()  : this[this.mediaType].nativeElement.pause();

        if (this.mediaType === 'video' && !this.startVideo) {
            this.showVideo();
        }
        if (this.play) {
            let playInterval = setInterval(() => {

                if (this.mediaType && this.ranger) {
                    this.mediaProgress = Math.round(this[this.mediaType].nativeElement.currentTime);
                    this.mediaProgressFormated  = this.formatTime(this.mediaProgress);

                    let mediaProgressLong = Math.round(this[this.mediaType].nativeElement.currentTime*1000)/1000;
                    let mediaDurationLong = Math.round(this[this.mediaType].nativeElement.duration*1000)/1000;

                    this.rangePosition = `${(mediaProgressLong * 100) / mediaDurationLong}`;
                    this.ranger.nativeElement.value = this.rangePosition;

                    if (mediaProgressLong >= mediaDurationLong) {
                        this.play = false;
                        clearInterval(playInterval);
                    }
                }


            }, 100);
        }

    }

    formatTime(seconds) {
        const h = Math.floor(seconds / 3600)
        const m = Math.floor((seconds % 3600) / 60)
        const s = seconds % 60
        return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
            .filter(a => a)
            .join(':')
    }

    setIcon(categoryId){
        if(this.postCategories[categoryId]){
            return this.postCategories[categoryId];
        }

        return '';
    }

    closePreviewPopup() {
        this.closePreview.emit(true);
    }

    addContent() {
        document.body.classList.remove('is-hidden');
        this.loadPreview.createContent(this.publication.id).subscribe(data => {
            if (data.id) {
                this.ngZone.run( () => {
                    //this.router.navigate(['/new-content/', data.id])
                    this.newContentService.setId(data.id);
                    this.newContentService.show();

                    //this.popup = false;
                    this.backService.setLink("global-library");
                });
            }
        })
    }
}
