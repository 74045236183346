/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tokens-sweepstakes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/activity/activity.component.ngfactory";
import * as i3 from "../../../shared/activity/activity.component";
import * as i4 from "../../../shared/header/header.component.ngfactory";
import * as i5 from "../../../shared/header/header.component";
import * as i6 from "../../../service/menu.service";
import * as i7 from "../../../shared/balance-card/balance-card.component.ngfactory";
import * as i8 from "../../../shared/balance-card/balance-card.component";
import * as i9 from "@angular/common";
import * as i10 from "./tokens-sweepstakes.component";
import * as i11 from "../../../service/tokens/tokensSweepstakes.service";
import * as i12 from "../../../service/tokens/tokens.service";
var styles_TokensSweepstakesComponent = [i0.styles];
var RenderType_TokensSweepstakesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TokensSweepstakesComponent, data: {} });
export { RenderType_TokensSweepstakesComponent as RenderType_TokensSweepstakesComponent };
function View_TokensSweepstakesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "tokens__activity"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-activity", [], null, null, null, i2.View_ActivityComponent_0, i2.RenderType_ActivityComponent)), i1.ɵdid(2, 638976, null, 0, i3.ActivityComponent, [], { activity: [0, "activity"] }, null), (_l()(), i1.ɵted(-1, 0, ["Activity"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activity; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TokensSweepstakesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "tokens__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed felis a eros ultricies fringilla. Quisque lobortis elementum nisi non rutrum. In varius metus vel aliquam molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed felis a eros ultricies fringilla."])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "tokens__btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "tokens__btn"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["WEBSITE"]))], null, null); }
export function View_TokensSweepstakesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "tokens content"]], [[2, "is-visible", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "menuAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("menuAction" === en)) {
        var pd_1 = (_co.menu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i5.HeaderComponent, [i6.MenuService], { menuActionCheck: [0, "menuActionCheck"], backActionCheck: [1, "backActionCheck"] }, { menuAction: "menuAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["Sweepstakes"])), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "tokens__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "tokens__top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-balance-card", [], null, null, null, i7.View_BalanceCardComponent_0, i7.RenderType_BalanceCardComponent)), i1.ɵdid(7, 114688, null, 0, i8.BalanceCardComponent, [], { data: [0, "data"] }, null), (_l()(), i1.ɵted(-1, 0, ["Available Points"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "tokens__btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "tokens__btn"]], [[2, "is-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showRedeem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["HOW TO REDEEM"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TokensSweepstakesComponent_1)), i1.ɵdid(13, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TokensSweepstakesComponent_2)), i1.ɵdid(15, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.data; _ck(_v, 7, 0, currVal_3); var currVal_5 = !_co.redeem; _ck(_v, 13, 0, currVal_5); var currVal_6 = _co.redeem; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 0, 0, currVal_0); var currVal_4 = _co.redeem; _ck(_v, 10, 0, currVal_4); }); }
export function View_TokensSweepstakesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tokens-sweepstakes", [], null, null, null, View_TokensSweepstakesComponent_0, RenderType_TokensSweepstakesComponent)), i1.ɵdid(1, 114688, null, 0, i10.TokensSweepstakesComponent, [i11.TokensSweepstakesService, i6.MenuService, i12.TokensService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TokensSweepstakesComponentNgFactory = i1.ɵccf("app-tokens-sweepstakes", i10.TokensSweepstakesComponent, View_TokensSweepstakesComponent_Host_0, {}, {}, []);
export { TokensSweepstakesComponentNgFactory as TokensSweepstakesComponentNgFactory };
