<div class="popup"  *ngIf="publication">
    <app-header
            (backAction)="closePreviewPopup()"
            [backActionCheck]="true">Global Library</app-header>
    <div class="popup__inner">
        <div class="library-sound" *ngIf="publication['file_type'] === postTypes.audio">
            <img src="{{publication.avatar_link}}" alt="" class="library-sound__picture">
            <div class="library-sound__control play-controls">

                <div class="play-controls__range">
                    <div class="range">
                        <input value="0" max="100" min="0" #ranger (input)="changeRange(ranger.value)" class="range__input play-controls__input" type="range">
                        <span [ngStyle]="{width: rangePosition+'%'}" class="range__position"></span>
                    </div>

                    <div class="play-controls__time">
                        <div class="play-controls__time-start">{{mediaProgressFormated}}</div>
                        <div class="play-controls__time-start">{{mediaDurationFormated}}</div>
                    </div>
                </div>

                <div class="library-sound__bottom">
                    <div class="library-sound__title">Title</div>
                    <audio #audioElement class="js-sound-item" src="{{publication.url_link}}">
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                    <button class="js-sound-control" *ngIf="!play" (click)="toggleMedia()" class="play-controls__btn">
                        <svg class="play-controls__icon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_b)">
                                <circle cx="9" cy="9" r="9" fill="#C4C4C4" fill-opacity="0.6"/>
                            </g>
                            <path d="M13.25 8.13397C13.9167 8.51887 13.9167 9.48113 13.25 9.86603L7.625 13.1136C6.95833 13.4985 6.125 13.0174 6.125 12.2476L6.125 5.75241C6.125 4.9826 6.95833 4.50148 7.625 4.88638L13.25 8.13397Z" fill="white"/>
                            <defs>
                                <filter id="filter0_b" x="-4" y="-4" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    </button>
                    <button *ngIf="play" (click)="toggleMedia()" class="play-controls__btn play-controls__btn_pause">
                        <svg class="play-controls__icon" width="40" height="40">
                            <use xlink:href="../../../../assets/images/sprite.svg#pause"></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <div class="library-media" *ngIf="publication['file_type'] === postTypes.video">
            <div class="library-media__video">
                <video class="library-media__video-player" #videoElement controls="" muted="muted" controlslist="nodownload" style="width: 100%">
                    <source src="{{publication.url_link}}" type="video/mp4">
                    Your browser does not support the video tag.
                </video>

                <button *ngIf="!startVideo" (click)="toggleMedia()" class="library-media__play-btn">
                    <span class="visually-hidden">Play/Pause</span>
                    <svg class="library-media__icon-play" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_b)">
                            <circle cx="9" cy="9" r="9" fill="#C4C4C4" fill-opacity="0.6"/>
                        </g>
                        <path d="M13.25 8.13397C13.9167 8.51887 13.9167 9.48113 13.25 9.86603L7.625 13.1136C6.95833 13.4985 6.125 13.0174 6.125 12.2476L6.125 5.75241C6.125 4.9826 6.95833 4.50148 7.625 4.88638L13.25 8.13397Z" fill="white"/>
                        <defs>
                            <filter id="filter0_b" x="-4" y="-4" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                </button>

                <div *ngIf="startVideo" class="library-media__controls">
                    <div class="play-controls">
                        <div class="play-controls__range">
                            <div class="range">
                                <input value="0" max="100" min="0" #ranger (input)="changeRange(ranger.value)" class="range__input play-controls__input" type="range">
                                <span [ngStyle]="{width: rangePosition+'%'}" class="range__position"></span>
                            </div>

                            <div class="play-controls__time">
                                <div class="play-controls__time-start">{{mediaProgressFormated}}</div>
                                <div class="play-controls__time-start">{{mediaDurationFormated}}</div>
                            </div>
                        </div>
                        <button *ngIf="!play" (click)="toggleMedia()" class="play-controls__btn">
                            <svg class="play-controls__icon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_b)">
                                    <circle cx="9" cy="9" r="9" fill="#C4C4C4" fill-opacity="0.6"/>
                                </g>
                                <path d="M13.25 8.13397C13.9167 8.51887 13.9167 9.48113 13.25 9.86603L7.625 13.1136C6.95833 13.4985 6.125 13.0174 6.125 12.2476L6.125 5.75241C6.125 4.9826 6.95833 4.50148 7.625 4.88638L13.25 8.13397Z" fill="white"/>
                                <defs>
                                    <filter id="filter0_b" x="-4" y="-4" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>
                        </button>
                        <button *ngIf="play" (click)="toggleMedia()" class="play-controls__btn play-controls__btn_pause">
                            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1.38452" y1="1.07692" x2="1.38452" y2="12.9231" stroke="#131313" stroke-width="2" stroke-linecap="round"/>
                                <line x1="8.30762" y1="1.07692" x2="8.30762" y2="12.9231" stroke="#131313" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="library-media" *ngIf="publication['file_type'] === postTypes.image">
            <div class="library-media__image">
                <img src="{{publication.avatar_link}}" alt="" class="library-media__preview">
            </div>
        </div>

        <div class="library-info" *ngIf="publication['file_type'] === postTypes.video || publication['file_type'] === postTypes.image">
            <div class="library-info__categories">
                <ul class="categories">
                    <li class="categories__category">
                        <svg class="categories__svg_icon">
                            <use [attr.xlink:href]="'#category_'+publication.category_id"></use>
                        </svg>

                    </li>
                </ul>
            </div>

            <div class="library-info__title">Lorem Ipsum</div>
            <div class="library-info__descr">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt eget nibh id ultrices. Lorem ipsum dolor sit amet, consectetur.</div>
            <div class="library-info__tags">
                <div  class="tags">
                    <!--                <a href="#" class="tags__item">#tagtag</a>-->
                    <!--            <div *ngFor="let tag of publication.tags" class="tags__item">{{tag}}</div>-->
                    <div class="tags__item">#tagtag</div>
                    <div class="tags__item">#tag</div>
                    <div class="tags__item">#tagtag</div>
                    <div class="tags__item">#tag</div>
                    <div class="tags__item">#tagtag</div>
                    <div class="tags__item">#tag</div>

                </div>
            </div>
        </div>

    </div>
    <div class="add">

        <button (click)="addContent()" class="btn-add">
            <svg class="btn-add__icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.1484 9.21094H9.35156V5.41406C9.35156 5.21989 9.19417 5.0625 9 5.0625C8.80583 5.0625 8.64844 5.21989 8.64844 5.41406V9.21094H4.85156C4.65739 9.21094 4.5 9.36833 4.5 9.5625C4.5 9.75667 4.65739 9.91406 4.85156 9.91406H8.64844V13.7109C8.64844 13.9051 8.80583 14.0625 9 14.0625C9.19417 14.0625 9.35156 13.9051 9.35156 13.7109V9.91406H13.1484C13.3426 9.91406 13.5 9.75667 13.5 9.5625C13.5 9.36833 13.3426 9.21094 13.1484 9.21094Z" fill="white"/>
                <circle cx="9" cy="9" r="8.5" stroke="white"/>
            </svg>
            Add
        </button>

    </div>
</div>




