import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SearchService} from "./search.service";
import {ARTICLE_CONFIG, COLLECTION_CONFIG, HOME_ARTICLES_CONFIG} from "../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ArticleService {
    private config = HOME_ARTICLES_CONFIG;
    private configArticles = ARTICLE_CONFIG;

    public visible = new BehaviorSubject(null);
    public articleId = new BehaviorSubject(null);
    public sliderCreated = new BehaviorSubject(false);

    public showDetailsId = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private http: HttpClient,
                private searchService: SearchService) {

    }

    show() {
        this.visible.next(true);
        //this.searchService.hide();
        document.body.classList.add('is-hidden');
    }

    showArticle(id) {
        this.hide();
        this.articleId.next(id);
        document.body.classList.add('is-hidden');
    }

    createSlider() {
        this.sliderCreated.next(true);
    }

    showDetails(id, advertising) {
        this.showDetailsId.next({id: id, advertisingLink: advertising});
    }

    hide() {
        this.visible.next(false);
        this.articleId.next(null);
        this.showDetailsId.next(null);
        document.body.classList.remove('is-hidden');
    }

    getData(id) {
        return this.http.post<any>(this.config.articles, {id});
    }

    // get new 3 slides
    getNewSlides(direction, id) {
        return this.http.post<any>(this.config.add, {direction, id});
    }

    buyPublication(id) {
        return this.http.post<any>(this.configArticles.buy, {id});
    }

    setAdvertising(id) {
        return this.http.post<any>(this.configArticles.view, {id});
    }

    toggleLike(id) {
        return this.http.post<any>(this.configArticles.like, {id});
    }
}
