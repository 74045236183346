<app-header-search></app-header-search>

<div *ngIf="!search">
    <app-collection-main
            [parent]="'home'"
            (dataChanged)="updateData($event)"
            [popup]="popup"
            (showPopup)="showPopup($event)">
    </app-collection-main>
</div>


<!--<video controls="" muted="muted" controlslist="nodownload" style="width: 100%; height: 500px">-->
    <!--<source src="http://158.69.144.84/~irisdev/apps/video.mp4" type="video/mp4">-->
    <!--Your browser does not support the video tag.-->
<!--</video>-->


<!--<video controls="" muted="muted" controlslist="nodownload" src="http://158.69.144.84/~irisdev/apps/video.mp4"></video>-->
