export class SearchItem {
    public _title: string;
    public _id: number;
    private _descr: string;
    private _img: string;
    public _price: string;
    public _categories: any;

    constructor(data) {
        this._title = data.title;
        this._id = data.id;
        this._descr = data.description;
        this._img = data.avatar_link;
        this._price = data.tokens;
        this._categories = data.categories;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get descr(): string {
        return this._descr;
    }

    set descr(value: string) {
        this._descr = value;
    }

    get img(): string {
        return this._img;
    }

    set img(value: string) {
        this._img = value;
    }

    get price(): string {
        return this._price;
    }

    set price(value: string) {
        this._price = value;
    }

    get categories(): any {
        return this._categories;
    }

    set categories(value: any) {
        this._categories = value;
    }

}
