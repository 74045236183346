import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {tick} from "@angular/core/testing";
import {POST_TYPES} from "../../../config/post_types";

@Component({
    selector: 'app-publication-advertisement',
    templateUrl: './publication-advertisement.component.html',
    styleUrls: ['./publication-advertisement.component.scss']
})
export class PublicationAdvertisementComponent implements OnInit {


    @Input() advertising;
    progressWidth = 0;
    timeLeft = 0;
    @Output() advertisingIsOver = new EventEmitter();

    @ViewChild('videoElement', {static: false}) video: ElementRef;
    @ViewChild('audioElement', {static: false}) audio: ElementRef;

    postTypes = POST_TYPES;
    //videoDuration = 15;
    constructor() {
    }

    ngOnInit() {
        this.timeLeft = Math.round(this.advertising.time);

    }

    ngAfterViewInit() {
        const type = Object.keys(this.postTypes).find(key => this.postTypes[key] === this.advertising.type);

        if (type === 'video' || type === 'audio') {
            const mediaElement = type === 'video' ? (<HTMLVideoElement>this.video.nativeElement) : (<HTMLAudioElement>this.audio.nativeElement);

            mediaElement.addEventListener('loadeddata', () => {

                let tick = setInterval(() => {
                    this.progressWidth = Math.round(mediaElement.currentTime / this.advertising.time * 100);
                    this.timeLeft = Math.round(this.advertising.time - mediaElement.currentTime);

                    if (this.progressWidth >= 100) {
                        clearInterval(tick);
                        this.advertisingIsOver.emit(true);

                    }
                }, 100)
            });

        } else {
            let currentTime = 0;
            let tick = setInterval(() => {
                this.progressWidth = Math.round(currentTime / this.advertising.time * 100);
                this.timeLeft = Math.round(this.advertising.time - currentTime);
                currentTime += 1;
                if (this.progressWidth >= 100) {
                    clearInterval(tick);
                    this.advertisingIsOver.emit(true);

                }
            }, 1000)
        }


    }


    getAdvertisingType(item) {
        const type:string = Object.keys(this.postTypes).find(key => this.postTypes[key] === item.type);
        return type;
    }

}
