import {Component, HostListener, Injectable, NgZone, OnInit} from '@angular/core';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {LoadPreviewService} from "../../service/global-library/loadpreview.service";
import {BackService} from "../../service/session/back.service";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {BehaviorSubject, Subscription} from "rxjs";
import {POST_CATEGORIES} from "../../config/post_categories";
import {POST_TYPES} from "../../config/post_types";
import {GlobalLibraryService} from "../../service/global-library/globalLibrary.service";
import {NewContentService} from "../../service/content/new-content.service";


@Component({
    selector: 'app-global-library',
    templateUrl: './global-library.component.html',
    styleUrls: ['./global-library.component.scss'],
})
@Injectable({providedIn: 'root'})
export class GlobalLibraryComponent implements OnInit {
    postCategories = POST_CATEGORIES;
    postTypes = POST_TYPES;

    visible;

    contentType = '';
    senseType = [];
    contentCost = false;

    typeDefault: number;

    types = [];
    categories = [];

    previewItem;
    popup = false;
    popupPreviewAudio = false;
    popupPreviewVideo = false;
    popupPreviewPhoto = false;
    pagesLoaded = 0;

    countLastAddedItems;
    itemsRemovedFromBegining = 0;

    collectionSettings = {
        buttons: true,
        addBtn: false
    }

    filter = false;

    items = [];

    menu = false;

    search  = false;

    pageUrl = '/home';

    previewPagination = 0;
    prevPagination = 0;
    dataRichEnd = false;

    loading = false;

    pageActive = 1;
    init = true;
    lastScrollTop = 0;

    constructor(private loadPreview: LoadPreviewService,
                private route: ActivatedRoute,
                private ngZone: NgZone,
                private backService: BackService,
                private newContentService: NewContentService,
                private globalLibraryService: GlobalLibraryService,
                private router: Router) {
        this.globalLibraryService.globalLibraryChanged.subscribe((value: any) => this.globalLibraryChanged(value));
        this.globalLibraryService.globalLibraryHidePreviewChanged.subscribe((value: any) => this.globalLibraryHidePreviewChanged(value));


    }

    ngOnInit() {

    }

    showPopup(status) {
        this.popup = status;
        document.body.classList.add('is-hidden');

    }

    hidePopup(status) {
        this.popup = status;
    }

    showSearch(status) {
        this.search = status
    }

    hidenMenu(state) {
        this.menu = state;
    }

    menuShowed(state) {
        this.menu = state;
    }

    searchState(state)  {
        this.search = state;
    }

    getTypeValue(value) {
        if (value.name === 'content') {
            this.contentType = value.value;
            this.typeDefault = this.postTypes[value.value];
            this.loadNewData(1, 'new');
        } else {
            if (value.name === 'free') {
                this.contentCost = false;
                this.items = this.items.filter(item => item.free);
            } else {
                this.contentCost = true;
                this.items = this.items.filter(item => !item.free)
            }

        }
    }

    getCategoriesValue(value) {
        this.senseType = [];
        this.categories.forEach((category) => {
            if (value.includes(category.title)) {
                this.senseType.push(category.id);
            }
        });

        this.loadNewData(1, 'new');
    }

    showPreviewPopup(showItem) {
        this.previewItem = [];
        const itemType = showItem['file_type'];
        this.popup = true;
        this.previewItem = this.items.filter(item => item.id === showItem.id)[0];
        if (itemType === 2) {
            this.popupPreviewAudio = true;
        } else if (itemType === 1) {
            this.popupPreviewVideo = true;
        } else if (itemType === 0) {
            this.popupPreviewPhoto = true;
        }
        document.body.classList.add('is-hidden');
    }

    closePreviewPopup() {
        this.popup = false;
        this.previewItem = [];

        document.body.classList.remove('is-hidden');
    }

    loadNewData(page, step = 'end') {
        this.typeDefault = this.typeDefault !== undefined ? this.typeDefault : null;
        const senseDefault = this.senseType ? this.senseType : '';

        this.loadPreview.loadPage(this.typeDefault, senseDefault, page).subscribe(data => {
            if (data.status) {

                if (data.result.length < 50 && step === 'end') {
                    this.previewPagination += 1;
                    this.pageActive = this.previewPagination;
                    this.loading = false;
                    this.dataRichEnd = true;
                    this.itemsRemovedFromBegining = data.result.length;

                    for (let i = 0; i < data.result.length; i++) {
                        this.items.push(data.result[i]);
                    }

                    this.prevPagination += 1;
                    this.pagesLoaded += 1;

                    this.countLastAddedItems = data.result.length;
                    return;
                }

                // ADD PAGE TO BEGINING
                if (step === 'end') {
                    // REMOVE PAGE FROM BEGINING
                    if (this.items.length > (2 * data.result.length)) {
                        this.items.splice(0, data.result.length);
                        this.prevPagination += 1;
                        this.pagesLoaded -= 1;
                    }
                    for (let i = 0; i < data.result.length; i++) {
                        this.items.push(data.result[i]);
                    }

                    this.previewPagination += 1;
                    this.pagesLoaded += 1;
                    this.countLastAddedItems = data.result.length;
                }

                if ((step === 'start') && this.prevPagination) {

                    let countItems = 50;
                    if (this.itemsRemovedFromBegining < 50) {
                        countItems = countItems + this.itemsRemovedFromBegining;
                    }

                    // REMOVE PAGE FROM END
                    this.items.splice(this.items.length - countItems, countItems);
                    this.previewPagination -= 2;
                    this.dataRichEnd = false;

                    // ADD PAGE TO BEGINING
                    for (let i = data.result.length - 1; i >= 0; i--) {
                        this.items.unshift(data.result[i]);
                    }
                    this.itemsRemovedFromBegining = 0;
                    this.prevPagination -= 1;

                }


                if (step === 'new') {
                    this.items = [];
                    for (let i = 0; i < data.result.length; i++) {
                        this.items.push(data.result[i]);
                    }
                    this.previewPagination = 1;
                    this.prevPagination = 0;
                    if (data.result.length < 50) {
                        this.dataRichEnd =  true;
                    }
                    this.pagesLoaded = 1;
                }

                this.pageActive = this.previewPagination;
                //console.log(this.items);

                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }
        })
    }

    setIcon(categoryId){
        if(this.postCategories[categoryId]){
            return this.postCategories[categoryId];
        }

        return '';
    }

    globalLibraryChanged(status){
        this.visible = status;

        if(status){
            this.items = [];

            this.loadNewData(1);
        }
    }

    hideLibrary() {
        this.globalLibraryService.hide();
    }

    scrollHandler(e) {

        if (!this.loading) {
            const elems = document.querySelectorAll('.collection__item');

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            //const rectStart = elems[0] !== undefined ? elems[40].getBoundingClientRect() : undefined;

            //elems[elems.length - 1].classList.add('control')


            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > this.lastScrollTop) {
                //console.log('down')
                if ((e.target.scrollHeight - e.target.scrollTop) < e.target.clientHeight * 1.2 && !this.dataRichEnd) {
                    this.loading = true;
                    const loadingPage = this.pageActive + 1;
                    this.loadNewData(loadingPage);

                }
            } else {
                //console.log('up')
                if (e.target.scrollTop <= rect.height * 5  && this.prevPagination > 0) {
                    this.loading = true;
                    const loadingPage = this.prevPagination;
                    this.loadNewData(loadingPage, 'start');
                }
            }
            this.lastScrollTop = st <= 0 ? 0 : st;

        }
    }

    globalLibraryHidePreviewChanged(state) {
        this.popup = state;
    }
}

