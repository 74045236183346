import {DOMAIN_API} from "./local_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {PreviewPublication} from '../models/preview-publication';
import {HomePreviewPublication} from "../models/home-preview-publication";
import {HomeArticles} from "../models/home-articles";
import {UserInfo} from "../models/user-info";
import {NewContent} from "../models/new-content";
import {TokensSweepstakes} from "../models/tokens/tokensSweepstakes";
import {TokensTokens} from "../models/tokens/tokensTokens";
import {TokensDistribution} from "../models/tokens/tokensDistribution";
import {TokensAdvertisement} from "../models/tokens/tokensAdvertisement";
import {UsersEditing} from "../models/admin/usersEditing";
import {UserItemEditing} from "../models/admin/userItemEditing.service";
import {AdSettingCost} from "../models/admin/adSettingCost";
import {UsersWithActiveAds} from "../models/admin/usersWithActiveAds";
import {TokenToDollarRatio} from "../models/admin/distributions/tokenToDollarRatio";
import {PayoutsToUsers} from "../models/admin/distributions/payoutsToUsers";
import {SnekAPekPayouts} from "../models/admin/distributions/snekAPekPayouts";
import {Notifications} from "../models/notifications";
import {UserPayment} from "../models/user-payment";
import {Stripe} from "../models/stripe";
import {Collection} from "../models/collection";
import {Article} from "../models/article";

const API = DOMAIN_API + '/api';
const AUTH = API + '/users/session';
const USERS = API + '/users';




//buy post view (post {id}) - API+"posts/post/buy"
//post view (post {id}) - API+"posts/post/view"
//post add like,add unlike (post {id}) - API+"posts/like/add"

export const AUTH_CONFIG: AuthConfig = {
    checkUser: USERS + '/register/check',
    register: USERS + '/register',
    login: AUTH + '/login',
    refresh: AUTH + '/refresh',
    current: AUTH + '/details',
    sendPin: USERS + '/pin/request',
    checkPhoneAndSendPin: USERS + '/phone/check',
};

export const GLOBAL_LIBRARY_CONFIG: PreviewPublication = {
    list: API+"/global/library",
    create: API+"/posts/post/createFromLibrary"
};

export const HOME_LIBRARY_CONFIG: HomePreviewPublication = {
    home: API+"/posts/list",
};

export const HOME_ARTICLES_CONFIG: HomeArticles = {
    articles: API+'/posts/list/slides',
    add: API+'/jsons/find/file?name=addNewSlide',
};

export const USER_INFO_CONFIG: UserInfo = {
    info: API+'/users/user/find',
    save: API+'/users/user/save'
};

export const USER_PAYMENT_CONFIG: UserPayment = {
    info: API+'/account/settings',
    save: API+'/account/settings/save'
};

export const NEW_CONTENT_CONFIG: NewContent = {
    info: API+'/posts/post',
    save: API+'/posts/post/save',
};

export const TOKENS_SWEEPSTAKES_CONFIG: TokensSweepstakes = {
    data: API+'/jsons/find/file?name=tokensSweepstakes',
};

export const TOKENS_TOKENS_CONFIG: TokensTokens = {
    data: API+'/tokens/tokens/avaliable',
    activity: API+'/jsons/find/file?name=activity',
    submit: API+'/tokens/tokens/buy'
};

export const TOKENS_DISTRIBUTION_CONFIG: TokensDistribution = {
    data: API+'/payout/payout',
    submit: API+'/payout/bank/save',
    savePaypalInfo: API+'/payout/paypal/save',
    payout: API+'/payout/payout/create',
};

export const TOKENS_ADVERTISEMENT_CONFIG: TokensAdvertisement = {
    data: API+'/tokens/advertisement/avaliable',
    collection: API+'/posts/collection',
    submit: API+'/tokens/advertisement/buy',
};

export const USERS_EDITING_CONFIG: UsersEditing = {
    data: API+'/users/users/users',
};

export const USER_ITEM_EDITING_CONFIG: UserItemEditing = {
    user: API+'/users/user/find-id',
    userSaveStatus: API+'/users/user/change-status',
    userSaveInfo: API+'/users/user/save-by-id',
};

export const AD_SETTING_COST_CONFIG: AdSettingCost = {
    data: API+'/jsons/find/file?name=adSettingsCost',
    save: API+'/jsons/find/file?name=success',
};

export const USERS_WITH_ACTIVE_ADS_CONFIG: UsersWithActiveAds = {
    data: API+'/jsons/find/file?name=usersWithActiveAds',
    deactivate: API+'/jsons/find/file?name=success'
};

export const TOKEN_TO_DOLLAR_CONFIG: TokenToDollarRatio = {
    data: API+'/distributions/token_dollar_ratio',
    save: API+'/distributions/token_dollar_ratio/save'
};

export const PAYOUTS_TO_USERS_CONFIG: PayoutsToUsers = {
    data: API+'/jsons/find/file?name=payoutsToUsers',
};

export const SNEK_A_PEK_PAYOUTS_CONFIG: SnekAPekPayouts = {
    data: API+'/jsons/find/file?name=snekAPekPayouts',
};

export const NOTIFICATIONS_CONFIG: Notifications = {
    data: API+'/notifications/notifications',
    total: API+'/notifications/notifications/total',
};

export const STRIPE_CONFIG: Stripe = {
    getPublishableKey: API+'/app/stripe/publishable-key',
};

export const COLLECTION_CONFIG: Collection = {
    data: API+'/posts/collection',
    post: API+'/posts/post',
    delete: API+'/posts/post/delete'
};

export const ARTICLE_CONFIG: Article = {
    buy: API+'/posts/post/buy',
    view: API+'/posts/post/view',
    like: API+'/posts/like/add'
}
