import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {NOTIFICATIONS_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class NotificationsListService {
    private config = NOTIFICATIONS_CONFIG;
    public visible = new BehaviorSubject(null);

    constructor(private http: HttpClient) {
    }

    show() {
        this.visible.next(true);
    }

    hide() {
        this.visible.next(false);
    }

    getData(page) {
        return this.http.post<any>(this.config.data, {page});
    }

    getTotal() {
        return this.http.post<any>(this.config.total, {});
    }
}
