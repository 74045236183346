import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BalanceCard} from "../../models/balanceCard";
import {ActivityItem} from "../../models/activityItem";

@Component({
    selector: 'app-balance-card',
    templateUrl: './balance-card.component.html',
    styleUrls: ['./balance-card.component.scss']
})
export class BalanceCardComponent implements OnInit {

    @Input() data: BalanceCard;

    constructor() {
    }

    ngOnInit() {

    }

    getAvailable(item){
        const balanceCard = new BalanceCard(item);
        return balanceCard.available;
    }

    getDate(item){
        const balanceCard = new BalanceCard(item);
        return balanceCard.date;
    }

    getCurrency(item){
        const balanceCard = new BalanceCard(item);
        return balanceCard.currency;
    }

    getAmount(item){
        const balanceCard = new BalanceCard(item);
        return +balanceCard.amount;
    }

    getAction(item){
        const balanceCard = new BalanceCard(item);
        return balanceCard.action;
    }
}
