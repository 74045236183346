/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/header/header.component.ngfactory";
import * as i3 from "../../shared/header/header.component";
import * as i4 from "../../service/menu.service";
import * as i5 from "../../shared/nav/nav.component.ngfactory";
import * as i6 from "../../shared/nav/nav.component";
import * as i7 from "@angular/common";
import * as i8 from "../../shared/policy/policy.component.ngfactory";
import * as i9 from "../../shared/policy/policy.component";
import * as i10 from "./account-info/account-info.component.ngfactory";
import * as i11 from "./account-info/account-info.component";
import * as i12 from "@angular/forms";
import * as i13 from "../../service/account/accountSettings.service";
import * as i14 from "../../service/account/accountSettingsInfo.service";
import * as i15 from "./account-payment/account-payment.component.ngfactory";
import * as i16 from "./account-payment/account-payment.component";
import * as i17 from "../../shared/popup/popup.component";
import * as i18 from "../../service/stripe/stripe.service";
import * as i19 from "../../service/account/accountSettingsPayment.service";
import * as i20 from "./account.component";
var styles_AccountComponent = [i0.styles];
var RenderType_AccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountComponent, data: {} });
export { RenderType_AccountComponent as RenderType_AccountComponent };
function View_AccountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "account content"]], [[2, "is-visible", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "menuAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("menuAction" === en)) {
        var pd_1 = (_co.menu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { menuActionCheck: [0, "menuActionCheck"], backActionCheck: [1, "backActionCheck"] }, { menuAction: "menuAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["account settings"])), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "account__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "account__top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "account__avatar-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", ""], ["class", "account__avatar"], ["src", "../../../assets/images/woman-avatar.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "account__btn-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, ":svg:svg", [["height", "14"], ["width", "14"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#edit-pencil"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "account__nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-nav", [], null, [[null, "clickAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickAction" === en)) {
        var pd_0 = (_co.showChild($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NavComponent_0, i5.RenderType_NavComponent)), i1.ɵdid(13, 114688, null, 0, i6.NavComponent, [], { navItems: [0, "navItems"] }, { clickAction: "clickAction" }), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "account__policy"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Terms and Creative Content Policy "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "account__policy-read"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["READ POLICIES"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.navItems; _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 0, 0, currVal_0); }); }
export function View_AccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-policy", [], null, [[null, "policyState"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("policyState" === en)) {
        var pd_0 = (_co.policyState($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_PolicyComponent_0, i8.RenderType_PolicyComponent)), i1.ɵdid(3, 114688, null, 0, i9.PolicyComponent, [], { policy: [0, "policy"] }, { policyState: "policyState" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-account-info", [], null, null, null, i10.View_AccountInfoComponent_0, i10.RenderType_AccountInfoComponent)), i1.ɵdid(5, 114688, null, 0, i11.AccountInfoComponent, [i12.FormBuilder, i13.AccountSettingsService, i4.MenuService, i14.AccountSettingsInfoService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-account-payment", [], null, null, null, i15.View_AccountPaymentComponent_0, i15.RenderType_AccountPaymentComponent)), i1.ɵdid(7, 114688, null, 0, i16.AccountPaymentComponent, [i12.FormBuilder, i4.MenuService, i17.PopupComponent, i13.AccountSettingsService, i18.StripeService, i19.AccountSettingsPaymentService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.policy; _ck(_v, 3, 0, currVal_1); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_AccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account", [], null, null, null, View_AccountComponent_0, RenderType_AccountComponent)), i1.ɵdid(1, 114688, null, 0, i20.AccountComponent, [i13.AccountSettingsService, i14.AccountSettingsInfoService, i19.AccountSettingsPaymentService, i4.MenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountComponentNgFactory = i1.ɵccf("app-account", i20.AccountComponent, View_AccountComponent_Host_0, {}, {}, []);
export { AccountComponentNgFactory as AccountComponentNgFactory };
