import {Component, HostListener, OnInit} from '@angular/core';
import {HomeCollectionService} from "../../service/global-library/homeCollection.service";
import {HomeArticlesService} from "../../service/global-library/homeArticles.service";
import {MenuBottomAddContentService} from "../../service/menu/menuBottomAddContent.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../../service/login.service";
import {SearchService} from "../../service/search.service";
import {MenuService} from "../../service/menu.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    popup = false;
    menu = false;

    data = {};

    search  = false;
    notLogged;

    constructor(private homeArticlesService: HomeArticlesService,
                private route: ActivatedRoute,
                private router: Router,
                private searchService: SearchService,
                private menuService: MenuService,
                private loginService: LoginService,) {

        this.searchService.visible.subscribe((value: any) => this.showSearch(value));

        this.route.queryParams.subscribe(params => {
            this.notLogged = params['notLogged'];
            if (this.notLogged) {
                this.loginService.show();
            }
        });
        if (this.route.snapshot.routeConfig.path === 'redirected' && !this.menuService.menuChanged.value) {
            this.router.navigate(['/'], {queryParams: {notLogged: true}});
        }

    }


    ngOnInit() {
        // if (this.notLogged) {
        //     setTimeout(() => {
        //         this.loginService.show();
        //     });
        // }
        this.searchService.hide();
    }

    showPopup(status) {
        this.popup = status;
        document.body.classList.add('is-hidden');
    }

    hidePopup(status) {
        this.popup = status;
    }

    searchState(state)  {
        this.search = state;
    }

    updateData(items) {
        this.data = items;
        this.searchService.setNewData(items);
    }

    showSearch(value) {
        this.search = value;
    }
}
