import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-tokens-budget',
    templateUrl: './tokens-budget.component.html',
    styleUrls: ['./tokens-budget.component.scss']
})
export class TokensBudgetComponent implements OnInit {

    @Output() closeBudget: EventEmitter<any> = new EventEmitter();
    @Output() goToNext: EventEmitter<any> = new EventEmitter();

    budget = 700;
    duration = 7;

    constructor() {
    }

    ngOnInit() {

    }

    formatLabel(value: number) {
        return value + ' per day';
    }

    formatLabelDays(value: number) {
        if (value === 1) {
            return value + ' day';
        }
        return value + ' days';
    }

    goBack() {
        this.closeBudget.emit(true);
    }

    goNext() {
        this.goToNext.emit({budget: this.budget, duration: this.duration});
    }
}
