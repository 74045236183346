import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {USER_ITEM_EDITING_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class UserItemEditingService {
    private config = USER_ITEM_EDITING_CONFIG;
    //visible = new BehaviorSubject(null);
    id = new BehaviorSubject(null);

    constructor(private http: HttpClient) {

    }

    show(id) {
        //this.visible.next(true);
        this.id.next(id);
    }

    hide() {
        this.id.next(null);
    }

    getUser(id) {
        return this.http.post<any>(this.config.user, {id});
    }

    changeUserState(id, status) {
        return this.http.post<any>(this.config.userSaveStatus, {id, status});
    }

    saveUserInfo(id, phone, firstName, lastName, userName) {
        return this.http.post<any>(this.config.userSaveInfo, {id, phone, firstName, lastName, userName});
    }

}
