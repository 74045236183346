import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TOKENS_DISTRIBUTION_CONFIG, TOKENS_TOKENS_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class TokensDistributionService {
    private config = TOKENS_DISTRIBUTION_CONFIG;
    public visible = new BehaviorSubject(null);

    constructor(private http: HttpClient) {

    }

    show() {
        this.visible.next(true);
    }

    hide() {
        this.visible.next(false);
    }

    getData() {
        return this.http.post<any>(this.config.data, {});
    }

    saveBankInfo(routing, account) {
        return this.http.post<any>(this.config.submit, {routing, account});
    }

    savePaypalInfo(email) {
        return this.http.post<any>(this.config.savePaypalInfo, {email});
    }

    payout(amount) {
        return this.http.post<any>(this.config.payout, {amount});
    }
}
