import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuService} from "../../service/menu.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SearchService} from "../../service/search.service";
import {MenuBottomAddContentService} from "../../service/menu/menuBottomAddContent.service";
import {HomeCollectionService} from "../../service/global-library/homeCollection.service";

@Component({
    selector: 'app-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements OnInit {

    logged = true;
    search = false;

    @Input() menuAction: any;
    @Input() onClick;
    @Input() canAdd = true;

    //@Output() menuShowed = new EventEmitter();
    @Input() hidenDetails;

    searchValue = '';
    searchCategories = [];
    searchCost = '';
    searchType = '';

    searchForm: FormGroup;

    constructor(private menuService:MenuService,
                private formBuilder: FormBuilder,
                private searchService: SearchService,
                private menuBottomAddContentService: MenuBottomAddContentService,
                private homeCollectionService: HomeCollectionService,
                private auth:AuthenticationService) {
        this.searchService.visible.subscribe((value: any) => this.searchVisiability(value));

        this.searchService.categories.subscribe((value: any) => this.searchFilterCategories(value));
        this.searchService.cost.subscribe((value: any) => this.searchFilterCost(value));
        this.searchService.type.subscribe((value: any) => this.searchFilterType(value));

    }

    ngOnInit() {
        this.searchForm = this.formBuilder.group({
            search: [null, Validators.required],
        });
    }

    showMenu() {
        this.menuService.show();
    }

    searchHandler() {
        this.search = !this.search;
        this.searchService.visible.next(this.search);
        if (!this.search) {
            this.searchService.resetSearchCategories();
            this.searchService.resetSearchCost();
            this.searchService.resetSearchType();
        }
    }

    showBottomMenu() {
        this.menuBottomAddContentService.toggle();
    }

    isAuth(){
        return this.auth.isUserLogged();
    }

    submitSearch() {
        const searchedValue = this.searchValue.trim();
        if (!!searchedValue.length) {
            console.log('submit with value')
            this.homeCollectionService.loadPublications([], 1, searchedValue).subscribe(data => {
                console.log(data)
                if (data.status) {

                    this.searchService.setNewData(data.result);
                }
            })
        }
    }

    searchVisiability(value) {

    }

    searchFilterCategories(value) {
        this.searchCategories = value;
    }

    searchFilterCost(value) {
        this.searchCost = value;
    }

    searchFilterType(value) {
        this.searchType = value;
    }
}
