import {Component, OnInit} from '@angular/core';
import {TokensSweepstakesService} from "../../../service/tokens/tokensSweepstakes.service";
import {ActivityItem} from "../../../models/activityItem";
import {BalanceCard} from "../../../models/balanceCard";
import {TokensService} from "../../../service/tokens/tokens.service";
import {MenuService} from "../../../service/menu.service";


@Component({
    selector: 'app-tokens-sweepstakes',
    templateUrl: './tokens-sweepstakes.component.html',
    styleUrls: ['./tokens-sweepstakes.component.scss']
})
export class TokensSweepstakesComponent implements OnInit {

    visible: boolean;

    redeem = false;

    data: BalanceCard;
    activity: [ActivityItem];

    constructor(private tokensSweepstakesService: TokensSweepstakesService,
                private menuService: MenuService,
                private tokensService: TokensService) {
        this.tokensSweepstakesService.visible.subscribe((value: any) => this.componentVisibility(value));
    }

    ngOnInit() {

    }

    showRedeem() {
        this.redeem = !this.redeem;
    }

    componentVisibility(state) {
        this.visible = state;
        if (this.visible) {
            this.tokensSweepstakesService.getData().subscribe(data => {
                if (data.status) {
                    this.activity = data.result;
                }
                if (data.info) {
                    this.data = data.info;
                }
                if (!data.status && !data.result && ! data.info) {
                    this.visible = false;
                }

            })
        }
    }

    goBack() {
        this.tokensSweepstakesService.hide();
    }

    menu() {
        // this.tokensSweepstakesService.hide();
        // this.tokensService.hide();
        //this.menuService.hide();
        this.menuService.show();
    }
}
