import {Component, OnInit} from '@angular/core';
import {MenuService} from "../../service/menu.service";
import {SearchService} from "../../service/search.service";

@Component({
    selector: 'app-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

    popup = false;
    menu = false;
    data = {};
    search  = false;


    constructor(private menuService: MenuService,
                private searchService: SearchService,) {
        this.searchService.visible.subscribe((value: any) => this.searchVisiability(value));

    }

    ngOnInit() {
        this.menuService.hide();
    }

    showPopup(status) {
        this.popup = status;
        document.body.classList.add('is-hidden');
    }

    hidePopup(status) {
        this.popup = status;
    }

    showSearch(status) {
        this.search = status
    }

    hidenMenu(state) {
        this.menu = state;
    }

    menuShowed(state) {
        this.menu = state;
    }

    searchState(state)  {
        this.search = state;
    }

    updateData(items) {
        this.data = items;
        this.searchService.setNewData(items);
    }

    searchVisiability(value) {
        this.search = value;
    }

}
