import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountSettingsService} from "../../service/account/accountSettings.service";
import {MenuService} from "../../service/menu.service";
import {AccountSettingsPaymentService} from "../../service/account/accountSettingsPayment.service";
import {AccountSettingsInfoService} from "../../service/account/accountSettingsInfo.service";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    visible: boolean;
    policy = false;

    navItems = [
        {
            icon: 'personal-i.svg',
            title: 'Personal Info',
            url: 'personal-info'
        },
        {
            icon: 'payment-i.svg',
            title: 'Payment Method',
            url: 'payment-method'
        },
        // {
        //     icon: 'payment-i.svg',
        //     title: 'Payout Method',
        //     url: 'payout-method'
        // }
    ];




    constructor(private accountSettingsService: AccountSettingsService,
                private accountSettingsInfoService: AccountSettingsInfoService,
                private accountSettingsPaymentService: AccountSettingsPaymentService,
                private menuService: MenuService,) {
        this.accountSettingsService.visible.subscribe((value: any) => this.changeVisibility(value));
    }

    ngOnInit() {

    }


    showPolicy() {
        this.policy = true;
    }

    policyState(state) {
        this.policy = state;
    }

    changeVisibility(value) {
        this.visible = value;
    }

    goBack() {
        this.accountSettingsService.hide();
    }

    menu() {
        // this.accountSettingsService.hide();
        //this.menuService.hide();
        this.menuService.show();
    }

    showChild(child) {
        this.policy = false;
        switch (child) {
            case 'personal-info':
                this.accountSettingsInfoService.show();
                break;
            case 'payment-method':
                this.accountSettingsPaymentService.show();
                break;
            default:
                return;
        }
    }

}
