import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MenuService} from "../../../service/menu.service";
import {DistributionsService} from "../../../service/admin/distributions/distributions.service";
import {DistributionsSnekService} from "../../../service/admin/distributions/distributionsSnek.service";
import {DistributionsRatioService} from "../../../service/admin/distributions/distributionsRatio.service";
import {DistributionsPayoutsService} from "../../../service/admin/distributions/distributionsPayouts.service";

@Component({
    selector: 'app-distributions',
    templateUrl: './distributions.component.html',
    styleUrls: ['./distributions.component.scss']
})
export class DistributionsComponent implements OnInit {
    visible: boolean;

    navItems = [
        {
            icon: 'ratio-i.svg',
            title: 'token to dollar ratio',
            url: 'token-to-dollar-ratio'
        },
        {
            icon: 'token-arrow-i.svg',
            title: 'payouts to users',
            url: 'payouts-to-users'
        },
        {
            icon: 'logo-arrow-i.svg',
            title: 'snek a pek payouts',
            url: 'snek-a-pek-payuots'
        }
    ];




    constructor(private menuService: MenuService,
                private distributionsService: DistributionsService,
                private distributionsSnekService: DistributionsSnekService,
                private distributionsRatioService: DistributionsRatioService,
                private distributionsPayoutsService: DistributionsPayoutsService) {
        this.distributionsService.visible.subscribe((value: any) => this.toggleVisibility(value));
    }

    ngOnInit() {


    }

    showChild(child) {
        switch (child) {
            case 'token-to-dollar-ratio':
                this.distributionsRatioService.show();
                break;
            case 'payouts-to-users':
                this.distributionsPayoutsService.show();
                break;
            case 'snek-a-pek-payuots':
                this.distributionsSnekService.show();
                break;
            default:
                return;
        }
    }

    goBack() {
        this.distributionsService.hide();
    }

    menu() {
        this.distributionsService.hide();
        //this.menuService.hide();
    }

    toggleVisibility(state) {
        this.visible = state;
    }


}
