/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/header/header.component.ngfactory";
import * as i3 from "../../../shared/header/header.component";
import * as i4 from "../../../service/menu.service";
import * as i5 from "../../../shared/nav/nav.component.ngfactory";
import * as i6 from "../../../shared/nav/nav.component";
import * as i7 from "./ad-settings-active/ad-settings-active.component.ngfactory";
import * as i8 from "./ad-settings-active/ad-settings-active.component";
import * as i9 from "@angular/forms";
import * as i10 from "../../../service/admin/adSettings.service";
import * as i11 from "../../../shared/popup/popup.component";
import * as i12 from "../../../service/admin/adSettingsActive.service";
import * as i13 from "./ad-settings-costs/ad-settings-cost.component.ngfactory";
import * as i14 from "./ad-settings-costs/ad-settings-cost.component";
import * as i15 from "../../../service/admin/adSettingsCost.service";
import * as i16 from "./ad-settings.component";
var styles_AdSettingsComponent = [i0.styles];
var RenderType_AdSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdSettingsComponent, data: {} });
export { RenderType_AdSettingsComponent as RenderType_AdSettingsComponent };
export function View_AdSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "page content"]], [[2, "is-visible", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "menuAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("menuAction" === en)) {
        var pd_1 = (_co.menu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { menuActionCheck: [0, "menuActionCheck"], backActionCheck: [1, "backActionCheck"] }, { menuAction: "menuAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["Ad Settings"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "page__nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-nav", [], null, [[null, "clickAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickAction" === en)) {
        var pd_0 = (_co.showChild($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NavComponent_0, i5.RenderType_NavComponent)), i1.ɵdid(7, 114688, null, 0, i6.NavComponent, [], { navItems: [0, "navItems"] }, { clickAction: "clickAction" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-ad-settings-active", [], null, null, null, i7.View_AdSettingsActiveComponent_0, i7.RenderType_AdSettingsActiveComponent)), i1.ɵdid(9, 114688, null, 0, i8.AdSettingsActiveComponent, [i9.FormBuilder, i4.MenuService, i10.AdSettingsService, i11.PopupComponent, i12.AdSettingsActiveService], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-settings-cost", [], null, null, null, i13.View_AdSettingsCostComponent_0, i13.RenderType_AdSettingsCostComponent)), i1.ɵdid(11, 114688, null, 0, i14.AdSettingsCostComponent, [i9.FormBuilder, i4.MenuService, i10.AdSettingsService, i11.PopupComponent, i15.AdSettingsCostService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.navItems; _ck(_v, 7, 0, currVal_3); _ck(_v, 9, 0); _ck(_v, 11, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 0, 0, currVal_0); }); }
export function View_AdSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-settings", [], null, null, null, View_AdSettingsComponent_0, RenderType_AdSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i16.AdSettingsComponent, [i10.AdSettingsService, i12.AdSettingsActiveService, i15.AdSettingsCostService, i4.MenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdSettingsComponentNgFactory = i1.ɵccf("app-ad-settings", i16.AdSettingsComponent, View_AdSettingsComponent_Host_0, {}, {}, []);
export { AdSettingsComponentNgFactory as AdSettingsComponentNgFactory };
