<div *ngIf="menu">
    <div class="notifications" [class.is-active]="menu">
        <div class="notifications__inner">
            <app-button class="notifications__close" (onClick)="closeMenu()" [type]="'close'"></app-button>
            <div *ngIf="isAuth()" class="notifications__header">
                <img src="{{currentUser.avatar}}" class="notifications__avatar"/>
                <div class="notifications__info">
                    <div>{{currentUser.firstName}}</div>
                    <div>{{currentUser.lastName}}</div>
                </div>
                <button class="notifications__show-more show-more" (click)="showMore()">
                    <span class="visually-hidden">Show more</span>
                    <svg class="show-more__icon" width="33" height="29">
                        <use xlink:href="../../../../assets/images/sprite.svg#bell"></use>
                    </svg>
                    <span class="show-more__counter">{{notificationsTotalAmount}}</span>
                </button>
            </div>
            
            <div class="notifications__menu">
                <div class="menu">
                    <div>
                        <a class="menu__link" href="/">
                            <svg class="menu__icon" width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.21383 5.12354C4.1268 5.2675 4.04193 5.41255 3.95275 5.55329C2.37126 8.05661 1.27968 10.749 1.13141 13.7283C0.884304 18.6705 3.13837 22.2901 7.2769 24.788C8.51997 25.5401 9.9113 26.0429 11.3241 26.7015C11.6067 27.5385 11.9333 28.5011 12.2911 29.554C10.7099 29.3551 9.17958 28.8633 7.77864 28.1036C4.8273 26.5468 2.65489 24.2358 1.27108 21.2071C-1.57497 14.9628 0.775791 8.2081 4.21383 5.12354Z"/>
                                <path d="M18.3151 29.1812C18.8964 28.8825 19.4325 28.644 19.9353 28.3432C24.0255 25.9301 26.6964 22.4523 27.4882 17.739C28.1855 13.5961 26.8318 10.0754 23.686 7.29163C22.9919 6.67601 22.3967 6.02708 21.9616 5.2127C21.6876 4.70129 21.3406 4.22748 21.0172 3.72252L22.689 3.33252C26.6427 5.78749 29.6596 11.1433 28.8753 17.0847C27.9019 24.4603 22.2055 28.3432 18.3151 29.1812Z"/>
                                <path d="M7.70059 21.0751V17.3417H4.37347C5.89798 16.3028 7.59684 15.6054 9.00896 14.3157C10.2741 14.4036 11.6026 14.1529 12.9514 14.4324V12.6327H9.3764C10.9023 11.5765 12.5753 10.8258 13.973 9.5664L17.6675 9.45544C18.4327 10.503 19.1848 11.5765 20.1171 12.529C19.9203 12.6385 19.6939 12.6834 19.4701 12.6572C18.7175 11.8364 18.0264 10.9613 17.4024 10.039C16.3102 11.0981 15.1531 12.0491 13.6286 12.4886C13.6286 13.4699 13.6286 14.4267 13.6286 15.3849C13.6286 15.7437 13.9024 15.9209 14.0839 16.1731C14.6024 15.9056 15.1021 15.6032 15.5796 15.2682C16.0583 14.9521 16.4997 14.5828 16.8952 14.1673H21.8405C22.7742 15.516 23.7929 16.9051 25.0105 18.1659L24.4947 18.4195V23.1126C24.2699 23.3417 24.0725 23.3028 23.7987 23.1933C23.7987 22.5665 23.7987 21.9253 23.7987 21.2826C23.7987 20.346 23.8059 19.4094 23.7987 18.4728C23.7987 18.2351 23.8232 17.9901 23.6546 17.7855C22.8567 16.8455 22.1074 15.8654 21.4096 14.8489C19.9471 16.2898 18.424 17.5593 16.3966 18.0881V23.1486C16.0306 23.3273 15.7093 23.2625 15.3145 23.2092V18.2279C13.7439 18.1803 12.2439 18.2279 10.7035 18.1068L13.5854 16.4959L12.3735 14.8517C11.2727 15.9339 10.13 16.8691 8.58533 17.2927V21.0708C8.27697 21.2019 8.04642 21.176 7.70059 21.0751Z"/>
                                <path d="M19.682 17.9396H21.3549V20.748C20.8002 20.9094 20.5581 20.9166 19.682 20.797V17.9396Z"/>
                                <circle cx="10.0591" cy="5.51976" r="2.47831"/>
                                <path class="stroke" d="M13.1186 3.63658C13.2873 3.52935 13.4719 3.412 13.6349 3.30829C13.7179 3.25548 14.0074 3.07147 14.0499 3.04436" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M6.12285 7.71556C6.29147 7.60833 6.4761 7.49097 6.6391 7.38726C6.72209 7.33446 7.01158 7.15045 7.05413 7.12334" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M13.1205 7.12334C13.2884 7.23162 13.4723 7.35021 13.6347 7.45486C13.7174 7.50815 14.0056 7.69409 14.048 7.72139" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M7.5807 1.58355C7.69705 1.74601 7.82439 1.9239 7.93691 2.08095C7.9942 2.16091 8.19387 2.43983 8.22327 2.48082" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M12.2458 8.2896C12.3622 8.45206 12.4895 8.62995 12.602 8.787C12.6593 8.86696 12.859 9.14589 12.8884 9.18687" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M12.4108 1.58357C12.3281 1.76549 12.2376 1.96464 12.1577 2.14056C12.117 2.23012 11.9751 2.54239 11.9542 2.58834" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M8.32887 8.58118C8.24621 8.76311 8.15568 8.96226 8.07579 9.13818C8.03513 9.22774 7.89316 9.54001 7.87234 9.58596" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M9.93691 1C9.93647 1.19983 9.93595 1.41859 9.93557 1.61179C9.93538 1.71016 9.93456 2.05318 9.93449 2.10362" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M9.93691 8.87274C9.93647 9.07257 9.93595 9.29133 9.93557 9.48453C9.93538 9.5829 9.93456 9.92592 9.93449 9.97637" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M13.412 5.374C13.6118 5.37926 13.8305 5.38506 14.0236 5.39009C14.122 5.39266 14.4649 5.40175 14.5153 5.40303" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M5.5398 5.08238C5.73909 5.09696 5.95727 5.11296 6.14996 5.12701C6.24806 5.13416 6.59017 5.15926 6.64048 5.1629" stroke-linecap="round" stroke-linejoin="round"/>
                                <path class="stroke" d="M6.12287 3.04143C6.29082 3.14971 6.47465 3.2683 6.63705 3.37296C6.71974 3.42624 7.00799 3.61218 7.05041 3.63948" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <span class="menu__title">Home</span>
                        </a>

                        <div *ngIf="isAuth() && isCustomer()" [class.is-active]="chartPie" class="menu__accordion accordion">
                            <button (click)="toggleAccordion('pie')" class="menu__btn accordion__btn">
                                <svg class="menu__icon" width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.51783 1.92023C4.42453 2.07458 4.33353 2.23009 4.23792 2.38099C2.54233 5.0649 1.372 7.95156 1.21304 11.1458C0.948101 16.4445 3.36478 20.3252 7.80189 23.0034C9.13463 23.8097 10.6263 24.3488 12.1411 25.0549C12.444 25.9522 12.7942 26.9843 13.1778 28.1132C11.4825 27.9 9.84183 27.3726 8.33982 26.5581C5.17556 24.889 2.84643 22.4113 1.36278 19.1641C-1.68859 12.4693 0.83176 5.22732 4.51783 1.92023Z"/>
                                    <path d="M19.6365 27.7135C20.2597 27.3933 20.8345 27.1375 21.3736 26.815C25.7589 24.2278 28.6225 20.4992 29.4714 15.4458C30.219 11.0041 28.7676 7.2293 25.3949 4.24474C24.6507 3.5847 24.0126 2.88896 23.5461 2.01582C23.2523 1.46752 22.8803 0.95953 22.5336 0.418139L24.3259 0C28.5649 2.63208 31.7994 8.37429 30.9585 14.7443C29.9149 22.652 23.8076 26.815 19.6365 27.7135Z"/>
                                    <circle cx="15.9537" cy="12.9856" r="10.0174"/>
                                    <path d="M15.785 2.75793C10.1222 2.75793 5.53149 7.34861 5.53149 13.0115C5.53149 18.6743 10.1222 23.265 15.785 23.265C21.4452 23.2586 26.0321 18.6717 26.0386 13.0115C26.0386 7.34861 21.4479 2.75793 15.785 2.75793ZM15.785 22.5814C10.4997 22.5814 6.21506 18.2968 6.21506 13.0115C6.21506 7.72611 10.4997 3.4415 15.785 3.4415C21.0679 3.44753 25.349 7.72863 25.355 13.0115C25.355 18.2968 21.0704 22.5814 15.785 22.5814Z" fill="#353A40"/>
                                    <path d="M7.24209 13.549H7.24995C7.24999 13.549 7.25008 13.549 7.25012 13.549C7.43883 13.5446 7.58827 13.3881 7.58387 13.1994C7.58251 13.1369 7.58182 13.0744 7.58182 13.0117C7.58182 12.7462 7.59481 12.4785 7.61976 12.2164C7.63788 12.0285 7.50022 11.8615 7.31233 11.8433C7.12443 11.8252 6.95738 11.9629 6.93927 12.1508C6.91227 12.4348 6.89825 12.7243 6.89825 13.0117C6.89825 13.0801 6.89894 13.1479 6.90031 13.2151C6.90458 13.4008 7.05637 13.5491 7.24209 13.549Z" fill="#353A40"/>
                                    <path d="M9.11998 17.7944C9.00984 17.641 8.79623 17.606 8.64285 17.7161C8.48948 17.8263 8.45444 18.0399 8.56458 18.1933C8.77226 18.4816 8.99677 18.7574 9.23687 19.0194C9.30169 19.0898 9.39307 19.1298 9.48877 19.1298L9.48911 19.1301C9.57456 19.13 9.65688 19.0979 9.71986 19.0402C9.85896 18.9126 9.86828 18.6964 9.74066 18.5573C9.51889 18.3155 9.31164 18.0607 9.11998 17.7944Z" fill="#353A40"/>
                                    <path d="M7.96455 9.54817C8.01146 9.57111 8.06307 9.58282 8.11527 9.58235C8.24532 9.58269 8.36431 9.50912 8.4222 9.39266C8.56715 9.09834 8.72967 8.81299 8.9089 8.5382C8.91249 8.53299 8.91595 8.52773 8.91924 8.52235C9.01801 8.3615 8.96768 8.151 8.80683 8.05223C8.64598 7.95345 8.43548 8.00378 8.33671 8.16463C8.14236 8.46237 7.96613 8.77155 7.80899 9.09052C7.7256 9.25983 7.79523 9.46473 7.96455 9.54817Z" fill="#353A40"/>
                                    <path d="M7.36563 11.5054C7.38977 11.5107 7.41442 11.5134 7.43912 11.5133C7.59899 11.5125 7.73698 11.4011 7.77133 11.245C7.84174 10.9244 7.93146 10.6083 8.03997 10.2986C8.10244 10.1204 8.00866 9.92525 7.83046 9.86279C7.65226 9.80033 7.45715 9.89411 7.39469 10.0723C7.27741 10.408 7.18017 10.7504 7.10349 11.0977C7.10336 11.0983 7.10319 11.0989 7.10306 11.0996C7.0635 11.2841 7.18107 11.4658 7.36563 11.5054Z" fill="#353A40"/>
                                    <path d="M7.61986 15.5619C7.6199 15.5619 7.6199 15.5619 7.61994 15.5619C7.80224 15.513 7.91042 15.3256 7.8615 15.1433C7.77648 14.8265 7.71065 14.5049 7.66429 14.1801C7.66416 14.1792 7.66404 14.1782 7.66386 14.1772C7.63644 13.9904 7.46281 13.8613 7.27607 13.8887C7.08932 13.9161 6.96013 14.0897 6.98756 14.2765C7.03784 14.6284 7.10915 14.9769 7.20117 15.3203C7.24125 15.4697 7.37664 15.5735 7.53134 15.5735C7.56124 15.5737 7.59102 15.5697 7.61986 15.5619Z" fill="#353A40"/>
                                    <path d="M8.59443 16.9641C8.43623 16.6766 8.2955 16.3799 8.17301 16.0755C8.17173 16.0722 8.1704 16.0689 8.16904 16.0657C8.09581 15.8917 7.89544 15.81 7.72143 15.8832C7.54746 15.9565 7.46577 16.1568 7.539 16.3308C7.67187 16.6604 7.82435 16.9816 7.99562 17.293C8.05582 17.4021 8.1707 17.4698 8.29537 17.4697V17.4707C8.35283 17.4706 8.40935 17.4561 8.45972 17.4284C8.6251 17.3374 8.68543 17.1295 8.59443 16.9641Z" fill="#353A40"/>
                                    <path d="M20.181 6.08625C20.458 6.2627 20.7242 6.45533 20.9784 6.66318C21.0394 6.7133 21.1158 6.7406 21.1948 6.74042C21.2972 6.74042 21.3943 6.69437 21.4592 6.61503C21.5787 6.46892 21.5572 6.2536 21.4111 6.13406C21.1361 5.90882 20.848 5.70029 20.5481 5.50962C20.5458 5.50816 20.5436 5.50671 20.5413 5.5053C20.3809 5.4058 20.1702 5.45519 20.0707 5.61561C19.9713 5.77604 20.0206 5.98675 20.181 6.08625Z" fill="#353A40"/>
                                    <path d="M23.6575 10.7019C23.7496 11.0163 23.8227 11.3358 23.8766 11.6589C23.904 11.8237 24.0465 11.9446 24.2136 11.9446C24.233 11.9452 24.2524 11.9442 24.2717 11.9416C24.2717 11.9416 24.2717 11.9416 24.2718 11.9416C24.458 11.9105 24.5837 11.7344 24.5526 11.5482C24.4938 11.1975 24.414 10.8506 24.3134 10.5095C24.3127 10.5071 24.312 10.5047 24.3113 10.5024C24.2562 10.3218 24.0652 10.2201 23.8846 10.2752C23.7041 10.3303 23.6024 10.5214 23.6575 10.7019Z" fill="#353A40"/>
                                    <path d="M9.23246 7.86846C9.3338 7.8694 9.43031 7.82535 9.49597 7.74815C9.70634 7.49578 9.93154 7.25611 10.1703 7.0304C10.3078 6.90082 10.3141 6.68439 10.1845 6.54695C10.0549 6.40951 9.83848 6.40318 9.70104 6.53276C9.44261 6.77637 9.19871 7.03497 8.97065 7.30725C8.91921 7.36868 8.89097 7.44627 8.89093 7.52642C8.8908 7.71521 9.04371 7.86833 9.23246 7.86846Z" fill="#353A40"/>
                                    <path d="M22.8841 8.89689C23.0486 9.18057 23.196 9.47373 23.3257 9.77493C23.3797 9.90045 23.5032 9.98175 23.6398 9.98171V9.98308C23.6862 9.98303 23.732 9.97359 23.7746 9.95526C23.948 9.88071 24.0282 9.6797 23.9536 9.50629C23.8133 9.17988 23.6535 8.86228 23.4751 8.5551C23.4697 8.54468 23.4639 8.53451 23.4575 8.52468C23.3547 8.36635 23.143 8.32132 22.9847 8.42411C22.8264 8.52691 22.7813 8.73856 22.8841 8.89689Z" fill="#353A40"/>
                                    <path d="M22.3388 8.07836C22.4034 8.16441 22.5047 8.21508 22.6122 8.21508L22.6112 8.21371C22.6852 8.21371 22.7571 8.18974 22.8163 8.14535C22.9673 8.03209 22.9979 7.81788 22.8846 7.66686C22.6706 7.38322 22.4399 7.11252 22.1939 6.85614C22.1901 6.852 22.1863 6.84798 22.1823 6.84405C22.0482 6.71114 21.8318 6.71212 21.6989 6.84615C21.566 6.98021 21.567 7.1966 21.701 7.32951C21.9282 7.56633 22.1412 7.81639 22.3388 8.07836Z" fill="#353A40"/>
                                    <path d="M10.724 6.46987V6.4685C10.7968 6.46906 10.8677 6.44616 10.9264 6.40322C11.1907 6.20887 11.4663 6.03038 11.7518 5.86867C11.9161 5.7757 11.9739 5.56713 11.881 5.40282C11.788 5.23851 11.5794 5.18066 11.4151 5.27362C11.1059 5.44943 10.8075 5.64343 10.5214 5.85466C10.436 5.9187 10.3854 6.01897 10.3847 6.12569C10.3833 6.31449 10.5353 6.46854 10.724 6.46987Z" fill="#353A40"/>
                                    <path d="M12.5038 5.46209C12.5487 5.46196 12.593 5.45304 12.6344 5.43578C12.9373 5.30966 13.2475 5.20204 13.5634 5.11347C13.7448 5.0625 13.8507 4.87422 13.8001 4.69269C13.7495 4.51086 13.561 4.40453 13.3791 4.4552C13.037 4.55154 12.7009 4.66817 12.3726 4.8045C12.2446 4.85743 12.1613 4.9824 12.1615 5.12086C12.1618 5.30961 12.3151 5.46239 12.5038 5.46209Z" fill="#353A40"/>
                                    <path d="M15.8322 4.46101C15.826 4.27226 15.6679 4.12426 15.4792 4.1305C15.1244 4.14246 14.7705 4.17557 14.4196 4.22962C14.2537 4.25555 14.1312 4.3982 14.1306 4.56615C14.13 4.7549 14.2825 4.90844 14.4712 4.90908C14.4885 4.90921 14.5058 4.9081 14.5229 4.90567C14.847 4.85564 15.1739 4.82505 15.5017 4.81407C15.6905 4.80783 15.8385 4.64976 15.8322 4.46101Z" fill="#353A40"/>
                                    <path d="M23.5257 16.661C23.3597 16.5713 23.1523 16.6332 23.0626 16.7992C22.9109 17.0902 22.7417 17.3718 22.5561 17.6424C22.517 17.6993 22.4961 17.7667 22.496 17.8357C22.4959 18.0245 22.6489 18.1775 22.8377 18.1776V18.178C22.9512 18.1786 23.0577 18.1227 23.1217 18.0289C23.3221 17.7357 23.5047 17.4306 23.6686 17.1154C23.7517 16.9502 23.6884 16.7489 23.5257 16.661Z" fill="#353A40"/>
                                    <path d="M21.9526 18.4205C21.7359 18.6671 21.5048 18.9007 21.2605 19.1202C21.1884 19.185 21.1471 19.2774 21.1471 19.3744C21.1471 19.5632 21.3 19.7162 21.4888 19.7162V19.7152C21.5726 19.7152 21.6535 19.6845 21.7161 19.6287C21.9806 19.3913 22.2308 19.1384 22.4653 18.8714C22.5743 18.734 22.5616 18.5364 22.436 18.414C22.3007 18.2823 22.0843 18.2853 21.9526 18.4205Z" fill="#353A40"/>
                                    <path d="M11.042 20.4626L11.0414 20.4625L11.0405 20.4626H11.042Z" fill="#353A40"/>
                                    <path d="M24.6625 12.6045C24.654 12.4157 24.494 12.2697 24.3052 12.2783C24.1165 12.2868 23.9704 12.4468 23.979 12.6356C23.9847 12.7605 23.9875 12.8858 23.9875 13.0116C23.9876 13.3402 23.9682 13.6685 23.9294 13.9949C23.9072 14.1822 24.0408 14.3522 24.2281 14.3746C24.2418 14.3762 24.2554 14.377 24.2692 14.377V14.376C24.4421 14.3758 24.5876 14.2465 24.6082 14.0748C24.6501 13.7219 24.6711 13.3669 24.6711 13.0116C24.6711 12.8748 24.6682 12.7391 24.6625 12.6045Z" fill="#353A40"/>
                                    <path d="M19.7746 5.44645C19.8569 5.27654 19.7859 5.07215 19.616 4.98987C19.2951 4.83632 18.9652 4.70221 18.6282 4.58827C18.4489 4.52786 18.2546 4.62429 18.1942 4.8036C18.1338 4.9829 18.2302 5.17725 18.4095 5.23766C18.7204 5.34314 19.0248 5.46708 19.321 5.60884C19.3669 5.63118 19.4173 5.64289 19.4683 5.64302V5.63926C19.5989 5.63875 19.7177 5.56394 19.7746 5.44645Z" fill="#353A40"/>
                                    <path d="M24.1714 14.7166C23.9887 14.6691 23.8021 14.7786 23.7545 14.9612C23.6765 15.28 23.5796 15.5939 23.4643 15.9012C23.3979 16.0778 23.4872 16.2749 23.6639 16.3414C23.7024 16.3558 23.7431 16.3632 23.7842 16.3632C23.9265 16.3632 24.054 16.275 24.1041 16.1418C24.2293 15.8088 24.3343 15.4685 24.4185 15.1229C24.4593 14.943 24.3499 14.7631 24.1714 14.7166Z" fill="#353A40"/>
                                    <path d="M20.4907 19.7295C20.2222 19.9178 19.9427 20.09 19.6537 20.2449C19.5428 20.3045 19.4736 20.4201 19.4736 20.546C19.4736 20.7347 19.6266 20.8878 19.8154 20.8878V20.8892C19.8716 20.8897 19.9269 20.8763 19.9767 20.8502C20.2898 20.6822 20.5926 20.4956 20.8835 20.2914C21.0386 20.183 21.0765 19.9693 20.968 19.8141C20.8596 19.6589 20.6459 19.6211 20.4907 19.7295Z" fill="#353A40"/>
                                    <path d="M12.9821 20.7238C12.6735 20.6112 12.3719 20.4801 12.0791 20.3311C11.9154 20.2548 11.7206 20.319 11.6344 20.4777C11.5443 20.6436 11.6056 20.8511 11.7715 20.9412C12.0888 21.1018 12.4155 21.2433 12.7497 21.365C12.7871 21.3786 12.8265 21.3855 12.8662 21.3855L12.8652 21.3869C13.0091 21.387 13.1377 21.297 13.1868 21.1617C13.2512 20.9843 13.1595 20.7882 12.9821 20.7238Z" fill="#353A40"/>
                                    <path d="M11.2309 19.8368C10.958 19.6544 10.6964 19.4558 10.4471 19.2421C10.4408 19.2363 10.4343 19.2307 10.4276 19.2254C10.2797 19.1081 10.0647 19.1329 9.94742 19.2808C9.83014 19.4287 9.85492 19.6437 10.0028 19.761C10.2728 19.9923 10.5561 20.2074 10.8515 20.4052C10.9076 20.4426 10.9735 20.4626 11.0409 20.4626C11.1549 20.4626 11.2613 20.4057 11.3248 20.311C11.4298 20.1541 11.3877 19.9419 11.2309 19.8368Z" fill="#353A40"/>
                                    <path d="M14.8957 21.1673C14.5695 21.1319 14.2457 21.0773 13.926 21.0035C13.7469 20.9662 13.5701 21.0765 13.5249 21.2538C13.4784 21.4368 13.5889 21.6228 13.7719 21.6693C14.1183 21.7494 14.4693 21.8086 14.8229 21.8467C14.8351 21.8481 14.8474 21.8488 14.8598 21.8488C15.0335 21.848 15.179 21.7171 15.198 21.5443C15.2187 21.3567 15.0833 21.1879 14.8957 21.1673Z" fill="#353A40"/>
                                    <path d="M18.7614 20.6583C18.4559 20.7774 18.1433 20.8779 17.8256 20.9591C17.6742 20.9977 17.5683 21.134 17.5682 21.2903C17.5682 21.479 17.7212 21.6321 17.91 21.6321V21.6317C17.9387 21.6318 17.9673 21.6283 17.9951 21.6215C18.3396 21.5332 18.6785 21.4243 19.0099 21.2951C19.1815 21.2254 19.2665 21.0318 19.2015 20.8583C19.1351 20.6816 18.9382 20.5921 18.7614 20.6583Z" fill="#353A40"/>
                                    <path d="M17.8656 4.71493C17.9041 4.53011 17.7854 4.34914 17.6007 4.31065C17.2525 4.23861 16.9005 4.18735 16.5462 4.15718C16.3581 4.14125 16.1927 4.28078 16.1768 4.46889C16.1608 4.657 16.3004 4.82243 16.4885 4.83836C16.8155 4.86609 17.1405 4.91334 17.4619 4.97986C17.4844 4.98452 17.5073 4.98691 17.5302 4.98704H17.5323C17.6937 4.98644 17.8327 4.87297 17.8656 4.71493Z" fill="#353A40"/>
                                    <path d="M16.86 21.1463C16.5344 21.1883 16.2067 21.2111 15.8784 21.2146C15.6897 21.2158 15.5375 21.3697 15.5387 21.5585C15.5399 21.7472 15.6938 21.8994 15.8825 21.8982H15.8873C16.2422 21.8944 16.5966 21.8698 16.9486 21.8244C17.1358 21.7999 17.2678 21.6283 17.2434 21.4411C17.2189 21.2538 17.0473 21.1218 16.86 21.1463Z" fill="#353A40"/>
                                    <path d="M15.1377 10.2799C14.7834 10.2799 14.448 10.2932 14.1314 10.32C13.8186 10.3467 13.5321 10.3773 13.272 10.4117C13.0157 10.446 12.7915 10.4804 12.5993 10.5148C12.4108 10.5454 12.2657 10.5683 12.1639 10.5836C12.1639 10.5797 12.1583 10.5549 12.147 10.5091C12.1394 10.4632 12.1281 10.404 12.1131 10.3314C12.098 10.2588 12.0848 10.1824 12.0735 10.1022C12.0622 10.0182 12.0565 9.94559 12.0565 9.88446C12.0565 9.7584 12.1112 9.66671 12.2205 9.60941C12.3335 9.54828 12.4862 9.50817 12.6784 9.48907C12.8706 9.46615 13.0949 9.45851 13.3512 9.46615C13.6075 9.46997 13.8826 9.47188 14.1766 9.47188H15.8331C16.0065 9.47188 16.2195 9.47188 16.472 9.47188C16.7245 9.47188 17.0053 9.46615 17.3144 9.45469C17.6272 9.44323 17.9608 9.42412 18.3151 9.39738C18.6731 9.37064 19.0387 9.33244 19.4119 9.28278C19.4119 9.2866 19.4175 9.30761 19.4288 9.34581C19.4401 9.38019 19.4514 9.42795 19.4627 9.48907C19.4741 9.54637 19.4854 9.61323 19.4967 9.68963C19.508 9.76222 19.5136 9.83862 19.5136 9.91885C19.5136 10.0144 19.4797 10.0888 19.4119 10.1423C19.3478 10.1958 19.2611 10.2359 19.1518 10.2627C19.0425 10.2856 18.9162 10.3009 18.773 10.3085C18.6336 10.3123 18.4866 10.3142 18.332 10.3142C17.9024 10.3142 17.5386 10.3104 17.2409 10.3028C16.9431 10.2951 16.6868 10.2875 16.472 10.2799V15.7638C16.472 16.0083 16.4776 16.2242 16.4889 16.4113C16.504 16.5985 16.521 16.7628 16.5398 16.9042C16.5587 17.0417 16.5775 17.1582 16.5964 17.2537C16.6152 17.3454 16.6303 17.4199 16.6416 17.4772C16.5851 17.5078 16.5229 17.5364 16.455 17.5631C16.3872 17.5861 16.3156 17.6052 16.2402 17.6205C16.1686 17.6396 16.097 17.6529 16.0254 17.6606C15.9537 17.6682 15.8859 17.672 15.8218 17.672C15.7238 17.672 15.6315 17.6625 15.5448 17.6434C15.4619 17.6243 15.3903 17.5937 15.33 17.5517C15.2697 17.5058 15.2225 17.4466 15.1886 17.374C15.1547 17.3015 15.1377 17.2098 15.1377 17.099V10.2799Z" fill="#353A40"/>
                                </svg>
                                <span class="menu__title">Tokens</span>
                            </button>

                            <div class="accordion__inner">
                                <div class="chart">
                                    <ul class="chart__data">
                                        <li style="color: #FBE399;" class="chart__item">lorem</li>
                                        <li style="color: #FE5E8B;" class="chart__item">lorem</li>
                                        <li style="color: #C961BD;" class="chart__item">lorem</li>
                                    </ul>
                                    <div class="chart__chart">
                                        <div style="display: block; width: 185px">
                                            <canvas baseChart
                                                    [data]="pieChartData"
                                                    [options]="pieChartOptions"
                                                    [labels]="pieChartLabels"
                                                    [colors]="pieChartColors"
                                                    [chartType]="pieChartType"></canvas>
                                        </div>

                                        <button style="right: 38px; bottom: 10px" (click)="openChart()" class="chart__btn">Tap</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isAuth() && isCustomer()" [class.is-active]="chartLine" class="menu__accordion accordion">
                            <button (click)="toggleAccordion('line')" class="menu__btn accordion__btn">
                                <svg class="menu__icon" width="33" height="30">
                                    <use xlink:href="../../../../assets/images/sprite.svg#book-in-circle"></use>
                                </svg>
                                <span class="menu__title">Library</span>
                            </button>

                            <div class="accordion__inner">
                                <div class="chart">
                                    <ul class="chart__data">
                                        <li style="color: #FBE399;" class="chart__item">lorem</li>
                                        <li style="color: #FE5E8B;" class="chart__item">lorem</li>
                                        <li style="color: #C961BD;" class="chart__item">lorem</li>
                                    </ul>
                                    <div class="chart__chart">
                                        <div style="display: block; width: 185px">
                                            <canvas baseChart
                                                    [datasets]="lineChartData"
                                                    [labels]="lineChartLabels"
                                                    [options]="lineChartOptions"
                                                    [colors]="lineChartColors"
                                                    [chartType]="lineChartType"
                                                    [plugins]="lineChartPlugins">
                                            </canvas>
                                        </div>

                                        <button (click)="openChart()" class="chart__btn">Tap</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isAuth() && isCustomer()" [class.is-active]="chartBar" class="menu__accordion accordion">
                            <button (click)="toggleAccordion('bar')" class="menu__btn accordion__btn">
                                <svg class="menu__icon" width="33" height="30">
                                    <use xlink:href="../../../../assets/images/sprite.svg#box-in-circle"></use>
                                </svg>
                                <span class="menu__title">Collection</span>
                            </button>

                            <div class="accordion__inner">
                                <div class="chart">
                                    <ul class="chart__data">
                                        <li style="color: #FBE399;" class="chart__item">lorem</li>
                                        <li style="color: #FE5E8B;" class="chart__item">lorem</li>
                                        <li style="color: #C961BD;" class="chart__item">lorem</li>
                                    </ul>
                                    <div class="chart__chart">
                                        <div style="display: block; width: 185px">
                                            <canvas baseChart
                                                    [datasets]="barChartData"
                                                    [labels]="barChartLabels"
                                                    [options]="barChartOptions"
                                                    [chartType]="barChartType">
                                            </canvas>
                                        </div>

                                        <button (click)="openChart()" class="chart__btn">Tap</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button *ngIf="isAuth() && isCustomer()" class="menu__link" (click)="showAccountSettings()">
                            <svg class="menu__icon" width="29" height="27">
                                <use xlink:href="../../../../assets/images/sprite.svg#settings-in-circle"></use>
                            </svg>
                            <span class="menu__title">Account Settings</span>
                        </button>

                    </div>
                    <div>
                        <button *ngIf="!isAuth()" class="menu__link" (click)="showLogin()">
                            <svg class="menu__icon" width="29" height="27">
                                <use xlink:href="../../../../assets/images/sprite.svg#user-in-circle"></use>
                            </svg>
                            <span class="menu__title">Login</span>
                        </button>


                        <button *ngIf="!isAuth()" class="menu__link" (click)="showSignUp()">
                            <svg class="menu__icon" width="29" height="27">
                                <use xlink:href="../../../../assets/images/sprite.svg#user-in-circle"></use>
                            </svg>
                            <span class="menu__title">Sign Up</span>
                        </button>

                        <button *ngIf="isAuth() && isAdmin()" class="menu__link" (click)="showUsers()">
                            <svg class="menu__icon" width="29" height="27">
                                <use xlink:href="../../../../assets/images/sprite.svg#user-in-circle"></use>
                            </svg>
                            <span class="menu__title">Users</span>
                        </button>

                        <button *ngIf="isAuth() && isAdmin()" class="menu__link" (click)="showDistributions()">
                            <svg class="menu__icon" width="32" height="29">
                                <use xlink:href="../../../../assets/images/sprite.svg#arrows-in-circle"></use>
                            </svg>
                            <span class="menu__title">Distributions</span>
                        </button>

                        <button *ngIf="isAuth() && isAdmin()" class="menu__link" (click)="showAdSettings()">
                            <svg class="menu__icon" width="29" height="27">
                                <use xlink:href="../../../../assets/images/sprite.svg#settings-in-circle"></use>
                            </svg>
                            <span class="menu__title">ad settings</span>
                        </button>

                        <button *ngIf="isAuth()" class="menu__link" (click)="logOut()">
                            <svg class="menu__icon" width="32" height="29">
                                <use xlink:href="../../../../assets/images/sprite.svg#arrow-in-circle"></use>
                            </svg>
                            <span class="menu__title">Log Out</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>


    <app-notifications-list></app-notifications-list>
</div>


<div [class.is-active]="chartPopup" class="popup">
    <div class="popup__inner">
        <app-button class="popup__close" (onClick)="closeChartPopup()" [type]="'close'"></app-button>
        <div class="popup__chart" *ngIf="chartPie">
            <canvas baseChart
                    [data]="pieChartData"
                    [options]="pieChartOptions"
                    [labels]="pieChartLabels"
                    [colors]="pieChartColors"
                    [chartType]="pieChartType"></canvas>
        </div>

        <div class="popup__chart" *ngIf="chartLine">
            <canvas baseChart
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [colors]="lineChartColors"
                    [chartType]="lineChartType"
                    [plugins]="lineChartPlugins">
            </canvas>
        </div>

        <div class="popup__chart" *ngIf="chartBar">
            <canvas baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [chartType]="barChartType">
            </canvas>
        </div>
    </div>
</div>
