<div *ngIf="publication !== undefined">
    <div *ngIf="getAdvertising(publication).url === ''" class="details__content">
        <div class="content">
            <img src="{{getPlaceholder(publication)}}" alt="" class="content__preview">
        </div>
    </div>
    <app-publication-advertisement (advertisingIsOver)="advertisingIsOver()" [advertising]="getAdvertising(publication)" *ngIf="getAdvertising(publication).url !== ''"></app-publication-advertisement>

    <div class="publication__wrapper">

        <div *ngIf="!noMenu">
            <app-publication-menu [id]="getId(publication)"></app-publication-menu>
<!--            <button class="btn-menu js-publication-btn-menu" (click)="toggleMenu()">-->
<!--                <svg *ngIf="!menu" width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <circle cx="15" cy="15.1538" r="14.75" fill="#2E2E2E" stroke="white" stroke-width="0.5"/>-->
<!--                    <circle cx="15.3261" cy="15.4799" r="2.03261" stroke="white" stroke-width="0.5"/>-->
<!--                    <circle cx="15.3261" cy="23.3058" r="2.03261" stroke="white" stroke-width="0.5"/>-->
<!--                    <circle cx="15.3261" cy="7.6537" r="2.03261" stroke="white" stroke-width="0.5"/>-->
<!--                </svg>-->
<!--    -->
<!--                <svg *ngIf="menu" width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <circle cx="15" cy="15.1538" r="14.75" fill="#E5E558" stroke="#353A40" stroke-width="0.5"/>-->
<!--                    <circle cx="15.3261" cy="15.4799" r="2.03261" stroke="#353A40" stroke-width="0.5"/>-->
<!--                    <circle cx="15.3261" cy="23.3058" r="2.03261" stroke="#353A40" stroke-width="0.5"/>-->
<!--                    <circle cx="15.3261" cy="7.6537" r="2.03261" stroke="#353A40" stroke-width="0.5"/>-->
<!--                </svg>-->
<!--            </button>-->
        </div>


        <app-publication-statistics
                (likeEmitted)="toggleLike($event)"
                [data]="{free: getPayment(publication),
                         likes: getLikes(publication),
                         likedByUser: getLikedByUser(publication),
                         reposts: getReposts(publication),
                         tokens: getTokens(publication)}">
        </app-publication-statistics>
    </div>

    <div class="info">
        <img src="{{getAuthorAvatar()}}" alt="" class="info__avatar">
        <div class="info__about">
            <div class="info__top">
                <div class="info__author">{{getAuthorName()}}</div>
                <ul class="info__categories categories">

                    <li *ngFor="let category of getCategories(publication)"  class="categories__category">
                        <svg class="categories__svg_icon">
                            <use [attr.xlink:href]="'#category_'+category"></use>
                        </svg>

                    </li>

                </ul>
            </div>

            <div class="info__title">{{getTitle(publication)}}</div>
            <div class="info__descr">{{getDescription(publication)}}</div>
            <div class="info__tags">
                <div *ngFor="let tag of getTags(publication)" class="tags">
                    <!--                <a href="#" class="tags__item">#tagtag</a>-->
                    <div class="tags__item">{{tag}}</div>

                </div>
            </div>
            <div class="info__time">
                {{getTimeAgo(publication)}}
            </div>
        </div>
    </div>
</div>


<!--<div class="menu js-publication-menu" [class.is-active]="menu">-->
<!--    <a class="menu__item" href="#">-->
<!--        <svg class="menu__icon" width="18" height="18">-->
<!--            <use xlink:href="../../../../assets/images/sprite.svg#edit"></use>-->
<!--        </svg>-->
<!--        Edit-->
<!--    </a>-->
<!--    <a class="menu__item" href="#">-->
<!--        <svg class="menu__icon" width="18" height="18">-->
<!--            <use xlink:href="../../../../../assets/images/sprite.svg#delete"></use>-->
<!--        </svg>-->

<!--        Delete-->
<!--    </a>-->
<!--</div>-->
