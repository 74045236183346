import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RadioComponent} from "./radio/radio.component";
import {ButtonComponent} from "./button/button.component";
import {HeaderComponent} from "./header/header.component";
import {NavComponent} from "./nav/nav.component";
import {PolicyComponent} from "./policy/policy.component";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {BalanceCardComponent} from "./balance-card/balance-card.component";
import {ActivityComponent} from "./activity/activity.component";
import {FilterComponent} from "./filter/filter.component";
import {HeaderSearchComponent} from "./header-search/header-search.component";
import {FooterFilterComponent} from "./footer-filter/footer-filter.component";
import {CollectionMainComponent} from "./collection-main/collection-main.component";
import {VideoPublicationComponent} from "./publication/video-publication/video-publication.component";
import {SoundPublicationComponent} from "./publication/sound-publication/sound-publication.component";
import {ImagePublicationComponent} from "./publication/image-publication/image-publication.component";
import {PublicationStatisticsComponent} from "./publication/publication-statistics/publication-statistics.component";
import {PublicationMenuComponent} from "./publication/publication-menu/publication-menu.component";
import {PublicationAdvertisementComponent} from "./publication/publication-advertisement/publication-advertisement.component";
import {CollectionThumbnailsComponent} from "./collection-thumbnails/collection-thumbnails.component";
import {MenuBottomComponent} from "./menu-bottom/menu-bottom.component";
import {PopupComponent} from "./popup/popup.component";
import {ReactiveFormsModule} from "@angular/forms";



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};


@NgModule({
    declarations: [
        RadioComponent,
        ButtonComponent,
        HeaderComponent,
        HeaderSearchComponent,
        NavComponent,
        PolicyComponent,
        BalanceCardComponent,
        ActivityComponent,
        FilterComponent,
        FooterFilterComponent,
        CollectionMainComponent,
        CollectionThumbnailsComponent,

        VideoPublicationComponent,
        SoundPublicationComponent,
        ImagePublicationComponent,

        PublicationStatisticsComponent,
        PublicationMenuComponent,
        PublicationAdvertisementComponent,

        MenuBottomComponent,
        PopupComponent,

    ],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        ReactiveFormsModule
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
    exports: [
        RadioComponent,
        ButtonComponent,
        HeaderComponent,
        HeaderSearchComponent,
        NavComponent,
        PolicyComponent,
        BalanceCardComponent,
        ActivityComponent,
        FilterComponent,
        FooterFilterComponent,
        CollectionMainComponent,
        CollectionThumbnailsComponent,

        VideoPublicationComponent,
        SoundPublicationComponent,
        ImagePublicationComponent,

        PublicationStatisticsComponent,
        PublicationMenuComponent,
        PublicationAdvertisementComponent,
        MenuBottomComponent,
        PopupComponent

    ]
})
export class SharedModule { }
