import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import {MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatRadioModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import {SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages-routing.module';

// import {ArticleComponent} from "./article/article.component";
// import {ArticleSliderComponent} from "./article/article-slider/article-slider.component";
// import {ArticleAboutComponent} from "./article/article-about/article-about.component";
// import {ArticleFooterComponent} from "./article/article-footer/article-footer.component";
import {SearchComponent} from "./search/search.component";
import {LoginComponent} from "./login/login.component";

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {SignUpComponent} from "./signup/signup.component";

import {MatSliderModule} from '@angular/material/slider';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import { ChartsModule } from 'ng2-charts';
import {ArticleDetailsComponent} from "./article/article-details/article-details.component";
import {LibraryComponent} from "./library/library.component";

import { NgxCurrencyModule } from "ngx-currency";
import {CollectionListComponent} from "./collection-list/collection-list.component";



@NgModule({
    declarations: [
        HomeComponent,
        MainComponent,

        SearchComponent,

        LoginComponent,
        SignUpComponent,

        CollectionListComponent,

        LibraryComponent,

    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        SweetAlert2Module.forRoot(),
        MatSliderModule,
        ChartsModule,
        NgxCurrencyModule,
        // CarouselModule,
        // SwiperModule,
        // SlickCarouselModule
    ],
    providers: [
        FocusMonitor,
        Platform,
        // {
        //     provide: SWIPER_CONFIG,
        //     useValue: DEFAULT_SWIPER_CONFIG
        // }
    ],
    exports: [
        LoginComponent,
        SignUpComponent,
        SearchComponent,
        CollectionListComponent
    ],
    entryComponents: [

        SearchComponent,

        LoginComponent,
        SignUpComponent,

        CollectionListComponent,

        LibraryComponent,
    ]
})
export class ChatQuizModule { }
