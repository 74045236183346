import { ROLES_CONFIG } from '../../config/roles';
import * as i0 from "@angular/core";
import * as i1 from "../../modules/auth/_services/authentication.service";
export class AccessService {
    constructor(auth) {
        this.auth = auth;
        this.roles = ROLES_CONFIG;
    }
    isCustomer() {
        if (this.roles.customer == this.auth.getRole()) {
            return true;
        }
        return false;
    }
    isAdmin() {
        if (this.roles.admin == this.auth.getRole()) {
            return true;
        }
        return false;
    }
}
AccessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccessService_Factory() { return new AccessService(i0.ɵɵinject(i1.AuthenticationService)); }, token: AccessService, providedIn: "root" });
