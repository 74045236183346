import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AdSettingsService} from "../../../service/admin/adSettings.service";
import {MenuService} from "../../../service/menu.service";
import {AdSettingsActiveService} from "../../../service/admin/adSettingsActive.service";
import {AdSettingsCostService} from "../../../service/admin/adSettingsCost.service";

@Component({
    selector: 'app-ad-settings',
    templateUrl: './ad-settings.component.html',
    styleUrls: ['./ad-settings.component.scss']
})
export class AdSettingsComponent implements OnInit {
    visible: boolean;

    navItems = [
        {
            icon: 'edit-i.svg',
            title: 'Edit Ad Costs',
            url: 'ad-settings-cost'
        },
        {
            icon: 'attention-i.svg',
            title: 'users with active ads',
            url: 'ad-settings-active'
        }
    ];

    constructor(private adSettingsService: AdSettingsService,
                private adSettingsActiveService: AdSettingsActiveService,
                private adSettingsCostService: AdSettingsCostService,
                private menuService: MenuService) {
        this.adSettingsService.visible.subscribe((value: any) => this.toggleVisibility(value));
    }

    ngOnInit() {

    }

    toggleVisibility(value) {
        this.visible = value;
    }

    goBack() {
        this.adSettingsService.hide();
    }

    menu() {
        this.adSettingsService.hide();
        //this.menuService.hide();
    }

    showChild(child) {
        switch (child) {
            case 'ad-settings-cost':
                this.adSettingsCostService.show();
                break;
            case 'ad-settings-active':
                this.adSettingsActiveService.show();
                break;
            default:
                return;
        }
    }
}
