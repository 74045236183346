import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AccountSettingsInfoService} from "./accountSettingsInfo.service";
import {AccountSettingsPaymentService} from "./accountSettingsPayment.service";

@Injectable({
    providedIn: 'root'
})
export class AccountSettingsService {
    public visible = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private accountSettingsInfoService: AccountSettingsInfoService,
                private accountSettingsPaymentService: AccountSettingsPaymentService,) {

    }

    show() {
        this.visible.next(true);

    }

    hide() {
        this.visible.next(false);
    }

    hideSection() {
        this.hide();
        this.accountSettingsInfoService.hide();
        this.accountSettingsPaymentService.hide();
    }

}
