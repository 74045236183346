<div class="account content" *ngIf="visible" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">Personal Info</app-header>
    <div class="account__inner">
        <div class="account__top">
            <div class="account__avatar-wrapper">
                <img src="../../../assets/images/woman-avatar.jpg" alt="" class="account__avatar">
            </div>
        </div>

        <div class="account__form">
            <form [formGroup]="accountForm" (ngSubmit)="onSubmit()" class="form-labeled">
                <div class="form-labeled__item">
                    <input mask="(000) 000-0000"
                           formControlName="phone"
                           placeholder="Phone Number"
                           [type]="'text'"
                           [value]=""
                           class="form-labeled__input"
                           id="phone"
                           autocomplete="off">
                    <label class="form-labeled__label" for="phone">Phone Number</label>
                </div>
                <div class="form-labeled__item">
                    <input formControlName="firstName"
                           id="firstName"
                           placeholder="First Name"
                           [value]=""
                           [type]="'text'"
                           class="form-labeled__input"
                           autocomplete="off">
                    <label class="form-labeled__label" for="firstName">First Name</label>
                </div>
                <div class="form-labeled__item">
                    <input formControlName="lastName"
                           id="lastName"
                           placeholder="Last Name"
                           [value]=""
                           [type]="'text'"
                           class="form-labeled__input"
                           autocomplete="off">
                    <label class="form-labeled__label" for="lastName">Last Name</label>
                </div>
                <div class="form-labeled__item">
                    <input formControlName="userName"
                           id="userName"
                           placeholder="User Name"
                           [value]=""
                           [type]="'text'"
                           class="form-labeled__input"
                           autocomplete="off">
                    <label class="form-labeled__label" for="userName">UserName</label>
                </div>

                <div class="form-labeled__btns">
                    <button class="form-labeled__btn" (click)="onSubmit()" [disabled]="!accountForm.valid">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<app-popup></app-popup>
