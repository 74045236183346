<div class="page content" *ngIf="visible" [class.is-visible]="visible">
<!--    <app-header *ngIf="!popup && visible" (backAction)="goBack()" [backActionCheck]="backActionCheck()" [nextActionCheck]="nextActionCheck()" [rightAccent]="true" (nextAction)="showPreview()" >New Content</app-header>-->

    <app-header (backAction)="goBack()" [backActionCheck]="backActionCheck()" [nextActionCheck]="nextActionCheck()" [rightAccent]="true" (nextAction)="showPreview()" >New Content</app-header>

    <div class="page__inner">
        <app-global-library-edit [categoriesList]="categories" [validation]="validation" [editable]="true" [publication]="item"></app-global-library-edit>
    </div>
</div>

<div class="popup" *ngIf="visible" [class.is-active]="popupPreviewPhoto">
    <div class="popup__inner">
        <app-image-publication [noMenu]="true" [publication]="item" [author]="author"></app-image-publication>
    </div>
</div>

<div class="popup" *ngIf="visible" [class.is-active]="popupPreviewAudio">
    <div class="popup__inner">
        <app-sound-publication [noMenu]="true" [publication]="item" [author]="author"></app-sound-publication>
    </div>
</div>

<div class="popup" *ngIf="visible" [class.is-active]="popupPreviewVideo">
    <div class="popup__inner">
        <app-video-publication [noMenu]="true" [publication]="item" [author]="author"></app-video-publication>
    </div>
</div>

<app-header *ngIf="popup && visible" [backActionCheck]="true" (backAction)="closePreview()">New Content</app-header>
<button *ngIf="popup && visible" (click)="saveData()" class="btn-publish">Publish</button>
