import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SignUpService {
    public signUpChanged = new BehaviorSubject(null);

    constructor(private ngZone: NgZone) {

    }

    show() {
        this.signUpChanged.next(true);
    }

    hide() {
        this.signUpChanged.next(false);
    }
}
