export class BalanceCard {
    private _currency: string;
    private _date: string;
    public _amount: string;
    public _available: number;
    public _action: string;

    constructor(data) {
        this._available = data.available;
        this._date = data.date;
        this._currency = data.currency;
        this._amount = data.amount;
        this._action = data.action;
    }

    get available(): number {
        return this._available;
    }

    set available(value: number) {
        this._available = value;
    }

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get currency(): string {
        return this._currency;
    }

    set currency(value: string) {
        this._currency = value;
    }

    get amount(): string {
        return this._amount;
    }

    set amount(value: string) {
        this._amount = value;
    }

    get action(): string {
        return this._action;
    }

    set action(value: string) {
        this._action = value;
    }

}
