import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MenuService} from "../../../../service/menu.service";
import {DistributionsService} from "../../../../service/admin/distributions/distributions.service";
import {DistributionsRatioService} from "../../../../service/admin/distributions/distributionsRatio.service";
import {PopupComponent} from "../../../../shared/popup/popup.component";

@Component({
    selector: 'app-distributions-ratio',
    templateUrl: './distributions-ratio.component.html',
    styleUrls: ['./distributions-ratio.component.scss']
})
export class DistributionsRatioComponent implements OnInit {
    visible: boolean;

    ratioForm: FormGroup;

    token = 0.00;
    dollar = 1.00;


    constructor(private formBuilder: FormBuilder,
                private menuService: MenuService,
                private distributionsService: DistributionsService,
                private popupComponent: PopupComponent,
                private distributionsRatioService: DistributionsRatioService) {
        this.distributionsRatioService.visible.subscribe((value: any) => this.toggleVisibility(value));

    }

    ngOnInit() {
        this.ratioForm = this.formBuilder.group({
            token: [null, Validators.required],
            dollar: [{value: '1', disabled: true}, Validators.required]
        });
    }

    onFormSubmit() {
        this.distributionsRatioService.saveRatio(this.token).subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }

    goBack() {
        this.distributionsRatioService.hide();
    }

    menu() {
        this.distributionsRatioService.hide();
        this.distributionsService.hide();
        //this.menuService.hide();
    }

    toggleVisibility(state) {
        this.visible = state;

        if (this.visible) {
            this.distributionsRatioService.getRatio().subscribe(data => {
                if (data.status) {
                    this.token = data.result.tokens;
                }
            });
        }
    }

}
