import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalLibraryService {
    public globalLibraryChanged = new BehaviorSubject(false);
    public globalLibraryHidePreviewChanged = new BehaviorSubject(false);
    constructor(private ngZone: NgZone) {

    }

    show() {
        this.globalLibraryChanged.next(true);
        document.body.classList.add('is-hidden');
    }

    hide() {
        this.globalLibraryChanged.next(false);
        this.globalLibraryHidePreviewChanged.next(false);
        document.body.classList.remove('is-hidden');
    }

}
