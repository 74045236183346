import {Directive,Component, EventEmitter, Input, OnInit, Output, ViewChildren, QueryList ,ViewChild,ElementRef} from '@angular/core';
import {POST_CATEGORIES} from "../../../config/post_categories";
import {PROJECT_CONFIG} from "../../../config/project";
import { Pipe, Sanitizer } from '@angular/core';
import {BalanceCard} from "../../../models/balanceCard";
import {PublicationItem} from "../../../models/publicationItem";


@Component({
    selector: 'app-image-publication',
    templateUrl: './image-publication.component.html',
    styleUrls: ['./image-publication.component.scss']
})

export class ImagePublicationComponent implements OnInit {
    postCategories = POST_CATEGORIES;
    projectConfig = PROJECT_CONFIG;

    @Input() publication;
    // @Input() menu;
    @Input() noMenu;
    @Input() author;


    menu = false;
    advertising: string;

    @Output() menuShowed = new EventEmitter();
    @Output() likeEmitted = new EventEmitter();
    repost = false;


    constructor() {
    }

    ngOnInit() {

    }


    toggleRepost() {
        this.repost = !this.repost;
    }

    toggleMenu() {
        this.menu = !this.menu;
        //this.menuShowed.emit(this.menu);
        if (this.menu) {
            document.body.classList.add('is-hidden');
        } else {
            document.body.classList.remove('is-hidden');
        }
    }

    setIcon(categoryId) {
        if (this.postCategories[categoryId]) {
            return this.postCategories[categoryId];
        }
    }

    getAuthorAvatar(){
        this.author = this.author ? this.author : this.publication.author;
        if(this.author){
            if(this.author.avatar_link){
                return this.author.avatar_link;
            }
        }


        return this.projectConfig.defaultAvatar;
    }

    getAuthorName(){
        let name = '';
        this.author = this.author ? this.author : this.publication.author;
        if(this.author) {
            if (this.author.first_name) {
                name = this.author.first_name;
            }

            if (this.author.last_name) {
                name = name + ' ' + this.author.last_name;
            }
        }

        return name;
    }

    getId(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.id;
    }

    getTitle(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.title;
    }

    getPlaceholder(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.placeholder;
    }

    getLikes(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.likes;
    }

    getTokens(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.tokens;
    }

    getReposts(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.reposts;
    }

    getPayment(item){
        const publicationItem = new PublicationItem(item);
        return !publicationItem.cost;
    }

    getDescription(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.descr;
    }

    getTimeAgo(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.timeAgo;
    }

    getTags(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.tags;
    }

    getCategories(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.categories;
    }

    getAdvertising(item){
        console.log(item)
        const publicationItem = new PublicationItem(item);
        this.advertising = publicationItem.advertising ? publicationItem.advertising : '';
        return publicationItem.advertising;
    }

    getLikedByUser(item){
        //console.log(item)
        const publicationItem = new PublicationItem(item);
        return publicationItem.user_likes;
    }

    advertisingIsOver() {
        this.publication.advertising.url = '';
        //console.log(this.publication)
    }

    toggleLike(state) {
      this.likeEmitted.emit(state);
    }
 }
