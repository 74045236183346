import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserInfoService} from "../../../service/account/user-info.service";

import {PopupComponent} from "../../../shared/popup/popup.component";
import {MenuService} from "../../../service/menu.service";
import {AccountSettingsInfoService} from "../../../service/account/accountSettingsInfo.service";
import {AccountSettingsService} from "../../../service/account/accountSettings.service";

@Component({
    selector: 'app-account-info',
    templateUrl: './account-info.component.html',
    styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
    visible: boolean;
    accountForm: FormGroup;

    // POPUPS
    @ViewChild(PopupComponent, {static: false})
    private popupComponent: PopupComponent;

    payouts = [
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
        {
            date: '27 July',
            user: 'Name of User',
            amount: '$300'
        },
    ]

    constructor(private formBuilder: FormBuilder,
                //private userInfoService: UserInfoService,
                private accountSettingsService: AccountSettingsService,
                private menuService: MenuService,
                private accountSettingsInfoService: AccountSettingsInfoService ) {
        this.accountSettingsInfoService.visible.subscribe((value: any) => this.changeVisibility(value));
    }

    ngOnInit() {
        this.accountForm = this.formBuilder.group({
            phone: [null, Validators.required],
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            userName: [null, Validators.required]
        });
    }

    onSubmit() {
        const formData = this.accountForm.value;
        this.accountSettingsInfoService.setData(formData.phone, formData.firstName, formData.lastName, formData.userName).subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess(data.message);
            } else {
                this.popupComponent.showPopupError(data.message);
            }
        });
    }

    goBack() {
        this.accountSettingsInfoService.hide();
    }

    menu() {
        // this.accountSettingsInfoService.hide();
        // this.accountSettingsService.hide();
        //this.menuService.hide();
        this.menuService.show();
    }

    changeVisibility(value) {
        this.visible = value;

        if (this.visible) {
            this.accountSettingsInfoService.loadData().subscribe(data => {
                if (data.status) {
                    this.accountForm.patchValue({
                        phone: data.result.phone,
                        firstName: data.result.first_name,
                        lastName: data.result.last_name,
                        userName: data.result.user_name
                    })
                }
            });
        }
    }

}
