<div class="distributions content" *ngIf="visible" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">payouts to users</app-header>
    <div class="distributions__inner">
        <div class="distributions__payouts">
            <ul class="payouts">

                <li *ngFor="let payout of payouts" class="payouts__item">
                    <div class="payouts__info">
                        <div class="payouts__date">{{payout.date}}</div>
                        <div class="payouts__user">{{payout.user}}</div>
                    </div>
                    <div class="payouts__amount">{{payout.amount}}</div>
                </li>

            </ul>
        </div>
    </div>
</div>

