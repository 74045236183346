/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/header/header.component.ngfactory";
import * as i3 from "../../shared/header/header.component";
import * as i4 from "../../service/menu.service";
import * as i5 from "../global-library/global-library-edit/global-library-edit.component.ngfactory";
import * as i6 from "../global-library/global-library-edit/global-library-edit.component";
import * as i7 from "../../shared/popup/popup.component";
import * as i8 from "../../service/content/types.service";
import * as i9 from "../../service/phone.service";
import * as i10 from "@angular/router";
import * as i11 from "../../shared/publication/image-publication/image-publication.component.ngfactory";
import * as i12 from "../../shared/publication/image-publication/image-publication.component";
import * as i13 from "../../shared/publication/sound-publication/sound-publication.component.ngfactory";
import * as i14 from "../../shared/publication/sound-publication/sound-publication.component";
import * as i15 from "../../shared/publication/video-publication/video-publication.component.ngfactory";
import * as i16 from "../../shared/publication/video-publication/video-publication.component";
import * as i17 from "@angular/common";
import * as i18 from "./new-content.component";
import * as i19 from "../../service/content/new-content.service";
import * as i20 from "../../service/global-library/globalLibrary.service";
import * as i21 from "../../service/session/back.service";
var styles_NewContentComponent = [i0.styles];
var RenderType_NewContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewContentComponent, data: {} });
export { RenderType_NewContentComponent as RenderType_NewContentComponent };
function View_NewContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "page content"]], [[2, "is-visible", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "nextAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("nextAction" === en)) {
        var pd_1 = (_co.showPreview() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { rightAccent: [0, "rightAccent"], nextActionCheck: [1, "nextActionCheck"], backActionCheck: [2, "backActionCheck"] }, { nextAction: "nextAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["New Content"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-global-library-edit", [], null, null, null, i5.View_GlobalLibraryEditComponent_0, i5.RenderType_GlobalLibraryEditComponent)), i1.ɵdid(6, 638976, null, 0, i6.GlobalLibraryEditComponent, [i7.PopupComponent, i8.TypesService, i9.PhoneService, i10.ActivatedRoute, i1.NgZone], { editable: [0, "editable"], publication: [1, "publication"], validation: [2, "validation"], categoriesList: [3, "categoriesList"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = _co.nextActionCheck(); var currVal_3 = _co.backActionCheck(); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = true; var currVal_5 = _co.item; var currVal_6 = _co.validation; var currVal_7 = _co.categories; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 0, 0, currVal_0); }); }
function View_NewContentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "popup"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-image-publication", [], null, null, null, i11.View_ImagePublicationComponent_0, i11.RenderType_ImagePublicationComponent)), i1.ɵdid(3, 114688, null, 0, i12.ImagePublicationComponent, [], { publication: [0, "publication"], noMenu: [1, "noMenu"], author: [2, "author"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item; var currVal_2 = true; var currVal_3 = _co.author; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupPreviewPhoto; _ck(_v, 0, 0, currVal_0); }); }
function View_NewContentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "popup"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-sound-publication", [], null, null, null, i13.View_SoundPublicationComponent_0, i13.RenderType_SoundPublicationComponent)), i1.ɵdid(3, 114688, null, 0, i14.SoundPublicationComponent, [], { noMenu: [0, "noMenu"], author: [1, "author"], publication: [2, "publication"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = _co.author; var currVal_3 = _co.item; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupPreviewAudio; _ck(_v, 0, 0, currVal_0); }); }
function View_NewContentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "popup"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-video-publication", [], null, null, null, i15.View_VideoPublicationComponent_0, i15.RenderType_VideoPublicationComponent)), i1.ɵdid(3, 114688, null, 0, i16.VideoPublicationComponent, [i7.PopupComponent], { publication: [0, "publication"], noMenu: [1, "noMenu"], author: [2, "author"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item; var currVal_2 = true; var currVal_3 = _co.author; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupPreviewVideo; _ck(_v, 0, 0, currVal_0); }); }
function View_NewContentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-header", [], null, [[null, "backAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.closePreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { backActionCheck: [0, "backActionCheck"] }, { backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["New Content"]))], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NewContentComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn-publish"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Publish"]))], null, null); }
export function View_NewContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewContentComponent_1)), i1.ɵdid(1, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewContentComponent_2)), i1.ɵdid(3, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewContentComponent_3)), i1.ɵdid(5, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewContentComponent_4)), i1.ɵdid(7, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewContentComponent_5)), i1.ɵdid(9, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewContentComponent_6)), i1.ɵdid(11, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.visible; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.visible; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.visible; _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.popup && _co.visible); _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.popup && _co.visible); _ck(_v, 11, 0, currVal_5); }, null); }
export function View_NewContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-content", [], null, null, null, View_NewContentComponent_0, RenderType_NewContentComponent)), i1.ɵdid(1, 638976, null, 0, i18.NewContentComponent, [i19.NewContentService, i20.GlobalLibraryService, i10.ActivatedRoute, i9.PhoneService, i21.BackService, i1.NgZone, i7.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewContentComponentNgFactory = i1.ɵccf("app-new-content", i18.NewContentComponent, View_NewContentComponent_Host_0, {}, {}, []);
export { NewContentComponentNgFactory as NewContentComponentNgFactory };
