import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TokensAdvertisementService} from "../../../service/tokens/tokensAdvertisement.service";
import {BalanceCard} from "../../../models/balanceCard";
import {ActivityItem} from "../../../models/activityItem";
import {CollectionItem} from "../../../models/collectionItem";
import {TokensService} from "../../../service/tokens/tokens.service";
import {MenuService} from "../../../service/menu.service";
import {ScrollUpdatingService} from "../../../service/scrollUpdating.service";
import {CollectionService} from "../../../service/collection.service";

@Component({
    selector: 'app-tokens-advertisement',
    templateUrl: './tokens-advertisement.component.html',
    styleUrls: ['./tokens-advertisement.component.scss'],
    providers: [ScrollUpdatingService]
})
export class TokensAdvertisementComponent implements OnInit {

    visible: boolean;

    popupCollection = false;
    popupBudget = false;
    popupCreate = false;

    collectionSettings = {
        page: 'tokensAdvertisement',
        checkbox: true,
        addBtn: false
    }

    redeem = false;

    data: BalanceCard;
    activity: [ActivityItem];
    collectionItems: [CollectionItem];

    checkedPublications;
    budget;
    duration;

    createSettings;
    limit = 0;

    constructor(private tokensAdvertisementService: TokensAdvertisementService,
                private menuService: MenuService,
                private collectionService: CollectionService,
                private scrollUpdating: ScrollUpdatingService,
                private tokensService: TokensService,) {
        this.tokensAdvertisementService.visible.subscribe((value: any) => this.componentVisibility(value));
        this.collectionService.visible.subscribe((value: any) => this.collectionVisibility(value));
    }

    ngOnInit() {

    }

    showRedeem() {
        this.redeem = !this.redeem;
    }

    showPopup(name) {
        if (name === 'collection') {
            this.collectionService.show();
            this.popupCollection = true;
        } else if (name === 'budget') {
            this.popupBudget = true;
        } else if (name === 'create') {
            this.popupCreate = true;
        }
        document.body.classList.add('is-hidden');

    }

    collectionVisibility(value) {
        if (!value) {
            this.popupCollection = false;
        }
    }

    hidePopup(name) {
        if (name === 'collection') {
            this.popupCollection = false;
        } else if (name === 'budget') {
            this.popupBudget = false;
        } else if (name === 'create') {
            this.popupCreate = false;
        }
        document.body.classList.remove('is-hidden');

    }

    componentVisibility(state) {
        this.visible = state;
        if (this.visible) {
            this.setData();
        }
    }

    setData() {
        this.tokensAdvertisementService.getData(1).subscribe(data => {
            if (data.status) {
                this.activity = data.result;
                this.limit = data.limit;

                this.scrollUpdating.setInitialData({
                    items: this.activity,
                    limit: this.limit,
                    cssClass: '.activity__item',
                    service: this.tokensAdvertisementService,
                    serviceName: 'tokensAdvertisement',
                    additionalParams: ''
                });
            }
            if (data.info) {
                this.data = data.info;
                this.data.currency = 'tokens';
                this.data.action = 'Last ad: ';
            }
            if (!data.status && !data.result && ! data.info) {
                this.visible = false;
            }

        })
    }

    goBack() {
        this.popupCollection = false;
        this.tokensAdvertisementService.hide();
    }

    menu() {
        // this.tokensAdvertisementService.hide();
        // this.tokensService.hide();
        this.menuService.show();
    }

    closeCollection(state) {
        this.popupCollection = false;
    }

    showBudget(state) {
        this.popupBudget = true;
        this.checkedPublications = state;
    }

    closeBudget(state) {
        this.popupBudget = false;
    }

    showCreate(state) {
        this.popupCreate = true;
        this.budget = state.budget;
        this.duration = state.duration;


        // TEMPORARY!!
        let checkedCategory = this.checkedPublications.category ? this.checkedPublications.category : [1, 2];

        let checkedId = this.checkedPublications.id;;
        // this.checkedPublications.forEach(el => {
        //     checkedIdes.push(el.id);
        //     if (checkedCategories.indexOf(el.category) === -1) {
        //         checkedCategories.push(el.category);
        //     }
        // })
        console.log(checkedCategory)

        this.createSettings = {
            categories: checkedCategory,
            checkedPublications: checkedId,
            duration: this.duration,
            budget: this.budget
        }
    }

    closeCreate(state) {
        this.popupCreate = false;
    }

    doneCreate(state) {
        this.popupCollection = false;
        this.collectionService.hide();
        this.popupCreate = false;
        this.popupBudget = false;
        this.setData();
    }

    emitScroll($event) {
        this.scrollUpdating.scroll($event);
    }

}
