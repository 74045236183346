import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuService} from "../../service/menu.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input() rightAccent: boolean;

    @Input() backLink: string;
    @Input() nextLink: string;
    @Input() menuLink: string;


    @Input() doneLink: string;

    @Input() value;

    @Input() menuActionCheck: boolean;
    @Output() menuAction = new EventEmitter();

    @Input() nextActionCheck: boolean;
    @Output() nextAction = new EventEmitter();

    @Input() backActionCheck: boolean;
    @Output() backAction = new EventEmitter();

    @Input() doneActionCheck: boolean;
    @Output() doneAction = new EventEmitter();


    constructor(private menuService:MenuService) {
    }

    ngOnInit() {

    }


    handleMenuClick() {
        this.menuService.show();
        this.menuAction.emit(true);
    }

    handleNextClick() {
        this.nextAction.emit(true);
    }

    handleBackClick() {
        this.backAction.emit(true);
    }

    handleDoneClick() {
        this.doneAction.emit(true);
    }
}
