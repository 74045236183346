<div class="tokens content" [class.is-visible]="visibility">
    <app-header
            [backActionCheck]="true"
            (backAction)="goBack()"
            [menuActionCheck]="true"
            (menuAction)="menu()">Tokens</app-header>
    <div class="tokens__inner">
        <div class="tokens__nav">
            <app-nav (clickAction)="showChild($event)" [navItems]="navItems"></app-nav>
        </div>
    </div>
</div>

<app-tokens-tokens></app-tokens-tokens>
<app-tokens-advertisement></app-tokens-advertisement>
<app-tokens-distribution></app-tokens-distribution>
<app-tokens-sweepstakes></app-tokens-sweepstakes>

