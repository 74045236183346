import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MenuService} from "../../../../service/menu.service";
import {DistributionsService} from "../../../../service/admin/distributions/distributions.service";
import {DistributionsSnekService} from "../../../../service/admin/distributions/distributionsSnek.service";

@Component({
    selector: 'app-distributions-snek',
    templateUrl: './distributions-snek.component.html',
    styleUrls: ['./distributions-snek.component.scss']
})
export class DistributionsSnekComponent implements OnInit {
    visible: boolean;

    payouts;

    constructor(private menuService: MenuService,
                private distributionsService: DistributionsService,
                private distributionsSnekService: DistributionsSnekService) {
        this.distributionsSnekService.visible.subscribe((value: any) => this.toggleVisibility(value));
    }

    ngOnInit() {

    }

    goBack() {
        this.distributionsSnekService.hide();
    }

    menu() {
        this.distributionsSnekService.hide();
        this.distributionsService.hide();
        //this.menuService.hide();
    }

    toggleVisibility(state) {
        this.visible = state;

        if (this.visible) {
            this.distributionsSnekService.getData().subscribe(data => {
                if (data.status) {
                    this.payouts = data.result;
                }
            });
        }
    }

}
