import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AD_SETTING_COST_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AdSettingsCostService {
    constructor(ngZone, http) {
        this.ngZone = ngZone;
        this.http = http;
        this.config = AD_SETTING_COST_CONFIG;
        this.visible = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    getData() {
        return this.http.post(this.config.data, {});
    }
    saveData(minPerDay, maxPerDay, min, max) {
        return this.http.post(this.config.save, { minPerDay, maxPerDay, min, max });
    }
}
AdSettingsCostService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdSettingsCostService_Factory() { return new AdSettingsCostService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.HttpClient)); }, token: AdSettingsCostService, providedIn: "root" });
