import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SearchService {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this.visible = new BehaviorSubject(false);
        this.data = new BehaviorSubject(null);
        this.categories = new BehaviorSubject([]);
        this.cost = new BehaviorSubject('');
        this.type = new BehaviorSubject('');
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    setNewData(newData) {
        this.data.next(newData);
    }
    setSearchCategories(newData) {
        this.categories.next(newData);
    }
    setSearchCost(newData) {
        this.cost.next(newData);
    }
    setSearchType(newData) {
        this.type.next(newData);
    }
    resetSearchCategories() {
        this.categories.next([]);
    }
    resetSearchCost() {
        this.cost.next('');
    }
    resetSearchType() {
        this.type.next('');
    }
}
SearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.ɵɵinject(i0.NgZone)); }, token: SearchService, providedIn: "root" });
