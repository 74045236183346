<div class="advertising">
    <audio *ngIf="getAdvertisingType(advertising) === 'audio'" #audioElement class="js-sound-item" src="{{advertising.url}}">
        Your browser does not support the
        <code>audio</code> element.
    </audio>

<!--    <audio *ngIf="getAdvertisingType(advertising) === 'video'" autoplay #audioElement class="js-sound-item" src="../../../../assets/audio/sound.mp3">-->
<!--        Your browser does not support the-->
<!--        <code>audio</code> element.-->
<!--    </audio>-->

    <img *ngIf="getAdvertisingType(advertising) === 'image'" class="advertising__img" src="{{advertising.url}}" alt="">

    <video *ngIf="getAdvertisingType(advertising) === 'video'" #videoElement class="advertising__img" #videoElement controls="" muted="muted" autoplay controlslist="nodownload" style="width: 100%;">
        <source src="{{advertising.url}}" type="video/mp4">
        Your browser does not support the video tag.
    </video>

    <div class="advertising__head">
        <a href="#" class="advertising__link">
            apps.apple.com
            <svg width="8" height="8">
                <use xlink:href="../../../../assets/images/sprite.svg#link"></use>
            </svg>
        </a>
    </div>

    <div class="advertising__info">
        <div class="advertising__info-inner">
            Advertisement ends in {{timeLeft}} sec.
        </div>
    </div>

    <div class="advertising__progress">
        <div class="progress">
            <div [ngStyle]="{width: progressWidth + '%'}" class="progress__inner"></div>
        </div>
    </div>
</div>


