import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ScrollUpdatingService {
    //public menuChanged = new BehaviorSubject(null);

    pageActive;

    prevPagination = 0;
    previewPagination = 1;
    //pagesLoaded = 1;
    lastScrollTop = 0;
    dataRichEnd = false;

    items = [];
    limit = 0;
    itemsCSSClass = '';
    dataService;
    dataServiceName;

    loading = false;

    dataParamsType;
    additionalParams;
    // only page = pag, page + categories = pagCat, page + categories + search = pagCatSearch,
    filteredCategories;
    filteredType;
    filteredCost;
    filteredSearch;
    countLastAddedItems;


    constructor() {

    }

    setInitialData({items, limit, cssClass, service, serviceName, additionalParams}) {
        this.items = items;
        this.limit = limit || 40;
        this.itemsCSSClass = cssClass;
        this.dataService = service;
        this.dataServiceName = serviceName;
        this.additionalParams = additionalParams;

        this.resetChangingProperties();
    }

    resetChangingProperties() {
        this.pageActive = 1;
        this.prevPagination = 0;
        this.previewPagination = 1;
        this.dataRichEnd = false;
        this.lastScrollTop = 0;
    }

    scroll(e) {

        if (!this.loading) {
            //console.log(this.limit)
            const elems = document.querySelectorAll(this.itemsCSSClass);

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            let rectStart;
            let documentRect = document.documentElement.getBoundingClientRect();

            if (this.prevPagination > 0) {
                rectStart = elems[0] !== undefined ? elems[Math.round(this.limit*.8)].getBoundingClientRect() : undefined;
            } else {
                rectStart = undefined;
            }

            //elems[elems.length - 1].classList.add('control')


            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > this.lastScrollTop){
                //console.log(rect.bottom, documentRect.bottom, rect.height * Math.round(this.limit*.2), this.limit*.2)
                if (rect !== undefined && rect.bottom < documentRect.bottom + rect.height * Math.round(this.limit*.2) && !this.dataRichEnd) {
                    //console.log('scroll bottom true')
                    this.loading = true;
                    const loadingPage = this.pageActive + 1;
                    this.loadNewData(loadingPage);

                }

            } else {
                //console.log('scroll up')
                if (e.target.scrollTop <= rect.height * 5  && this.prevPagination > 0) {
                    //console.log('scroll up true')
                    this.loading = true;
                    const loadingPage = this.countLastAddedItems < this.limit ? this.prevPagination - 1 : this.prevPagination;
                    this.loadNewData(loadingPage, 'start');
                }
            }
            this.lastScrollTop = st <= 0 ? 0 : st;

        }
    }

    loadNewData(page, step = 'end'):any {

        let dataParams = () => {
            if (this.dataParamsType === 'pag') {
                return page;
            } else if (this.dataParamsType === 'catPagSearch') {
                return `${this.filteredCategories}, ${page}, ${this.filteredSearch}`;
            } else if (this.dataParamsType === 'typeCatCostPag') {
                return `${this.filteredType}, ${this.filteredCategories}, ${this.filteredCost}, ${page}`;
            }
        }

        if (this.dataServiceName === 'tokensTokens') {
            this.dataService.getData(page).subscribe(data => {
                this.dataAddingLogic(page, step, data);
            })
        }

        if (this.dataServiceName === 'tokensAdvertisement') {
            this.dataService.getData(page).subscribe(data => {
                this.dataAddingLogic(page, step, data);
                console.log(data)
            })
        }


    }

    dataAddingLogic(page, step, data) {
        if (data.status) {
            this.dataRichEnd = data.result.length < this.limit ? true : false;

            if (this.dataRichEnd && step === 'end') {
                this.previewPagination = page;
                this.prevPagination += 1;
                this.pageActive = this.previewPagination;

                for (let i = 0; i < data.result.length; i++) {
                    this.items.push(data.result[i]);
                }

                this.countLastAddedItems = data.result.length;
                this.loading = false;
                //console.log(this.items, 'prev' + this.prevPagination, 'preview' + this.previewPagination, 'active' + this.pageActive);
                return;
            }

            // ADD PAGE TO BEGINING
            if (step === 'end') {
                // REMOVE PAGE FROM BEGINING
                if (this.items.length > (this.limit)) {
                    this.items.splice(0, data.result.length);
                    this.prevPagination += 1;
                }
                for (let i = 0; i < data.result.length; i++) {
                    this.items.push(data.result[i]);
                }

                this.previewPagination += 1;
            }

            if ((step === 'start') && this.prevPagination) {

                let additionalRemovedItems = 0;

                if (this.countLastAddedItems < this.limit) {
                    additionalRemovedItems = this.countLastAddedItems;
                    this.prevPagination -= 2;
                    this.previewPagination -= 2;
                } else {
                    this.prevPagination -= 1;
                    this.previewPagination -= 1;
                }

                // REMOVE PAGE FROM END
                this.items.splice(this.items.length - data.result.length - additionalRemovedItems, data.result.length + additionalRemovedItems);


                // ADD PAGE TO BEGINING
                for (let i = data.result.length-1; i >= 0; i--) {
                    this.items.unshift(data.result[i]);
                }

            }

            if (step === 'new') {
                this.items = [];
                for (let i = 0; i < data.result.length; i++) {
                    this.items.push(data.result[i]);
                }
                this.previewPagination = 1;
                this.prevPagination = 0;

            }

            this.pageActive = this.previewPagination;
            this.loading = false;
            //console.log(this.items, 'prev' + this.prevPagination, 'preview' + this.previewPagination, 'active' + this.pageActive);
        }
    }
}
