import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

    @Input() filter: boolean;
    @Input() onlyFree: boolean;
    @ViewChild("categories", {static: false}) categories: ElementRef;

    radioContentValue = '';
    radioCostValue = '';

    @Output() checkedSenseValue = new EventEmitter();
    @Output() checkedTypeValue = new EventEmitter();
    // @Output() checkedContentValue = new EventEmitter();

    checkboxIsHiden = false;
    senses = [];

    radio1 = {
        title: 'Content Type',
        name: 'content',
        variants: [
            {
                label: 'Image',
                id: 'image'
            },
            {
                label: 'Video',
                id: 'video'
            },
            {
                label: 'Audio',
                id: 'audio'
            }
        ]
    };

    radio2 = {
        title: 'Cost',
        name: 'cost',
        variants: [
            {
                label: 'Free',
                id: 'free'
            },
            {
                label: 'Paid',
                id: 'paid'
            }
        ]
    };



    constructor() {
    }

    ngOnInit() {

    }

    toggleVariants() {
        this.checkboxIsHiden = !this.checkboxIsHiden;
    }

    checkSenses(input) {
        const checkbox = input;
        const id =  checkbox.getAttribute('data-category');
        const elementIndex = this.senses.indexOf(id);

        if (elementIndex > -1) {
            checkbox.classList.remove('is-checked');
            this.senses.splice(elementIndex, 1);
        } else {
            checkbox.classList.add('is-checked');
            this.senses.push(id)
        }

        this.checkedSenseValue.emit(this.senses);

    }

    openFilter() {
        this.filter = true;
    }

    closeFilter() {
        this.filter = false;
    }

    radioChange(select) {
        this.checkedTypeValue.emit(select);
    }

    checkInput(e) {
        const input = e.currentTarget.parentNode.querySelector('input');
        const oldCheckValue = input.getAttribute('checked');
        if (oldCheckValue) {
            input.removeAttribute('checked');
        } else {
            input.setAttribute('checked', true);
        }

        this.checkSenses(input);
    }

}
