<div (scroll)="scrollHandler($event)" class="page content global-library" [class.is-visible]="visible">

    <div class="page__inner">

        <div class="page__header">
            <div *ngIf="contentType" class="page__title">{{contentType | uppercase}}</div>

            <div class="page__categories">
                <ul class="categories">
                    <li  *ngFor="let category of senseType"  class="categories__category">
                        <svg class="categories__svg_icon">
                            <use [attr.xlink:href]="'#category_'+category"></use>
                        </svg>
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="+typeDefault !== +postTypes.audio" class="page__collection"  >

            <app-collection-thumbnails (clickAction)="showPreviewPopup($event)" [items]="items" [settings]="collectionSettings"></app-collection-thumbnails>

        </div>

        <div *ngIf="+typeDefault === +postTypes.audio" class="page__audio">
            <ul class="audio">
                <ng-container *ngFor="let item of items" >
                    <li class="audio__item" >
                        <app-sound-publication [clickable]="true" (clickAction)="showPreviewPopup(item)" [noDescr]="true" [publication]="item"></app-sound-publication>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

    <div *ngIf="!!previewItem && popup" [class.is-active]="popup">
        <app-global-library-publication (closePreview)="closePreviewPopup()" [publication]="previewItem"></app-global-library-publication>
    </div>

    <button (click)="hideLibrary()" *ngIf="!popup" class="btn-cancel">Cancel</button>
    <app-header *ngIf="!popup">Global Library</app-header>
</div>

<div *ngIf="!popup && visible">
    <app-filter [onlyFree]="true" (checkedSenseValue)="getCategoriesValue($event)" (checkedTypeValue)="getTypeValue($event)" [filter]="filter"></app-filter>
</div>

