<div class="distributions content" *ngIf="visible" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">Distributions</app-header>
    <div class="distributions__inner">
        <div class="distributions__nav">
            <app-nav (clickAction)="showChild($event)" [navItems]="navItems"></app-nav>
        </div>
    </div>
</div>

<app-distributions-payouts></app-distributions-payouts>
<app-distributions-ratio></app-distributions-ratio>
<app-distributions-snek></app-distributions-snek>

