import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {SignUpService} from "../../service/signup.service";
import {first, map} from "rxjs/operators";
import {LoginService} from "../../service/login.service";

import {PopupComponent} from "../../shared/popup/popup.component";


@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignUpComponent implements OnInit {
    policy = false;

    registerForm: FormGroup;
    pinForm: FormGroup;

    phoneFormActive = true;
    phoneLastFour = '0005';
    numOfDigits = 6;
    userNotAvailable = false;

    checkUserAvailable;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    constructor(
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private signUpService: SignUpService,
        private loginService: LoginService,
        private router: Router,
        private popupComponent: PopupComponent) {

        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
    }
    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            phone: [null, Validators.required],
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            userName: [null, Validators.required],
            terms: [false, Validators.pattern('true')]
        });

        for (let i = 0; i< this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null))
        }

    }

    ngAfterViewInit() {
        // POPUPS USINGS
        //this.popupComponent.showPopupError();

    }

    resendSms(){
        let phone = this.registerForm.controls.phone.value;

        this.authenticationService.sendPin(phone).pipe().subscribe(data => {
            if(data.status){

            }else{
                this.popupComponent.showPopupError(data.message);
            }
        });
    }

    onPhoneSubmit(){
        if (this.registerForm.invalid) {
            return;
        }

        let phone = this.registerForm.controls.phone.value;
        let userName = this.registerForm.controls.userName.value;

        this.authenticationService.checkUser(phone,userName).subscribe((data:any) => {
            if(data.status){
                this.phoneLastFour = phone.substr(-4);

                this.phoneFormActive = false;

                let $this = this;
                setTimeout(function(){
                    $this.inputs.toArray()[0].nativeElement.focus();
                },0);
            }else{
                this.popupComponent.showPopupError(data.message);
            }
        });

    }

    onSubmit() {
        if (this.pinForm.invalid) {
            return;
        }

        let phone = this.registerForm.controls.phone.value;
        let userName = this.registerForm.controls.userName.value;
        let firstName = this.registerForm.controls.firstName.value;
        let lastName = this.registerForm.controls.lastName.value;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        this.authenticationService.register(phone,firstName,lastName,userName,pin).subscribe(data => {
            if(data.status){
                //this.router.navigate(['/']);

                this.popupComponent.showPopupSuccess(data.message);
                // success message - data.message
            }else{
                this.popupComponent.showPopupError()
                // error message
            }
        });
    }

    isPhoneClassActive(){
        if(this.phoneFormActive){
            return true;
        }

        return false;
    }

    isPinClassActive(){
        if(!this.phoneFormActive){
            return true;
        }

        return false;
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    showPolicy() {
        this.policy = true;
    }

    policyState(state) {
        this.policy = state;
    }

    hideSignUp() {
        this.signUpService.hide();
        document.body.classList.remove('is-hidden');
    }

    showLogin() {
        this.signUpService.hide();
        this.loginService.show();
    }

}
