/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nav.component";
var styles_NavComponent = [i0.styles];
var RenderType_NavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavComponent, data: {} });
export { RenderType_NavComponent as RenderType_NavComponent };
function View_NavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["class", "nav__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandler(_v.context.$implicit.url) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "nav__icon-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "nav__icon"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "nav__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["class", "nav__arrow"], ["height", "10"], ["width", "6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#arrow-right"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "../../../assets/images/nav-icons/", _v.context.$implicit.icon, ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_1); }); }
export function View_NavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navItems; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav", [], null, null, null, View_NavComponent_0, RenderType_NavComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavComponentNgFactory = i1.ɵccf("app-nav", i3.NavComponent, View_NavComponent_Host_0, { navItems: "navItems" }, { clickAction: "clickAction" }, []);
export { NavComponentNgFactory as NavComponentNgFactory };
