import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {POST_CATEGORIES_TITLES} from "../../../../app/config/post_categories";
import {TokensAdvertisementService} from "../../../service/tokens/tokensAdvertisement.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {TokensBudgetComponent} from "../tokens-budget/tokens-budget.component";

@Component({
    selector: 'app-tokens-create-advertisement',
    templateUrl: './tokens-create-advertisement.component.html',
    styleUrls: ['./tokens-create-advertisement.component.scss']
})
export class TokensCreateAdvertisementComponent implements OnInit {

    @Input() settings;
    @Output() closeCreate: EventEmitter<any> = new EventEmitter();
    @Output() done: EventEmitter<any> = new EventEmitter();

    postCategoriesTitles = POST_CATEGORIES_TITLES;

    constructor(private tokensAdvertisementService: TokensAdvertisementService,
                private popupComponent: PopupComponent) {
    }

    ngOnInit() {

    }

    ngOnChanges() {
        if (this.settings) {
            //console.log(this.settings.categories);
            //this.settings.categories = this.postCategoriesTitles[this.settings.categories];
            this.settings.categories = this.settings.categories.map(el => {
                return this.postCategoriesTitles[el];
            });
            //console.log(this.settings.categories);
        }
    }

    goBack() {
        this.closeCreate.emit(true);
    }

    submitSettings() {
        const checkedPublications = this.settings.checkedPublications;
        const budget = this.settings.budget;
        const duration = this.settings.duration;

        this.tokensAdvertisementService.saveSettings(checkedPublications, budget, duration).subscribe(data => {
           // console.log(checkedPublications, budget, duration)
            if (data.status) {
                this.popupComponent.showPopupSuccess(data.message);
                setTimeout(() => {this.done.emit(true);}, 500);

            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }
}
