/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./distributions-snek.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/header/header.component.ngfactory";
import * as i3 from "../../../../shared/header/header.component";
import * as i4 from "../../../../service/menu.service";
import * as i5 from "@angular/common";
import * as i6 from "./distributions-snek.component";
import * as i7 from "../../../../service/admin/distributions/distributions.service";
import * as i8 from "../../../../service/admin/distributions/distributionsSnek.service";
var styles_DistributionsSnekComponent = [i0.styles];
var RenderType_DistributionsSnekComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DistributionsSnekComponent, data: {} });
export { RenderType_DistributionsSnekComponent as RenderType_DistributionsSnekComponent };
function View_DistributionsSnekComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "payouts__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "payouts__date"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "payouts__amount"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.date; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.amount; _ck(_v, 4, 0, currVal_1); }); }
function View_DistributionsSnekComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "distributions content"]], [[2, "is-visible", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "menuAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("menuAction" === en)) {
        var pd_1 = (_co.menu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.MenuService], { menuActionCheck: [0, "menuActionCheck"], backActionCheck: [1, "backActionCheck"] }, { menuAction: "menuAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["Snek A Pek Payouts"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "distributions__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "distributions__payouts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [["class", "payouts"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DistributionsSnekComponent_2)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.payouts; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 0, 0, currVal_0); }); }
export function View_DistributionsSnekComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DistributionsSnekComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DistributionsSnekComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-distributions-snek", [], null, null, null, View_DistributionsSnekComponent_0, RenderType_DistributionsSnekComponent)), i1.ɵdid(1, 114688, null, 0, i6.DistributionsSnekComponent, [i4.MenuService, i7.DistributionsService, i8.DistributionsSnekService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DistributionsSnekComponentNgFactory = i1.ɵccf("app-distributions-snek", i6.DistributionsSnekComponent, View_DistributionsSnekComponent_Host_0, {}, {}, []);
export { DistributionsSnekComponentNgFactory as DistributionsSnekComponentNgFactory };
