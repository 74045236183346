/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../search/search.component.ngfactory";
import * as i3 from "../../search/search.component";
import * as i4 from "../../../service/search.service";
import * as i5 from "../../../service/article.service";
import * as i6 from "../../../service/global-library/homeCollection.service";
import * as i7 from "../../../shared/publication/sound-publication/sound-publication.component.ngfactory";
import * as i8 from "../../../shared/publication/sound-publication/sound-publication.component";
import * as i9 from "../../../shared/publication/video-publication/video-publication.component.ngfactory";
import * as i10 from "../../../shared/publication/video-publication/video-publication.component";
import * as i11 from "../../../shared/popup/popup.component";
import * as i12 from "../../../shared/publication/image-publication/image-publication.component.ngfactory";
import * as i13 from "../../../shared/publication/image-publication/image-publication.component";
import * as i14 from "@angular/common";
import * as i15 from "../../../shared/header-search/header-search.component.ngfactory";
import * as i16 from "../../../shared/header-search/header-search.component";
import * as i17 from "../../../service/menu.service";
import * as i18 from "@angular/forms";
import * as i19 from "../../../service/menu/menuBottomAddContent.service";
import * as i20 from "../../../modules/auth/_services/authentication.service";
import * as i21 from "./article-details.component";
var styles_ArticleDetailsComponent = [i0.styles];
var RenderType_ArticleDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArticleDetailsComponent, data: {} });
export { RenderType_ArticleDetailsComponent as RenderType_ArticleDetailsComponent };
function View_ArticleDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "details__search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-search", [], null, null, null, i2.View_SearchComponent_0, i2.RenderType_SearchComponent)), i1.ɵdid(2, 114688, null, 0, i3.SearchComponent, [i4.SearchService, i5.ArticleService, i6.HomeCollectionService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ArticleDetailsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "details__sound"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-sound-publication", [], null, [[null, "menuShowed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("menuShowed" === en)) {
        var pd_0 = (_co.menuShowed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_SoundPublicationComponent_0, i7.RenderType_SoundPublicationComponent)), i1.ɵdid(2, 114688, null, 0, i8.SoundPublicationComponent, [], { publication: [0, "publication"], menu: [1, "menu"] }, { menuShowed: "menuShowed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; var currVal_1 = _co.menu; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ArticleDetailsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "details__video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-video-publication", [], null, null, null, i9.View_VideoPublicationComponent_0, i9.RenderType_VideoPublicationComponent)), i1.ɵdid(2, 114688, null, 0, i10.VideoPublicationComponent, [i11.PopupComponent], { publication: [0, "publication"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ArticleDetailsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "details__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-image-publication", [], null, [[null, "likeEmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("likeEmitted" === en)) {
        var pd_0 = (_co.toggleLike($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_ImagePublicationComponent_0, i12.RenderType_ImagePublicationComponent)), i1.ɵdid(2, 114688, null, 0, i13.ImagePublicationComponent, [], { publication: [0, "publication"] }, { likeEmitted: "likeEmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ArticleDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleDetailsComponent_4)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleDetailsComponent_5)), i1.ɵdid(4, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleDetailsComponent_6)), i1.ɵdid(6, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.contentType === "sound") && !_co.search); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.contentType === "video") && !_co.search); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.contentType === "image") && !_co.search); _ck(_v, 6, 0, currVal_2); }, null); }
function View_ArticleDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "details"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "details__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "details__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-header-search", [], null, [[null, "searchOpen"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchOpen" === en)) {
        var pd_0 = (_co.searchOpen($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_HeaderSearchComponent_0, i15.RenderType_HeaderSearchComponent)), i1.ɵdid(4, 114688, null, 0, i16.HeaderSearchComponent, [i17.MenuService, i18.FormBuilder, i4.SearchService, i19.MenuBottomAddContentService, i6.HomeCollectionService, i20.AuthenticationService], { onClick: [0, "onClick"], canAdd: [1, "canAdd"], hidenDetails: [2, "hidenDetails"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleDetailsComponent_2)), i1.ɵdid(6, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleDetailsComponent_3)), i1.ɵdid(8, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.hidenDetails; var currVal_2 = false; var currVal_3 = _co.hidenDetails; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.search; _ck(_v, 6, 0, currVal_4); var currVal_5 = !_co.search; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 0, 0, currVal_0); }); }
export function View_ArticleDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleDetailsComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ArticleDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-article-details", [], null, null, null, View_ArticleDetailsComponent_0, RenderType_ArticleDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i21.ArticleDetailsComponent, [i5.ArticleService, i4.SearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArticleDetailsComponentNgFactory = i1.ɵccf("app-article-details", i21.ArticleDetailsComponent, View_ArticleDetailsComponent_Host_0, { data: "data", activePublication: "activePublication" }, { hidenDetails: "hidenDetails", likeEmitted: "likeEmitted" }, []);
export { ArticleDetailsComponentNgFactory as ArticleDetailsComponentNgFactory };
