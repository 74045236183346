import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TokensDistributionService} from "../../../service/tokens/tokensDistribution.service";
import {BalanceCard} from "../../../models/balanceCard";
import {ActivityItem} from "../../../models/activityItem";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {TokensService} from "../../../service/tokens/tokens.service";
import {MenuService} from "../../../service/menu.service";

@Component({
    selector: 'app-tokens-distribution',
    templateUrl: './tokens-distribution.component.html',
    styleUrls: ['./tokens-distribution.component.scss']
})
export class TokensDistributionComponent implements OnInit {

    visible: boolean;

    payout = false;
    bankInfo = false;
    paypalInfo = false;

    cardNumber = 6505;

    avaliableAmount = 0;
    transferAmount = 0;

    bankInfoForm: FormGroup;
    payoutForm: FormGroup;
    paypalInfoForm: FormGroup;

    data: BalanceCard;
    activity: [ActivityItem];

    constructor(private formBuilder: FormBuilder,
                private tokensDistributionService: TokensDistributionService,
                private tokensService: TokensService,
                private menuService: MenuService,
                private popupComponent: PopupComponent) {
        this.tokensDistributionService.visible.subscribe((value: any) => this.componentVisibility(value))
    }

    ngOnInit() {
        this.paypalInfoForm = this.formBuilder.group({
            email: [null, Validators.required]
        });
        this.bankInfoForm = this.formBuilder.group({
            account: [null, Validators.required],
            routing: [null, Validators.required],
            confirmAccount: [null, Validators.required]
        });
        this.payoutForm = this.formBuilder.group({
            transfer: [null, Validators.required]
        });
    }

    onPaypalInfoFormSubmit() {
        const formData = this.paypalInfoForm.value;
        if (formData.email == '') {
            this.popupComponent.showPopupError({text: 'Pass all the required fields.'});
            return;
        }
        this.tokensDistributionService.savePaypalInfo(formData.email).subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess(data.message);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }

    onBankInfoFormSubmit() {
        const formData = this.bankInfoForm.value;
        if (formData.account !== formData.confirmAccount) {
            this.popupComponent.showPopupError({text: 'The values of account and confirm account is not equal'});
            return;
        }
        this.tokensDistributionService.saveBankInfo(formData.routing, formData.account).subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess(data.message);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }

    showPayout() {
        //this.payout = !this.payout;
        this.payout = true;
        //if (this.bankInfo) {
            this.bankInfo = false;
        //}

        //if (this.paypalInfo) {
            this.paypalInfo = false;
        //}
    }

    showBankInfo() {
        this.bankInfo = !this.bankInfo;
        if (this.payout) {
            this.payout = false;
        }
    }

    showPaypalInfo(){
        //this.paypalInfo = !this.paypalInfo;
        this.paypalInfo = true;
        //if (this.payout) {
            this.payout = false;
        //}
    }

    componentVisibility(state) {
        this.visible = state;
        if (this.visible) {
            this.tokensDistributionService.getData().subscribe(data => {
                if (data.status) {
                    this.activity = data.result;
                }
                if (data.info) {
                    this.payout = true;

                    this.data = data.info;
                    this.data.currency = 'dollars';

                    this.avaliableAmount = this.data.available;
                }
                if (!data.status && !data.result && ! data.info) {
                    this.visible = false;
                }

                //this.bankInfoForm.controls['account'].setValue(data.bankInfo.account);
                //this.bankInfoForm.controls['routing'].setValue(data.bankInfo.routing);

                this.paypalInfoForm.controls['email'].setValue(data.paypal.email);
            })
        }
    }

    goBack() {
        this.tokensDistributionService.hide();
    }

    menu() {
        // this.tokensDistributionService.hide();
        // this.tokensService.hide();
        this.menuService.show();
    }

    onFormSubmit() {
        const formData = this.payoutForm.value;
        if (formData.transfer > this.avaliableAmount) {
            this.popupComponent.showPopupError({text: 'Insufficient funds on the account'});
            return;
        }
        this.tokensDistributionService.payout(formData.transfer).subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess(data.message);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }

}
