import { BehaviorSubject } from 'rxjs';
import { TOKENS_ADVERTISEMENT_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TokensAdvertisementService {
    constructor(http) {
        this.http = http;
        this.config = TOKENS_ADVERTISEMENT_CONFIG;
        this.visible = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    getData(page) {
        return this.http.post(this.config.data, { page });
    }
    // cost values: free - 1; paid - 2
    getCollection(type, sense, cost, page) {
        return this.http.post(this.config.collection, { type, sense, cost, page });
    }
    saveSettings(elements, budget, duration) {
        return this.http.post(this.config.submit, { elements, budget, duration });
    }
}
TokensAdvertisementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokensAdvertisementService_Factory() { return new TokensAdvertisementService(i0.ɵɵinject(i1.HttpClient)); }, token: TokensAdvertisementService, providedIn: "root" });
