import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HomeArticle} from "../../models/home-articles";
import {ActivityItem} from "../../models/activityItem";
import {PROJECT_CONFIG} from "../../config/project";

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

    @Input() activity;
    @Input() data;
    @Output() listScrolled: EventEmitter<any> = new EventEmitter();

    transactionsTypeDebit = PROJECT_CONFIG.transactionsTypeDebit;
    transactionsTypeCredit = PROJECT_CONFIG.transactionsTypeCredit;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        console.log(this.activity);
    }

    getTitle(item){
        const activityItem = new ActivityItem(item);
        return activityItem.title;
    }

    getDate(item){
        const activityItem = new ActivityItem(item);
        return activityItem.date;
    }

    getCurrency(item){
        const activityItem = new ActivityItem(item);
        return activityItem.currency;
    }

    getAmount(item){
        const activityItem = new ActivityItem(item);
        return activityItem.amount;
    }

    getDirection(item){
        const activityItem = new ActivityItem(item);

        if(activityItem.direction == this.transactionsTypeDebit){
            return 'in';
        }else if(activityItem.direction == this.transactionsTypeCredit){
            return 'out';
        }

        return false;
    }

    listScroll($event) {
        this.listScrolled.emit($event);
    }

}
