<div class="edit-publication" *ngIf="publication">
    <div class="edit-publication__head">
        <div class="edit-publication__preview">
            <img *ngIf="getAvatar()!=''; else addImg" (click)="addAvatar()" src="{{getAvatar()}}" alt="" class="edit-publication__picture">
            <ng-template #addImg>
                <button (click)="addAvatar()" class="edit-publication__add-img">
                    <svg class="edit-publication__add-icon" width="16" height="16">
                        <use xlink:href="../../../../assets/images/sprite.svg#plus"></use>
                    </svg>
                    Add Photo
                </button>
            </ng-template>

            <!--<img src="{{publication.avatar_link}}" alt="" class="edit-publication__picture">-->
<!--            <ng-template #addImgPreview>-->
<!--                <img src="{{defaultImage}}" alt="" class="edit-publication__picture">-->
<!--            </ng-template>-->

<!--            Opportunity to add image-->
<!--            <img *ngIf="getAvatar()!=''; else addImg" (click)="addAvatar()" src="{{getAvatar()}}" alt="" class="edit-publication__picture">-->
<!--            <ng-template #addImg>-->
<!--                <button (click)="addAvatar()" class="edit-publication__add-img">-->
<!--                    <svg class="edit-publication__add-icon" width="16" height="16">-->
<!--                        <use xlink:href="../../../../assets/images/sprite.svg#plus"></use>-->
<!--                    </svg>-->
<!--                    Add Photo-->
<!--                </button>-->
<!--            </ng-template>-->

            <svg *ngIf="publication.file_type === isVideo()" class="edit-publication__icon-play" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b)">
                    <circle cx="9" cy="9" r="9" fill="#C4C4C4" fill-opacity="0.6"/>
                </g>
                <path d="M13.25 8.13397C13.9167 8.51887 13.9167 9.48113 13.25 9.86603L7.625 13.1136C6.95833 13.4985 6.125 13.0174 6.125 12.2476L6.125 5.75241C6.125 4.9826 6.95833 4.50148 7.625 4.88638L13.25 8.13397Z" fill="white"/>
                <defs>
                    <filter id="filter0_b" x="-4" y="-4" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                    </filter>
                </defs>
            </svg>
        </div>
        <div *ngIf="!editable" class="edit-publication__title">{{publication.title}}</div>

        <input
                *ngIf="editable"
                class="edit-publication__title edit"
                [class.is-error]="!validation.title"
                type="text"
                [(ngModel)]="publication.title"
                placeholder="Title">
    </div>

    <div *ngIf="!editable" class="edit-publication__descr">{{publication.description}}</div>
    <div *ngIf="editable" class="edit-publication__descr edit">
        <textarea class="edit-publication__textarea"
                  [class.is-error]="!validation.description"
                     type="text"
                     #textboxdescr
                     (change)="publication.description = textboxdescr.value"
                     placeholder="Add Description">{{publication.description}}</textarea>
        <span class="edit-publication__descr-left">140 Characters Left</span>
    </div>

    <div class="edit-publication__categories">
        <div *ngIf="!editable" class="accordion accordion_no-arrow is-hidden is-disabled">
            <div class="accordion__head">
                Taste, Smell
                <svg class="accordion__arrow" width="18" height="10">
                    <use xlink:href="../../../../assets/images/sprite.svg#thick-arrow"></use>
                </svg>
            </div>
        </div>

        <div *ngIf="editable"
             class="accordion"
             [class.is-error]="!validation.categories"
             [class.is-hidden]="!chooseCategory">
            <div (click)="toggleCategories()" class="accordion__head">
                Choose Category
                <svg class="accordion__arrow" width="18" height="10">
                    <use xlink:href="../../../../assets/images/sprite.svg#thick-arrow"></use>
                </svg>
            </div>
            <div class="accordion__body">
                <div class="choose-category">
                    <div *ngFor="let category of categoriesList" class="choose-category__item">
                        <input (change)="updatessCategories($event)" data-title="{{category.title}}" id="{{category.title}}" [attr.element]="category.id" type="checkbox" class="choose-category__input">
                        <label for="{{category.title}}" class="choose-category__label">{{category.title}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="edit-publication__cost">
        <div class="cost">
            <div class="cost__toggle">
                <div class="toggle">
                    <input (change)="changeCost()" [checked]="checkCost()" class="toggle__input" id="toggle" type="checkbox">
                    <label for="toggle" class="toggle__label"></label>
                </div>
            </div>

            <div class="cost__head" *ngIf="!publication.payment_type">
                <div class="cost__title">Paid</div>
                <div class="cost__subtitle">Type amount of tokens you want to get for this content.</div>
            </div>

            <div class="cost__head" *ngIf="publication.payment_type">
                <div class="cost__title">Free</div>
                <div class="cost__subtitle">The amount of tokens you receive for reposting the content</div>
            </div>

            <div class="cost__tokens">
                <svg *ngIf="publication.payment_type" width="18" height="18" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill="white"/>
                    <path d="M6 0C2.6863 0 0 2.6863 0 6C0 9.3137 2.6863 12 6 12C9.31215 11.9963 11.9963 9.31215 12 6C12 2.6863 9.3137 0 6 0ZM6 11.6C2.9072 11.6 0.4 9.0928 0.4 6C0.4 2.9072 2.9072 0.4 6 0.4C9.09132 0.403525 11.5965 2.90867 11.6 6C11.6 9.0928 9.0928 11.6 6 11.6Z" fill="black"/>
                    <path d="M1.00125 6.31447H1.00585C1.00587 6.31447 1.00592 6.31447 1.00595 6.31447C1.11637 6.31189 1.20382 6.22029 1.20125 6.10987C1.20045 6.07334 1.20005 6.03674 1.20005 6.00007C1.20005 5.84467 1.20765 5.68807 1.22225 5.53467C1.23285 5.42472 1.1523 5.32697 1.04235 5.31637C0.932399 5.30577 0.834649 5.38632 0.824049 5.49627C0.808249 5.66247 0.800049 5.83187 0.800049 6.00007C0.800049 6.04007 0.800449 6.07974 0.801249 6.11907C0.803749 6.22772 0.892574 6.31449 1.00125 6.31447Z" fill="black"/>
                    <path d="M2.10013 8.79847C2.03568 8.70872 1.91068 8.68822 1.82093 8.75267C1.73118 8.81712 1.71068 8.94212 1.77513 9.03187C1.89665 9.20059 2.02803 9.36199 2.16853 9.51527C2.20645 9.55649 2.25993 9.57992 2.31593 9.57987L2.31613 9.58007C2.36613 9.58002 2.4143 9.56124 2.45115 9.52744C2.53255 9.45277 2.538 9.32627 2.46333 9.24487C2.33355 9.10337 2.21228 8.95429 2.10013 8.79847Z" fill="black"/>
                    <path d="M1.42366 3.97309C1.45111 3.98651 1.48131 3.99336 1.51186 3.99309C1.58796 3.99329 1.65759 3.95024 1.69146 3.88209C1.77629 3.70986 1.87139 3.54289 1.97626 3.38209C1.97836 3.37904 1.98039 3.37596 1.98231 3.37281C2.04011 3.27869 2.01066 3.15551 1.91654 3.09771C1.82241 3.03991 1.69924 3.06936 1.64144 3.16349C1.52771 3.33771 1.42459 3.51864 1.33264 3.70529C1.28384 3.80436 1.32459 3.92426 1.42366 3.97309Z" fill="black"/>
                    <path d="M1.07366 5.11876C1.08779 5.12186 1.10221 5.12341 1.11666 5.12336C1.21021 5.12294 1.29096 5.05771 1.31106 4.96636C1.35226 4.77876 1.40476 4.59384 1.46826 4.41256C1.50481 4.30829 1.44994 4.19411 1.34566 4.15756C1.24139 4.12101 1.12721 4.17589 1.09066 4.28016C1.02204 4.47661 0.965135 4.67696 0.92026 4.88016C0.920185 4.88054 0.920085 4.88091 0.92001 4.88129C0.89686 4.98929 0.96566 5.09561 1.07366 5.11876Z" fill="black"/>
                    <path d="M1.22225 7.49226C1.22227 7.49226 1.22227 7.49226 1.2223 7.49226C1.32897 7.46363 1.39227 7.35396 1.36365 7.24728C1.3139 7.06191 1.27537 6.87371 1.24825 6.68368C1.24817 6.68311 1.2481 6.68253 1.248 6.68196C1.23195 6.57268 1.13035 6.49709 1.02107 6.51314C0.911796 6.52919 0.836196 6.63078 0.852246 6.74006C0.881671 6.94598 0.923396 7.14994 0.977246 7.35086C1.0007 7.43829 1.07992 7.49906 1.17045 7.49906C1.18795 7.49913 1.20537 7.49683 1.22225 7.49226Z" fill="black"/>
                    <path d="M1.79225 8.31301C1.69967 8.14478 1.61732 7.97113 1.54565 7.79301C1.5449 7.79108 1.54412 7.78916 1.54332 7.78726C1.50047 7.68546 1.38322 7.63766 1.2814 7.68051C1.1796 7.72336 1.1318 7.84061 1.17465 7.94243C1.2524 8.13526 1.34162 8.32326 1.44185 8.50543C1.47707 8.56931 1.5443 8.60893 1.61725 8.60883V8.60943C1.65087 8.60938 1.68395 8.60088 1.71342 8.58466C1.8102 8.53141 1.8455 8.40978 1.79225 8.31301Z" fill="black"/>
                    <path d="M8.57239 1.94765C8.73444 2.0509 8.89024 2.16363 9.03899 2.28525C9.07466 2.31458 9.11941 2.33055 9.16559 2.33045C9.22556 2.33045 9.28236 2.3035 9.32034 2.25708C9.39029 2.17158 9.37766 2.04558 9.29216 1.97563C9.13129 1.84383 8.96266 1.7218 8.78716 1.61023C8.78586 1.60938 8.78454 1.60853 8.78322 1.6077C8.68934 1.54948 8.56606 1.57838 8.50784 1.67225C8.44964 1.76613 8.47851 1.88943 8.57239 1.94765Z" fill="black"/>
                    <path d="M10.6069 4.64858C10.6608 4.83253 10.7036 5.01953 10.7351 5.20858C10.7511 5.30503 10.8345 5.37573 10.9323 5.37578C10.9437 5.3761 10.955 5.3755 10.9663 5.37398C10.9663 5.37398 10.9663 5.37398 10.9664 5.37398C11.0753 5.3558 11.1489 5.25273 11.1307 5.14378C11.0963 4.93855 11.0496 4.73558 10.9907 4.53598C10.9903 4.53458 10.9899 4.53318 10.9895 4.5318C10.9572 4.42615 10.8455 4.36665 10.7398 4.3989C10.6342 4.43115 10.5747 4.54293 10.6069 4.64858Z" fill="black"/>
                    <path d="M2.16591 2.99043C2.22521 2.99098 2.28169 2.9652 2.32011 2.92003C2.44321 2.77235 2.57499 2.6321 2.71471 2.50003C2.79514 2.4242 2.79884 2.29755 2.72301 2.21713C2.64719 2.1367 2.52054 2.133 2.44011 2.20883C2.28889 2.35138 2.14616 2.5027 2.01271 2.66203C1.98261 2.69798 1.96609 2.74338 1.96606 2.79028C1.96599 2.90075 2.05546 2.99035 2.16591 2.99043Z" fill="black"/>
                    <path d="M10.1541 3.59237C10.2503 3.75837 10.3366 3.92992 10.4125 4.10617C10.4441 4.17962 10.5163 4.2272 10.5963 4.22717V4.22797C10.6234 4.22795 10.6502 4.22242 10.6752 4.2117C10.7766 4.16807 10.8235 4.05045 10.7799 3.94897C10.6978 3.75797 10.6043 3.57212 10.4999 3.39237C10.4968 3.38627 10.4933 3.38032 10.4896 3.37457C10.4294 3.28192 10.3056 3.25557 10.2129 3.31572C10.1203 3.37587 10.0939 3.49972 10.1541 3.59237Z" fill="black"/>
                    <path d="M9.83525 3.11325C9.87302 3.1636 9.9323 3.19325 9.99525 3.19325L9.99465 3.19245C10.0379 3.19245 10.08 3.17842 10.1146 3.15245C10.203 3.08617 10.2209 2.96082 10.1546 2.87245C10.0294 2.70647 9.89442 2.54807 9.75045 2.39805C9.74825 2.39562 9.74597 2.39327 9.74365 2.39097C9.6652 2.3132 9.53857 2.31377 9.4608 2.3922C9.38302 2.47065 9.3836 2.59727 9.46202 2.67505C9.595 2.81362 9.71962 2.95995 9.83525 3.11325Z" fill="black"/>
                    <path d="M3.03869 2.17177V2.17097C3.08124 2.17129 3.12274 2.15789 3.15709 2.13277C3.31177 2.01904 3.47304 1.91459 3.64009 1.81997C3.73624 1.76557 3.77009 1.64352 3.71569 1.54737C3.66129 1.45122 3.53924 1.41737 3.44309 1.47177C3.26217 1.57464 3.08754 1.68817 2.92009 1.81177C2.87012 1.84924 2.84052 1.90792 2.84009 1.97037C2.83932 2.08084 2.92824 2.17099 3.03869 2.17177Z" fill="black"/>
                    <path d="M4.07996 1.58219C4.10619 1.58211 4.13216 1.57689 4.15636 1.56679C4.33359 1.49299 4.51511 1.43001 4.69996 1.37819C4.80614 1.34836 4.86811 1.23819 4.83851 1.13196C4.80886 1.02556 4.69856 0.963336 4.59216 0.992986C4.39194 1.04936 4.19526 1.11761 4.00316 1.19739C3.92829 1.22836 3.87951 1.30149 3.87964 1.38251C3.87981 1.49296 3.96951 1.58236 4.07996 1.58219Z" fill="black"/>
                    <path d="M6.02746 0.99649C6.02381 0.88604 5.93131 0.79944 5.82086 0.80309C5.61324 0.81009 5.40619 0.829465 5.20086 0.86109C5.10376 0.876265 5.03209 0.95974 5.03174 1.05801C5.03136 1.16846 5.12059 1.25831 5.23106 1.25869C5.24116 1.25876 5.25126 1.25811 5.26126 1.25669C5.45096 1.22741 5.64224 1.20951 5.83406 1.20309C5.94451 1.19944 6.03111 1.10694 6.02746 0.99649Z" fill="black"/>
                    <path d="M10.5298 8.13542C10.4326 8.08289 10.3113 8.11912 10.2588 8.21627C10.17 8.38654 10.071 8.55132 9.96237 8.70967C9.93952 8.74297 9.92727 8.78239 9.92725 8.82279C9.9272 8.93324 10.0167 9.02282 10.1272 9.02287V9.02307C10.1936 9.02342 10.2559 8.99074 10.2934 8.93587C10.4106 8.76424 10.5175 8.58572 10.6134 8.40127C10.662 8.30464 10.625 8.18684 10.5298 8.13542Z" fill="black"/>
                    <path d="M9.60931 9.16524C9.48246 9.30954 9.34724 9.44624 9.20431 9.57464C9.16209 9.61257 9.13796 9.66664 9.13794 9.72342C9.13791 9.83387 9.22744 9.92344 9.33791 9.92344V9.92284C9.38694 9.92284 9.43429 9.90484 9.47091 9.87224C9.62566 9.73329 9.77206 9.58529 9.90931 9.42904C9.97309 9.34864 9.96566 9.23302 9.89214 9.16142C9.81299 9.08437 9.68636 9.08609 9.60931 9.16524Z" fill="black"/>
                    <path d="M11.1948 5.76191C11.1898 5.65146 11.0962 5.56599 10.9857 5.57101C10.8753 5.57604 10.7898 5.66966 10.7948 5.78011C10.7981 5.85319 10.7998 5.92651 10.7998 6.00011C10.7999 6.19241 10.7885 6.38456 10.7658 6.57551C10.7528 6.68514 10.831 6.78459 10.9406 6.79771C10.9486 6.79866 10.9566 6.79914 10.9646 6.79911V6.79851C11.0658 6.79841 11.151 6.72279 11.163 6.62231C11.1875 6.41581 11.1998 6.20806 11.1998 6.00011C11.1998 5.92011 11.1981 5.84071 11.1948 5.76191Z" fill="black"/>
                    <path d="M8.33454 1.57327C8.38269 1.47384 8.34111 1.35424 8.24171 1.30609C8.05394 1.21624 7.86091 1.13777 7.66371 1.07109C7.55879 1.03574 7.44506 1.09217 7.40971 1.19709C7.37436 1.30202 7.43079 1.41574 7.53571 1.45109C7.71766 1.51282 7.89579 1.58534 8.06911 1.66829C8.09596 1.68137 8.12544 1.68822 8.15531 1.68829V1.68609C8.23169 1.68579 8.30124 1.64202 8.33454 1.57327Z" fill="black"/>
                    <path d="M10.9076 6.99796C10.8007 6.97013 10.6915 7.03421 10.6636 7.14111C10.618 7.32766 10.5613 7.51131 10.4938 7.69111C10.4549 7.79448 10.5072 7.90983 10.6106 7.94871C10.6331 7.95713 10.657 7.96145 10.681 7.9615C10.7643 7.9615 10.8389 7.90988 10.8682 7.83191C10.9415 7.63706 11.0029 7.43796 11.0522 7.23571C11.0761 7.13041 11.012 7.02518 10.9076 6.99796Z" fill="black"/>
                    <path d="M8.75362 9.93121C8.5965 10.0414 8.43295 10.1421 8.26382 10.2328C8.19895 10.2677 8.15845 10.3353 8.15845 10.409C8.15845 10.5194 8.24797 10.609 8.35842 10.609V10.6098C8.3913 10.6101 8.4237 10.6023 8.45282 10.587C8.63605 10.4887 8.81325 10.3795 8.98342 10.26C9.07422 10.1966 9.09637 10.0715 9.03292 9.98071C8.96947 9.88991 8.84442 9.86776 8.75362 9.93121Z" fill="black"/>
                    <path d="M4.36007 10.5127C4.17947 10.4468 4.00302 10.3701 3.83167 10.2829C3.73589 10.2383 3.62187 10.2758 3.57142 10.3687C3.51869 10.4657 3.55462 10.5872 3.65167 10.6399C3.83737 10.7339 4.02852 10.8167 4.22407 10.8879C4.24594 10.8958 4.26902 10.8999 4.29227 10.8999L4.29167 10.9007C4.37587 10.9008 4.45109 10.8481 4.47984 10.7689C4.51752 10.6651 4.46389 10.5504 4.36007 10.5127Z" fill="black"/>
                    <path d="M3.3351 9.99389C3.17545 9.88711 3.02233 9.77089 2.8765 9.64589C2.8728 9.64249 2.869 9.63921 2.86505 9.63609C2.7785 9.56744 2.6527 9.58196 2.58408 9.66851C2.51545 9.75506 2.52995 9.88086 2.6165 9.94949C2.77445 10.0848 2.94028 10.2107 3.1131 10.3265C3.14593 10.3484 3.18448 10.3601 3.22393 10.3601C3.29063 10.3601 3.35293 10.3268 3.39005 10.2714C3.4515 10.1796 3.4269 10.0553 3.3351 9.99389Z" fill="black"/>
                    <path d="M5.47972 10.7724C5.28884 10.7517 5.09939 10.7198 4.91232 10.6766C4.80749 10.6547 4.70404 10.7193 4.67762 10.8231C4.65037 10.9301 4.71507 11.039 4.82212 11.0662C5.02487 11.113 5.23024 11.1477 5.43712 11.17C5.44429 11.1708 5.45149 11.1712 5.45872 11.1712C5.56039 11.1708 5.64554 11.0941 5.65667 10.9931C5.66874 10.8833 5.58952 10.7845 5.47972 10.7724Z" fill="black"/>
                    <path d="M7.7419 10.4745C7.5631 10.5442 7.38023 10.6029 7.1943 10.6505C7.1057 10.673 7.04373 10.7528 7.0437 10.8443C7.0437 10.9547 7.13323 11.0443 7.2437 11.0443V11.0441C7.26048 11.0441 7.2772 11.0421 7.2935 11.0381C7.4951 10.9864 7.6934 10.9227 7.8873 10.8471C7.98775 10.8063 8.03745 10.693 7.9994 10.5915C7.9606 10.4881 7.84533 10.4357 7.7419 10.4745Z" fill="black"/>
                    <path d="M7.21771 1.14522C7.24024 1.03707 7.17081 0.931166 7.06269 0.908641C6.85899 0.866491 6.65296 0.836491 6.44569 0.818841C6.33561 0.809516 6.23881 0.891166 6.22949 1.00124C6.22016 1.11132 6.30181 1.20812 6.41189 1.21744C6.60324 1.23367 6.79344 1.26132 6.98149 1.30024C6.99464 1.30297 7.00804 1.30437 7.02149 1.30444H7.02269C7.11714 1.30409 7.19846 1.23769 7.21771 1.14522Z" fill="black"/>
                    <path d="M6.62916 10.7602C6.43864 10.7848 6.24684 10.7982 6.05476 10.8003C5.94431 10.8009 5.85529 10.891 5.85596 11.0015C5.85664 11.1119 5.94671 11.2009 6.05716 11.2003H6.05996C6.26764 11.198 6.47499 11.1836 6.68096 11.1571C6.79054 11.1428 6.86776 11.0423 6.85346 10.9327C6.83916 10.8232 6.73874 10.7459 6.62916 10.7602Z" fill="black"/>
                    <path d="M5.62113 4.40157C5.41381 4.40157 5.21752 4.4094 5.03226 4.42505C4.8492 4.44069 4.68158 4.45858 4.5294 4.4787C4.37942 4.49882 4.24819 4.51893 4.13571 4.53905C4.02543 4.55694 3.94052 4.57035 3.88097 4.57929C3.88097 4.57706 3.87766 4.56253 3.87105 4.5357C3.86664 4.50888 3.86002 4.47423 3.8512 4.43175C3.84238 4.38928 3.83466 4.34457 3.82804 4.29762C3.82142 4.24844 3.81812 4.20597 3.81812 4.1702C3.81812 4.09643 3.8501 4.04278 3.91406 4.00925C3.98022 3.97348 4.06955 3.95001 4.18203 3.93883C4.29451 3.92542 4.42574 3.92095 4.57571 3.92542C4.72569 3.92765 4.88669 3.92877 5.05872 3.92877H6.02805C6.12951 3.92877 6.25412 3.92877 6.40189 3.92877C6.54966 3.92877 6.71397 3.92542 6.89483 3.91871C7.07789 3.912 7.27307 3.90083 7.48039 3.88518C7.68992 3.86953 7.90386 3.84718 8.1222 3.81812C8.1222 3.82035 8.12551 3.83265 8.13213 3.855C8.13874 3.87512 8.14536 3.90306 8.15198 3.93883C8.15859 3.97236 8.16521 4.01148 8.17183 4.05619C8.17844 4.09867 8.18175 4.14338 8.18175 4.19032C8.18175 4.24621 8.1619 4.2898 8.1222 4.3211C8.08471 4.35239 8.03398 4.37586 7.97002 4.39151C7.90606 4.40493 7.83218 4.41387 7.74837 4.41834C7.66676 4.42057 7.58075 4.42169 7.49032 4.42169C7.23889 4.42169 7.02606 4.41946 6.85182 4.41499C6.67758 4.41051 6.52761 4.40604 6.40189 4.40157V7.61059C6.40189 7.75366 6.4052 7.87996 6.41182 7.9895C6.42064 8.09904 6.43056 8.19516 6.44159 8.27788C6.45262 8.35835 6.46365 8.42654 6.47467 8.48242C6.4857 8.53607 6.49452 8.57967 6.50114 8.6132C6.46806 8.63108 6.43167 8.64785 6.39197 8.6635C6.35227 8.67691 6.31036 8.68809 6.26625 8.69703C6.22435 8.7082 6.18244 8.71603 6.14054 8.7205C6.09863 8.72497 6.05893 8.72721 6.02144 8.72721C5.96409 8.72721 5.91006 8.72162 5.85933 8.71044C5.81081 8.69926 5.7689 8.68138 5.73362 8.65679C5.69833 8.62996 5.67076 8.59531 5.65091 8.55284C5.63106 8.51037 5.62113 8.45671 5.62113 8.39189V4.40157Z" fill="black"/>
                </svg>
                <svg *ngIf="!publication.payment_type" class="cost__icon" width="18" height="18">
                    <use xlink:href="../../../../assets/images/sprite.svg#token"></use>
                </svg>
                <input (change)="changeTokens()"
                       #tokensInput
                       currencyMask
                       type="number"
                       [options]="{ prefix: '', thousands: ',', decimal: '.' }"
                       [(ngModel)]="publication.tokens"
                       class="cost__input">
            </div>

            <div class="cost__info">
                <img src="../../../../assets/images/nav-icons/attention-i.svg" alt="" class="cost__attention">
                Half of content must be free.
            </div>

            <div class="cost__range">
                <div class="range-slider">
                    <mat-slider class="range-slider__input range-slider__input--big range-slider__input--disabled"
                                [(ngModel)]="total"
                                thumbLabel
                                [displayWith]="formatLabel"
                                min="0"
                                max="100"
                                step="1"
                                #textboxpercent
                                (change)="publication.freePercent = textboxpercent.value"
                                [value]="publication.freePercent"></mat-slider>
                    <div class="range-slider__values">
                        <div class="range-slider__min">0%</div>
                        <div class="range-slider__max">100%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="edit-publication__tags">
        <div class="add-tags">
            <div class="accordion accordion_no-arrow" [class.is-hidden]="!searchTags">
                <div (click)="openSearchTags()" class="accordion__head">
                    Add Tags
                    <svg class="accordion__arrow" width="18" height="10">
                        <use xlink:href="../../../../assets/images/sprite.svg#thick-arrow"></use>
                    </svg>
                </div>
                <div class="accordion__body">
                    <div class="search">
                        <div class="search__wrapper">
                            <form (submit)="addTag($event)">
                                <input #tagsInput (input)="onSearch($event)" type="text" class="search__input">
                            </form>
                            <button (click)="closeSearchTags()" class="search__close">
                                <svg class="search__icon" width="22" height="22">
                                    <use xlink:href="../../../../assets/images/sprite.svg#close"></use>
                                </svg>
                            </button>
                        </div>

                        <ul #dropdown class="search__dropdown">
                            <li (click)="selectTag($event)"  *ngFor="let item of searchVariants" data-title="{{item.title}}">
                                <div class="search__item">
                                    <div class="search__title">#{{item.title}}</div>
                                    <div class="search__quantity">Publications: {{item.quantity}}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div  class="tags">
<!--            <div *ngFor="let tag of publication.tags" class="tags__item">{{tag}}</div>-->
            <div *ngFor="let tag of publication.tags" class="tags__item">{{tag}}</div>
        </div>
    </div>

</div>
