import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { USER_PAYMENT_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AccountSettingsPaymentService {
    constructor(ngZone, http) {
        this.ngZone = ngZone;
        this.http = http;
        this.visible = new BehaviorSubject(null);
        this.config = USER_PAYMENT_CONFIG;
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    loadData() {
        return this.http.post(this.config.info, {});
    }
    setData(number, expDateMM, expDateYY, securityCode, zipCode, description) {
        return this.http.post(this.config.save, { number, expDateMM, expDateYY, securityCode, zipCode, description });
    }
}
AccountSettingsPaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountSettingsPaymentService_Factory() { return new AccountSettingsPaymentService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.HttpClient)); }, token: AccountSettingsPaymentService, providedIn: "root" });
