import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AdSettingsActiveService} from "../../../../service/admin/adSettingsActive.service";
import {MenuService} from "../../../../service/menu.service";
import {AdSettingsService} from "../../../../service/admin/adSettings.service";
import {UserWithActiveAd} from "../../../../models/userWithActiveAd";
import {BalanceCard} from "../../../../models/balanceCard";
import {PopupComponent} from "../../../../shared/popup/popup.component";

@Component({
    selector: 'app-ad-settings-active',
    templateUrl: './ad-settings-active.component.html',
    styleUrls: ['./ad-settings-active.component.scss']
})
export class AdSettingsActiveComponent implements OnInit {
    visible: boolean;
    edit = false;

    userEditForm: FormGroup;

    users = [];
    activeUserId;

    constructor(private formBuilder: FormBuilder,
                private menuService: MenuService,
                private adSettingsService: AdSettingsService,
                private popupComponent: PopupComponent,
                private adSettingsActiveService: AdSettingsActiveService) {
        this.adSettingsActiveService.visible.subscribe((value: any) => this.toggleVisibility(value));
    }

    ngOnInit() {
        this.userEditForm = this.formBuilder.group({
            phone: ['', Validators.required],
            name: ['', Validators.required]
        });
    }

    onSearchSubmit() {
        this.findUsers();
    }

    toggleEdit(event) {
        const user = event.currentTarget;
        const userId = user.getAttribute('data-user-id');
        this.activeUserId = userId - 1;

        this.edit = !this.edit;
    }

    toggleVisibility(value) {
        this.visible = value;

        if (this.visible) {
            this.findUsers();
        }
    }

    goBack() {
        this.adSettingsActiveService.hide();
    }

    menu() {
        this.adSettingsActiveService.hide();
        this.adSettingsService.hide();
        //this.menuService.hide();
    }

    findUsers(){
        const name = this.userEditForm.controls['name'].value.trim();
        const phone = this.userEditForm.controls['phone'].value.trim();

        this.adSettingsActiveService.getUsers(name, phone).subscribe(data => {
            this.users = [];
            if (data.status) {
                this.users = data.result;
            }
        });

    }

    getId(user){
        const userItem = new UserWithActiveAd(user);
        return user.id;
    }

    getAvatar(user){
        const userItem = new UserWithActiveAd(user);
        return user.avatar;
    }

    getFirstName(user){
        const userItem = new UserWithActiveAd(user);
        return user.firstName;
    }

    getLastName(user){
        const userItem = new UserWithActiveAd(user);
        return user.lastName;
    }

    getPrice(user){
        const userItem = new UserWithActiveAd(user);
        return user.price;
    }

    getDays(user){
        const userItem = new UserWithActiveAd(user);
        return user.days;
    }

    showAgreement() {
        this.popupComponent.showPopupAgreement({text: 'Do you really want to delete this advertisement?',
            confirmButtonText: 'Yes, I do!',
            confirmAction: this.deleteUser,
            cancelButtonText: 'No, I don\'t!'}, (status) => {
            if (status) {
                this.deleteUser();
            }
        });
    }

    deleteUser() {
        this.adSettingsActiveService.deactivateAdvertisement(this.activeUserId).subscribe(data => {
            this.users = [];
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.findUsers();
                this.edit = false;
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }

}
