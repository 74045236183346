import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class LoginService {
    constructor(ngZone, route, router) {
        this.ngZone = ngZone;
        this.route = route;
        this.router = router;
        this.loginChanged = new BehaviorSubject(null);
    }
    show() {
        this.loginChanged.next(true);
        document.body.classList.add('is-hidden');
    }
    hide() {
        this.loginChanged.next(false);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
        });
        document.body.classList.remove('is-hidden');
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i1.Router)); }, token: LoginService, providedIn: "root" });
