<router-outlet></router-outlet>
<div *ngIf="search">
    <!--    <app-search [data]="data"></app-search>-->
    <app-search></app-search>
</div>
<app-article></app-article>
<!--<app-notifications (hidenMenu)="hidenMenu($event)" [menu]="menu"></app-notifications>-->

<div *ngIf="menu">
    <app-notifications></app-notifications>
</div>

<app-tokens></app-tokens>
<app-users></app-users>
<app-ad-settings></app-ad-settings>
<app-distributions></app-distributions>
<app-account></app-account>
<app-collection></app-collection>

<div *ngIf="signup">
    <app-signup></app-signup>
</div>

<div *ngIf="login">
    <app-login></app-login>
</div>



<app-menu-bottom></app-menu-bottom>

<app-global-library></app-global-library>
<app-new-content></app-new-content>








<svg style="width:0;height:0;position:absolute;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <symbol id="category_1" viewBox="0 0 15 22">
            <path d="M7.13236 13.3832C7.49802 14.0306 7.66196 14.6397 7.73587 15.3171C6.43604 16.7981 6.56886 18.463 7.21183 20.2358C8.59446 18.2963 9.37914 16.1823 9.65088 13.845L10.2277 13.4315C10.1833 16.3391 9.25076 18.8965 7.77144 21.2944H6.32657L7.02122 20.4836C6.70779 19.6761 6.37492 18.932 6.12929 18.1601C5.75584 16.9892 5.70972 15.805 6.296 14.6774C6.52107 14.2407 6.82894 13.8461 7.13236 13.3832Z" fill="#FF6961"/>
            <path d="M2.17958 6.90601C2.13456 6.98047 2.09066 7.05549 2.04454 7.12829C1.22652 8.42312 0.661905 9.81575 0.585216 11.3568C0.457401 13.9131 1.6233 15.7853 3.76393 17.0773C4.4069 17.4663 5.12656 17.7264 5.85733 18.0671C6.00348 18.5 6.17242 18.9979 6.35747 19.5425C5.53961 19.4396 4.74808 19.1852 4.02345 18.7923C2.49689 17.9871 1.37323 16.7917 0.657459 15.2251C-0.814642 11.9953 0.401273 8.50148 2.17958 6.90601Z" fill="#FF6961"/>
            <path d="M9.47339 19.3495C9.77403 19.1951 10.0513 19.0717 10.3114 18.9161C12.427 17.6679 13.8086 15.8691 14.2181 13.4311C14.5788 11.2883 13.8786 9.46719 12.2514 8.02732C11.8924 7.70889 11.5846 7.37324 11.3595 6.952C11.2178 6.68748 11.0383 6.44241 10.871 6.18122L11.7357 5.97949C13.7808 7.24931 15.3412 10.0196 14.9356 13.0927C14.4321 16.9077 11.4856 18.9161 9.47339 19.3495Z" fill="#FF6961"/>
            <path d="M9.17883 13.923C8.95043 13.3718 8.71981 12.8149 8.47974 12.2364C8.91097 11.853 9.4039 11.4328 9.87459 10.9899C10.3842 10.5098 10.8143 9.96853 11.0249 9.28388C11.2783 8.45975 11.1516 7.65618 10.8871 6.86094C10.8604 6.78259 10.8315 6.70423 10.8088 6.62476C10.8049 6.61143 10.8176 6.59253 10.8232 6.57642C12.6082 9.1105 12.5909 11.8641 9.17883 13.923Z" fill="#FF6961"/>
            <path d="M4.77002 10.6759C6.41662 10.9871 7.91428 11.5317 8.65617 13.2033C8.99571 13.968 9.02628 14.7782 8.91458 15.599C8.72174 17.015 8.15602 18.2893 7.40635 19.5013C7.42191 19.4407 7.43303 19.379 7.45359 19.3207C7.79591 18.3571 8.04098 17.3707 8.06488 16.3459C8.12045 14.123 7.1585 12.412 5.39298 11.116C5.18737 10.9654 4.97841 10.8226 4.77002 10.6759Z" fill="#FF6961"/>
            <path d="M8.7382 7.65881C9.22762 8.62548 9.69561 9.57263 9.93022 10.6304L9.53232 11.0489C9.46786 10.8266 9.43063 10.6377 9.36061 10.4626C9.18889 10.0347 9.00106 9.61346 8.82212 9.18834C8.78711 9.10554 8.73376 9.01662 8.7382 8.93326C8.76154 8.50314 8.69763 8.15063 8.7382 7.65881Z" fill="#FF6961"/>
            <path d="M4.882 9.13323C5.09312 9.11668 5.30467 9.09451 5.51323 9.16229C5.53396 9.16899 5.55438 9.17558 5.57512 9.18227C5.57544 9.18237 5.57585 9.18264 5.57634 9.18263C5.87833 9.24993 6.1659 9.36883 6.46398 9.46281C6.7688 9.55868 7.04008 9.47659 7.26555 9.27289C7.45721 9.09991 7.62162 8.89656 7.79823 8.70677C7.79369 8.70001 7.78926 8.6935 7.78473 8.68676C7.58975 8.71052 7.39515 8.74509 7.19932 8.75488C6.69399 8.78015 6.19038 8.73817 5.70042 8.60888C5.48019 8.55076 5.26421 8.47654 5.05485 8.38678C4.84302 8.29599 4.64668 8.17954 4.44141 8.07604C4.38548 8.04783 4.32251 8.01066 4.26485 8.07378C4.09767 8.25601 3.93414 8.4411 3.82516 8.66622C3.76486 8.79052 3.70376 8.91244 3.71081 9.0573C3.71073 9.0576 3.71084 9.0578 3.71095 9.058C3.72045 9.10083 3.74157 9.14 3.77561 9.16733C3.80517 9.1907 3.84137 9.20344 3.87861 9.21068C3.87893 9.21078 3.87913 9.21067 3.87941 9.21075C3.89923 9.21306 3.919 9.21388 3.93836 9.21306C4.05062 9.20852 4.16317 9.20355 4.27536 9.19698C4.27586 9.19696 4.27605 9.19686 4.27635 9.19694C4.28445 9.19576 4.29265 9.19478 4.30075 9.19362C4.4915 9.16735 4.68327 9.14385 4.87539 9.13382C4.87759 9.1336 4.87978 9.13342 4.882 9.13323Z" fill="#FF6961"/>
            <path d="M3.89057 6.77096C3.90483 6.7702 3.92013 6.7681 3.93314 6.76238C4.04668 6.71308 4.1584 6.65917 4.27351 6.61364C4.37777 6.57263 4.48487 6.53853 4.5919 6.50524C4.6668 6.48217 4.74371 6.46598 4.82984 6.44411C4.81188 6.41887 4.80566 6.40588 4.79589 6.39719C4.53491 6.16422 4.25591 5.94792 4.02282 5.68558C3.9611 5.6161 3.90007 5.53739 3.89271 5.44474C3.88332 5.327 3.96654 5.2175 4.06973 5.15996C4.16975 5.10419 4.29486 5.1247 4.39078 5.06757C4.48741 5.01002 4.53344 4.88105 4.53713 4.77417C4.5421 4.63046 4.49412 4.49015 4.48478 4.34664C4.47274 4.16168 4.53243 3.97546 4.64062 3.82597C4.77817 3.63585 4.91827 3.61005 5.11617 3.51927C5.19374 3.48371 5.26485 3.42729 5.30139 3.35017C5.45702 3.0219 5.05036 3.07378 4.87313 3.10024C4.57519 3.14472 4.30037 3.31062 4.09914 3.53149C3.82959 3.82737 3.67776 4.21264 3.58183 4.60121C3.48589 4.98978 3.44036 5.38918 3.35158 5.77944C3.31836 5.92546 3.27884 6.07252 3.28389 6.22218C3.2944 6.5357 3.57727 6.78568 3.89057 6.77096Z" fill="#FF6961"/>
            <path d="M5.06849 3.86309C4.89308 3.96291 4.76421 4.11419 4.74185 4.31282C4.71651 4.53884 4.69998 4.60831 4.94595 4.66522C5.03299 4.68506 5.12321 4.69129 5.21181 4.70381C5.21253 4.7088 5.21308 4.71392 5.21363 4.719C5.07597 4.76013 4.93833 4.80125 4.79443 4.8446C4.78526 4.89502 4.77424 4.95729 4.76085 5.03146C5.04338 4.9575 5.31828 4.9321 5.6028 4.82487C5.53608 4.77179 5.4937 4.73422 5.44753 4.70182C5.30986 4.60472 5.21559 4.48388 5.24987 4.30566C5.2653 4.22546 5.29158 4.14258 5.33368 4.07374C5.42026 3.93244 5.5208 3.79955 5.62215 3.65384C5.58871 3.65528 5.55818 3.65457 5.52847 3.65806C5.35431 3.67892 5.21488 3.78001 5.06849 3.86309Z" fill="#FF6961"/>
            <path d="M6.51437 3.27818C6.51387 3.2782 6.51358 3.27811 6.51318 3.27833C6.44069 3.31877 6.37601 3.37195 6.3027 3.41418C6.21648 3.46385 6.12608 3.50598 6.04156 3.55866C5.80584 3.70559 5.56535 3.95817 5.46409 4.221C5.43652 4.29263 5.42553 4.37319 5.44875 4.44634C5.47897 4.54151 5.56536 4.61274 5.66157 4.63951C5.86387 4.69583 6.07776 4.57944 6.23187 4.45777C6.27858 4.42089 6.32443 4.37545 6.33466 4.31682C6.34622 4.2506 6.30475 4.18094 6.24358 4.15306C6.18241 4.12517 6.10704 4.13696 6.0526 4.17639C5.96361 4.24086 5.95686 4.33964 5.94719 4.43932C5.91585 4.41492 5.89936 4.37487 5.89664 4.33523C5.89393 4.29559 5.90354 4.25606 5.91655 4.21854C5.95086 4.11941 6.0095 4.02882 6.08589 3.95693C6.13859 3.90731 6.20485 3.86493 6.27725 3.86654C6.33153 3.86774 6.3846 3.89536 6.41671 3.93915C6.45684 3.99389 6.45493 4.07674 6.45787 4.14093C6.45788 4.14144 6.45799 4.14163 6.4581 4.14183C6.47278 4.16992 6.49611 4.16333 6.51968 4.14423C6.51988 4.14412 6.52015 4.14371 6.52063 4.14318C6.52095 4.14326 6.52091 4.14277 6.52112 4.14265C6.60448 4.04335 6.67692 3.93549 6.75368 3.83118C6.80465 3.76191 6.95432 3.63008 6.82166 3.559C6.79658 3.54555 6.76851 3.53889 6.74194 3.52869C6.68711 3.50764 6.63907 3.47027 6.60372 3.42352C6.592 3.40802 6.58076 3.37489 6.56755 3.34416C6.55324 3.31064 6.53653 3.27992 6.51437 3.27818Z" fill="#FF6961"/>
            <path d="M5.62442 6.30023C5.64736 6.37262 5.67172 6.43417 5.7732 6.4426C5.94014 6.45666 6.10597 6.4899 6.27087 6.52288C6.38358 6.54537 6.39499 6.53307 6.42167 6.42138C6.46364 6.24632 6.5145 6.07244 6.57432 5.90257C6.64661 5.69793 6.74026 5.50066 6.8092 5.29507C6.86489 5.12985 6.90004 4.95729 6.94011 4.78713C7.00463 4.51145 7.03102 4.23205 7.00468 3.94946C6.99875 3.88613 6.96539 3.82945 6.93999 3.77228C6.96068 3.81881 6.78044 4.04813 6.75084 4.09046C6.67594 4.19748 6.59275 4.29865 6.50266 4.39321C6.32213 4.58267 6.11374 4.74542 5.88708 4.8761C5.41338 5.14922 4.94848 5.2124 4.41735 5.22156C4.30283 5.2235 4.1885 5.34042 4.20241 5.44411C4.21669 5.54939 4.25695 5.64633 4.337 5.72003C4.44352 5.81805 4.55261 5.91286 4.66026 6.00922C4.80025 6.13489 4.93964 6.26139 5.08013 6.38652C5.0924 6.39743 5.10952 6.40923 5.12494 6.40963C5.25487 6.41272 5.38496 6.41338 5.5274 6.41488C5.41115 6.01749 5.10532 5.8272 4.77607 5.65487C4.91091 5.66217 5.04025 5.67664 5.16775 5.70119C5.34294 5.73509 5.47011 5.83781 5.53638 6.00338C5.57443 6.09879 5.59295 6.20198 5.62442 6.30023ZM4.54176 5.41439C4.60284 5.40626 4.66451 5.38853 4.72496 5.3921C5.04554 5.41067 5.36016 5.39552 5.67433 5.31419C5.9569 5.24127 6.20581 5.10989 6.43949 4.94485C6.53714 4.87588 6.62274 4.78996 6.72667 4.70047C6.74711 4.86977 6.6745 4.99503 6.59233 5.09399C6.48014 5.22953 6.34872 5.36706 6.19563 5.44643C5.93603 5.58118 5.65525 5.65003 5.35037 5.57247C5.08771 5.50552 4.81618 5.47294 4.54176 5.41439Z" fill="#FF6961"/>
            <path d="M6.69719 6.49614C6.69809 6.49404 6.69894 6.49194 6.69976 6.48981C6.71804 6.44225 6.71826 6.38428 6.7274 6.33445C6.762 6.14548 6.81138 5.95051 6.89459 5.77666C6.96795 5.62344 7.09254 5.49037 7.18826 5.34806C7.39414 5.04197 7.56991 4.70095 7.59357 4.32637C7.61057 4.0574 7.54792 3.78934 7.46302 3.53354C7.41906 3.40113 7.36574 3.26568 7.26332 3.17094C7.16695 3.08177 6.78757 2.99388 6.75429 3.19505C6.73293 3.3242 6.95337 3.43721 7.01443 3.5294C7.13505 3.71158 7.19697 3.93127 7.20649 4.14852C7.22159 4.4923 7.11955 4.83027 6.9981 5.15225C6.90668 5.39463 6.80321 5.63291 6.72704 5.88078C6.66117 6.09511 6.56007 6.3499 6.65097 6.57165C6.64635 6.55967 6.69154 6.50928 6.69719 6.49614Z" fill="#FF6961"/>
            <path d="M6.92506 6.61256C7.26105 6.60374 7.59589 6.57564 7.93123 6.54579C8.04195 6.53591 8.15714 6.51161 8.25819 6.46523C8.35512 6.42074 8.43903 6.35595 8.49342 6.26419C8.56907 6.13658 8.57349 5.97904 8.55603 5.83173C8.52822 5.59741 8.44809 5.37002 8.32624 5.16818C8.23918 5.02398 8.04298 4.71304 7.85872 4.68326C7.77726 4.67009 7.78418 4.72417 7.74959 4.80072C7.62042 5.08665 7.41287 5.32713 7.22977 5.57878C7.15322 5.68398 6.6249 6.6211 6.92506 6.61256Z" fill="#FF6961"/>
            <path d="M4.43504 7.16871C4.65786 7.10288 4.88507 7.04887 5.11624 7.02262C5.23268 7.0094 5.34999 7.00331 5.46715 7.00649C5.58189 7.00961 5.68325 7.04089 5.79488 7.06471C5.81592 7.06919 5.85194 7.06586 5.83889 7.09388C5.83199 7.10869 5.65444 7.11165 5.63069 7.11444C5.55854 7.12291 5.48655 7.13279 5.4148 7.14405C5.27125 7.16655 5.12858 7.19455 4.98718 7.22799C4.71468 7.2924 4.45388 7.38267 4.19256 7.48068C4.14547 7.49833 4.05624 7.55137 4.00409 7.54274C4.01262 7.55342 4.01685 7.56006 4.02167 7.56406C4.02248 7.56463 4.02333 7.56521 4.02417 7.56575C4.25432 7.72221 4.51994 7.83108 4.77547 7.93663C5.02554 8.03994 5.28127 8.12951 5.54112 8.20492C5.80624 8.2819 6.07566 8.34417 6.3477 8.39126C6.48513 8.41505 6.6232 8.435 6.76176 8.45103C6.89392 8.46632 7.02026 8.46552 7.15168 8.47104C7.42525 8.48243 7.69518 8.44539 7.9603 8.37807C8.04392 8.35693 8.12872 8.32562 8.21265 8.28606C8.47211 8.62687 8.80026 9.14776 9.06136 9.68625L9.45933 9.66284C9.17272 9.07178 9.05838 8.231 8.7799 7.86505C9.01444 7.6098 9.17433 7.29169 9.16793 6.97044C9.16509 6.82947 9.04749 6.47972 8.86177 6.46179C8.8394 6.4596 8.81604 6.46227 8.79176 6.47068C7.67151 6.85901 6.55816 6.76792 5.39549 6.66833C5.26158 6.66592 5.12764 6.66857 4.99375 6.67771C4.70987 6.69715 4.42656 6.75026 4.16078 6.85364C3.90834 6.95186 3.66884 7.08435 3.4864 7.28906C3.42062 7.36285 3.38398 7.4188 3.36616 7.5143C3.54404 7.48334 3.72472 7.40296 3.89564 7.3447C4.0747 7.28374 4.25363 7.22231 4.43504 7.16871Z" fill="#FF6961"/>
            <path d="M8.9758 3.91987C8.91777 4.02509 8.91651 4.15118 8.91669 4.27131C8.91695 4.43929 8.97302 6.19606 8.87291 6.20139C9.19269 6.1854 9.33091 6.39083 9.39133 6.6814C9.40915 6.6681 9.417 6.66424 9.42187 6.65815C9.64271 6.38493 9.73526 6.05159 9.85649 5.73132C9.92099 5.56065 9.9874 5.39019 10.0642 5.22499C10.2202 4.89044 10.4372 4.59697 10.692 4.33054C10.7832 4.2353 10.7726 4.15681 10.6567 4.0918C10.5665 4.04098 10.4698 4.00188 10.3765 3.95618C10.1753 3.85726 9.95729 3.8246 9.73924 3.78915C9.53338 3.75571 9.09616 3.70157 8.9758 3.91987Z" fill="#FF6961"/>
             <path d="M5.81252 3.30112C5.95413 3.22821 6.13717 3.10795 6.15906 3.08762C6.30894 2.9487 6.39701 2.76648 6.56026 2.63786C6.75049 2.48802 6.97623 2.38976 7.2217 2.39624C7.43907 2.40196 7.65648 2.41512 7.87359 2.42636C7.89476 2.4274 7.91501 2.43875 7.96125 2.45333C7.88023 2.4811 7.82553 2.50804 7.76772 2.51794C7.59092 2.54798 7.40973 2.55862 7.23648 2.60261C7.12978 2.62978 7.04032 2.70463 6.94669 2.759C6.98299 2.80172 7.02188 2.80446 7.07526 2.8206C7.13723 2.83933 7.19722 2.86456 7.25408 2.8955C7.36779 2.95737 7.46909 3.04233 7.5485 3.14465C7.63302 3.25354 7.69236 3.3815 7.72266 3.51588C7.75448 3.65685 7.74467 3.80236 7.76188 3.94527C7.77504 4.05451 7.78718 4.16409 7.80981 4.27142C7.85017 4.46434 7.99079 4.58217 8.14105 4.69025C8.16851 4.7099 8.19857 4.72632 8.22932 4.74543C8.20311 4.59683 8.16845 4.46068 8.16759 4.30779C8.16677 4.15514 8.18181 4.00305 8.1921 3.85088C8.20907 3.60023 8.21117 3.33956 8.11142 3.10899C8.07214 3.01822 8.03431 2.91043 8.13762 2.84513C8.21896 2.92224 8.29765 3.00008 8.35285 3.09888C8.35553 3.10228 8.35791 3.10608 8.36039 3.10959C8.51409 3.32743 8.41211 3.62456 8.39118 3.86542C8.3677 4.13596 8.37125 4.40978 8.41507 4.67815C8.42121 4.7157 8.42813 4.75307 8.43584 4.79034C8.46792 4.94491 8.51383 5.09625 8.56635 5.24504C8.59318 5.321 8.62175 5.39632 8.65114 5.47134C8.67578 5.53421 8.69739 5.63846 8.74132 5.68782C8.7583 5.67342 8.75224 5.62757 8.75014 5.60538C8.73638 5.46095 8.71244 5.31677 8.70883 5.17205C8.70029 4.84489 8.69431 4.51766 8.68843 4.19059C8.68263 3.86868 8.73341 3.55322 8.75738 3.23309C8.78198 2.90407 8.76665 2.55848 8.60994 2.26815C8.45323 1.97782 8.12316 1.76059 7.79931 1.82367C7.35574 1.91008 6.89509 2.05399 6.4863 2.2463C6.0649 2.44454 5.47037 2.78682 5.64512 3.34726C5.65327 3.37318 5.72498 3.34618 5.81252 3.30112Z" fill="#FF6961"/>
            <path d="M8.26715 1.89812C8.26509 1.89621 8.26296 1.8945 8.26089 1.89261C8.16373 1.82225 8.05326 1.77591 7.93028 1.78039C7.88461 1.78203 7.84002 1.78956 7.79418 1.79703C7.78395 1.80038 7.7737 1.80392 7.76336 1.80789C7.76 1.80925 7.75654 1.81042 7.75317 1.81177C7.81205 1.7969 7.87183 1.78639 7.93238 1.78423C8.05757 1.77967 8.16874 1.82567 8.26715 1.89812Z" fill="#FF6961"/>
        </symbol>

        <symbol id="category_2" viewBox="0 0 17 19">
            <path d="M14.426 11.3083C14.2899 11.8091 14.0836 12.2882 13.8135 12.7314C13.226 13.7144 12.3655 14.5055 11.3366 15.0085C11.2236 15.0631 11.0999 15.092 10.9744 15.0933C9.38511 15.0989 7.7954 15.0989 6.20526 15.0933C6.10734 15.0957 6.01012 15.0763 5.92063 15.0365C4.3961 14.2814 3.35642 13.1005 2.82025 11.4822C2.80328 11.4309 2.79522 11.3766 2.77783 11.3062L14.426 11.3083Z" fill="#F99F4A"/>
                                <path d="M2.6641 10.8794C2.57927 10.4493 2.51352 10.0353 2.57927 9.59033H14.6304C14.6898 10.0264 14.6079 10.4455 14.5337 10.8794H2.6641Z" fill="#F99F4A"/>
                                <path d="M8.8953 6.97818C8.52499 6.68507 8.41131 6.27955 8.56995 5.8613C8.63636 5.68292 8.72395 5.51316 8.83083 5.35567C9.16466 4.87294 9.18502 4.3758 8.94027 3.85447C8.90803 3.78533 8.883 3.71279 8.85034 3.63135C9.29871 3.89562 9.58079 4.57898 9.381 5.07698C9.31265 5.23883 9.23475 5.39649 9.1477 5.5491C9.08025 5.67254 8.99796 5.78791 8.92415 5.90796C8.8459 6.03033 8.79597 6.16863 8.77799 6.31276C8.76001 6.45689 8.77444 6.60322 8.82022 6.74106C8.8461 6.81996 8.87155 6.89886 8.8953 6.97818Z" fill="#F99F4A"/>
                                <path d="M7.83416 1.95375C7.70035 1.84897 7.65927 1.704 7.71659 1.55449C7.74059 1.49072 7.77224 1.43004 7.81086 1.37374C7.93149 1.20117 7.93884 1.02345 7.8504 0.837086C7.83876 0.812369 7.82971 0.786439 7.81791 0.757324C7.97992 0.851795 8.08185 1.09609 8.00966 1.27411C7.98496 1.33197 7.95681 1.38833 7.92536 1.44288C7.90099 1.48701 7.87125 1.52826 7.84458 1.57117C7.81631 1.61491 7.79826 1.66435 7.79177 1.71588C7.78527 1.7674 7.79048 1.81971 7.80703 1.86899C7.81638 1.89719 7.82557 1.9254 7.83416 1.95375Z" fill="#F99F4A"/>
                                <path d="M8.34685 2.80825C8.21304 2.70346 8.17196 2.5585 8.22929 2.40898C8.25329 2.34521 8.28493 2.28453 8.32355 2.22823C8.44418 2.05566 8.45154 1.87794 8.3631 1.69158C8.35145 1.66686 8.34241 1.64093 8.33061 1.61182C8.49262 1.70629 8.59455 1.95058 8.52235 2.1286C8.49766 2.18646 8.46951 2.24282 8.43805 2.29738C8.41368 2.3415 8.38395 2.38275 8.35728 2.42566C8.329 2.46941 8.31096 2.51885 8.30446 2.57037C8.29797 2.62189 8.30318 2.6742 8.31972 2.72348C8.32907 2.75169 8.33827 2.77989 8.34685 2.80825Z" fill="#F99F4A"/>
                                <path d="M7.73463 4.85933C7.53392 4.70964 7.4723 4.50255 7.55829 4.28895C7.59428 4.19786 7.64176 4.11117 7.69969 4.03073C7.88063 3.78421 7.89167 3.53033 7.759 3.26409C7.74153 3.22878 7.72797 3.19174 7.71026 3.15015C7.95328 3.2851 8.10618 3.63409 7.99789 3.88841C7.96084 3.97107 7.91862 4.05158 7.87143 4.12952C7.83488 4.19255 7.79027 4.25148 7.75027 4.31278C7.70786 4.37528 7.68079 4.4459 7.67105 4.51951C7.6613 4.59312 7.66912 4.66784 7.69394 4.73824C7.70796 4.77853 7.72176 4.81882 7.73463 4.85933Z" fill="#F99F4A"/>
                                <path d="M9.52098 5.41309C9.7712 5.56962 9.95609 5.81166 10.0413 6.09425C10.1265 6.37683 10.1062 6.68074 9.98419 6.94949C9.89891 7.13541 9.79592 7.31269 9.67666 7.47888C9.41324 7.84919 9.35258 8.23647 9.53201 8.65727C9.54375 8.68318 9.54526 8.71258 9.53625 8.73956C9.25586 8.48759 9.08152 8.17921 9.17739 7.79914C9.23932 7.55099 9.37888 7.32235 9.48535 7.08608C9.57019 6.90029 9.67623 6.72255 9.73519 6.52955C9.82003 6.2551 9.73731 5.98489 9.64399 5.72359C9.60666 5.61839 9.56213 5.51447 9.52098 5.41309Z" fill="#F99F4A"/>
                                <path d="M7.55529 8.76886C7.16504 8.56143 6.99112 8.01932 7.16886 7.60744C7.22987 7.48032 7.30079 7.35821 7.38095 7.24221C7.45688 7.12302 7.54469 7.01188 7.62019 6.89268C7.7192 6.7451 7.78033 6.57539 7.79816 6.39857C7.81599 6.22175 7.78998 6.04325 7.72242 5.87888C7.64988 5.69054 7.55571 5.51068 7.46791 5.32446C7.8781 5.60358 8.12031 6.27931 7.97693 6.76415C7.92736 6.91119 7.86769 7.05462 7.79835 7.19343C7.72497 7.35377 7.64479 7.51072 7.56717 7.66937C7.43958 7.92529 7.41469 8.22031 7.4976 8.49399C7.5222 8.57755 7.53408 8.66451 7.55529 8.76886Z" fill="#F99F4A"/>
                                <path d="M2.47018 4.0293C2.41917 4.11369 2.36941 4.19872 2.31714 4.28122C1.39005 5.74869 0.750158 7.327 0.663243 9.07348C0.518386 11.9706 1.83974 14.0925 4.26578 15.5568C4.99447 15.9977 5.81008 16.2924 6.63829 16.6785C6.80393 17.1691 6.99539 17.7334 7.20512 18.3506C6.27821 18.2341 5.38115 17.9457 4.5599 17.5004C2.8298 16.5878 1.55632 15.2331 0.745118 13.4576C-0.923259 9.79713 0.454775 5.83749 2.47018 4.0293Z" fill="#F99F4A"/>
                                <path d="M10.7363 18.1322C11.0771 17.9571 11.3913 17.8173 11.6861 17.6409C14.0838 16.2264 15.6495 14.1877 16.1137 11.4247C16.5224 8.99611 15.7289 6.93221 13.8848 5.30036C13.4779 4.93947 13.129 4.55907 12.8739 4.08167C12.7133 3.78188 12.5099 3.50413 12.3203 3.20811L13.3003 2.97949C15.618 4.41862 17.3865 7.55824 16.9268 11.0411C16.3562 15.3648 13.0169 17.6409 10.7363 18.1322Z" fill="#F99F4A"/>
        </symbol>

        <symbol id="category_3" viewBox="0 0 15 21">
            <path d="M12.0163 8.72791C11.6655 8.51347 11.3343 8.29173 10.986 8.10178C10.4765 7.82253 9.94002 7.61194 9.35057 7.57384C9.30055 7.57071 9.24997 7.56826 9.19961 7.56912C9.03318 7.57179 8.94569 7.63157 8.89054 7.78967C8.79209 8.0709 8.69723 8.3535 8.60134 8.63571C8.23995 9.69934 7.88031 10.763 7.51712 11.826C7.28244 12.5102 6.62044 12.9281 5.92135 12.7929C5.25916 12.6644 4.80116 12.256 4.59483 11.6108C4.42235 11.0739 4.71788 10.5973 5.27233 10.4821C5.78929 10.3743 6.25032 10.5302 6.67469 10.8189C6.76667 10.8867 6.8548 10.9596 6.93866 11.0372C7.08689 11.1651 7.25514 11.1252 7.3185 10.9393C7.7409 9.70367 8.16216 8.46744 8.58229 7.23059C8.67415 6.96128 8.76591 6.69223 8.85617 6.42173C8.93947 6.17184 9.01983 6.12849 9.27756 6.19915C10.0662 6.41464 10.7192 6.84274 11.25 7.46056C11.5708 7.83978 11.8295 8.26755 12.0163 8.72791Z" fill="#E5E558"/>
                                <path d="M2.17957 5.35474C2.13456 5.4292 2.09066 5.50422 2.04453 5.57702C1.22651 6.87185 0.661904 8.26448 0.585215 9.80548C0.457399 12.3618 1.6233 14.234 3.76392 15.526C4.40689 15.915 5.12654 16.1751 5.85731 16.5158C6.00346 16.9487 6.1724 17.4466 6.35746 17.9912C5.53959 17.8883 4.74807 17.6339 4.02344 17.241C2.49688 16.4358 1.37322 15.2404 0.657457 13.6738C-0.81464 10.444 0.401272 6.9502 2.17957 5.35474Z" fill="#E5E558"/>
                                <path d="M9.47363 17.7982C9.77428 17.6438 10.0516 17.5204 10.3117 17.3648C12.4273 16.1166 13.8088 14.3178 14.2184 11.8798C14.579 9.737 13.8788 7.91591 12.2517 6.47604C11.8927 6.15762 11.5848 5.82196 11.3597 5.40073C11.218 5.13621 11.0385 4.89114 10.8713 4.62995L11.736 4.42822C13.781 5.69804 15.3415 8.46829 14.9358 11.5414C14.4323 15.3564 11.4859 17.3648 9.47363 17.7982Z" fill="#E5E558"/>
                                <path d="M2.73995 3.96246C2.5685 4.09226 2.44345 4.20904 2.30033 4.29251C1.97305 4.48118 1.62135 4.4503 1.45039 4.23606C1.27942 4.02181 1.34781 3.66233 1.62135 3.39163C2.04437 2.97327 2.65105 3.05916 2.91678 3.59429C3.23917 4.24329 3.54496 4.90002 3.84488 5.56012C3.93085 5.75024 4.02366 5.80525 4.23565 5.74927C4.92684 5.56736 5.62438 5.40861 6.3185 5.23827C6.59937 5.16927 6.63405 5.09351 6.50949 4.82764C6.3483 4.48601 6.18613 4.14389 6.02102 3.80419C5.92675 3.61118 5.88278 3.60587 5.72501 3.75883C5.52034 3.95764 5.28538 4.09226 4.9923 4.08792C4.63132 4.0831 4.42665 3.80853 4.52239 3.46642C4.61813 3.12431 4.94638 2.83913 5.28831 2.81259C5.62096 2.78509 5.89842 2.96266 6.06987 3.31732C6.43915 4.08712 6.8073 4.85691 7.1743 5.62671C7.29691 5.88438 7.25246 5.97317 6.97354 6.02866C6.07231 6.2072 5.16961 6.37753 4.27131 6.56668C4.07983 6.60673 3.99288 6.55944 3.91424 6.39104C3.57768 5.66724 3.23038 4.95165 2.88552 4.23268C2.83765 4.14824 2.79271 4.06572 2.73995 3.96246Z" fill="#E5E558"/>
                                <path d="M10.3341 11.9889C10.453 11.9731 10.5458 11.9508 10.6381 11.9498C10.8485 11.9484 11.0091 12.0608 11.0313 12.2125C11.0535 12.3642 10.9199 12.5204 10.7124 12.5766C10.3916 12.6633 10.1237 12.4535 10.1458 12.1193C10.1725 11.7139 10.2094 11.3093 10.25 10.9047C10.2619 10.7883 10.2327 10.7358 10.115 10.7044C9.73141 10.6017 9.35126 10.486 8.96951 10.3768C8.81501 10.3327 8.7771 10.36 8.76254 10.524C8.74438 10.735 8.72656 10.9466 8.71082 11.1578C8.70211 11.2779 8.72178 11.2926 8.84056 11.2618C8.99476 11.2216 9.14554 11.2211 9.28536 11.3043C9.45772 11.4066 9.47928 11.597 9.33735 11.7371C9.19541 11.8773 8.95754 11.9253 8.78556 11.8436C8.61778 11.765 8.53402 11.6017 8.55089 11.3814C8.58889 10.9042 8.62743 10.4273 8.66652 9.95072C8.67972 9.79126 8.72599 9.76032 8.87576 9.81049C9.35947 9.97297 9.84159 10.1399 10.3269 10.2964C10.4302 10.3299 10.4588 10.377 10.4495 10.4808C10.4086 10.9265 10.3752 11.3712 10.3397 11.8169C10.3391 11.8713 10.3376 11.924 10.3341 11.9889Z" fill="#E5E558"/>
                                <path d="M8.02388 14.2056C7.71811 14.475 7.40548 14.7317 7.117 15.013C6.97788 15.1598 6.85896 15.3245 6.76339 15.5027C6.59538 15.7895 6.62076 15.8743 6.87472 16.0822C7.5545 16.6399 8.23318 17.1986 8.91076 17.7584C9.17983 17.9799 9.31871 18.2613 9.28755 18.6146C9.23665 19.192 8.59334 19.6709 8.02328 19.5583C7.67308 19.4891 7.4767 19.1861 7.55796 18.8255C7.64181 18.4515 7.87925 18.1936 8.20188 18.006C8.45944 17.8564 8.4653 17.8029 8.23246 17.6079C7.47272 16.9717 6.7113 16.3359 5.94821 15.7003C5.70678 15.4988 5.69969 15.459 5.90783 15.2115C6.46033 14.5564 7.17738 14.2482 8.02388 14.2056Z" fill="#E5E558"/>
                                <path d="M7.69152 20.8316C8.01828 18.5694 7.99314 13.1855 5.27855 9.74699C1.88532 5.44889 2.71478 3.41295 2.56396 3.11133" stroke="#E5E558" stroke-width="0.5"/>
                                <path d="M9.19958 20.2283C9.52633 17.9661 9.5012 12.5822 6.78661 9.14371C3.39337 4.84562 4.22283 2.80968 4.07202 2.50806" stroke="#E5E558" stroke-width="0.5"/>
                                <path d="M10.7833 16.7596C10.7833 14.4221 10.4063 11.8583 8.06874 8.61585C4.86631 4.17377 5.73118 2.20641 5.58037 1.90479" stroke="#E5E558" stroke-width="0.5"/>
                                <path d="M12.2911 15.4778C12.2911 13.2156 11.8387 11.2551 9.65191 8.08805C6.54044 3.58178 7.23895 1.75401 7.08813 1.45239" stroke="#E5E558" stroke-width="0.5"/>
                                <path d="M13.4978 13.5927C13.1962 11.4059 13.1208 10.5011 10.9341 7.33404C7.82259 2.82777 8.74732 1.30162 8.59651 1" stroke="#E5E558" stroke-width="0.5"/>
        </symbol>

        <symbol id="category_4" viewBox="0 0 17 18">
            <path d="M2.36781 3.92505C2.31891 4.00595 2.27121 4.08745 2.2211 4.16653C1.33244 5.57318 0.719068 7.08608 0.635756 8.76018C0.496902 11.5373 1.76349 13.5712 4.08899 14.9748C4.78748 15.3974 5.56929 15.6799 6.36317 16.05C6.52195 16.5203 6.70548 17.0612 6.90651 17.6529C6.01801 17.5411 5.15813 17.2647 4.37092 16.8378C2.71253 15.9631 1.49182 14.6645 0.714238 12.9626C-0.884995 9.45384 0.435928 5.65831 2.36781 3.92505Z" fill="#8DF36B"/>
                                <path d="M10.2917 17.4432C10.6184 17.2753 10.9196 17.1413 11.2021 16.9723C13.5005 15.6163 15.0013 13.6621 15.4462 11.0136C15.8381 8.68572 15.0774 6.70735 13.3097 5.14314C12.9197 4.79721 12.5853 4.43257 12.3407 3.97495C12.1868 3.68759 11.9918 3.42135 11.8101 3.1376L12.7495 2.91846C14.9711 4.29794 16.6663 7.30744 16.2256 10.646C15.6787 14.7905 12.4778 16.9723 10.2917 17.4432Z" fill="#8DF36B"/>
                                <path d="M7.33539 15.9035C7.22364 15.9035 7.1119 15.9062 7.0006 15.9035C6.81824 15.8982 6.78293 15.8758 6.80572 15.6912C6.83612 15.4396 6.8303 15.1861 6.8598 14.9358C6.8817 14.7964 6.89143 14.6554 6.88886 14.5143C6.8683 14.171 6.94115 13.8331 6.93892 13.4899C6.93541 13.1927 6.91661 12.896 6.8826 12.6008C6.85087 12.3076 6.72571 12.0452 6.56257 11.7998C6.0919 11.0932 5.41921 10.6663 4.63387 10.3919C4.13308 10.2212 3.61506 10.1059 3.08913 10.0482C2.97873 10.0347 2.86877 10.016 2.75837 10.0035C2.6873 9.99497 2.62427 9.97709 2.60908 9.89619C2.59388 9.81528 2.65377 9.77863 2.7159 9.74511C3.0632 9.55157 3.43821 9.51804 3.82083 9.5757C4.32367 9.6508 4.80417 9.81394 5.2802 9.98558C5.63778 10.1152 6.00385 10.2225 6.38154 10.26C6.69442 10.2909 6.97155 10.2019 7.1414 9.89976C7.26017 9.69229 7.32718 9.45924 7.33673 9.22036C7.34075 9.08627 7.33136 8.95218 7.34209 8.81808C7.34319 8.77289 7.35343 8.72839 7.3722 8.68726C7.39097 8.64613 7.41787 8.60923 7.45128 8.57879C7.4847 8.54834 7.52393 8.52497 7.56662 8.5101C7.60931 8.49522 7.65457 8.48915 7.69967 8.49224C7.85879 8.49671 7.98886 8.63259 8.00719 8.81987C8.03758 9.12605 7.97277 9.42731 7.94953 9.7308C7.93925 9.8649 7.92226 9.99631 7.92182 10.1304C7.92182 11.8951 7.92182 13.66 7.92182 15.4253C7.92182 15.4771 7.92182 15.5294 7.92182 15.5817C7.91824 15.8803 7.89589 15.9035 7.60893 15.9049L7.33539 15.9035Z" fill="#8DF36B"/>
                                <path d="M8.67628 15.9038C8.55694 15.9038 8.43804 15.9078 8.3187 15.9038C8.16226 15.8976 8.13633 15.873 8.13991 15.7156C8.14617 15.5453 8.18461 15.3782 8.18461 15.2052C8.1779 14.7292 8.17388 14.2522 8.18461 13.7749C8.20204 13.0762 8.24674 12.3781 8.22394 11.679C8.21756 11.4415 8.19681 11.2046 8.16181 10.9697C8.13754 10.8004 8.13035 10.6291 8.14036 10.4583C8.14448 10.4006 8.16256 10.3447 8.19304 10.2955C8.22352 10.2463 8.26548 10.2052 8.31534 10.1758C8.36519 10.1464 8.42144 10.1295 8.47925 10.1267C8.53706 10.1238 8.5947 10.135 8.64723 10.1593C8.67449 10.1719 8.69854 10.1905 8.71759 10.2137C8.73663 10.237 8.75017 10.2642 8.75718 10.2934C8.7952 10.4066 8.81246 10.5258 8.80814 10.6452C8.79205 10.8333 8.8278 11.0175 8.8649 11.1985C8.9467 11.6008 9.22919 11.907 9.69359 11.8851C9.84962 11.882 10.0049 11.8623 10.1567 11.8261C10.5804 11.7121 10.997 11.5744 11.4118 11.4314C11.8119 11.2879 12.2302 11.2008 12.6544 11.1726C12.9246 11.1571 13.1927 11.2292 13.4187 11.3782C13.4728 11.4135 13.5438 11.4457 13.5353 11.527C13.5255 11.6196 13.4424 11.6106 13.3802 11.6213C13.0508 11.6781 12.7209 11.7309 12.3915 11.7885C11.7877 11.8889 11.207 12.0975 10.6774 12.4044C10.0588 12.7683 9.58274 13.2684 9.32484 13.9492C9.2244 14.2222 9.17266 14.5107 9.17198 14.8016C9.1684 15.0979 9.14784 15.3956 9.20505 15.6919C9.23768 15.8596 9.1903 15.8967 9.01241 15.9043H8.67628V15.9038Z" fill="#8DF36B"/>
                                <path d="M3.18805 5.35864C3.35432 5.67599 3.66497 5.74662 3.95997 5.84495C4.09406 5.88965 4.22816 5.93435 4.35823 5.98843C4.82666 6.18018 5.06042 6.54089 5.09976 7.03614C5.1217 7.36374 5.06834 7.69201 4.94376 7.99579C4.89459 8.12229 4.81816 8.18352 4.67558 8.17726C4.25274 8.15805 3.87907 8.03379 3.56261 7.73834C3.20905 7.40802 2.95562 7.02765 2.88232 6.54223C2.83538 6.22935 2.84298 5.91915 2.97797 5.62459C3.01999 5.53118 3.05261 5.42882 3.18805 5.35864Z" fill="#8DF36B"/>
                                <path d="M12.7734 7.07446C12.9125 7.20521 13.0051 7.37787 13.0371 7.56613C13.1649 8.18877 13.0062 8.74615 12.6008 9.22709C12.3639 9.50779 12.0841 9.73977 11.7127 9.83408C11.5536 9.87476 11.3904 9.88191 11.2295 9.90873C11.1886 9.91739 11.1458 9.91058 11.1096 9.88962C11.0734 9.86866 11.0462 9.83502 11.0333 9.7952C10.8281 9.26553 10.7566 8.73274 11.0087 8.19681C11.0868 8.03438 11.2169 7.9027 11.3784 7.8227C11.5595 7.71953 11.7517 7.63721 11.9514 7.57731C12.2723 7.49417 12.5713 7.37483 12.7734 7.07446Z" fill="#8DF36B"/>
                                <path d="M9.26888 7.146C9.3994 7.36635 9.6135 7.45664 9.82134 7.55677C9.95543 7.62158 10.0895 7.68281 10.2035 7.78517C10.3707 7.93759 10.4717 8.11593 10.4717 8.35015C10.4739 8.62191 10.4529 8.88294 10.3099 9.1252C10.2411 9.24186 10.1601 9.26868 10.0337 9.24812C9.54601 9.16945 9.10573 8.72694 8.98505 8.20577C8.92246 7.94358 8.94053 7.6686 9.0369 7.41686C9.05464 7.35808 9.08442 7.30363 9.12437 7.257C9.16431 7.21036 9.21352 7.17256 9.26888 7.146Z" fill="#8DF36B"/>
                                <path d="M10.1703 1.19019C10.4761 1.46284 10.4832 1.80522 10.4282 2.13464C10.3357 2.68755 9.95802 3.12603 9.37918 3.24761C9.23794 3.27711 9.15123 3.25029 9.0882 3.1229C8.96679 2.89094 8.91115 2.63018 8.92729 2.36886C8.93264 2.24911 8.96615 2.1323 9.0251 2.02793C9.08404 1.92355 9.16676 1.83454 9.26655 1.76812C9.39873 1.67259 9.54381 1.59629 9.69743 1.54151C9.88337 1.48161 10.0327 1.35735 10.1703 1.19019Z" fill="#8DF36B"/>
                                <path d="M6.56399 6.55078C6.66588 6.6606 6.74281 6.79116 6.78949 6.93351C6.83617 7.07586 6.85149 7.22662 6.83441 7.37545C6.81711 7.59882 6.74697 7.81487 6.62978 8.00581C6.51259 8.19675 6.3517 8.35711 6.16037 8.47366C6.03989 8.54765 5.90532 8.59575 5.76524 8.61491C5.58333 8.64352 5.50466 8.59703 5.43404 8.42763C5.32431 8.17346 5.28942 7.89326 5.33347 7.61994C5.37504 7.36919 5.55427 7.20605 5.76346 7.08045C5.87118 7.01563 5.98695 6.96468 6.10137 6.9106C6.26988 6.83014 6.43124 6.74075 6.56399 6.55078Z" fill="#8DF36B"/>
                                <path d="M5.44262 3.1392C5.42698 3.38369 5.54543 3.58617 5.62588 3.79804C5.78724 4.22713 5.49805 4.73669 5.04928 4.8386C5.01354 4.84756 4.97599 4.84632 4.94091 4.83502C4.90583 4.82373 4.87462 4.80282 4.85083 4.77468C4.67204 4.60215 4.61214 4.3724 4.6108 4.14489C4.60565 3.96549 4.64485 3.78759 4.72492 3.62698C4.805 3.46636 4.92348 3.32798 5.06984 3.22412C5.16371 3.15484 5.27322 3.09495 5.44262 3.1392Z" fill="#8DF36B"/>
                                <path d="M3.86929 0.488325C4.03154 0.408764 4.18217 0.455696 4.32208 0.517378C4.78291 0.723881 5.08193 1.28439 4.93398 1.79036C4.86962 2.01385 4.8066 2.05318 4.58266 2.00178C4.5469 1.99373 4.51025 1.98614 4.47494 1.97586C3.98327 1.83327 3.79599 1.5414 3.88985 1.03989C3.93173 0.857437 3.92464 0.667156 3.86929 0.488325Z" fill="#8DF36B"/>
                                <path d="M7.1394 4.94383C7.33786 4.86829 7.49698 4.92953 7.63555 5.00104C8.04408 5.20844 8.38646 5.7162 8.20633 6.26732C8.14912 6.44298 8.10308 6.47829 7.91625 6.47025C7.42457 6.44924 7.09739 6.0492 7.16578 5.57272C7.19528 5.36443 7.26545 5.15212 7.1394 4.94383Z" fill="#8DF36B"/>
                                <path d="M8.27404 6.9168C8.21101 7.11213 8.24453 7.29226 8.27091 7.47686C8.35091 8.032 7.96428 8.21347 7.54144 8.29527C7.45205 8.3127 7.40422 8.24655 7.37159 8.17816C7.30877 8.05899 7.28088 7.92451 7.29114 7.79019C7.33986 7.36154 7.56469 7.01737 8.0063 6.89624C8.09525 6.87478 8.09525 6.87478 8.27404 6.9168Z" fill="#8DF36B"/>
                                <path d="M6.83865 2.82642C6.88737 2.98375 6.99599 3.0499 7.10281 3.1165C7.15332 3.14779 7.2074 3.17282 7.25612 3.2059C7.68924 3.50224 7.60745 3.92776 7.37681 4.27506C7.3111 4.37429 7.20964 4.36803 7.11756 4.33004C6.93563 4.26187 6.78153 4.13513 6.67953 3.96978C6.53516 3.72662 6.52175 3.46112 6.56778 3.19472C6.58009 3.11611 6.61112 3.04161 6.65827 2.97751C6.70541 2.91341 6.76728 2.86159 6.83865 2.82642Z" fill="#8DF36B"/>
                                <path d="M10.4831 4.42203C10.3741 4.58965 10.4487 4.7738 10.4769 4.95125C10.5569 5.46438 10.206 5.67445 9.83861 5.7558C9.73134 5.77905 9.62853 5.76743 9.57803 5.64451C9.34694 5.07372 9.74922 4.39924 10.3303 4.36795C10.3803 4.36527 10.4358 4.35767 10.4831 4.42203Z" fill="#8DF36B"/>
                                <path d="M6.38794 10.1141C6.1725 10.1051 5.97717 10.0162 5.77335 9.95943C5.70228 9.93931 5.63524 9.89819 5.56327 9.88702C5.46378 9.87466 5.36796 9.84163 5.28198 9.79006C5.196 9.73849 5.12175 9.66951 5.064 9.58754C4.92142 9.38641 4.7319 9.22773 4.57233 9.04179C4.50707 8.96536 4.38371 8.87998 4.46953 8.75528C4.55535 8.63057 4.68541 8.6927 4.79716 8.7222C4.897 8.75372 4.98797 8.8084 5.06266 8.88177C5.22759 9.03106 5.41085 9.1589 5.56149 9.32517C5.81581 9.60498 6.1202 9.83293 6.38794 10.1141Z" fill="#8DF36B"/>
                                <path d="M9.74414 11.7523C9.83845 11.5829 10.0123 11.4962 10.1464 11.3769C10.4763 11.0899 10.7641 10.7569 11.1164 10.4954C11.2285 10.4123 11.3398 10.3167 11.4914 10.3167C11.5673 10.3167 11.6411 10.3167 11.6831 10.398C11.7251 10.4793 11.6867 10.5375 11.6344 10.5969C11.4283 10.8298 11.2254 11.0649 11.0198 11.2982C10.9656 11.3696 10.8926 11.4244 10.8088 11.4564C10.4688 11.561 10.1282 11.6643 9.78705 11.7662C9.77766 11.7702 9.76247 11.7586 9.74414 11.7523Z" fill="#8DF36B"/>
                                <path d="M12.2277 5.59245C12.1504 5.75246 12.17 5.93751 12.1647 6.12077C12.1539 6.47835 11.7677 6.77067 11.42 6.69513C11.3876 6.68844 11.358 6.67202 11.3351 6.64806C11.3123 6.6241 11.2973 6.59374 11.2922 6.56104C11.2454 6.4031 11.2545 6.23385 11.3181 6.08189C11.4316 5.8137 11.7275 5.47981 12.1226 5.53389C12.1438 5.5332 12.1648 5.53828 12.1833 5.54859C12.2018 5.55891 12.2171 5.57407 12.2277 5.59245Z" fill="#8DF36B"/>
                                <path d="M9.98901 6.19734C10.2036 6.05163 10.3949 6.1455 10.5594 6.2483C10.6615 6.31071 10.7482 6.39538 10.8131 6.496C10.8779 6.59661 10.9192 6.71057 10.9339 6.82937C10.9674 7.06582 10.9008 7.14717 10.6684 7.14761C10.2863 7.14761 10.0994 6.97776 10.0663 6.58487C10.0552 6.45301 10.052 6.3216 9.98901 6.19734Z" fill="#8DF36B"/>
                                <path d="M6.84155 0.0691247C6.99889 -0.0435128 7.13253 0.00744288 7.25545 0.0418599C7.57995 0.131255 7.8097 0.496432 7.77617 0.831663C7.765 0.947429 7.7136 0.999725 7.59739 1.01045C7.18662 1.04889 6.94346 0.846413 6.92692 0.434749C6.92717 0.307907 6.89794 0.182743 6.84155 0.0691247Z" fill="#8DF36B"/>
                                <path d="M11.2241 11.3222C11.28 11.1783 11.398 11.126 11.4972 11.0541C11.7261 10.8909 11.9402 10.7041 12.2012 10.5883C12.2935 10.5401 12.394 10.5098 12.4976 10.4989C12.5348 10.4958 12.572 10.5044 12.6041 10.5236C12.6362 10.5428 12.6614 10.5715 12.6763 10.6057C12.6934 10.6394 12.6979 10.678 12.689 10.7147C12.6801 10.7513 12.6583 10.7836 12.6276 10.8055C12.4426 10.9664 12.2593 11.1283 12.0019 11.1721C11.7444 11.2159 11.482 11.2722 11.2241 11.3222Z" fill="#8DF36B"/>
                                <path d="M4.92178 9.6488C4.63826 9.62514 4.35645 9.58424 4.07789 9.52633C3.83385 9.48164 3.67562 9.31715 3.508 9.16071C3.48266 9.13804 3.46482 9.1082 3.45683 9.07515C3.44885 9.04209 3.45111 9.0074 3.46331 8.97566C3.49102 8.89967 3.55806 8.89118 3.6269 8.88627C3.73154 8.88191 3.83552 8.90486 3.92861 8.95286C4.29602 9.13076 4.60264 9.3985 4.92178 9.6488Z" fill="#8DF36B"/>
                                <path d="M12.5601 11.0158C12.7335 10.8504 12.9512 10.7579 13.1746 10.6837C13.2569 10.6569 13.3619 10.6578 13.3981 10.7695C13.4321 10.8741 13.3624 10.9358 13.2815 10.9836C13.1948 11.042 13.0932 11.0743 12.9887 11.0766C12.8501 11.0739 12.7111 11.0766 12.5726 11.0766C12.5685 11.056 12.565 11.0359 12.5601 11.0158Z" fill="#8DF36B"/>
                                <path d="M3.63101 9.42258C3.42004 9.42258 3.26539 9.41901 3.11074 9.42258C2.9905 9.42616 2.90021 9.35598 2.80143 9.30771C2.77442 9.29385 2.7536 9.27036 2.74309 9.24189C2.73258 9.21342 2.73314 9.18203 2.74466 9.15395C2.74772 9.13737 2.75403 9.12156 2.76323 9.10743C2.77242 9.0933 2.78432 9.08113 2.79824 9.07162C2.81215 9.0621 2.82781 9.05544 2.84432 9.052C2.86082 9.04856 2.87784 9.04843 2.8944 9.05159C2.99071 9.059 3.08482 9.08415 3.17197 9.12579C3.33638 9.20588 3.49051 9.30554 3.63101 9.42258Z" fill="#8DF36B"/>
        </symbol>

        <symbol id="category_5" viewBox="0 0 16 19">
            <path d="M5.79846 15.3625C5.54965 15.3422 5.2972 15.3293 5.05307 15.3002C4.18588 15.1974 3.52378 14.9644 3.08862 14.5903C3.12818 14.6016 3.1693 14.6121 3.2073 14.6243C4.19213 14.943 5.28939 15.0516 6.46681 15.0057C7.20335 14.9774 7.90553 14.8741 8.61448 14.7859C9.29533 14.7017 9.98033 14.6316 10.6919 14.6668C11.4664 14.7054 12.1577 14.8448 12.7943 15.0497C12.8135 15.056 12.8265 15.0668 12.8109 15.0585C12.0687 15.034 11.3014 15.0043 10.529 14.9837C9.94252 14.9676 9.35553 15.011 8.80812 15.111C8.00756 15.2575 7.18461 15.3474 6.32211 15.3505C6.27707 15.3526 6.23249 15.3564 6.18885 15.362L5.79846 15.3625Z" fill="#DCBCFF"/>
                                <path d="M11.7018 7.12742C11.3194 6.92121 10.9727 6.70323 10.6014 6.54161C10.0834 6.3036 9.49738 6.25966 8.9498 6.41776C8.60564 6.51807 8.40174 6.66979 8.30288 6.86424C8.69647 6.89953 9.11168 6.90387 9.51268 6.98003C10.1708 7.09586 10.784 7.39231 11.2841 7.83645C12.0027 8.48171 12.2023 9.27435 11.9274 10.1896C11.7723 10.7073 11.4022 11.3606 11.1427 11.599C11.0679 11.2454 11.0234 10.8763 10.9097 10.5302C10.4976 9.27249 9.70793 8.35229 8.44993 7.87423C8.10276 7.73629 7.71794 7.72663 7.36432 7.84698C5.66391 8.40802 4.65677 9.58088 4.28295 11.3222C4.26194 11.42 4.24526 11.5191 4.22116 11.656C4.17297 11.5693 4.1359 11.5142 4.10933 11.4541C3.81769 10.7921 3.5934 10.1122 3.59216 9.37714C3.59216 8.1789 4.25886 7.2748 5.40811 6.9243C5.83599 6.78849 6.28601 6.73676 6.73346 6.77197C6.76868 6.77506 6.80514 6.77197 6.8521 6.77197C6.56849 6.35397 5.75412 6.17068 5.05159 6.35026C4.3917 6.51931 3.91779 6.91997 3.60699 7.52683L3.552 7.51754C3.5656 7.35158 3.57486 7.185 3.59278 7.02214C3.69473 6.07779 4.29902 5.31488 5.12698 5.09257C5.67874 4.94457 6.21939 5.01826 6.73346 5.23809C7.04974 5.38104 7.35535 5.5466 7.64793 5.73349C7.7715 5.81027 7.85244 5.81027 7.97355 5.73039C8.48083 5.39538 9.02394 5.13963 9.63317 5.06098C10.3301 4.97119 10.877 5.21394 11.2539 5.81027C11.498 6.21095 11.651 6.66069 11.7018 7.12742Z" fill="#DCBCFF"/>
                                <path d="M7.86513 14.673C8.14107 15.9807 9.43664 17.982 10.8065 18.3865C10.6472 18.4858 10.1022 18.5088 9.19684 17.8068C8.06516 16.9292 7.40506 14.4417 7.19279 13.4358C7.02298 12.631 6.98536 9.88961 6.98778 8.61949C6.97009 8.53567 7.05652 8.36858 7.54377 8.37083C8.15282 8.37365 7.65167 10.7778 7.59016 11.4475C7.52865 12.1172 7.5202 13.0383 7.86513 14.673Z" fill="#DCBCFF"/>
                                <circle cx="4.71014" cy="3.39397" r="1.31268" fill="#DCBCFF"/>
                                <path d="M6.33081 2.39648C6.42012 2.33968 6.51791 2.27752 6.60424 2.22259C6.6482 2.19462 6.80153 2.09716 6.82407 2.0828" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.62524 4.55688C2.71455 4.50008 2.81234 4.43792 2.89868 4.38299C2.94263 4.35502 3.09597 4.25756 3.1185 4.2432" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.33155 4.24316C6.4205 4.30051 6.51787 4.36332 6.60389 4.41875C6.64769 4.44698 6.80036 4.54546 6.82283 4.55992" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3.39747 1.30908C3.45909 1.39513 3.52654 1.48935 3.58614 1.57253C3.61649 1.61488 3.72224 1.76262 3.73782 1.78433" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5.86842 4.86108C5.93004 4.94713 5.99749 5.04135 6.05709 5.12453C6.08743 5.16689 6.19319 5.31462 6.20876 5.33633" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5.95582 1.30909C5.91204 1.40545 5.86408 1.51093 5.82177 1.60411C5.80023 1.65155 5.72504 1.81694 5.71401 1.84128" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3.79371 5.01563C3.74993 5.11199 3.70197 5.21747 3.65966 5.31065C3.63812 5.35809 3.56293 5.52349 3.5519 5.54782" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M4.64527 1C4.64504 1.10584 4.64477 1.22171 4.64457 1.32404C4.64446 1.37615 4.64403 1.55783 4.644 1.58455" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M4.64527 5.16992C4.64504 5.27576 4.64477 5.39164 4.64457 5.49397C4.64446 5.54607 4.64403 5.72775 4.644 5.75447" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.48608 3.31664C6.59189 3.31942 6.70772 3.32249 6.81002 3.32516C6.8621 3.32652 7.04372 3.33134 7.07043 3.33201" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.31641 3.16234C2.42197 3.17007 2.53753 3.17854 2.63959 3.18598C2.69155 3.18977 2.87275 3.20307 2.8994 3.20499" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.62525 2.08129C2.7142 2.13865 2.81158 2.20146 2.89759 2.25689C2.94139 2.28511 3.09407 2.3836 3.11653 2.39806" stroke="#DCBCFF" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.23192 4.84155C2.18582 4.91781 2.14087 4.99463 2.09363 5.06918C1.25597 6.3951 0.677801 7.82118 0.59927 9.39919C0.468385 12.0169 1.66228 13.9341 3.85432 15.2571C4.51273 15.6555 5.24967 15.9218 5.99799 16.2706C6.14765 16.7139 6.32065 17.2238 6.51014 17.7815C5.67264 17.6762 4.8621 17.4157 4.12007 17.0133C2.55685 16.1887 1.4062 14.9646 0.673247 13.3604C-0.834205 10.053 0.41091 6.47534 2.23192 4.84155Z" fill="#DCBCFF"/>
                                <path d="M9.70093 17.584C10.0088 17.4258 10.2928 17.2994 10.5591 17.1401C12.7255 15.862 14.1402 14.0199 14.5596 11.5234C14.9289 9.3291 14.2119 7.46427 12.5457 5.98983C12.1781 5.66375 11.8628 5.32004 11.6323 4.88869C11.4872 4.61781 11.3034 4.36685 11.1321 4.09939L12.0176 3.89282C14.1117 5.19314 15.7097 8.02992 15.2943 11.1769C14.7787 15.0835 11.7615 17.1401 9.70093 17.584Z" fill="#DCBCFF"/>
        </symbol>

        <symbol id="category_6" viewBox="0 0 17 22">
            <path d="M2.71061 5.27271C2.66313 5.35126 2.61682 5.4304 2.56816 5.5072C1.70523 6.87311 1.10962 8.3422 1.02872 9.96782C0.89389 12.6645 2.1238 14.6395 4.38196 16.0025C5.06022 16.4128 5.81939 16.6872 6.59028 17.0465C6.74446 17.5032 6.92267 18.0285 7.11789 18.603C6.25512 18.4945 5.42013 18.2261 4.65572 17.8116C3.04535 16.9621 1.86 15.7011 1.10493 14.0486C-0.44799 10.6414 0.834681 6.95577 2.71061 5.27271Z" fill="#76D6FF"/>
                                <path d="M10.4048 18.3995C10.7219 18.2365 11.0145 18.1064 11.2888 17.9422C13.5206 16.6256 14.978 14.7279 15.41 12.1562C15.7905 9.89566 15.0518 7.97459 13.3353 6.45567C12.9566 6.11976 12.6319 5.76567 12.3944 5.32131C12.245 5.04227 12.0556 4.78374 11.8792 4.50821L12.7913 4.29541C14.9486 5.63494 16.5948 8.5573 16.1668 11.7991C15.6357 15.8236 12.5275 17.9422 10.4048 18.3995Z" fill="#76D6FF"/>
                                <path d="M16.5324 16.7851H0C0.0291175 16.7289 0.052145 16.6795 0.0793594 16.6328C0.363683 16.1432 0.65029 15.655 0.933091 15.1646C1.23473 14.6416 1.532 14.116 1.83326 13.5928C2.06677 13.1871 2.30466 12.7838 2.53874 12.3784C2.76711 11.9845 2.98977 11.5886 3.21739 11.1947C3.45642 10.7812 3.69925 10.3695 3.93828 9.95579C4.25972 9.39989 4.5781 8.84228 4.89935 8.28619C5.1302 7.88654 5.36504 7.48917 5.59531 7.08914C5.94092 6.48871 6.28348 5.88657 6.6287 5.28671C6.95736 4.71692 7.28965 4.14942 7.61831 3.5802C7.79987 3.26619 7.97876 2.95046 8.15955 2.63588C8.18905 2.5845 8.2216 2.53501 8.26461 2.4646L16.5324 16.7851ZM8.26442 3.39179C8.23397 3.43918 8.21227 3.47001 8.19362 3.50236C7.9145 3.98727 7.63538 4.47231 7.35625 4.95747C6.97563 5.61722 6.59451 6.27696 6.21287 6.9367C5.91726 7.44902 5.62215 7.96159 5.32755 8.47441C5.04348 8.96757 4.75909 9.4606 4.47439 9.9535C4.13183 10.5476 3.78857 11.1417 3.44462 11.7358C3.14545 12.2534 2.84628 12.771 2.54711 13.2887C2.31741 13.6853 2.0858 14.081 1.8561 14.4777C1.56302 14.984 1.27051 15.4906 0.978576 15.9978C0.919389 16.1003 0.861915 16.2041 0.796258 16.3201H15.7166C15.7102 16.294 15.7015 16.2685 15.6905 16.244C15.512 15.9348 15.3328 15.6259 15.1529 15.3172C14.9211 14.9179 14.6894 14.5183 14.4579 14.1183C14.1795 13.6361 13.9017 13.154 13.6243 12.6719C13.4409 12.3541 13.2555 12.0374 13.0724 11.7203C12.844 11.3234 12.6168 10.925 12.3873 10.5282C12.1532 10.1229 11.9167 9.71885 11.6831 9.3133C11.4548 8.91916 11.2302 8.5237 11.0028 8.12938C10.8125 7.79767 10.6187 7.46691 10.4271 7.13558C10.1882 6.72159 9.94955 6.30779 9.71115 5.89418C9.49547 5.52181 9.28061 5.14931 9.06657 4.77668C8.88387 4.45867 8.70308 4.13952 8.52 3.8217C8.43969 3.68259 8.35576 3.54537 8.26442 3.39179Z" fill="#76D6FF"/>
                                <path d="M3.92554 11.827C4.1109 11.6489 4.29188 11.4682 4.4801 11.2954C4.83805 10.9692 5.22684 10.6784 5.64099 10.4272C6.10478 10.1433 6.5954 9.91665 7.12085 9.77506C7.5 9.67258 7.89107 9.62081 8.28383 9.62109C8.87132 9.62109 9.42455 9.75431 9.96503 9.96632C10.5894 10.2107 11.1486 10.5648 11.6723 10.979C11.9777 11.2203 12.2641 11.4848 12.5287 11.7703C12.5843 11.8302 12.5837 11.8631 12.5287 11.9239C12.0442 12.4567 11.4869 12.9015 10.8751 13.2771C10.4416 13.5458 9.97448 13.7557 9.48583 13.9016C8.99544 14.0463 8.48313 14.1022 7.97306 14.0667C7.24816 14.018 6.58379 13.7813 5.94892 13.4418C5.47131 13.1869 5.02437 12.8783 4.61674 12.522C4.39332 12.3271 4.19007 12.1092 3.97825 11.901C3.95912 11.8775 3.94151 11.8528 3.92554 11.827ZM8.25776 13.6644C9.23805 13.68 10.0825 12.8767 10.0847 11.846C10.0863 10.8495 9.26317 10.0282 8.27679 10.0255C7.28299 10.0228 6.44315 10.8111 6.44163 11.8458C6.44011 12.8805 7.2868 13.6743 8.25776 13.6644ZM9.9833 13.2798C10.7601 12.9245 11.431 12.4362 12.04 11.8475C11.4266 11.2602 10.763 10.7711 9.98711 10.4202C10.6658 11.3826 10.6557 12.3267 9.9833 13.2798ZM6.54135 13.2783C5.8667 12.3267 5.86194 11.3796 6.53317 10.4278C5.87793 10.6918 4.95569 11.3563 4.49171 11.8551C5.09728 12.435 5.76222 12.9289 6.54135 13.2783Z" fill="#76D6FF"/>
                                <path d="M8.87179 10.8751C8.56006 10.9023 8.39164 11.0584 8.39449 11.3077C8.3977 11.4129 8.43995 11.5132 8.51301 11.589C8.58607 11.6648 8.68472 11.7107 8.78976 11.7178C9.03298 11.7309 9.1917 11.5665 9.23814 11.2529C9.46061 11.5258 9.52988 12.1724 9.12852 12.614C8.71935 13.0644 8.0525 13.1573 7.52744 12.7405C7.05756 12.3675 6.97554 11.6352 7.32685 11.1663C7.73278 10.6258 8.44854 10.5803 8.87179 10.8751Z" fill="#76D6FF"/>
                                <line x1="7.80231" y1="9.75285" x2="5.89322" y2="0.0483214" stroke="#76D6FF" stroke-width="0.5"/>
                                <path d="M9.60376 9.79224L11.1103 1.88316" stroke="#76D6FF" stroke-width="0.5"/>
                                <line x1="5.92405" y1="10.4694" x2="1.15134" y2="2.51492" stroke="#76D6FF" stroke-width="0.5"/>
                                <line x1="8.13707" y1="14.0282" x2="7.34162" y2="21.0282" stroke="#76D6FF" stroke-width="0.5"/>
                                <line x1="5.83815" y1="13.3811" x2="4.88361" y2="14.3357" stroke="#76D6FF" stroke-width="0.5"/>
                                <line x1="5.00464" y1="12.6172" x2="2.61828" y2="14.2081" stroke="#76D6FF" stroke-width="0.5"/>
                                <line x1="11.5467" y1="11.1034" x2="13.4558" y2="9.51245" stroke="#76D6FF" stroke-width="0.5"/>
                                <line x1="10.1823" y1="13.4148" x2="11.9323" y2="17.0739" stroke="#76D6FF" stroke-width="0.5"/>
                                <line x1="11.9412" y1="12.0116" x2="14.964" y2="12.9661" stroke="#76D6FF" stroke-width="0.5"/>
        </symbol>

        <symbol id="" viewBox="0 0 23 23">

        </symbol>
    </defs>
</svg>

<svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
    <linearGradient id="icons-gradient" x1="0%" y1="100%" x2="100%" y2="0%" >
        <stop offset="0%" style="stop-color:rgb(45,57,153);stop-opacity:1" />
        <stop offset="100%" style="stop-color:rgb(154,27,160);stop-opacity:1" />
    </linearGradient>
</svg>

