import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CollectionItem} from "../../models/collectionItem";
import {PopupComponent} from "../../shared/popup/popup.component";
import {TokensAdvertisementService} from "../../service/tokens/tokensAdvertisement.service";
import {POST_TYPES} from "../../config/post_types";
import {POST_CATEGORIES_ID} from "../../config/post_categories";
import {debounceTime} from "rxjs/operators";
import {CollectionService} from "../../service/collection.service";
import {ScrollUpdatingService} from "../../service/scrollUpdating.service";
import {BalanceCard} from "../../models/balanceCard";
import {CollectionListService} from "../../service/collectionList.service";
import {MenuService} from "../../service/menu.service";
import {TokensService} from "../../service/tokens/tokens.service";

@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss'],
    providers: [ScrollUpdatingService]
})
export class CollectionComponent implements OnInit {
    visible: boolean;
    @Input() settings;
    items: [CollectionItem?];
    limit;
    @Output() closeCollection: EventEmitter<any> = new EventEmitter();
    @Output() chooseSettings: EventEmitter<any> = new EventEmitter();

    postTypes = POST_TYPES;
    postCategoriesId = POST_CATEGORIES_ID;

    filter = false;

    parentComponent;


    checkedElements;

    previewPagination = 0;
    prevPagination = 0;
    dataRichEnd = false;
    loading = false;

    pageActive = 1;
    lastScrollTop = 0;
    pagesLoaded = 0;
    scrollIndex = this.pagesLoaded ? this.pagesLoaded : 1;

    publicationsType = '';
    publicationsSense = [];
    publicationsCost = '';

    constructor(private popupComponent: PopupComponent,
                private collectionService: CollectionService,
                private collectionListService: CollectionListService,
                private scrollUpdatingService: ScrollUpdatingService,
                private menuService: MenuService,
                private tokensService: TokensService,
                private tokensAdvertisementService: TokensAdvertisementService) {
        this.collectionService.visible.subscribe((value: any) => this.changeVisibility(value));

    }

    ngOnInit() {
    }

    emitScroll($event) {
        this.scrollUpdatingService.scroll($event);
    }

    goNext() {
        if (this.checkedElements) {
            this.chooseSettings.emit(this.checkedElements);
        } else {
            this.popupComponent.showPopupError({text: 'You should select at least one publication for advertisement'})
        }

    }

    toggleInput($event) {
        const inputId = $event.target.getAttribute('data-element-id');
        const inputCategory = $event.target.getAttribute('data-element-category');

        this.checkedElements = {id: inputId, category: inputCategory};

        console.log(this.checkedElements)

        // let isElement = false;
        // let elementIndex;
        //
        // this.checkedElements.forEach((el, index) => {
        //     if (+el.id === +inputId) {
        //         elementIndex = index;
        //         isElement = true;
        //     }
        // });
        //
        //
        // if (isElement) {
        //     this.checkedElements.splice(elementIndex, 1);
        // } else {
        //     this.checkedElements.push({id: inputId, category: inputCategory});
        // }
    }

    loadNewData(page, step = 'end') {
        const publicationsType = this.publicationsType !== undefined ? this.publicationsType : '';
        const publicationsSense = this.publicationsSense !== undefined ? this.publicationsSense : [];
        const publicationsCost = this.publicationsCost !== undefined ? this.publicationsCost : '';

        if (this.parentComponent === 'tokensAdvertisement') {
            this.tokensAdvertisementService.getCollection(publicationsType, publicationsSense, publicationsCost, page).subscribe(data => {
                //console.log(publicationsType, publicationsSense, publicationsCost, page)
                this.dataLoadingLogic(page, step, data);
                //console.log('tokens')
            })
        }

        if (this.parentComponent === 'collection') {
            this.collectionService.getCollection(publicationsType, publicationsSense, page).subscribe(data => {
                //console.log(publicationsType, publicationsSense, publicationsCost, page)
                this.dataLoadingLogic(page, step, data);
                //console.log('collection', data)
            })
        }

    }

    dataLoadingLogic(page, step, data) {
        if (data.status) {

            if (data.result.length < this.limit && step === 'end') {
                this.previewPagination += 1;
                this.pageActive = this.previewPagination;
                this.loading = false;
                this.dataRichEnd = true;

                for (let i = 0; i < data.result.length; i++) {
                    this.items.push(data.result[i]);
                }
                return;
            }

            // ADD PAGE TO BEGINING
            if (step === 'end') {
                // REMOVE PAGE FROM BEGINING
                if (this.items.length > (2 * data.result.length)) {
                    this.items.splice(0, data.result.length);
                    this.prevPagination += 1;
                    this.pagesLoaded -= 1;
                }
                for (let i = 0; i < data.result.length; i++) {
                    this.items.push(data.result[i]);
                }
                this.previewPagination += 1;
                this.pagesLoaded += 1;
                //console.log(this.items);
            }

            if ((step === 'start') && this.prevPagination) {

                // REMOVE PAGE FROM END
                this.items.splice(this.items.length - data.result.length, data.result.length);
                this.previewPagination -= 1;
                this.dataRichEnd = false;

                // ADD PAGE TO BEGINING
                for (let i = data.result.length-1; i >= 0; i--) {
                    this.items.unshift(data.result[i]);
                }
                this.prevPagination -= 1;
            }

            if (step === 'new') {
                this.items = [];
                for (let i = 0; i < data.result.length; i++) {
                    this.items.push(data.result[i]);
                }
                this.previewPagination = 1;
                this.prevPagination = 0;
                if (data.result.length < this.limit) {
                    this.dataRichEnd =  true;
                }
                this.pagesLoaded = 1;
            }


            this.pageActive = this.previewPagination;
            setTimeout(() => {
                this.loading = false;
            }, 500)


        }

    }


    scrollHandler(e) {

        if (!this.loading) {
            const elems = document.querySelectorAll('.collection__item');

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            const rectStart = elems[0] !== undefined ? elems[Math.round(this.limit * .666667)].getBoundingClientRect() : undefined;

            //elems[elems.length - 1].classList.add('control')
            //console.log(rectStart.top, -3 * rect.height)

            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > this.lastScrollTop){
                //console.log('bottom', rect.bottom, rect.height * 8.2 * this.scrollIndex, rect.bottom <= rect.height * 9)
                if (rect !== undefined && rect.bottom <= rect.height * 8.2 * this.scrollIndex && !this.dataRichEnd) {
                    //console.log('scroll bottom true')
                    this.loading = true;
                    const loadingPage = this.pageActive + 1;
                    this.loadNewData(loadingPage);

                }
            } else {
                //console.log('scroll up')
                //console.log('up', rect.bottom, rect.height,  rectStart.top, rect.height * -3, rectStart.top >= (rect.height * -3))
                if (rectStart !== undefined && rectStart.top >= (rect.height * -3)  && rect.bottom >= 3000  && this.prevPagination > 0) {
                    //console.log('scroll up true')
                    this.loading = true;
                    const loadingPage = this.prevPagination;
                    this.loadNewData(loadingPage, 'start');
                }
            }
            this.lastScrollTop = st <= 0 ? 0 : st;

        }


    }

    setCategories(value) {
        this.publicationsSense = [];

        value.forEach(category => {
            this.publicationsSense.push(this.postCategoriesId[category])
        });

        this.loadNewData(1, 'new');
    }

    setTypes(value) {
        if (value.name === 'content') {
            this.publicationsType = this.postTypes[value.value.toLowerCase()];
        } else if (value.name === 'cost') {
            if (value.value === 'free') {
                this.publicationsCost = '1';
            } else {
                this.publicationsCost = '2';
            }
        }
        this.loadNewData(1, 'new');
    }

    changeVisibility(value) {
        this.visible = value;

        if (this.visible) {
            const publicationsType = this.publicationsType;
            const publicationsSense = this.publicationsSense;
            const publicationsCost = this.publicationsCost;
            this.parentComponent = this.settings ? this.settings.page : 'collection';

            if (this.parentComponent === 'tokensAdvertisement') {
                this.tokensAdvertisementService.getCollection(publicationsType, publicationsSense, publicationsCost, 1).subscribe(data => {
                    this.items = data.result;
                    this.limit = data.limit || 30;
                    //console.log('tokens', data)
                })
            }
            if (this.parentComponent === 'collection') {
                this.collectionService.getCollection(publicationsType, publicationsSense, 1).subscribe(data => {
                    this.items = data.result;
                    this.limit = data.limit || 30;
                    //console.log('collection', data)
                })
            }
        }
    }

    menu() {
        // this.collectionService.hide();
        // this.tokensAdvertisementService.hide();
        // this.tokensService.hide();
        // //this.menuService.hide();
        // this.resetFilter();
        this.menuService.show();
    }

    goBack() {
        this.collectionService.hide();
        this.resetFilter();
    }

    resetFilter() {
        this.publicationsType = '';
        this.publicationsSense = [];
        this.publicationsCost = '';
    }

    getId(item){
        const collectionItem = new CollectionItem(item);
        return collectionItem.id;
    }

    getImg(item){
        const collectionItem = new CollectionItem(item);
        return collectionItem.img;
    }

    getType(item){
        const collectionItem = new CollectionItem(item);
        return collectionItem.type;
    }

    getCategory(item){
        const collectionItem = new CollectionItem(item);
        //console.log(item)
        return collectionItem.category;
    }

    showPublication(id) {
        this.collectionListService.showPost(id);
    }
}
