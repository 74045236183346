import { BehaviorSubject } from 'rxjs';
import { SNEK_A_PEK_PAYOUTS_CONFIG } from "../../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DistributionsSnekService {
    constructor(http) {
        this.http = http;
        this.config = SNEK_A_PEK_PAYOUTS_CONFIG;
        this.visible = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    getData() {
        return this.http.post(this.config.data, {});
    }
}
DistributionsSnekService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DistributionsSnekService_Factory() { return new DistributionsSnekService(i0.ɵɵinject(i1.HttpClient)); }, token: DistributionsSnekService, providedIn: "root" });
