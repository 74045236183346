import { BehaviorSubject } from 'rxjs';
import { USER_ITEM_EDITING_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserItemEditingService {
    constructor(http) {
        this.http = http;
        this.config = USER_ITEM_EDITING_CONFIG;
        //visible = new BehaviorSubject(null);
        this.id = new BehaviorSubject(null);
    }
    show(id) {
        //this.visible.next(true);
        this.id.next(id);
    }
    hide() {
        this.id.next(null);
    }
    getUser(id) {
        return this.http.post(this.config.user, { id });
    }
    changeUserState(id, status) {
        return this.http.post(this.config.userSaveStatus, { id, status });
    }
    saveUserInfo(id, phone, firstName, lastName, userName) {
        return this.http.post(this.config.userSaveInfo, { id, phone, firstName, lastName, userName });
    }
}
UserItemEditingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserItemEditingService_Factory() { return new UserItemEditingService(i0.ɵɵinject(i1.HttpClient)); }, token: UserItemEditingService, providedIn: "root" });
