export class AuthConfig {
    checkUser: string;
    register: string;
    login: string;
    refresh: string;
    current: string;
    //checkPhone: string;
    sendPin: string;
    refreshTokenUrl?: string;
    checkPhoneAndSendPin: string;
}
