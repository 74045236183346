import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MenuService} from "../../../service/menu.service";
import {AccountSettingsPaymentService} from "../../../service/account/accountSettingsPayment.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {AccountSettingsService} from "../../../service/account/accountSettings.service";
import {StripeService} from "../../../service/stripe/stripe.service";

@Component({
    selector: 'app-account-payment',
    templateUrl: './account-payment.component.html',
    styleUrls: ['./account-payment.component.scss']
})
export class AccountPaymentComponent implements OnInit {
    visible: boolean;
    paymentForm: FormGroup;
    availableYears = [];

    constructor(private formBuilder: FormBuilder,
                private menuService: MenuService,
                private popupComponent: PopupComponent,
                private accountSettingsService: AccountSettingsService,
                private stripeService: StripeService,
                private accountSettingsPaymentService: AccountSettingsPaymentService) {
        this.accountSettingsPaymentService.visible.subscribe((value: any) => this.changeVisibility(value));
    }

    ngOnInit() {
        this.paymentForm = this.formBuilder.group({
            ccnumber: new FormControl('', [Validators.required]),
            expDateMM: new FormControl('1', [Validators.required]),
            expDateYY: new FormControl('', [Validators.required]),
            securityCode: new FormControl('', [Validators.required]),
            zipCode: new FormControl(null, [Validators.required]),
            description: new FormControl('', [Validators.required]),
        });
    }
    onSubmit() {
        const $this = this;

        const formData = $this.paymentForm.value;

        $this.accountSettingsPaymentService.setData(formData.ccnumber, formData.expDateMM, formData.expDateYY, formData.securityCode, formData.zipCode, formData.description).subscribe(data => {
            if (data.status) {
                $this.popupComponent.showPopupSuccess(data.message);
            } else {
                $this.popupComponent.showPopupError(data.message);
            }
        });

    }

    goBack() {
        this.accountSettingsPaymentService.hide();
    }

    menu() {
        // this.accountSettingsPaymentService.hide();
        // this.accountSettingsService.hide();
        //this.menuService.hide();
        this.menuService.show();
    }

    changeVisibility(value) {
        this.visible = value;

        if (this.visible) {
            this.accountSettingsPaymentService.loadData().subscribe(data => {
                if (data.status) {
                    this.paymentForm.patchValue({
                        ccnumber: "0000 0000 0000 "+data.result.last_four,
                        expDateMM: data.result.month,
                        expDateYY: data.result.year,
                        zipCode: data.result.zip,
                        description: data.result.descriptions
                    });
                }
                this.getAvailableYears();
            });
        }
    }

    getAvailableYears() {
        const currentDate = new Date();

        const currentYear = currentDate.getFullYear();
        //const currentYearShort = currentYear.toString().substr(2, 2);

        const currentMonth = currentDate.getMonth();
        const checkedMonth = this.paymentForm.get('expDateMM').value;

        this.availableYears = [];

        let correctionIndex = currentMonth > checkedMonth ? 1 : 0;

        for (let i = 0; i < 10; i++) {
            this.availableYears.push(+currentYear + correctionIndex + i);
        }
    }
}
