import { HOME_ARTICLES_CONFIG } from '../../config/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HomeArticlesService {
    constructor(http) {
        this.http = http;
        this.config = HOME_ARTICLES_CONFIG;
    }
    loadData(number) {
        return this.http.post(this.config.articles, { number });
    }
}
HomeArticlesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomeArticlesService_Factory() { return new HomeArticlesService(i0.ɵɵinject(i1.HttpClient)); }, token: HomeArticlesService, providedIn: "root" });
