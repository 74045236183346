import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchItem} from "../../models/searchItem";
import {ActivityItem} from "../../models/activityItem";
import {SearchService} from "../../service/search.service";
import {POST_TYPES} from "../../config/post_types";
import {HomeCollectionService} from "../../service/global-library/homeCollection.service";
import {POST_CATEGORIES_ID} from "../../config/post_categories";
import {ArticleService} from "../../service/article.service";

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    //@Input() data;
    filter = false;

    items = [SearchItem];

    postTypes = POST_TYPES;
    postCategoriesId = POST_CATEGORIES_ID;

    publicationsType = '';
    publicationsSense;
    publicationsCost = '';

    senseType = [];

    typeDefault: number;
    categories = [];

    pagesLoaded = 0;

    previewPagination = 0;
    prevPagination = 0;

    loading = false;
    dataRichEnd = false;
    countLastAddedItems;
    itemsRemovedFromBegining = 0

    pageActive = 1;
    init = true;
    lastScrollTop = 0;

    constructor(private searchService: SearchService,
                private articleService: ArticleService,
                private homeCollectionService: HomeCollectionService) {
        this.searchService.data.subscribe((value: any) => this.searchDataChanged(value));
    }

    ngOnInit() {
    }

    getTitle(item){
        const searchItem = new SearchItem(item);
        return searchItem.title;
    }

    getId(item){
        const searchItem = new SearchItem(item);
        return searchItem.id;
    }

    getDescr(item){
        const searchItem = new SearchItem(item);
        return searchItem.descr;
    }

    getPrice(item){
        const searchItem = new SearchItem(item);
        return parseInt(searchItem.price);
    }

    getImg(item){
        const searchItem = new SearchItem(item);
        return searchItem.img;
    }

    getCategories(item){
        const searchItem = new SearchItem(item);
        return searchItem.categories;
    }

    searchDataChanged(value) {
        this.items = value;
    }

    setCategories(value) {
        this.publicationsSense = [];

        value.forEach(category => {
            this.publicationsSense.push(this.postCategoriesId[category])
        });
        this.searchService.categories.next(this.publicationsSense);

        this.loadNewData(1, 'new');
    }

    setTypes(value) {
        if (value.name === 'content') {
            this.publicationsType = this.postTypes[value.value.toLowerCase()];
            this.searchService.setSearchType(this.publicationsType);
        } else if (value.name === 'cost') {
            if (value.value === 'free') {
                this.publicationsCost = '1';
            } else {
                this.publicationsCost = '2';
            }
            this.searchService.setSearchCost(this.publicationsCost);
        }
        this.loadNewData(1, 'new');
    }

    loadNewData(page, step = 'end') {
        const publicationsType = this.publicationsType !== undefined ? this.publicationsType : '';
        const publicationsSense = this.publicationsSense !== undefined ? this.publicationsSense : '';
        const publicationsCost = this.publicationsCost !== undefined ? this.publicationsCost : '';

        // this.homeCollectionService.loadPublications(publicationsType, publicationsSense, publicationsCost, page, '').subscribe(data => {
        this.homeCollectionService.loadPublications(publicationsSense, page, '').subscribe(data => {

            if (data.status) {

                if (data.result.length < 50 && step === 'end') {
                    this.previewPagination += 1;
                    this.pageActive = this.previewPagination;
                    this.loading = false;
                    this.dataRichEnd = true;
                    this.itemsRemovedFromBegining = data.result.length;

                    for (let i = 0; i < data.result.length; i++) {
                        this.items.push(data.result[i]);
                    }

                    this.prevPagination += 1;
                    this.pagesLoaded += 1;

                    this.countLastAddedItems = data.result.length;
                    return;
                }

                // ADD PAGE TO BEGINING
                if (step === 'end') {
                    // REMOVE PAGE FROM BEGINING
                    if (this.items.length > (2 * data.result.length)) {
                        this.items.splice(0, data.result.length);
                        this.prevPagination += 1;
                        this.pagesLoaded -= 1;
                    }
                    for (let i = 0; i < data.result.length; i++) {
                        this.items.push(data.result[i]);
                    }

                    this.previewPagination += 1;
                    this.pagesLoaded += 1;
                    this.countLastAddedItems = data.result.length;
                }

                if ((step === 'start') && this.prevPagination) {

                    let countItems = 50;
                    if (this.itemsRemovedFromBegining < 50) {
                        countItems = countItems + this.itemsRemovedFromBegining;
                    }

                    // REMOVE PAGE FROM END
                    this.items.splice(this.items.length - countItems, countItems);
                    this.previewPagination -= 2;
                    this.dataRichEnd = false;

                    // ADD PAGE TO BEGINING
                    for (let i = data.result.length - 1; i >= 0; i--) {
                        this.items.unshift(data.result[i]);
                    }
                    this.itemsRemovedFromBegining = 0;
                    this.prevPagination -= 1;

                }


                if (step === 'new') {
                    this.items = [];
                    for (let i = 0; i < data.result.length; i++) {
                        this.items.push(data.result[i]);
                    }
                    this.previewPagination = 1;
                    this.prevPagination = 0;
                    if (data.result.length < 50) {
                        this.dataRichEnd =  true;
                    }
                    this.pagesLoaded = 1;
                }

                this.pageActive = this.previewPagination;
                //console.log(this.items);

                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }
        })
    }

    showPublication(id) {
        console.log(id)
        this.articleService.showArticle(id);
        this.searchService.hide();
    }
}
