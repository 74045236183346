import {Inject, Injectable, NgZone} from '@angular/core';
import {NOTIFICATIONS_TYPES} from "../../config/notifications_types";

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private notificationTypes = NOTIFICATIONS_TYPES;

    constructor() {

    }

    liked() {
        return this.notificationTypes.liked;
    }

    shared() {
        return this.notificationTypes.shared;
    }

    purchased() {
        return this.notificationTypes.purchased;
    }

    advertisementEnded() {
        return this.notificationTypes.advertisementEnded;
    }
}
