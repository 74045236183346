export class CollectionItem {
    public _id?: string;
    private _type?: number;
    private _img?: string;
    private _category?: string;

    constructor(data) {
        this._id = data.id;
        this._type = data.file_type;
        this._img = data.avatar_link;
        this._category = data.category;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get type(): number {
        return this._type;
    }

    set type(value: number) {
        this._type = value;
    }

    get img(): string {
        return this._img;
    }

    set img(value: string) {
        this._img = value;
    }

    get category(): string {
        return this._category;
    }

    set category(value: string) {
        this._category = value;
    }

}
