/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./collection-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/publication/video-publication/video-publication.component.ngfactory";
import * as i3 from "../../shared/publication/video-publication/video-publication.component";
import * as i4 from "../../shared/popup/popup.component";
import * as i5 from "../../shared/publication/image-publication/image-publication.component.ngfactory";
import * as i6 from "../../shared/publication/image-publication/image-publication.component";
import * as i7 from "../../shared/publication/sound-publication/sound-publication.component.ngfactory";
import * as i8 from "../../shared/publication/sound-publication/sound-publication.component";
import * as i9 from "@angular/common";
import * as i10 from "../../shared/header/header.component.ngfactory";
import * as i11 from "../../shared/header/header.component";
import * as i12 from "../../service/menu.service";
import * as i13 from "./collection-list.component";
import * as i14 from "../../service/collection.service";
import * as i15 from "../../service/collectionList.service";
var styles_CollectionListComponent = [i0.styles];
var RenderType_CollectionListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CollectionListComponent, data: {} });
export { RenderType_CollectionListComponent as RenderType_CollectionListComponent };
function View_CollectionListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-video-publication", [], null, null, null, i2.View_VideoPublicationComponent_0, i2.RenderType_VideoPublicationComponent)), i1.ɵdid(2, 114688, null, 0, i3.VideoPublicationComponent, [i4.PopupComponent], { publication: [0, "publication"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CollectionListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-image-publication", [], null, null, null, i5.View_ImagePublicationComponent_0, i5.RenderType_ImagePublicationComponent)), i1.ɵdid(2, 114688, null, 0, i6.ImagePublicationComponent, [], { publication: [0, "publication"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CollectionListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-sound-publication", [], null, [[null, "menuShowed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("menuShowed" === en)) {
        var pd_0 = (_co.menuShowed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_SoundPublicationComponent_0, i7.RenderType_SoundPublicationComponent)), i1.ɵdid(2, 114688, null, 0, i8.SoundPublicationComponent, [], { publication: [0, "publication"], menu: [1, "menu"] }, { menuShowed: "menuShowed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.menu; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CollectionListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "collection__item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollectionListComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollectionListComponent_3)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["' "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollectionListComponent_4)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.getType(_v.context.$implicit) === _co.postTypes["video"]); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.getType(_v.context.$implicit) === _co.postTypes["image"]); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.getType(_v.context.$implicit) === _co.postTypes["audio"]); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_CollectionListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "page content"]], [[2, "is-visible", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-header", [], null, [[null, "backAction"], [null, "menuAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backAction" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("menuAction" === en)) {
        var pd_1 = (_co.menuClose() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_HeaderComponent_0, i10.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i11.HeaderComponent, [i12.MenuService], { menuActionCheck: [0, "menuActionCheck"], backActionCheck: [1, "backActionCheck"] }, { menuAction: "menuAction", backAction: "backAction" }), (_l()(), i1.ɵted(-1, 0, ["Collection"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "page__collection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [["class", "collection-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollectionListComponent_1)), i1.ɵdid(8, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.itemsSlider; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 0, 0, currVal_0); }); }
export function View_CollectionListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-collection-list", [], null, null, null, View_CollectionListComponent_0, RenderType_CollectionListComponent)), i1.ɵdid(1, 4308992, null, 0, i13.CollectionListComponent, [i1.ElementRef, i12.MenuService, i14.CollectionService, i15.CollectionListService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CollectionListComponentNgFactory = i1.ɵccf("app-collection-list", i13.CollectionListComponent, View_CollectionListComponent_Host_0, { settings: "settings" }, {}, []);
export { CollectionListComponentNgFactory as CollectionListComponentNgFactory };
