import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserShortInfo} from "../../../models/admin/userShortInfo";
import {UserEditingService} from "../../../service/admin/userEditing.service";
import {MenuService} from "../../../service/menu.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {ActivityItem} from "../../../models/activityItem";
import {UserInfo} from "../../../models/user-info";
import {UserItemEditingService} from "../../../service/admin/userItemEditing.service";


@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    visible: boolean;

    userForm: FormGroup;

    items= [];
    showUserId;

    constructor(private formBuilder: FormBuilder,
                private menuService: MenuService,
                private popupComponent: PopupComponent,
                private userItemEditingService: UserItemEditingService,
                private userEditingService: UserEditingService) {
        this.userEditingService.visible.subscribe((value: any) => this.componentVisibility(value));
    }

    ngOnInit() {

        this.userForm = this.formBuilder.group({
            phone: ['', Validators.required],
            name: ['', Validators.required]
        });

    }

    onUserSubmit() {

    }

    componentVisibility(state) {
        this.visible = state;

        if (this.visible) {
            this.findUsers();
        }
    }

    goBack() {
        this.userEditingService.hide();
    }

    menu() {
        this.userEditingService.hide();
        //this.menuService.hide();
    }

    searchUser($event) {
        $event.preventDefault();

        this.findUsers();
    }

    findUsers(){
        const name = this.userForm.controls['name'].value.trim();
        const phone = this.userForm.controls['phone'].value.trim();

        this.userEditingService.getUsers(name, phone).subscribe(data => {
            this.items = [];
            if (data.status) {
                this.items = data.result;
            }
        });

    }

    showUserInfo($event) {
        this.userItemEditingService.show($event);
    }

    getId(item){
        const user = new UserShortInfo(item);
        return user.id;
    }

    getImg(item){
        const user = new UserShortInfo(item);
        return user.img;
    }

    getFirstName(item){
        const user = new UserShortInfo(item);
        return user.firstName;
    }

    getLastName(item){
        const user = new UserShortInfo(item);
        return user.lastName;
    }
}
