import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ArticleService} from "../../../service/article.service";
import {SearchService} from "../../../service/search.service";

@Component({
    selector: 'app-article-details',
    templateUrl: './article-details.component.html',
    styleUrls: ['./article-details.component.scss']
})
export class ArticleDetailsComponent implements OnInit {

    //@Input() popup: boolean;
    @Input() data: any;
    @Output() hidenDetails = new EventEmitter();
    @Input() activePublication: any;
    @Output() likeEmitted = new EventEmitter();

    visible;
    menu = false;
    contentType = 'image';
    // contentType = 'sound';
    // contentType = 'video';

    item;

    ngOnChanges() {
        if (this.data) {
            this.data.forEach(el => {
                if (el.id == this.activePublication) {
                    this.item = el;
                }
            })
        }
    }

    free = true;
    repost = false;
    play = true;
    startVideo = false;

    rangePosition = '0';

    search = false;

    constructor(private articleService: ArticleService,
                private searchService: SearchService) {
        this.articleService.showDetailsId.subscribe((value: any) => this.changeVisibility(value));
        this.searchService.visible.subscribe((value: any) => this.searchOpen(value));

    }

    ngOnInit() {
    }


    closePopup() {
        //this.popup = false;
        // this.popupShow.emit(this.popup);
        //document.body.classList.remove('modal');
        this.articleService.showDetailsId.next(null);

    }

    toggleRepost() {
        this.repost = !this.repost;
    }

    showVideo() {
        this.startVideo = true;
    }

    toggleVideo() {
        this.play = !this.play;
    }

    changeRange(value) {
        this.rangePosition = value;
    }

    // hideDetails() {
    //     this.popup = false;
    //     this.hidenDetails.emit(this.popup);
    // }

    searchOpen(state) {
        this.search = state;
    }

    menuShowed(state) {
        this.menu = state;
    }

    changeVisibility(value) {
        if (value) {
            this.data.forEach(el => {
                if (el.id == value.id) {
                    this.item = el;
                    this.item.advertising = value.advertising;
                    this.visible = true;
                }
            })
        } else {
            this.visible = false;
        }
    }

    toggleLike(state) {
        this.likeEmitted.emit(state);
    }
 }
