export class UserShortInfo {
    public _id: string;
    private _img: string;
    private _firstName: string;
    public _lastName: string;

    constructor(data:any) {
        this._id = data.id;
        this._img = data.avatar_link;
        this._firstName = data.first_name;
        this._lastName = data.last_name;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get img(): string {
        return this._img;
    }

    set img(value: string) {
        this._img = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

}
