import {Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PopupComponent} from "../../shared/popup/popup.component";
import {HomeCollectionService} from "../../service/global-library/homeCollection.service";
import {NewContentService} from "../../service/content/new-content.service";
import {GlobalLibraryService} from "../../service/global-library/globalLibrary.service";
import {PhoneService} from "../../service/phone.service";
import {POST_TYPES} from '../../config/post_types';
import {BackService} from "../../service/session/back.service";

@Component({
    selector: 'app-new-content',
    templateUrl: './new-content.component.html',
    styleUrls: ['./new-content.component.scss']
})
export class NewContentComponent implements OnInit {
    private configPostTypes = POST_TYPES;

    visible;
    item;

    popup = false;
    popupPreviewVideo = false;
    popupPreviewPhoto = false;
    popupPreviewAudio = false;

    publicationID;

    validation = {
        title: true,
        description: true,
        categories: true
    };

    categories;
    author;

    constructor(private newContentService: NewContentService,
                private globalLibraryService: GlobalLibraryService,
                private route: ActivatedRoute,
                private phoneService:PhoneService,
                private backService:BackService,
                private ngZone: NgZone,
                private popupComponent: PopupComponent) {

    }

    ngOnInit() {
        this.newContentService.newContentChanged.subscribe((value: any) => this.newContentChanged(value));
        this.newContentService.newContentId.subscribe((value: any) => this.idChanged(value));
        this.phoneService.postAvatarUploded.subscribe((value: any) => this.changeAvatar(value));
    }

    ngOnChanges() {

    }

    showPreview() {
        if (this.item['file_type'] !== undefined && this.validateData()) {
            this.popup = true;
            if (this.item['file_type'] === this.configPostTypes.video) {
                this.popupPreviewVideo = true;
            } else if (this.item['file_type'] === this.configPostTypes.image) {
                this.popupPreviewPhoto = true;
            } else if (this.item['file_type'] === this.configPostTypes.audio) {
                this.popupPreviewAudio = true;
            }
        }
    }

    closePreview() {
        this.popup = false;
        this.popupPreviewVideo = false;
        this.popupPreviewPhoto = false;
        this.popupPreviewAudio = false;
    }

    saveData() {
        const id = this.item.id;
        const title = this.item.title;
        const description = this.item.description;
        const categories = this.item.categories;
        const payment = this.item.payment_type !== undefined ? this.item.payment_type : 0;
        const tokens = this.item.tokens;
        const freePart = this.item.freePercent;
        const tags = this.item.tags;

        if (this.validateData()) {
            this.newContentService.saveData(id, title, description, categories, payment, tokens, freePart, tags).subscribe(data => {
                if (data.status) {
                    this.popupComponent.showPopupSuccess({text: data.message});
                    //console.log(data);

                    this.newContentService.hide();
                    this.globalLibraryService.hide();
                    this.closePreview();
                }else{
                    this.popupComponent.showPopupError({text: data.message});
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please, fill all required fields: Title, Description and Categories'});
        }

    }

    validateTitle() {
        if (this.item.title.trim()) {
            this.validation.title  = true;
            return true;
        }
        this.validation.title  = false;
        return false;
    }

    validateDescription() {
        if (this.item.description.trim()) {
            this.validation.description  = true;
            return true;
        }
        this.validation.description  = false;
        return false;
    }

    validateCategories() {
        if (this.item.categories.length) {
            this.validation.categories  = true;
            return true;
        }
        this.validation.categories  = false;
        return false;
    }

    validateData() {
        this.validateTitle();
        this.validateDescription();
        this.validateCategories();

        for (let item in this.validation) {
            if (!this.validation[item]) {
                return false;
            }
        }

        return true;
    }

    goBack(){
        const direction = this.backService.getLink();

        if (direction === 'global-library') {
            this.newContentService.hide();
        }

    }

    backActionCheck(){
        if(this.backService.getLink()) {
            return true;
        }

        return false;
    }

    nextActionCheck(){
        // if(this.backService.getLink()){
        //     return false;
        // }

        return true;
    }

    newContentChanged(status){
        this.visible = status;
    }

    idChanged(id){
        this.publicationID = id;

        if (this.publicationID) {
            this.newContentService.loadData(this.publicationID).subscribe(data => {
                if (data.status) {
                    setTimeout(() => {
                        this.item = data.result;
                        this.categories = data.categories;

                        this.author = data.author;
                    }, 0);
                }else{
                    if(data.code){

                    }else{
                        this.popupComponent.showPopupError({text:data.message});
                    }
                }
            });
        }
    }

    changeAvatar(data){
        if(data){
            this.ngZone.run( () => {
                this.item.avatar_link = data.avatar_link;
            });
        }
    }
}
