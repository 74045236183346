<div class="page content" *ngIf="visible" [class.is-visible]="visible">

    <app-header *ngIf="parentComponent === 'collection'"
            [backActionCheck]="true"
            (backAction)="goBack()"
            [menuActionCheck]="true"
            (menuAction)="menu()">Collection</app-header>

    <app-header *ngIf="parentComponent === 'tokensAdvertisement'"
                [backActionCheck]="true"
                (backAction)="goBack()"
                [nextActionCheck]="true"
                (nextAction)="goNext()">Collection</app-header>

    <div class="page__inner" #collectionWrapper (scroll)="scrollHandler($event)">
        <div class="page__collection">

            <ul class="collection">
                <li *ngFor="let item of items; let i = index" data-type="{{getType(item)}}" class="collection__item">
                    <button (click)="showPublication(getId(item))" *ngIf="settings === undefined || (!settings.checkbox && !settings.buttons)" class="collection__link" [attr.data-element-id]="getId(item)">
                        <img class="collection__img" src="{{getImg(item)}}" alt="">

                        <svg *ngIf="getType(item) === postTypes['video']" class="collection__icon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_b)">
                                <circle cx="9" cy="9" r="9" fill="#C4C4C4" fill-opacity="0.6"/>
                            </g>
                            <path d="M13.25 8.13397C13.9167 8.51887 13.9167 9.48113 13.25 9.86603L7.625 13.1136C6.95833 13.4985 6.125 13.0174 6.125 12.2476L6.125 5.75241C6.125 4.9826 6.95833 4.50148 7.625 4.88638L13.25 8.13397Z" fill="white"/>
                            <defs>
                                <filter id="filter0_b" x="-4" y="-4" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                                </filter>
                            </defs>
                        </svg>

                        <svg *ngIf="getType(item) === postTypes['audio']" class="collection__icon" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.36006 3.744C6.40915 3.04705 5.43351 2.18806 4.73913 1.14648L4.13126 0.23471C4.00248 0.0415452 3.76244 -0.04447 3.53939 0.0224854C3.31686 0.0899683 3.16491 0.294984 3.16491 0.527323V8.80335C2.85334 8.62165 2.49595 8.50993 2.10994 8.50993C0.946272 8.50993 0 9.4562 0 10.6199C0 11.7835 0.946272 12.7298 2.10994 12.7298C3.27361 12.7298 4.21988 11.7835 4.21988 10.6198V2.25927C4.96525 3.20142 5.89763 3.97992 6.73626 4.59497C7.14833 4.89683 7.38479 5.36253 7.38479 5.87247C7.38479 6.74507 6.67493 7.45492 5.80233 7.45492C5.51077 7.45492 5.27485 7.69085 5.27485 7.98241C5.27485 8.27396 5.51077 8.50989 5.80233 8.50989C7.25654 8.50989 8.43975 7.32667 8.43975 5.87247C8.43975 5.03538 8.0364 4.24004 7.36006 3.744Z" fill="white"/>
                            <g filter="url(#filter0_b)">
                                <path d="M17.3326 4.3101L9.87748 6.42004C9.65083 6.48496 9.49473 6.69201 9.49473 6.92745V14.0782C9.18317 13.8965 8.82578 13.7847 8.43976 13.7847C7.2761 13.7847 6.32983 14.731 6.32983 15.8947C6.32983 17.0584 7.2761 18.0046 8.43976 18.0046C9.60343 18.0046 10.5497 17.0584 10.5497 15.8947C10.5497 15.7744 10.5497 9.43546 10.5497 9.43546L16.9499 7.62693V11.9682C16.6383 11.7865 16.2809 11.6748 15.8949 11.6748C14.7312 11.6748 13.7849 12.6211 13.7849 13.7847C13.7849 14.9484 14.7312 15.8947 15.8949 15.8947C17.0585 15.8947 18.0048 14.9484 18.0048 13.7847C18.0048 13.6645 18.0048 4.81751 18.0048 4.81751C18.0048 4.46863 17.6711 4.21449 17.3326 4.3101Z" fill="white" fill-opacity="0.5"/>
                            </g>
                            <defs>
                                <filter id="filter0_b" x="-13.6702" y="-15.7102" width="51.675" height="53.7148" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feGaussianBlur in="BackgroundImage" stdDeviation="10"/>
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    </button>

                    <button *ngIf="settings !== undefined && settings.buttons" class="collection__link">
                        <img class="collection__img" src="{{getImg(item)}}" alt="">

                        <svg *ngIf="getType(item) === postTypes['video']" class="collection__icon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_b)">
                                <circle cx="9" cy="9" r="9" fill="#C4C4C4" fill-opacity="0.6"/>
                            </g>
                            <path d="M13.25 8.13397C13.9167 8.51887 13.9167 9.48113 13.25 9.86603L7.625 13.1136C6.95833 13.4985 6.125 13.0174 6.125 12.2476L6.125 5.75241C6.125 4.9826 6.95833 4.50148 7.625 4.88638L13.25 8.13397Z" fill="white"/>
                            <defs>
                                <filter id="filter0_b" x="-4" y="-4" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                                </filter>
                            </defs>
                        </svg>

                        <svg *ngIf="getType(item) === postTypes['audio']" class="collection__icon" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.36006 3.744C6.40915 3.04705 5.43351 2.18806 4.73913 1.14648L4.13126 0.23471C4.00248 0.0415452 3.76244 -0.04447 3.53939 0.0224854C3.31686 0.0899683 3.16491 0.294984 3.16491 0.527323V8.80335C2.85334 8.62165 2.49595 8.50993 2.10994 8.50993C0.946272 8.50993 0 9.4562 0 10.6199C0 11.7835 0.946272 12.7298 2.10994 12.7298C3.27361 12.7298 4.21988 11.7835 4.21988 10.6198V2.25927C4.96525 3.20142 5.89763 3.97992 6.73626 4.59497C7.14833 4.89683 7.38479 5.36253 7.38479 5.87247C7.38479 6.74507 6.67493 7.45492 5.80233 7.45492C5.51077 7.45492 5.27485 7.69085 5.27485 7.98241C5.27485 8.27396 5.51077 8.50989 5.80233 8.50989C7.25654 8.50989 8.43975 7.32667 8.43975 5.87247C8.43975 5.03538 8.0364 4.24004 7.36006 3.744Z" fill="white"/>
                            <g filter="url(#filter0_b)">
                                <path d="M17.3326 4.3101L9.87748 6.42004C9.65083 6.48496 9.49473 6.69201 9.49473 6.92745V14.0782C9.18317 13.8965 8.82578 13.7847 8.43976 13.7847C7.2761 13.7847 6.32983 14.731 6.32983 15.8947C6.32983 17.0584 7.2761 18.0046 8.43976 18.0046C9.60343 18.0046 10.5497 17.0584 10.5497 15.8947C10.5497 15.7744 10.5497 9.43546 10.5497 9.43546L16.9499 7.62693V11.9682C16.6383 11.7865 16.2809 11.6748 15.8949 11.6748C14.7312 11.6748 13.7849 12.6211 13.7849 13.7847C13.7849 14.9484 14.7312 15.8947 15.8949 15.8947C17.0585 15.8947 18.0048 14.9484 18.0048 13.7847C18.0048 13.6645 18.0048 4.81751 18.0048 4.81751C18.0048 4.46863 17.6711 4.21449 17.3326 4.3101Z" fill="white" fill-opacity="0.5"/>
                            </g>
                            <defs>
                                <filter id="filter0_b" x="-13.6702" y="-15.7102" width="51.675" height="53.7148" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feGaussianBlur in="BackgroundImage" stdDeviation="10"/>
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    </button>

                    <div *ngIf="settings !== undefined && settings.checkbox" class="collection__checkbox">
                        <input #collectionItem
                               (change)="toggleInput($event)"
                               type="radio"
                               class="collection__input"
                               [attr.data-element-id]="getId(item)"
                               [attr.data-element-category]="getCategory(item)"
                               name="check"
                               id="item{{i}}">
                        <label class="collection__label" for="item{{i}}">
                            <img class="collection__img" src="{{getImg(item)}}" alt="">

                            <svg *ngIf="getType(item) === postTypes['video']" class="collection__icon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_b)">
                                    <circle cx="9" cy="9" r="9" fill="#C4C4C4" fill-opacity="0.6"/>
                                </g>
                                <path d="M13.25 8.13397C13.9167 8.51887 13.9167 9.48113 13.25 9.86603L7.625 13.1136C6.95833 13.4985 6.125 13.0174 6.125 12.2476L6.125 5.75241C6.125 4.9826 6.95833 4.50148 7.625 4.88638L13.25 8.13397Z" fill="white"/>
                                <defs>
                                    <filter id="filter0_b" x="-4" y="-4" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>

                            <svg *ngIf="getType(item) === postTypes['audio']" class="collection__icon" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.36006 3.744C6.40915 3.04705 5.43351 2.18806 4.73913 1.14648L4.13126 0.23471C4.00248 0.0415452 3.76244 -0.04447 3.53939 0.0224854C3.31686 0.0899683 3.16491 0.294984 3.16491 0.527323V8.80335C2.85334 8.62165 2.49595 8.50993 2.10994 8.50993C0.946272 8.50993 0 9.4562 0 10.6199C0 11.7835 0.946272 12.7298 2.10994 12.7298C3.27361 12.7298 4.21988 11.7835 4.21988 10.6198V2.25927C4.96525 3.20142 5.89763 3.97992 6.73626 4.59497C7.14833 4.89683 7.38479 5.36253 7.38479 5.87247C7.38479 6.74507 6.67493 7.45492 5.80233 7.45492C5.51077 7.45492 5.27485 7.69085 5.27485 7.98241C5.27485 8.27396 5.51077 8.50989 5.80233 8.50989C7.25654 8.50989 8.43975 7.32667 8.43975 5.87247C8.43975 5.03538 8.0364 4.24004 7.36006 3.744Z" fill="white"/>
                                <g filter="url(#filter0_b)">
                                    <path d="M17.3326 4.3101L9.87748 6.42004C9.65083 6.48496 9.49473 6.69201 9.49473 6.92745V14.0782C9.18317 13.8965 8.82578 13.7847 8.43976 13.7847C7.2761 13.7847 6.32983 14.731 6.32983 15.8947C6.32983 17.0584 7.2761 18.0046 8.43976 18.0046C9.60343 18.0046 10.5497 17.0584 10.5497 15.8947C10.5497 15.7744 10.5497 9.43546 10.5497 9.43546L16.9499 7.62693V11.9682C16.6383 11.7865 16.2809 11.6748 15.8949 11.6748C14.7312 11.6748 13.7849 12.6211 13.7849 13.7847C13.7849 14.9484 14.7312 15.8947 15.8949 15.8947C17.0585 15.8947 18.0048 14.9484 18.0048 13.7847C18.0048 13.6645 18.0048 4.81751 18.0048 4.81751C18.0048 4.46863 17.6711 4.21449 17.3326 4.3101Z" fill="white" fill-opacity="0.5"/>
                                </g>
                                <defs>
                                    <filter id="filter0_b" x="-13.6702" y="-15.7102" width="51.675" height="53.7148" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feGaussianBlur in="BackgroundImage" stdDeviation="10"/>
                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>
                        </label>
                    </div>
                </li>

            </ul>

        </div>
    </div>
<!--    <div *ngIf="!filter" class="page__footer">-->
    <div class="page__footer">
        <a href="/new-content"  *ngIf="settings ? settings.addBtn : true" class="btn-add">
            <svg class="btn-add__icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.1484 9.21094H9.35156V5.41406C9.35156 5.21989 9.19417 5.0625 9 5.0625C8.80583 5.0625 8.64844 5.21989 8.64844 5.41406V9.21094H4.85156C4.65739 9.21094 4.5 9.36833 4.5 9.5625C4.5 9.75667 4.65739 9.91406 4.85156 9.91406H8.64844V13.7109C8.64844 13.9051 8.80583 14.0625 9 14.0625C9.19417 14.0625 9.35156 13.9051 9.35156 13.7109V9.91406H13.1484C13.3426 9.91406 13.5 9.75667 13.5 9.5625C13.5 9.36833 13.3426 9.21094 13.1484 9.21094Z" fill="white"/>
                <circle cx="9" cy="9" r="8.5" stroke="white"/>
            </svg>
            Add
        </a>
    </div>
</div>

<app-filter *ngIf="visible" (checkedSenseValue)="setCategories($event)" (checkedTypeValue)="setTypes($event)" [filter]="filter"></app-filter>

<app-collection-list></app-collection-list>
