import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {USERS_WITH_ACTIVE_ADS_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class AdSettingsActiveService {
    private config = USERS_WITH_ACTIVE_ADS_CONFIG;
    public visible = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private http: HttpClient) {

    }

    show() {
        this.visible.next(true);
    }

    hide() {
        this.visible.next(false);
    }

    getUsers(name, phone) {
        return this.http.post<any>(this.config.data, {name, phone});
    }

    deactivateAdvertisement(id) {
        return this.http.post<any>(this.config.deactivate, {id});
    }
}
