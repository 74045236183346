import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuService} from "../../../service/menu.service";
import {NotificationsListService} from "../../../service/users/notificationsList.service";
import {NotificationItem} from "../../../models/notificationItem";
import {ActivityItem} from "../../../models/activityItem";
import {NotificationsService} from "../../../service/notifications/notifications.service";
import {PROJECT_CONFIG} from "../../../config/project";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";

@Component({
    selector: 'app-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {

    visible: boolean;
    items: [NotificationItem?];
    projectConfig = PROJECT_CONFIG;

    currentUser = {
        firstName: '',
        lastName: '',
        avatar: ''
    };

    storage = sessionStorage;

    pagesLoaded = 0;

    countLastAddedItems;
    itemsRemovedFromBegining = 0;

    previewPagination = 0;
    prevPagination = 0;
    dataRichEnd = false;

    loading = false;

    pageActive = 1;
    init = true;
    lastScrollTop = 0;

    constructor(private menuService:MenuService,
                private auth:AuthenticationService,
                private notificationsService:NotificationsService,
                private notificationsListService: NotificationsListService) {
        this.notificationsListService.visible.subscribe((value: any) => this.toggleVisibility(value));

    }

    ngOnInit() {

    }


    closeMenu() {
        this.notificationsListService.hide();
        //this.menuService.hide();
    }

    back() {
        this.notificationsListService.hide();
    }

    toggleVisibility(value) {
        this.visible = value;

        if (this.visible) {
            this.items = [];
            this.notificationsListService.getData(1).subscribe(data => {
                if (data.status) {
                    this.items = data.result;
                }

                const sessionCurrentUser = this.auth.getCurrentUser();

                this.currentUser = {
                    firstName: sessionCurrentUser.first_name,
                    lastName: sessionCurrentUser.last_name,
                    avatar: sessionCurrentUser.avatar_link ? sessionCurrentUser.avatar_link : this.projectConfig.defaultAvatar
                }
            })
        }
    }

    getText(item){
        const notificationItem = new NotificationItem(item);
        return notificationItem.text;
    }

    getType(item){
        const notificationItem = new NotificationItem(item);
        return notificationItem.type;
    }

    getTime(item){
        const notificationItem = new NotificationItem(item);
        return notificationItem.time;
    }

    getAvatar(item){
        const notificationItem = new NotificationItem(item);
        return notificationItem.avatar;
    }

    loadNewData(page, step = 'end') {
        this.notificationsListService.getData(page).subscribe(data => {
            if (data.status) {
                //console.log('load', data.result)
                if (data.result.length < 50 && step === 'end') {
                    this.previewPagination += 1;
                    this.pageActive = this.previewPagination;
                    this.loading = false;
                    this.dataRichEnd = true;
                    this.itemsRemovedFromBegining = data.result.length;

                    for (let i = 0; i < data.result.length; i++) {
                        this.items.push(data.result[i]);
                    }

                    this.prevPagination += 1;
                    this.pagesLoaded += 1;

                    this.countLastAddedItems = data.result.length;
                    return;
                }

                // ADD PAGE TO BEGINING
                if (step === 'end') {
                    // REMOVE PAGE FROM BEGINING
                    if (this.items.length > (2 * data.result.length)) {
                        this.items.splice(0, data.result.length);
                        this.prevPagination += 1;
                        this.pagesLoaded -= 1;
                    }
                    for (let i = 0; i < data.result.length; i++) {
                        this.items.push(data.result[i]);
                    }

                    this.previewPagination += 1;
                    this.pagesLoaded += 1;
                    this.countLastAddedItems = data.result.length;
                }

                if ((step === 'start') && this.prevPagination) {

                    let countItems = 50;
                    if (this.itemsRemovedFromBegining < 50) {
                        countItems = countItems + this.itemsRemovedFromBegining;
                    }

                    // REMOVE PAGE FROM END
                    this.items.splice(this.items.length - countItems, countItems);
                    this.previewPagination -= 2;
                    this.dataRichEnd = false;

                    // ADD PAGE TO BEGINING
                    for (let i = data.result.length - 1; i >= 0; i--) {
                        this.items.unshift(data.result[i]);
                    }
                    this.itemsRemovedFromBegining = 0;
                    this.prevPagination -= 1;

                }


                if (step === 'new') {
                    this.items = [];
                    for (let i = 0; i < data.result.length; i++) {
                        this.items.push(data.result[i]);
                    }
                    this.previewPagination = 1;
                    this.prevPagination = 0;
                    if (data.result.length < 50) {
                        this.dataRichEnd =  true;
                    }
                    this.pagesLoaded = 1;
                }

                this.pageActive = this.previewPagination;
                //console.log(this.items);

                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }
        })
    }

    scrollHandler(e) {
        if (!this.loading) {
            const elems = document.querySelectorAll('.notific-list__item');

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;

            //elems[elems.length - 1].classList.add('control')

            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > this.lastScrollTop) {
                //console.log('down')
                if ((e.target.scrollHeight - e.target.scrollTop) < e.target.clientHeight * 1.2 && !this.dataRichEnd) {
                    this.loading = true;
                    const loadingPage = this.pageActive + 1;
                    this.loadNewData(loadingPage);

                }
            } else {
                //console.log('up')
                if (e.target.scrollTop <= rect.height * 5  && this.prevPagination > 0) {
                    this.loading = true;
                    const loadingPage = this.prevPagination;
                    this.loadNewData(loadingPage, 'start');
                }
            }
            this.lastScrollTop = st <= 0 ? 0 : st;

        }
    }

    isLiked(item){
        if(this.getType(item) == this.notificationsService.liked()){
            return true;
        }

        return false;
    }

    isShared(item){
        if(this.getType(item) == this.notificationsService.shared()){
            return true;
        }

        return false;
    }

    isPurchased(item){
        if(this.getType(item) == this.notificationsService.purchased()){
            return true;
        }

        return false;
    }

    isAttention(item){
        if(this.getType(item) == this.notificationsService.advertisementEnded()){
            return true;
        }

        return false;
    }
}
