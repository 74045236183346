import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class DistributionsService {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this.visible = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
}
DistributionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DistributionsService_Factory() { return new DistributionsService(i0.ɵɵinject(i0.NgZone)); }, token: DistributionsService, providedIn: "root" });
