<div *ngIf="publication !== undefined">
    <app-publication-advertisement (advertisingIsOver)="advertisingIsOver()" [advertising]="getAdvertising(publication)" *ngIf="getAdvertising(publication).url !== ''"></app-publication-advertisement>

    <div *ngIf="getAdvertising(publication).url === ''"  class="sound">
        <img *ngIf="!clickable" src="{{getPlaceholder(publication)}}" alt="" class="sound__picture">

        <button (click)="handleClick()" *ngIf="clickable">
            <img src="{{getPlaceholder(publication)}}" alt="" class="sound__picture">
        </button>

        <audio #audioElement class="js-sound-item" src="{{getFile(publication)}}">
            Your browser does not support the
            <code>audio</code> element.
        </audio>
        <div class="sound__control play-controls">
            <div class="sound__top">
                <div class="sound__title">{{getTitle(publication)}}</div>
                <button *ngIf="!play && getFile(publication) != ''" (click)="toggleMedia()" class="play-controls__btn">
                    <svg class="play-controls__icon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_b)">
                            <circle cx="9" cy="9" r="9" fill="#C4C4C4" fill-opacity="0.6"/>
                        </g>
                        <path d="M13.25 8.13397C13.9167 8.51887 13.9167 9.48113 13.25 9.86603L7.625 13.1136C6.95833 13.4985 6.125 13.0174 6.125 12.2476L6.125 5.75241C6.125 4.9826 6.95833 4.50148 7.625 4.88638L13.25 8.13397Z" fill="white"/>
                        <defs>
                            <filter id="filter0_b" x="-4" y="-4" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                </button>
                <button *ngIf="play && getFile(publication) != ''" (click)="toggleMedia()" class="play-controls__btn play-controls__btn_pause">
                    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1.38452" y1="1.07692" x2="1.38452" y2="12.9231" stroke="#131313" stroke-width="2" stroke-linecap="round"/>
                        <line x1="8.30762" y1="1.07692" x2="8.30762" y2="12.9231" stroke="#131313" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </button>
            </div>
            <div *ngIf="getFile(publication) != ''" class="play-controls__range">
                <div class="range">
                    <input value="0" max="100" min="0" #ranger (input)="changeRange(ranger.value)" class="range__input play-controls__input" type="range">
                    <span [ngStyle]="{width: rangePosition+'%'}" class="range__position"></span>
                </div>

                <div class="play-controls__time">
                    <div class="play-controls__time-start">{{mediaProgressFormated}}</div>
                    <div class="play-controls__time-start">{{mediaDurationFormated}}</div>
                </div>
            </div>
        </div>
    </div>

    <app-publication-statistics *ngIf="!noDescr"
            [data]="{free: getPayment(publication),
                         likes: getLikes(publication),
                         reposts: getReposts(publication),
                         tokens: getTokens(publication)}">
    </app-publication-statistics>

    <div *ngIf="!noDescr" class="info">
        <div *ngIf="!noMenu" class="publication__wrapper publication__wrapper--menu">
    <!--        <app-publication-menu></app-publication-menu>-->
            <app-publication-menu [id]="getId(publication)"></app-publication-menu>

        </div>
    <!--    <img src="../../../../assets/images/{{publication.authorAvatar}}" alt="" class="info__avatar">-->
        <img src="{{getAuthorAvatar()}}" alt="" class="info__avatar">
        <div class="info__about">
            <div class="info__top">
                <div class="info__author">{{getAuthorName()}}</div>
                <ul class="info__categories categories">
                    <li *ngFor="let category of getCategories(publication)" class="categories__category">
                        <svg class="categories__svg_icon">
                            <use [attr.xlink:href]="'#category_'+category"></use>
                        </svg>
                    </li>
                </ul>
            </div>

            <div class="info__title">{{getTitle(publication)}}</div>
            <div class="info__descr">{{getDescription(publication)}}</div>
            <div class="info__tags">
                <div class="tags">
                    <div *ngFor="let tag of getTags(publication)"  class="tags__item">{{tag}}</div>

                </div>
            </div>
            <div class="info__time">
                {{getTimeAgo(publication)}}
            </div>
        </div>
    </div>
</div>


