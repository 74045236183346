import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {TOKENS_ADVERTISEMENT_CONFIG, TOKENS_DISTRIBUTION_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class TokensAdvertisementService {
    private config = TOKENS_ADVERTISEMENT_CONFIG;
    public visible = new BehaviorSubject(null);

    constructor(private http: HttpClient) {

    }

    show() {
        this.visible.next(true);
    }

    hide() {
        this.visible.next(false);
    }

    getData(page) {
        return this.http.post<any>(this.config.data, {page});
    }


    // cost values: free - 1; paid - 2
    getCollection(type, sense, cost, page) {
        return this.http.post<any>(this.config.collection, {type, sense, cost, page});
    }

    saveSettings(elements, budget, duration) {
        return this.http.post<any>(this.config.submit, {elements, budget, duration});
    }
}
