import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import 'chartjs-plugin-datalabels';
import {Color, Label} from "ng2-charts";
import {ChartDataSets, ChartOptions} from "chart.js";
import {MenuService} from "../../service/menu.service";
import {SignUpService} from "../../service/signup.service";
import {AccessService} from "../../service/users/access.service";
import {LoginService} from "../../service/login.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {TokensService} from "../../service/tokens/tokens.service";
import {UserEditingService} from "../../service/admin/userEditing.service";
import {AdSettingsService} from "../../service/admin/adSettings.service";
import {DistributionsService} from "../../service/admin/distributions/distributions.service";
import {NotificationsListService} from "../../service/users/notificationsList.service";
import {PROJECT_CONFIG} from "../../config/project";
import {AccountSettingsService} from "../../service/account/accountSettings.service";
import {CollectionService} from "../../service/collection.service";

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    notificationsTotalAmount;
    chartPopup = false;

    chartPie = false;
    chartLine = false;
    chartBar = false;

    menu = true;
    signup = false;
    @Input() handleClick: any;
    @Output() hidenMenu = new EventEmitter();


    // PIE CHART SETTINGS
    public pieChartOptions: any = {
        responsive: true,
        legend: {
            display: false,
            labels: {
                display: true
            }
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    return value + '%';
                },
                color: '#000',
            }
        }
    };
    public pieChartLabels = ['lorem', 'lorem', 'lorem'];
    public pieChartData = [27, 31, 42];
    public pieChartType = 'pie';
    public pieChartColors: Array<any> = [
        { // first color
            // backgroundColor: '#FBE399',
            backgroundColor: ['#FE5E8B', '#C961BD', '#FBE399'],
            borderColor: '#2b2b2b',
            pointBackgroundColor: 'rgba(225,10,24,0.2)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(225,10,24,0.2)'
        }
        ];


    // LINE CHART SETTINGS
    lineChartData: ChartDataSets[] = [
        { data: [450, 59, 80, 81, 56, 55, 40], label: 'lorem 1', borderWidth: 1 },
        { data: [28, 48, 300, 19, 86, 150, 90], label: 'lorem 2', borderWidth: 1 },
        { data: [28, 48, 40, 19, 200, 27, 90], label: 'lorem 3', borderWidth: 1 }
    ];

    //Labels shown on the x-axis
    lineChartLabels: Label[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    // Define chart options
    lineChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            display: false,
        },
        plugins: {
            datalabels: {
                display: false
            }
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    beginAtZero: true,
                    fontColor: "#fff", // this here
                }
            }],
            yAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    beginAtZero: true,
                    fontColor: "#fff", // this here
                    maxTicksLimit: 5
                }
            }]
        }
    };

    // Define colors of chart segments
    lineChartColors: Color[] = [

        { // green
            borderColor: '#AAF5B8',
            pointBackgroundColor: '#AAF5B8',
        },
        { // blue
            borderColor: '#3992E1',
            pointBackgroundColor: '#3992E1',
        },
        { // violet
            borderColor: '#7D5081',
            pointBackgroundColor: '#7D5081',
        }
    ];

    // Define type of chart
    lineChartType = 'line';

    lineChartPlugins = [];

    // BAR CHART SETTINGS
    public barChartOptions = {
        legend: {
            display: false,
            labels: false
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: false
            }
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    beginAtZero: true,
                    fontColor: "#fff", // this here
                }
            }],
            yAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    beginAtZero: true,
                    fontColor: "#fff", // this here
                    maxTicksLimit: 6
                }
            }]
        }
    };
    public barChartLabels = ['Lorem', 'Lorem', 'Lorem'];
    public barChartType = 'bar';
    public barChartData = [
        {
            data: [400, 270, 250],
            backgroundColor: ['#4970D1', '#9769CD', '#C961BD']
        },
    ];

    items = [
        {
            title: 'Trees',
            desc: 'For your garden',
            img: 'search/bg_1.jpg',
            categories: [
                'vision',
                'smell'
            ]
        },
        {
            title: 'Trees',
            desc: 'For your garden',
            img: 'search/bg_2.jpg',
            price: '98.30',
            categories: [
                'vision',
                'sound'
            ]
        },
        {
            title: 'Trees',
            desc: 'For your garden',
            img: 'search/bg_3.jpg',
            categories: [
                'vision'
            ]
        },
        {
            title: 'Trees',
            desc: 'For your garden',
            img: 'search/bg_4.jpg',
            price: '98.30',
            categories: [
                'vision',
                'sense',
                'taste'
            ]
        },
        {
            title: 'Trees',
            desc: 'For your garden',
            img: 'search/bg_1.jpg',
            categories: [
                'vision',
                'sound',
                'touch'
            ]
        },
        {
            title: 'Trees',
            desc: 'For your garden',
            img: 'search/bg_2.jpg',
            categories: [
                'vision',
                'smell'
            ]
        },
        {
            title: 'Trees',
            desc: 'For your garden',
            img: 'search/bg_3.jpg',
            categories: [
                'vision',
                'taste'
            ]
        },
        {
            title: 'Trees',
            desc: 'For your garden',
            img: 'search/bg_4.jpg',
            price: '98.30',
            categories: [
                'vision',
                'taste'
            ]
        },
        {
            title: 'Trees',
            desc: 'For your garden',
            img: 'search/bg_1.jpg',
            categories: [
                'vision',
                'smell',
                'touch'
            ]
        },
    ];

    projectConfig = PROJECT_CONFIG;
    storage = sessionStorage;

    currentUser = {
        firstName: '',
        lastName: '',
        avatar: ''
    };

    constructor(private menuService: MenuService,
                private signUpService: SignUpService,
                private loginService: LoginService,
                private accountSettingsService: AccountSettingsService,
                private access: AccessService,
                private auth:AuthenticationService,
                private tokensService: TokensService,
                private userEditingService: UserEditingService,
                private adSettingsService: AdSettingsService,
                private distributionsService: DistributionsService,
                private notificationsListService: NotificationsListService,
                private collectionService: CollectionService,
                private router: Router) {
        this.auth.userLoggedIn.subscribe((value: any) => this.userLoggedIn(value));
        this.menuService.totalNotificationsAmount.subscribe((value: any) => this.updateNotificationsTotalAmount(value));

    }

    ngOnInit() {
        this.userLoggedIn(true);
    }

    userLoggedIn(value) {
        const sessionCurrentUser = this.auth.getCurrentUser();

        if (sessionCurrentUser && value) {
            this.currentUser = {
                firstName: sessionCurrentUser.first_name,
                lastName: sessionCurrentUser.last_name,
                avatar: sessionCurrentUser.avatar_link ? sessionCurrentUser.avatar_link : this.projectConfig.defaultAvatar
            }
        }
    }

    closeMenu() {
        this.menu = false;
        this.menuService.hide();
    }

    showSignUp() {
        this.hideAllMenuItems();
        this.signup = false;
        this.signUpService.show();
        document.body.classList.add('is-hidden');
    }

    showLogin() {
        this.hideAllMenuItems();
        this.loginService.show();
    }

    showAccountSettings() {
        this.hideAllMenuItems();
        this.accountSettingsService.show();
    }

    logOut(){
        this.auth.logout();
    }

    showMore() {
        this.notificationsListService.show();
    }

    toggleAccordion(type) {
        if (type === 'pie') {
            this.chartLine = false;
            this.chartBar = false;
            if (this.chartPie) {
                this.hideAllMenuItems();
                this.tokensService.show();
            } else {
                this.chartPie = true;
            }
        }
        if (type === 'line') {
            this.chartPie = false;
            this.chartBar = false;
            if (this.chartLine) {
                this.hideAllMenuItems();
                this.router.navigate(["/library"]);
            } else {
                this.chartLine = true;
            }
        }
        if (type === 'bar') {
            this.chartPie = false;
            this.chartLine = false;
            if (this.chartBar) {
                this.hideAllMenuItems();
                this.collectionService.show();
            } else {
                this.chartBar = true;
            }
        }
    }

    openChart() {
        this.chartPopup = true;
    }

    closeChartPopup() {
        this.chartPopup = false;
    }

    isCustomer(){
        return this.access.isCustomer();
    }

    isAdmin(){
        return this.access.isAdmin();
    }

    isAuth(){
        return this.auth.isUserLogged();
    }

    showUsers() {
        this.hideAllMenuItems();
        this.userEditingService.show();
    }

    showAdSettings() {
        this.hideAllMenuItems();
        this.adSettingsService.show();
    }

    showDistributions() {
        this.hideAllMenuItems();
        this.distributionsService.show();
    }

    updateNotificationsTotalAmount(value) {
        this.notificationsTotalAmount = value ? value : 0;
    }

    hideAllMenuItems() {
        this.distributionsService.hide();
        this.adSettingsService.hide();
        this.userEditingService.hide();
        this.collectionService.hide();
        this.tokensService.hideSection();
        this.accountSettingsService.hideSection();
        this.loginService.hide();
        this.signUpService.hide();
        document.body.classList.remove('is-hidden');
        this.menuService.hide();
    }

}
