import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {USERS_EDITING_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class UserEditingService {
    private config = USERS_EDITING_CONFIG;
    visible = new BehaviorSubject(null);

    constructor(private http: HttpClient) {

    }

    show() {
        this.visible.next(true);
    }

    hide() {
        this.visible.next(false);
    }

    getUsers(name, phone) {
        return this.http.post<any>(this.config.data, {name, phone});
    }
}
