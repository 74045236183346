import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./accountSettingsInfo.service";
import * as i2 from "./accountSettingsPayment.service";
export class AccountSettingsService {
    constructor(ngZone, accountSettingsInfoService, accountSettingsPaymentService) {
        this.ngZone = ngZone;
        this.accountSettingsInfoService = accountSettingsInfoService;
        this.accountSettingsPaymentService = accountSettingsPaymentService;
        this.visible = new BehaviorSubject(null);
    }
    show() {
        this.visible.next(true);
    }
    hide() {
        this.visible.next(false);
    }
    hideSection() {
        this.hide();
        this.accountSettingsInfoService.hide();
        this.accountSettingsPaymentService.hide();
    }
}
AccountSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountSettingsService_Factory() { return new AccountSettingsService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.AccountSettingsInfoService), i0.ɵɵinject(i2.AccountSettingsPaymentService)); }, token: AccountSettingsService, providedIn: "root" });
