<div class="users content" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">Users</app-header>
    <div class="users__inner">
        <form [formGroup]="userForm" (submit)="searchUser($event)" class="form-simple users__form">
            <div class="form-simple__item">
                <input formControlName="name"
                       placeholder="Name"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">

            </div>
            <div class="form-simple__item">
                <input mask="(000) 000-0000"
                       formControlName="phone"
                       placeholder="Phone Number"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">

            </div>

            <div class="form-simple__btns">
                <button class="form-simple__btn">
                    Search
                </button>
            </div>
        </form>

        <div class="users__result">
            <ul class="result">

                <li *ngFor="let item of items" class="result__item">
                    <img src="{{getImg(item)}}" alt="" class="result__avatar">
                    <div class="result__info">
                        <div class="result__info-top">{{getFirstName(item)}}</div>
                        <div class="result__info-bottom">{{getLastName(item)}}</div>
                    </div>
                    <button (click)="showUserInfo(getId(item))" class="result__btn">
                        <span class="visually-hidden">Edit</span>
                        <svg width="19" height="18">
                            <use xlink:href="../../../../assets/images/sprite.svg#edit"></use>
                        </svg>
                    </button>
                </li>

            </ul>
        </div>
    </div>
</div>

<app-edit-user></app-edit-user>
