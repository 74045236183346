<button
    [ngClass]="getClasses()"
    (click)="handleClick()"
    class="btn">
    <ng-content></ng-content>

    <div *ngIf="type == 'close'">
        <span class="visually-hidden">Close</span>
        <svg class="btn__icon-close" width="34" height="34">
            <use xlink:href="../../../../assets/images/sprite.svg#close"></use>
        </svg>
    </div>
</button>
