import * as i0 from "@angular/core";
export class JwtInterceptor {
    constructor() {
        this.hasError = false;
        this.refreshingToken = false;
    }
    intercept(request, next) {
        const w = window;
        if (w && w.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${w.token}`
                }
            });
        }
        return next.handle(request);
    }
}
JwtInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtInterceptor_Factory() { return new JwtInterceptor(); }, token: JwtInterceptor, providedIn: "root" });
