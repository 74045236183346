import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ArticleService} from "../../service/article.service";
import {SearchService} from "../../service/search.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {LoginService} from "../../service/login.service";

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
    //@Input() popup: boolean;
    //@Input() data: any;
    //@Output() popupShow = new EventEmitter();

    free;
    cost;

    visible;
    search = false;
    data = [];

    details = false;
    repost = false;
    activeSlide = 1;
    like = false;

    noUpdatedData = false;

    constructor(private articleService: ArticleService,
                private popupComponent: PopupComponent,
                private auth:AuthenticationService,
                private loginService: LoginService,
                private searchService: SearchService) {
        this.articleService.articleId.subscribe((value: any) => this.changeVisibility(value));
        this.searchService.visible.subscribe((value: any) => this.changeSearchVisibility(value));

    }

    ngOnInit() {

    }

    isRepost(state) {
        this.repost = state;
    }

    closePopup() {
        this.articleService.hide();

    }

    showDetails(state) {
        //this.details = state;
        //console.log(this.data, this.activeSlide)

        const publicationId = this.activeSlide;

        if (state === 'paid') {


            if (this.auth.isUserLogged()) {
                this.articleService.buyPublication(publicationId).subscribe(data => {
                    if (data.status) {
                        let advertising = {url: "",
                            time: 0};
                        this.articleService.showDetailsId.next({ id: publicationId,
                            advertising: advertising});
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                })
            } else {
                this.popupComponent.showPopupAgreement({text: 'You should be logged in for watching paid content. Do you want to log in?',
                    confirmButtonText: 'Yes, I do!',
                    cancelButtonText: 'No, I do not!'}, (status) => {
                    if (status) {
                        this.loginService.show();
                    }
                })
            }

        } else {
            this.articleService.setAdvertising(publicationId).subscribe(data => {
                console.log(data)
                let advertising, advertisingVideo;
                if (data.status && data.advertisement.status) {
                    advertising = {url: data.advertisement.url_link,
                                   type: data.advertisement.file_type,
                                   time: data.advertisement.time};
                } else {
                    advertising = {url: "",
                                   time: 0};
                }
                this.articleService.showDetailsId.next({ id: publicationId,
                                                                advertising: advertising});
            })
        }

    }

    hideDetails(state) {
        this.details = state;
    }

    onSlideChange(state) {
        this.activeSlide = state.slideId;
        this.free = state.paymentType;
        this.cost = state.paymentForView;
        if (!this.cost) {
            this.free = 1;
        }
        this.like = state.liked;
    }

    changeVisibility(value) {
        if (value) {
            this.articleService.getData(value).subscribe(data => {
                if (data.status) {
                    this.data = data.result;
                    this.activeSlide = this.data[0].id;
                    this.free = this.data[0].payment_type;
                    //this.free = 1;
                    this.cost = this.data[0].payment_for_view;
                    if (!this.cost) {
                        this.free = 1;
                    }

                    this.like = this.data[0].user_likes ? this.data[0].user_likes : false;
                    this.articleService.createSlider();
                    this.visible = true;
                }
            })
        } else {
            this.visible = false;
        }
    }

    changeSearchVisibility(value) {
        this.search = value;
    }

    toggleLike(state) {
        console.log(this.activeSlide)

        if (this.auth.isUserLogged()) {
            this.articleService.toggleLike(this.activeSlide).subscribe(data => {
                if (data.status) {
                    this.like = state;
                    this.data.find(el => {
                        if (el.id === this.activeSlide) {
                            el.user_likes = state;
                            if (state) {
                                el.likes += 1;
                            } else {
                                el.likes -= 1;
                            }
                        }
                    });
                }
            });
        } else {
            this.popupComponent.showPopupAgreement({text: 'You should be logged in for liking content. Do you want to log in?',
                confirmButtonText: 'Yes, I do!',
                cancelButtonText: 'No, I do not!'}, (status) => {
                if (status) {
                    this.loginService.show();
                }
            })
        }


    }

}
