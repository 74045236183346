import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./users/notificationsList.service";
export class MenuService {
    constructor(ngZone, route, router, notificationsListService) {
        this.ngZone = ngZone;
        this.route = route;
        this.router = router;
        this.notificationsListService = notificationsListService;
        this.menuChanged = new BehaviorSubject(false);
        this.totalNotificationsAmount = new BehaviorSubject(null);
    }
    show() {
        this.menuChanged.next(true);
        //this.searchService.hide();
        this.getTotalNotificationsAmount();
        document.body.classList.add('is-hidden');
    }
    hide() {
        this.menuChanged.next(false);
        // this.router.navigate(
        //     [],
        //     {
        //         relativeTo: this.route,
        //         queryParams: {},
        //         //queryParamsHandling: 'merge', // remove to replace all query params by provided
        //     });
        document.body.classList.remove('is-hidden');
    }
    getTotalNotificationsAmount() {
        this.notificationsListService.getTotal().subscribe(data => {
            if (data.status) {
                this.totalNotificationsAmount.next(data.result);
            }
        });
    }
}
MenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.NotificationsListService)); }, token: MenuService, providedIn: "root" });
