import {Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnInit, Output} from '@angular/core';
import {PopupComponent} from "../../popup/popup.component";
import {CollectionService} from "../../../service/collection.service";
import {CollectionListService} from "../../../service/collectionList.service";
import {LoadPreviewService} from "../../../service/global-library/loadpreview.service";
import {NewContentService} from "../../../service/content/new-content.service";

@Component({
    selector: 'app-publication-menu',
    templateUrl: './publication-menu.component.html',
    styleUrls: ['./publication-menu.component.scss']
})
export class PublicationMenuComponent implements OnInit {

    menu = false;
    @Input() id;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.menu) {
            if(!this.eRef.nativeElement.contains(event.target) && !event.target.classList.contains('btn-menu')) {
                this.menu = false;
            }
        }

    }


    constructor(private eRef: ElementRef,
                private collectionService: CollectionService,
                private collectionListService: CollectionListService,
                private loadPreview: LoadPreviewService,
                private ngZone: NgZone,
                private newContentService: NewContentService,
                private popupComponent: PopupComponent) {
    }

    ngOnInit() {

    }

    toggleMenu() {
        this.menu = !this.menu;
    }

    editPublication() {
        this.loadPreview.createContent(this.id).subscribe(data => {
            if (data.id) {
                this.ngZone.run( () => {
                    //this.router.navigate(['/new-content/', data.id])
                    this.newContentService.setId(data.id);
                    this.newContentService.show();

                    //this.popup = false;
                    //this.backService.setLink("global-library");
                });
            }
        })
    }

    askAboutDeleting() {
        this.popupComponent.showPopupAgreement({text: 'Do you really want to delete this publication?',
            confirmButtonText: 'Yes, I do!',
            cancelButtonText: 'No, I don\'t!'}, (status) => {
            if (status) {
                this.deletePublication();
                console.log(status)
            }
        });
    }

    deletePublication() {
        this.collectionService.deletePost(this.id).subscribe(data => {
            if (data.status) {
                this.collectionListService.hide();
                this.collectionService.show();
            } else {
                this.popupComponent.showPopupError({text: data.message})
            }
        })
    }
}
