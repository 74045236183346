import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PAYOUTS_TO_USERS_CONFIG} from "../../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class DistributionsPayoutsService {
    private config = PAYOUTS_TO_USERS_CONFIG;
    public visible = new BehaviorSubject(null);

    constructor(private http: HttpClient) {

    }

    show() {
        this.visible.next(true);
    }

    hide() {
        this.visible.next(false);
    }

    getData() {
        return this.http.post<any>(this.config.data, {});
    }
}
