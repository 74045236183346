<div *ngIf="visible"  class="details" [class.is-active]="visible">
    <div class="details__inner">


        <div class="details__header">
            <app-header-search [canAdd]="false" (searchOpen)="searchOpen($event)" [hidenDetails]="hidenDetails" [onClick]="hidenDetails"></app-header-search>
        </div>

<!--        <div class="details__header" *ngIf="!item.advertising">-->
<!--            <app-header-search (searchOpen)="searchOpen($event)" [hidenDetails]="hidenDetails" [onClick]="hidenDetails"></app-header-search>-->
<!--        </div>-->

<!--        <div class="details__advertising" *ngIf="item.advertising">-->
<!--            <app-publication-advertisement></app-publication-advertisement>-->
<!--        </div>-->

        <div *ngIf="search" class="details__search">
            <app-search></app-search>
        </div>




    </div>
    <div *ngIf="!search">
        <div *ngIf="contentType === 'sound' && !search" class="details__sound">
            <app-sound-publication [menu]="menu" (menuShowed)="menuShowed($event)" [publication]="item"></app-sound-publication>
        </div>


        <div class="details__video" *ngIf="contentType === 'video' && !search">
            <app-video-publication [publication]="item"></app-video-publication>
        </div>

        <div class="details__image" *ngIf="contentType === 'image' && !search">
            <app-image-publication (likeEmitted)="toggleLike($event)" [publication]="item"></app-image-publication>
        </div>
    </div>


</div>


