export class NotificationItem {
    public _text: string;
    private _type: number;
    private _time: string;
    public _avatar: string;

    constructor(data) {
        this._text = data.title;
        this._type = data.type;
        this._time = data.time_ago;
        this._avatar = data.avatar_link;
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    get type(): number {
        return this._type;
    }

    set type(value: number) {
        this._type = value;
    }

    get time(): string {
        return this._time;
    }

    set time(value: string) {
        this._time = value;
    }

    get avatar(): string {
        return this._avatar;
    }

    set avatar(value: string) {
        this._avatar = value;
    }

}
