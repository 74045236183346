import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TokensTokensService} from "../../../service/tokens/tokensTokens.service";
import {BalanceCard} from "../../../models/balanceCard";
import {ActivityItem} from "../../../models/activityItem";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {TokensService} from "../../../service/tokens/tokens.service";
import {MenuService} from "../../../service/menu.service";
import {DistributionsRatioService} from "../../../service/admin/distributions/distributionsRatio.service";
import {ScrollUpdatingService} from "../../../service/scrollUpdating.service";

@Component({
    selector: 'app-tokens-tokens',
    templateUrl: './tokens-tokens.component.html',
    styleUrls: ['./tokens-tokens.component.scss'],
    providers: [ScrollUpdatingService]
})
export class TokensTokensComponent implements OnInit {

    visible: boolean;
    purchase = false;

    purchaseData = {
        tokens: 0,
        amount: 0
    };

    ratioForm: FormGroup;

    token: number;
    dollar: number;

    data: BalanceCard;
    activity: [ActivityItem?];


    pagesLoaded = 0;

    countLastAddedItems;
    itemsRemovedFromBegining = 0;

    previewPagination = 0;
    prevPagination = 0;
    dataRichEnd = false;

    loading = false;

    pageActive = 1;
    init = true;
    lastScrollTop = 0;

    limit = 0;

    constructor(private formBuilder: FormBuilder,
                private tokensTokensService: TokensTokensService,
                private tokensService: TokensService,
                private menuService: MenuService,
                private distributionsRatioService: DistributionsRatioService,
                private scrollUpdating: ScrollUpdatingService,
                private popupComponent: PopupComponent) {
        this.tokensTokensService.tokensVisible.subscribe((value: any) => this.componentVisibility(value));
    }

    ngOnInit() {
        this.ratioForm = this.formBuilder.group({
            token: [null, Validators.required],
            dollar: [null, Validators.required]
        });


    }

    onFormSubmit() {
        const formData = this.ratioForm.value;
        this.tokensTokensService.setRatio(formData.dollar).subscribe(data => {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});

                this.loadData();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        });
    }

    showPurchase() {
        this.purchase = !this.purchase;
    }

    componentVisibility(state) {
        this.visible = state;
        if (this.visible) {
            this.loadData();
        }
    }

    loadData(){
        this.tokensTokensService.getData(1).subscribe(data => {
            if (data.status) {
                this.activity = data.result;
                this.limit = data.limit;

                this.scrollUpdating.setInitialData({
                    items: this.activity,
                    limit: this.limit,
                    cssClass: '.activity__item',
                    service: this.tokensTokensService,
                    serviceName: 'tokensTokens',
                    additionalParams: ''
                });
            }
            if (data.info) {
                this.data = data.info;
                this.data.currency = 'tokens';
                this.data.action = 'Last Purchase: ';
            }
            if (!data.status && !data.result && ! data.info) {
                this.visible = false;
            }
            this.purchaseData.tokens = data.purchase.tokens;
            this.purchaseData.amount = data.purchase.amount;

        });
    }

    emitScroll($event) {
        this.scrollUpdating.scroll($event);
    }

    goBack() {
        this.tokensTokensService.hide();
        this.activity = [];
    }

    menu() {
        // this.tokensTokensService.hide();
        // this.tokensService.hide();
        // this.activity = [];
        this.menuService.show();
    }

    inputDollars($event) {

        const newValue = +$event.target.value;

        this.dollar = newValue;
        const dollarsToTokens = this.purchaseData.tokens;

        this.token = this.dollar * dollarsToTokens;
        this.token = Math.round((this.token)*100)/100;
        this.ratioForm.controls['token'].setValue(this.token);
    }

    inputTokens($event) {
        const newValue = +$event.target.value;
        const dollarsToTokens = this.purchaseData.tokens;
        const countDollars = newValue / dollarsToTokens;

        this.token = newValue;
        this.dollar = this.token / dollarsToTokens;
        this.dollar = Math.round((this.dollar)*100)/100;
        this.ratioForm.controls['dollar'].setValue(this.dollar);
    }



    // loadNewData() {
    //
    //
    //     this.tokensTokensService.getData(1).subscribe(data => {
    //         if (data.status) {
    //             console.log('load', data.result)
    //             this.limit = data.result.limit;
    //             if (data.result.length < this.limit) {
    //                 this.previewPagination += 1;
    //                 this.pageActive = this.previewPagination;
    //                 this.loading = false;
    //                 this.dataRichEnd = true;
    //                 this.itemsRemovedFromBegining = data.result.length;
    //
    //                 for (let i = 0; i < data.result.length; i++) {
    //                     this.activity.push(data.result[i]);
    //                 }
    //
    //                 this.prevPagination += 1;
    //                 this.pagesLoaded += 1;
    //
    //                 this.countLastAddedItems = data.result.length;
    //                 return;
    //             } else {
    //                 this.activity = [];
    //                 for (let i = 0; i < data.result.length; i++) {
    //                     this.activity.push(data.result[i]);
    //                 }
    //                 this.previewPagination = 1;
    //                 this.prevPagination = 0;
    //                 if (data.result.length < 50) {
    //                     this.dataRichEnd =  true;
    //                 }
    //                 this.pagesLoaded = 1;
    //             }
    //
    //             this.pageActive = this.previewPagination;
    //             //console.log(this.items);
    //
    //             setTimeout(() => {
    //                 this.loading = false;
    //             }, 500);
    //         }
    //     })
    // }

    // scrollHandler(e) {
    //     if (!this.loading) {
    //         const elems = document.querySelectorAll('.notific-list__item');
    //
    //         const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
    //
    //         //elems[elems.length - 1].classList.add('control')
    //
    //         var st = e.target.pageYOffset || e.target.scrollTop;
    //         if (st > this.lastScrollTop) {
    //             //console.log('down')
    //             if ((e.target.scrollHeight - e.target.scrollTop) < e.target.clientHeight * 1.2 && !this.dataRichEnd) {
    //                 this.loading = true;
    //                 const loadingPage = this.pageActive + 1;
    //                 this.loadNewData(loadingPage);
    //
    //             }
    //         } else {
    //             //console.log('up')
    //             if (e.target.scrollTop <= rect.height * 5  && this.prevPagination > 0) {
    //                 this.loading = true;
    //                 const loadingPage = this.prevPagination;
    //                 this.loadNewData(loadingPage, 'start');
    //             }
    //         }
    //         this.lastScrollTop = st <= 0 ? 0 : st;
    //
    //     }
    // }
}
