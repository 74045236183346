import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {USER_INFO_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class AccountSettingsInfoService {
    public visible = new BehaviorSubject(null);
    private config = USER_INFO_CONFIG;

    constructor(private ngZone: NgZone,
                private http: HttpClient) {

    }

    show() {
        this.visible.next(true);

    }

    hide() {
        this.visible.next(false);
    }

    loadData() {
        return this.http.post<any>(this.config.info, {});
    }

    setData(phone,firstName,lastName,userName) {
        return this.http.post<any>(this.config.save, {phone,firstName,lastName,userName});
    }

}




