<div class="users content" [class.is-visible]="visible">
    <app-header [backActionCheck]="true"
                (backAction)="goBack()"
                [menuActionCheck]="true"
                (menuAction)="menu()">Edit User</app-header>
    <div class="users__inner">
        <img (click)="addAvatar()" src="{{getUserAvatar()}}" alt="" class="users__user">
        <form [formGroup]="userEditForm" (ngSubmit)="onUserSubmit()" class="form-simple">
            <div class="form-simple__item">
                <input mask="(000) 000-0000"
                       formControlName="phone"
                       placeholder="Phone Number"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>

            <div class="form-simple__item">
                <input formControlName="firstName"
                       placeholder="First Name"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>

            <div class="form-simple__item">
                <input formControlName="lastName"
                       placeholder="Last Name"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>

            <div class="form-simple__item">
                <input formControlName="userName"
                       placeholder="UserName"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>


            <div class="form-simple__btns">
                <button type="button" *ngIf="userState" (click)="changeUserState()" class="form-simple__btn">
                    SUSPEND
                </button>
                <button type="button" *ngIf="!userState" (click)="changeUserState()" class="form-simple__btn">
                    ACTIVATE
                </button>
                <button type="submit" class="form-simple__btn" (click)="saveUserInfo()" [disabled]="!userEditForm.valid">
                    Save
                </button>
            </div>
        </form>
    </div>
</div>

