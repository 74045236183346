import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";
import {SignUpComponent} from "./signup/signup.component";
import {UsersComponent} from "./admin/users/users.component";
import {EditUserComponent} from "./admin/users/edit-user/edit-user.component";
import {DistributionsComponent} from "./admin/distributions/distributions.component";
import {DistributionsRatioComponent} from "./admin/distributions/distributions-ratio/distributions-ratio.component";
import {DistributionsPayoutsComponent} from "./admin/distributions/distributions-payouts/distributions-payouts.component";
import {DistributionsSnekComponent} from "./admin/distributions/distributions-snek/distributions-snek.component";
import {AccountInfoComponent} from "./account/account-info/account-info.component";
import {AccountPaymentComponent} from "./account/account-payment/account-payment.component";
import {AccountComponent} from "./account/account.component";
import {TokensComponent} from "./tokens/tokens.component";
import {TokensTokensComponent} from "./tokens/tokens-tokens/tokens-tokens.component";
import {TokensSweepstakesComponent} from "./tokens/tokens-sweepstakes/tokens-sweepstakes.component";
import {TokensAdvertisementComponent} from "./tokens/tokens-advertisement/tokens-advertisement.component";
import {TokensDistributionComponent} from "./tokens/tokens-distribution/tokens-distribution.component";
import {TokensBudgetComponent} from "./tokens/tokens-budget/tokens-budget.component";
import {TokensCreateAdvertisementComponent} from "./tokens/tokens-create-advertisement/tokens-create-advertisement.component";
import {AdSettingsComponent} from "./admin/ad-settings/ad-settings.component";
import {AdSettingsCostComponent} from "./admin/ad-settings/ad-settings-costs/ad-settings-cost.component";
import {AdSettingsActiveComponent} from "./admin/ad-settings/ad-settings-active/ad-settings-active.component";
import {CollectionComponent} from "./collection/collection.component";
import {LibraryComponent} from "./library/library.component";
import {CollectionListComponent} from "./collection-list/collection-list.component";
import {GlobalLibraryComponent} from "./global-library/global-library.component";
import {NewContentComponent} from "./new-content/new-content.component";

import {AuthGuard} from '../modules/auth/_guards/auth.guard';

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: HomeComponent},
            {path: 'home/:page', component: HomeComponent},
            {path: 'redirected', component: HomeComponent},
            {path: 'login', component: LoginComponent},
            {path: 'signup', component: SignUpComponent},
            // {path: 'distributions', component: DistributionsComponent},
            // {path: 'token-to-dollar-ratio', component: DistributionsRatioComponent},
            // {path: 'payouts-to-users', component: DistributionsPayoutsComponent},
            // {path: 'snek-a-pek-payuots', component: DistributionsSnekComponent},
            // {path: 'account', component: AccountComponent},
            // {path: 'personal-info', component: AccountInfoComponent, canActivate: [AuthGuard]},
            // {path: 'payment-method', component: AccountPaymentComponent, canActivate: [AuthGuard]},
            {path: 'library', component: LibraryComponent},
            // {path: 'new-content', component: NewContentComponent},
            // {path: 'new-content/:id', component: NewContentComponent},
            // {path: 'tokens-budget', component: TokensBudgetComponent},
            // {path: 'create-advertisement', component: TokensCreateAdvertisementComponent},
            // {path: 'collection', component: CollectionComponent},
            // {path: 'collection-list', component: CollectionListComponent},
            {path: '**', redirectTo: 'redirected', pathMatch: 'full'}
        ]
    }

];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
