import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Input() type

    ishiden = false;

    @Input() filter: boolean;
    @Output('onClick')
    emitClick: EventEmitter<any> = new EventEmitter();


    constructor() {
    }

    ngOnInit() {

    }

    toggleVariants() {
        this.ishiden =  !this.ishiden;
    }

    getClasses() {
        if (this.type == 'close') {
            return 'btn_close';
        }
    }

    handleClick() {
        this.emitClick.emit();
    }

}
