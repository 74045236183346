import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

import {OwlOptions, SlidesOutputData} from 'ngx-owl-carousel-o';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {CollectionListService} from "../../service/collectionList.service";
import {MenuService} from "../../service/menu.service";
import {CollectionService} from "../../service/collection.service";
import {PublicationItem} from "../../models/publicationItem";
import {POST_TYPES} from "../../config/post_types";


@Component({
    selector: 'app-collection-list',
    templateUrl: './collection-list.component.html',
    styleUrls: ['./collection-list.component.scss']
})
export class CollectionListComponent implements OnInit, AfterViewInit {
    visible: boolean;
    publicationId;

    postTypes = POST_TYPES;
    @Input() settings;

    config: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 'auto',
        loop: true,
        initialSlide: 4,
        shortSwipes: false,
        preventClicks: false,
        preventClicksPropagation: false,
    };

    menu = false;
    index = 2;
    oldIndex = 2;
    swiper;

    filter = false;

    customOptions = {
        loop: true,
        dots: false,
        navSpeed: 700,
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
    }

    items = []

    itemsSlider = [];

    activeSlides: SlidesOutputData;

    constructor(private elementRef: ElementRef,
                private menuService: MenuService,
                private collectionService: CollectionService,
                private collectionListService: CollectionListService) {
        this.collectionListService.visible.subscribe((value: any) => this.changeVisibility(value));
        this.collectionListService.id.subscribe((value: any) => this.publicationIdChange(value));


    }

    ngOnInit() {
        //this.itemsSlider  = this.items.slice(4);

        this.itemsSlider  = this.items;
    }

    ngAfterViewInit(): void {


    }

    menuShowed(state) {
        this.menu = state;
    }


    public onIndexChange($event) {
        this.index = this.swiper.realIndex;
        //console.log(this.swiper.realIndex)

        // console.log(this.itemsSlider.length+"kkkk");
        // console.log(this.index, this.oldIndex);

        if (this.index > this.oldIndex) {



            // this.itemsSlider[nextIndex] = {
            //
            //     type: 'sound',
            //     img: 'collection/img_11.jpg',
            //     likes: 122,
            //     tokens: 29,
            //     reposts: 50,
            //     author: 'User Name',
            //     authorAvatar: 'woman-avatar.jpg',
            //     title: 'Lorem Ipsum 5555-'+Math.round(+new Date()/1000)+Math.random(),
            //     categories: [
            //         'vision',
            //         'sense'
            //     ],
            //     descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta dignissim. ',
            //     tags: [
            //         '#tagtag',
            //         '#tag',
            //         '#tagtag',
            //         '#tag',
            //         '#tag'
            //     ],
            //     time: '1 hour ago'
            //
            // }

            console.log('next dd')
            // this.itemsSlider.shift();
            // this.swiper.removeSlide(0);
            // this.swiper.ape
            // this.swiper.updateSlides();

        } else {
            console.log('prev')
            // this.itemsSlider[0] = {
            //
            //     type: 'sound',
            //     img: 'collection/img_11.jpg',
            //     likes: 122,
            //     tokens: 29,
            //     reposts: 50,
            //     author: 'User Name',
            //     authorAvatar: 'woman-avatar.jpg',
            //     title: 'Lorem Ipsum 000000-'+Math.round(+new Date()/1000)+Math.random(),
            //     categories: [
            //         'vision',
            //         'sense'
            //     ],
            //     descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta dignissim. ',
            //     tags: [
            //         '#tagtag',
            //         '#tag',
            //         '#tagtag',
            //         '#tag',
            //         '#tag'
            //     ],
            //     time: '1 hour ago'
            //
            // }


        }
        this.oldIndex = this.index;


    }

    getData(data: SlidesOutputData) {
        this.activeSlides = data;
        // console.log(this.activeSlides.startPosition);
        const index = this.activeSlides.startPosition;
        if (index > this.oldIndex) {
            // this.itemsSlider[1] = {
            //
            //     type: 'music',
            //     img: 'collection/img_11.jpg',
            //     likes: 122,
            //     tokens: 29,
            //     reposts: 50,
            //     author: 'User Name',
            //     authorAvatar: 'woman-avatar.jpg',
            //     title: 'Lorem Ipsum wdwdw22',
            //     categories: [
            //         'vision',
            //         'sense'
            //     ],
            //     descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta dignissim. ',
            //     tags: [
            //         '#tagtag',
            //         '#tag',
            //         '#tagtag',
            //         '#tag',
            //         '#tag'
            //     ],
            //     time: '1 hour ago'
            //
            // }

            //console.log('next', this.itemsSlider)

        } else {

            console.log('prev', this.itemsSlider)

        }
        //this.oldIndex = index;

    }

    activeIndex = 3;
    list = 3
    slideNext() {

        console.log('next', this.swiper.activeIndex, this.swiper.realIndex, this.items.length,this.activeIndex)
        //console.log(this.swiper.activeIndex - 5)
        //if (this.swiper.realIndex !== 10) {
            let index = this.activeIndex;
            let nextIndex = index + 2;

            if(nextIndex == this.itemsSlider.length){
                nextIndex = 0;
            }else if(nextIndex > this.itemsSlider.length){
                nextIndex = nextIndex - this.itemsSlider.length;
            }

            // if(index == 5){
            //     this.itemsSlider[index] = {
            //
            //         type: 'sound',
            //         img: 'collection/img_11.jpg',
            //         likes: 122,
            //         tokens: 29,
            //         reposts: 50,
            //         author: 'User Name',
            //         authorAvatar: 'woman-avatar.jpg',
            //         title: 'Lorem Ipsum 5555'+this.list,
            //         categories: [
            //             'vision',
            //             'sense'
            //         ],
            //         descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta dignissim. ',
            //         tags: [
            //             '#tagtag',
            //             '#tag',
            //             '#tagtag',
            //             '#tag',
            //             '#tag'
            //         ],
            //         time: '1 hour ago'
            //
            //     }
            // }

        this.list = this.list +2;


            console.log('next1',nextIndex);

            this.itemsSlider[nextIndex] = {

                type: 'sound',
                img: 'collection/img_11.jpg',
                likes: 122,
                tokens: 29,
                reposts: 50,
                author: 'User Name',
                authorAvatar: 'woman-avatar.jpg',
                title: 'Lorem Ipsum '+this.list,
                categories: [
                    'vision',
                    'sense'
                ],
                descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta dignissim. ',
                tags: [
                    '#tagtag',
                    '#tag',
                    '#tagtag',
                    '#tag',
                    '#tag'
                ],
                time: '1 hour ago'

            }

            ++this.activeIndex;
            if(this.activeIndex > this.itemsSlider.length){
                this.activeIndex = 0;
            }

            console.log(this.itemsSlider);
        //}
    }

    slidePrev() {
        if (this.swiper.activeIndex !== 5) {
            // this.itemsSlider[0] = {
            //
            //     type: 'sound',
            //     img: 'collection/img_11.jpg',
            //     likes: 122,
            //     tokens: 29,
            //     reposts: 50,
            //     author: 'User Name',
            //     authorAvatar: 'woman-avatar.jpg',
            //     title: 'Lorem Ipsum 000',
            //     categories: [
            //         'vision',
            //         'sense'
            //     ],
            //     descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta dignissim. ',
            //     tags: [
            //         '#tagtag',
            //         '#tag',
            //         '#tagtag',
            //         '#tag',
            //         '#tag'
            //     ],
            //     time: '1 hour ago'
            //
            // }
        }
        console.log('prev', this.swiper.realIndex)

    }

    changeVisibility(value) {
        this.visible = value;
        if (this.visible) {
            if (this.publicationId) {
                this.collectionListService.getPost(this.publicationId).subscribe(data => {
                    //console.log(data)
                    if (data.status) {
                        this.items[0] = data.result;
                        this.items[0].author = data.author;
                        //console.log(this.items[0])
                    }
                });
            }
        }
    }

    publicationIdChange(value) {
        this.publicationId = value;
    }

    goBack() {
        this.collectionListService.hide();
    }

    menuClose() {
        this.collectionListService.hide();
        this.collectionService.hide();
        this.menuService.hide();
    }

    getType(item){
        const publicationItem = new PublicationItem(item);
        return publicationItem.type;
    }

}
